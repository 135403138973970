import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

// CSSS
import './Input.css';

// Theme
import { colors } from 'theme/theme';

// Components
import Icon from 'components/Icon';
import Text from 'components/Text';

interface InputProps {
  title?: string,
  placeholder?: string,
  value?: string | number | undefined,
  defaultValue?: string | number | undefined,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  icon?: string,
  error?: boolean,
  errorTitle?: boolean,
  errorMessage?: string,
  type?: string,
  className?: string,
  name?: string,
  visible?: boolean
  required?: boolean,
  iconLeft?: boolean,
  readOnly?: boolean,
  maxLength?: number;
  ignoreLastPass?: boolean;
  tabIndex?: number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
}

const Container = styled.div<{ $readOnly?: boolean }>`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.dark};
  padding: 10px 12px;
  border-radius: 3px;
  border: solid 1px ${props => props.color};
  background-color: ${({ $readOnly }) => ($readOnly ? colors.disabledGrey : '')};
`;
const TextError = styled.text`
  font-size: 12px;
  font-family: Roboto-Regular;
  color: ${colors.red};
`;
const TextCharMsj = styled.text`
  font-size: 12px;
  font-family: Roboto-Regular;
  color: ${colors.dark};
`;

const Input = (({
  title = '',
  placeholder = '',
  value,
  onChange,
  icon,
  error = false,
  errorTitle = false,
  errorMessage,
  type = 'text',
  className = '',
  name,
  visible = false,
  required,
  iconLeft,
  readOnly = false,
  maxLength,
  ignoreLastPass,
  tabIndex,
  onKeyDown,
  defaultValue,
  ...rest
}: InputProps, _ref: React.Ref<HTMLInputElement>) => {
  const [focus, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const iconColor = useMemo(() => {
    if (error) {
      return colors.red;
    }
    if (focus) {
      return colors.dark;
    }
    return colors.grey;
  }, [focus, error]);

  const inputColor = useMemo(() => {
    if (error) {
      return colors.red;
    }
    if (focus) {
      return colors.dark;
    }
    return colors.mediumGrey;
  }, [focus, error]);

  return (
    <div className={`text-left ${className}`}>
      {(title || required) && (
        <Text size={14} color={colors.darkGrey} className="mb-2 d-flex">
          {title}
          {required && <Text size={14} color={colors.red} className="ml-1">*</Text>}
        </Text>
      )}
      <Container
        className={`d-flex ${iconLeft ? 'flex-row-reverse' : ''}`}
        color={inputColor}
        $readOnly={readOnly}
      >
        <input
          ref={_ref}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          type={showPassword ? 'text' : type}
          className="input"
          name={name}
          readOnly={readOnly}
          maxLength={maxLength}
          data-lpignore={ignoreLastPass ? 'true' : undefined}
          tabIndex={tabIndex}
          onKeyDown={onKeyDown}
          defaultValue={defaultValue}
          {...rest}
        />
        {
          visible && type === 'password'
            ? (
              <>
                <Icon
                  icon={showPassword ? 'las la-eye-slash' : 'las la-eye'}
                  size={18}
                  color={iconColor}
                  onClick={() => setShowPassword(s => (!s))}
                  className="cursor-pointer"
                />
              </>
            )
            : (
              <>
                {icon && (
                  <Icon
                    icon={error ? 'las la-exclamation-circle' : icon}
                    size={18}
                    color={iconColor}
                    className={`${iconLeft ? 'mr-2' : ''}`}
                  />
                )}
              </>
            )

        }

      </Container>
      <TextError>
        {error && errorMessage}
      </TextError>
      <TextCharMsj>
        {errorTitle && errorMessage}
      </TextCharMsj>
    </div>
  );
});

const FowardRefInput = React.forwardRef(Input);

export default React.memo(FowardRefInput);
