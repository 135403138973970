import styled from 'styled-components';

// Theme
import { theme } from 'theme/theme';

// Components
import Text from 'components/Text';
import StatusBox from 'components/StatusBox';
import PageSizeSelector from 'components/PageSizeSelector';
import DataTablePaginator from 'components/Paginator/DataTablePaginator';
import Column from 'components/Table/Column';
import TableRow from 'components/Table/TableRow';
import UserAvatar from 'components/UserAvatar';

export const MiddleContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const StyledTableRow = styled(TableRow)`
  padding: 13px 20px;
`;

export const StyledColumn = styled(Column)<{ $justifyContent?: string }>`
  justify-content: ${({ $justifyContent }) => $justifyContent};
  padding: 0 5px;

  &:first-of-type {
    padding: 0 5px 0px 0px;
  }

  &:last-of-type {
    padding: 0 0px 0px 5px;
  }
`;

export const TableText = styled(Text)<{ $uppercase?: boolean }>`
  ${({ $uppercase }) => ($uppercase ? `
    text-transform: uppercase;
  ` : '')}

  &.text-notes {
    max-width: 134px;
  }
`;

export const TableStatusBox = styled(StatusBox)``;

export const TableLogo = styled(UserAvatar)``;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;

  > i {
    font-size: 20px;
    color: ${theme.colors.grey};
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 55px;
  padding: 0 20px;
`;

export const StyledPageSizeSelector = styled(PageSizeSelector)``;

export const StyledDataTablePaginator = styled(DataTablePaginator)``;
