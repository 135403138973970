import React from 'react';
import classnames from 'classnames';

import { theme } from 'theme/theme';

// Styles
import {
  MainContainer,
} from './style';

export enum Status {
  STATUS_SENT = 'DELIVERED',
  STATUS_FAILED = 'ERROR',
  STATUS_PENDING = 'PENDING',
}

interface ThreeStatusBoxProps {
  className?: string;
  status: string;
  status1Name: string,
  status2Name: string,
  status3Name: string,
  status1Label: string; // sent
  status2Label: string; // failed
  status3Label: string; // pending
}

const TwoStatusBox = React.memo(({
  className,
  status,
  status1Name,
  status2Name,
  status3Name,
  status1Label,
  status2Label,
  status3Label,
}: ThreeStatusBoxProps) => {
  const statusName = {
    STATUS_1: status1Name,
    STATUS_2: status2Name,
    STATUS_3: status3Name,
  };

  const color = React.useMemo(() => {
    if (status.toUpperCase() === statusName.STATUS_1) {
      return theme.colors.green;
    }
    if (status.toUpperCase() === statusName.STATUS_2) {
      return theme.colors.grey;
    }
    return theme.colors.grey;
  }, [status]);

  return (
    <MainContainer
      className={classnames(className)}
      $color={color}
    >
      {status.toUpperCase() === statusName.STATUS_1 && status1Label}
      {status.toUpperCase() === statusName.STATUS_2 && status2Label}
      {status.toUpperCase() === statusName.STATUS_3 && status3Label}
    </MainContainer>
  );
});

export default TwoStatusBox;
