import styled from 'styled-components';

// Components
import Text from 'components/Text';

// Theme
import { theme } from 'theme/theme';

export const MainContainer = styled.div`
  
`;

export const Root = styled.div`
  outline: none !important;
  cursor: pointer;
`;

export const Input = styled.input`
  outline: none !important;
`;

export const DropBox = styled.div<{ $dragActive: boolean, $withContent: boolean }>`
  width: 100%;
  height: 137px;
  border: dashed 1px ${theme.colors.lightGrey};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.5s, background-color 0.5;

  ${({ $dragActive }) => ($dragActive ? `
    border-color: ${theme.colors.dark};
    border-style: solid;

    .placeholder,
    .styled-icon  {
      color: ${theme.colors.dark};
    }
  ` : '')}

  ${({ $withContent }) => ($withContent ? `
    background-color: ${theme.colors.gallery};
    border-color: ${theme.colors.lightGrey};
    border-style: solid !important;
  ` : `
    background-color: transparent;
  `)}
`;

export const EditImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Placeholder = styled(Text)`
  font-family: 'Lato';
  transition: color 0.5s;
`;

export const StyledIcon = styled.i`
  transform: translateY(-1px);
  color: ${theme.colors.grey};
  transition: color 0.5s;
`;

export const PlaceholderDivider = styled.div`
  width: 5px;
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 6px;
`;

export const Constraints = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ConstraintLabel = styled(Text)``;

export const ConstraintFileSizeLabel = styled(Text)`
  margin-top: 4px;
`;

export const RemoveButton = styled.div<{ $show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  transition: opacity 0.5s;
  
  ${({ $show }) => ($show ? `
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  ` : `
    opacity: 0;
    pointer-events: none;
  `)}
`;

export const StyledRemoveIcon = styled.i`
  font-size: 18px;
  color: ${theme.colors.grey};
`;

export const RemoveLabel = styled(Text)`
  margin-left: 5px;
  line-height: 1;
`;
