import { gql } from '@apollo/client';

import {
  salesRepsFragment,
} from 'data/fragments';

export const ADD_SALES_REP = gql`
  mutation insert_sales_reps_one($object: sales_reps_insert_input!) {
    insert_sales_reps_one(object: $object) {
      id
      ...salesRep
    }
  }
  ${salesRepsFragment}
`;

export const UPDATE_SALES_REP = gql`
  mutation update_sales_rep($salesRepId: uuid!,$input: sales_reps_set_input!) {
    update_sales_reps_by_pk(pk_columns: { id: $salesRepId }, _set: $input) {
      id
      ...salesRep
    }
  }
  ${salesRepsFragment}
`;

export const DELETE_SALES_REP = gql`
  mutation delete_sales_reps($salesRepId: uuid!,$deleted_at: timestamptz!) {
    update_sales_reps_by_pk(
      pk_columns: { id: $salesRepId }, 
      _set: {deleted_at: $deleted_at}
    ) {
      id
      ...salesRep
    }
  }
  ${salesRepsFragment}
`;
