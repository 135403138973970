/* eslint-disable no-console */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ApolloCache } from '@apollo/client';
import { getSchoolById } from 'data/school/school.query';

// Add SchoolNote
export function addSchoolNoteToGetSchoolById(
  cache: ApolloCache<CreateSchoolNoteResponse>,
  schoolNote: SchoolNote,
  schoolId: string,
) {
  try {
    const base = {
      query: getSchoolById,
      variables: {
        schoolId,
      },
    };
    const r = cache.readQuery<GetSchoolByIdResponse, GetSchoolByIdVariables>(base);
    const newData = [...(r?.schools_by_pk?.schools_notes ?? []), schoolNote];
    const newSchoolsByPk = {
      ...r?.schools_by_pk,
      schools_notes: newData,
    };
    cache.writeQuery({
      ...base,
      data: {
        schools_by_pk: newSchoolsByPk,
      },
    });
  } catch (e) {
    console.error(e.message);
  }
}
