import { gql } from '@apollo/client';

// Fragments

export const schoolDashboardElementFragment = gql`
  fragment schoolDashboardElement on schools_dashboard_elements {
    id
    type
    title
    component
    filename
    url
    school_id
    created_at
    updated_at
    schools_dashboard_elements_orders{
      order
    }
  }
`;
