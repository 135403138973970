import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

// Components
import { ButtonTypes } from 'components/Button';

// Styles
import {
  MainContainer,
  PrevButton,
  NextButton,
  PrevIcon,
  NextIcon,
} from './style';

interface StepButtonsProps {
  className?: string;
  showPrev?: boolean;
  prevDisabled?: boolean;
  prevType?: ButtonType;
  prevLabel?: string;
  showPrevIcon?: boolean,
  showNext?: boolean;
  nextDisabled?: boolean;
  nextType?: ButtonType;
  nextLabel?: string;
  showNextIcon?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  editMode?: boolean;
}

const StepButtons = React.memo(({
  className,
  showPrev,
  prevDisabled,
  prevType = ButtonTypes.SECONDARY,
  prevLabel,
  showPrevIcon = true,
  showNext,
  nextDisabled,
  nextType = ButtonTypes.PRIMARY,
  nextLabel,
  showNextIcon = true,
  onPrev,
  onNext,
  editMode,
}: StepButtonsProps) => {
  // Hooks
  const { t } = useTranslation();

  return (
    <MainContainer
      className={classnames(className)}
    >
      {/* Prev Button */}
      {
        (showPrev && onPrev) && (
          <PrevButton
            className="prev-button"
            type={prevType}
            disabled={prevDisabled}
            onClick={onPrev}
          >
            {
              showPrevIcon && (
                <PrevIcon
                  className={editMode ? 'las la-times' : 'las la-angle-left'}
                />
              )
            }
            {editMode ? t('STEP_BUTTONS.CANCEL_LABEL')
              : prevLabel || t('STEP_BUTTONS.PREV_LABEL')}
          </PrevButton>
        )
      }

      {/* Next Button */}
      {
        (showNext && onNext) && (
          <NextButton
            className="next-button"
            type={nextType}
            disabled={nextDisabled}
            onClick={onNext}
          >
            {editMode ? t('STEP_BUTTONS.SAVE_LABEL')
              : nextLabel || t('STEP_BUTTONS.NEXT_LABEL')}
            {
              showNextIcon && (
                <NextIcon
                  className={editMode ? 'las la-check' : 'las la-angle-right'}
                />
              )
            }
          </NextButton>
        )
      }
    </MainContainer>
  );
});

export default StepButtons;
