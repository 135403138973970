/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';
import moment from 'moment';

// Data
import { getSchoolById } from 'data/school';
import {
  createStudent,
  updateStudent,
  getStudentWithProvidersById,
} from 'data/student';
import states from 'data/static/states.json';
import provinces from 'data/static/provinces.json';
import genders from 'data/static/genders.json';
import {
  createStudentProviders,
  updateStudentProvider,
  deleteSoftStudentProvider,
} from 'data/studentsProviders/studentsProviders.mutation';

// Components
import PageLayout from 'components/PageLayout';
import Card from 'components/Card';

// Theme
import { theme } from 'theme/theme';

// Context
import useStudentContext from 'hooks/useStudentContext';
import {
  StudentContext,
  useProvideStudent,
} from 'context/StudentContext';

// Utils
import { Toaster } from 'utils/toaster';

// Cards
import StudentInfo from './StudentInfo';
import ParentInfo from './ParentInfo';
import InsuranceInfo from './InsuranceInfo';

// Styled Components
import {
  Container,
  Title,
  StyledButton,
} from './style';

const NewStudentPage = React.memo(() => {
  // Hooks
  const { t } = useTranslation();
  const match = useRouteMatch<{ id?: string, studentId?: string }>();
  const history = useHistory();
  const { params } = match;
  // NOTE: If we have an studentId then we are editing, else we are creating a new student
  const { id: schoolId, studentId } = params;
  const {
    handleSubmit,
    reset,
  } = useStudentContext();
  const [providersToDelete, setProvidersToDelete] = React.useState<StudentProviderInput[]>([]);

  type StateKey = keyof typeof states;
  type ProvinceKey = keyof typeof provinces;
  type GenderKey = keyof typeof genders;

  const stateOptions: OptionsType<OptionType> = Object.keys(states)
    // @ts-ignore
    .map((key: StateKey) => ({ value: key, label: states[key] }));

  const provinceOptions: OptionsType<OptionType> = Object.keys(provinces)
    // @ts-ignore
    .map((key: ProvinceKey) => ({ value: key, label: provinces[key] }));

  const genderOptions: OptionsType<OptionType> = Object.keys(genders)
    // @ts-ignore
    .map((key: GenderKey) => ({ value: key, label: genders[key] }));

  // Queries
  const [callGetStudent, {
    loading: loadingGetStudent,
    data: dataGetStudent,
    refetch: refetchGetStudent,
  }] = useLazyQuery<GetStudentWithProvidersResponse, GetStudentVariables>(getStudentWithProvidersById);

  const {
    data: dataGetSchoolById,
  } = useQuery<GetSchoolByIdResponse, GetSchoolByIdVariables>(getSchoolById, {
    variables: {
      schoolId: schoolId || '',
    },
    skip: !schoolId,
  });
  // Effects
  const school = React.useMemo(() => {
    if (dataGetSchoolById && dataGetSchoolById.schools_by_pk) {
      return dataGetSchoolById.schools_by_pk;
    }
    return undefined;
  }, [dataGetSchoolById]);

  React.useEffect(() => {
    if (studentId) {
      callGetStudent({
        variables: { studentId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  React.useEffect(() => {
    if (dataGetStudent
      && dataGetStudent.students_by_pk
      && !loadingGetStudent) {
      const studentStateProv = dataGetStudent.students_by_pk.country === 'Canada'
        ? provinceOptions.find(prov => prov.value === dataGetStudent.students_by_pk.state_prov
          || prov.label === dataGetStudent.students_by_pk.state_prov)
        : stateOptions.find(state => state.value === dataGetStudent.students_by_pk.state_prov
          || state.label === dataGetStudent.students_by_pk.state_prov);

      const parentInfo = dataGetStudent.students_by_pk.parents_info;
      const parentStateProv = parentInfo?.canadianAddress
        || parentInfo?.country === 'Canada'
        ? provinceOptions.find(prov => prov.value === parentInfo?.province?.value
          || prov.label === parentInfo?.state_prov)
        : stateOptions.find(state => state.value === parentInfo?.state?.value
          || state.label === parentInfo?.state_prov);

      const studProviders = dataGetStudent.students_by_pk.students_providers.length > 0
        ? dataGetStudent.students_by_pk.students_providers.map(provider => ({
          insuranceCompany: provider.provider
            ? {
              value: provider.provider.id,
              label: provider.provider.name,
            }
            : {
              value: 'OTHER',
              label: t('INSURANCE_INFORMATION_MODAL.OTHER'),
            },
          providerName: provider.provider_name,
          insuranceMemberId: provider.insurance_member_id,
          insuranceGroupId: provider.insurance_group_id,
          phone: provider.phone,
          _id: provider.id,
          primary: provider.primary,
          status: provider.status,
        }))
        : [{
          insuranceCompany: undefined,
          providerName: '',
          insuranceGroupId: '',
          insuranceMemberId: '',
          phone: '',
          _id: undefined,
          primary: null,
          status: null,
        }];
      reset({
        student: {
          firstName: dataGetStudent.students_by_pk.first_name,
          lastName: dataGetStudent.students_by_pk.last_name,
          academicId: dataGetStudent.students_by_pk.academic_id,
          dob: new Date(moment(dataGetStudent.students_by_pk.date_of_birth).format('MMMM DD, YYYY')),
          gender: dataGetStudent.students_by_pk.gender === 'X'
            ? genderOptions.find(option => option.label === 'Neutral')
            : genderOptions.find(option => option.label === dataGetStudent.students_by_pk.gender),
          address1: dataGetStudent.students_by_pk.address_1 || '',
          address2: dataGetStudent.students_by_pk.address_2 || '',
          city: dataGetStudent.students_by_pk.city || '',
          state: dataGetStudent.students_by_pk.country !== 'Canada'
            ? studentStateProv
            : null,
          province: dataGetStudent.students_by_pk.country === 'Canada'
            ? studentStateProv
            : null,
          zip: dataGetStudent.students_by_pk.postal_code || '',
          email: dataGetStudent.students_by_pk.email_address || '',
          phone: dataGetStudent.students_by_pk.primary_phone || '',
          canadianAddress: dataGetStudent.students_by_pk.country === 'Canada',
        },
        parent: {
          firstName: parentInfo?.first_name || parentInfo?.firstName,
          lastName: parentInfo?.last_name || parentInfo?.lastName,
          address1: parentInfo?.address_1 || parentInfo?.address1,
          address2: parentInfo?.address_2 || parentInfo?.address2,
          city: parentInfo?.city,
          state: !!!parentInfo?.canadianAddress // eslint-disable-line no-extra-boolean-cast
            || parentInfo?.country === 'United States'
            ? parentStateProv
            : null,
          province: parentInfo?.canadianAddress
            || parentInfo?.country === 'Canada'
            ? parentStateProv
            : null,
          zip: parentInfo?.postal_code || parentInfo?.zip,
          employerName: parentInfo?.employer_name || parentInfo?.employerName,
          employerPhone: parentInfo?.employer_phone || parentInfo?.employerPhone,
          canadianAddress: parentInfo?.canadianAddress
            || parentInfo?.country === 'Canada',
        },
        providers: studProviders,
      });
    }
  }, [loadingGetStudent, dataGetStudent]);

  // Mutations
  const [callCreateStudent] = useMutation<CreateStudentResponse, CreateStudentVariables>(createStudent, {
    onError: _error => {
      console.error('ERROR [createStudent]', _error);
      Toaster('error', t('TOASTER.ERROR.STUDENT_CREATION_FAILED'));
    },
    onCompleted: _data => {
      Toaster('success', t('TOASTER.SUCCESS.STUDENT_CREATED'));
      history.push(`/dashboard/${schoolId}/students/${_data.insert_students_one.id}`);
    },
  });

  const [
    callUpdateStudentProvider, { loading: loadingUpdateStundetProvider },
  ] = useMutation<UpdateStudentProviderResponse, UpdateStudentProviderVariables>(updateStudentProvider, {
    onError: _error => {
      console.error('ERROR [UpdateStudentProvider]', _error);
      Toaster('error', t('TOASTER.ERROR.STUDENT_CREATION_FAILED'));
    },
  });

  const [
    callCreateStudentProvider,
  ] = useMutation<CreateStudentProvidersResponse, CreateStudentProvidersVariables>(createStudentProviders, {
    onError: _error => {
      console.error('ERROR [createStudentProviders]', _error);
      Toaster('error', t('TOASTER.ERROR.STUDENT_CREATION_FAILED'));
    },
  });

  const [callUpdateStudent] = useMutation<UpdateStudentResponse, UpdateStudentVariables>(updateStudent, {
    onError: _error => {
      // TODO:
      console.error('ERROR [updateStudent]', _error);
    },
  });

  const [
    callDeleteStudentProvider,
  ] = useMutation<DeleteStudentProviderResponse, DeleteStudentProviderVariables>(deleteSoftStudentProvider, {
    onError: _error => {
      console.error('ERROR [deleteStudent]', _error);
      Toaster('error', t('TOASTER.ERROR.DELETION_FAILED'));
    },
  });

  // Handlers
  const handleCancel = React.useCallback(() => {
    history.push(`/dashboard/${schoolId}/students`);
  }, [history, schoolId]);

  const handleAdd = React.useCallback(async (data: CommonJSON) => {
    if (data) {
      if (studentId) {
        // Update student if needed
        const variables: UpdateStudentVariables = {
          studentId,
          input: {
            first_name: data.student.firstName,
            last_name: data.student.lastName,
            academic_id: data.student.academicId,
            gender: data.student.gender
              ? data.student.gender.value === 'N'
                ? 'X'
                : data.student.gender.label
              : 'X',
            date_of_birth: data.student.dob,
            email_address: data.student.email,
            primary_phone: data.student.phone,
            address_1: data.student.address1,
            address_2: data.student.address2,
            city: data.student.city,
            state_prov: data.student.canadianAddress
              ? (data.student.province ? data.student.province.label : undefined)
              : (data.student.state ? data.student.state.label : undefined),
            country: data.student.canadianAddress ? 'Canada' : 'United States',
            postal_code: data.student.zip,
            parents_info: {
              first_name: data.parent.firstName,
              last_name: data.parent.lastName,
              address_1: data.parent.address1,
              address_2: data.parent.address2,
              city: data.parent.city,
              postal_code: data.parent.zip,
              employer_name: data.parent.employerName,
              employer_phone: data.parent.employerPhone,
              country: data.parent.canadianAddress ? 'Canada' : 'United States',
              state_prov: data.parent.canadianAddress
                ? (data.parent.province ? data.parent.province.label : undefined)
                : (data.parent.state ? data.parent.state.label : undefined),
            },
            updated_at: new Date(),
          },
        };
        const studentProviders: any[] = [];
        if (data.providers && data.providers.length > 0) {
          data.providers.forEach((provider: any, index: number) => {
            const isOther = provider.insuranceCompany && provider.insuranceCompany.value === 'OTHER';
            if (provider.insuranceCompany && provider.insuranceCompany.value) {
              const previousValue = dataGetStudent?.students_by_pk
                ? dataGetStudent?.students_by_pk.students_providers.find(item => item.id === provider._id)
                : null;

              const verifyValuesUpdated = !!(previousValue
                  && (previousValue.insurance_member_id !== provider.insuranceMemberId
                    || previousValue.is_other_provider !== isOther
                    || (!isOther && previousValue.insurance_provider_id !== provider.insuranceCompany.value)));
              studentProviders.push({
                // eslint-disable-next-line @typescript-eslint/dot-notation
                id: provider['_id'],
                is_other_provider: isOther,
                provider_name: isOther ? provider.providerName : '',
                phone: provider.phone,
                insurance_member_id: provider.insuranceMemberId,
                insurance_group_id: provider.insuranceGroupId,
                insurance_provider_id: !isOther ? provider.insuranceCompany.value : null,
                school_id: schoolId,
                updated_at: new Date(),
                primary: index === 0 ? true : null,
                status: verifyValuesUpdated ? null : provider.status,
              });
            }
          });
        }
        const requestArray: any[] = [callUpdateStudent({ variables })];
        const providersToUpdate = [];
        const providersToCreate: students_providers_insert_input[] = [];

        for (let index = 0; index < studentProviders.length; index += 1) {
          const p = studentProviders[index];
          if (p.id) {
            providersToUpdate.push(p);
          } else {
            delete p.id;
            providersToCreate.push({
              ...p,
              updated_at: new Date(),
              created_at: new Date(),
              student_id: studentId,
            });
          }
        }
        providersToUpdate.forEach(element => {
          requestArray.push(
            callUpdateStudentProvider({
              variables: {
                studentProviderId: element.id,
                input: element,
              },
            }),
          );
        });
        if (providersToCreate.length > 0) {
          requestArray.push(callCreateStudentProvider({
            variables: {
              objects: providersToCreate,
            },
          }));
        }
        providersToDelete.forEach(prov => {
          requestArray.push(
            callDeleteStudentProvider({
              variables: {
                id: prov._id,
              },
            }),
          );
        });
        setProvidersToDelete([]);
        Promise.all(requestArray).then(() => {
          Toaster('success', t('TOASTER.SUCCESS.STUDENT_UPDATED'));
          if (refetchGetStudent) {
            refetchGetStudent();
          }
        });
      } else {
        const studentProviders: any[] = [];
        if (data.providers && data.providers.length > 0) {
          data.providers.forEach((provider: any, index: number) => {
            const isOther = provider.insuranceCompany && provider.insuranceCompany.value === 'OTHER';
            if (provider.insuranceCompany && provider.insuranceCompany.label) {
              studentProviders.push({
                is_other_provider: isOther,
                provider_name: isOther ? provider.providerName : '',
                phone: provider.phone,
                insurance_member_id: provider.insuranceMemberId,
                insurance_group_id: provider.insuranceGroupId,
                insurance_provider_id: !isOther ? provider.insuranceCompany.value : null,
                school_id: schoolId,
                created_at: new Date(),
                updated_at: new Date(),
                primary: index === 0 ? true : null,
              });
            }
          });
        }
        const variables: CreateStudentVariables = {
          object: {
            school_id: schoolId || '',
            first_name: data.student.firstName,
            last_name: data.student.lastName,
            academic_id: data.student.academicId,
            gender: data.student.gender
              ? data.student.gender.value === 'N'
                ? 'X'
                : data.student.gender.label
              : 'X',
            date_of_birth: data.student.dob,
            email_address: data.student.email,
            primary_phone: data.student.phone,
            address_1: data.student.address1,
            address_2: data.student.address2,
            city: data.student.city,
            state_prov: data.student.canadianAddress
              ? (data.student.province ? data.student.province.label : undefined)
              : (data.student.state ? data.student.state.label : undefined),
            country: data.student.canadianAddress ? 'Canada' : 'United States',
            postal_code: data.student.zip,
            parents_info: {
              first_name: data.parent.firstName,
              last_name: data.parent.lastName,
              address_1: data.parent.address1,
              address_2: data.parent.address2,
              city: data.parent.city,
              postal_code: data.parent.zip,
              employer_name: data.parent.employerName,
              employer_phone: data.parent.employerPhone,
              country: data.parent.canadianAddress ? 'Canada' : 'United States',
              state_prov: data.parent.canadianAddress
                ? (data.parent.province ? data.parent.province.label : undefined)
                : (data.parent.state ? data.parent.state.label : undefined),
            },
            created_at: new Date(),
            updated_at: new Date(),
            students_providers: studentProviders.length > 0
              ? {
                data: studentProviders,
              } : undefined,
          },
        };
        callCreateStudent({ variables });
      }
    }
  }, [callCreateStudent, callUpdateStudent, params, handleSubmit, dataGetStudent, providersToDelete]);

  return (
    <PageLayout
      crumbs={[
        {
          title: t('NEW_STUDENT_PAGE.BREADCRUMBS.DASHBOARD'),
          to: '/dashboard',
          state: {
            school: {
              value: schoolId,
            },
          },
        },
        {
          title: t('NEW_STUDENT_PAGE.BREADCRUMBS.MANAGE_STUDENTS'),
          to: `/dashboard/${schoolId}/students`,
        },
        {
          title: studentId ? t('NEW_STUDENT_PAGE.BREADCRUMBS.EDIT_STUDENT')
            : t('NEW_STUDENT_PAGE.BREADCRUMBS.NEW_STUDENT'),
          to: '',
        },
      ]}
    >

      <Container>
        <Card
          padding="25px 30px 15px 15px;"
        >
          {/* Title */}
          <Title
            size={18}
            fontFamily={theme.fontFamilies.roboto.bold}
            color={theme.colors.dark}
            weight={theme.fontWeights.bold}
            className="mb-4 ml-3"
          >
            {t('NEW_STUDENT_PAGE.STUDENT_INFORMATION')}
          </Title>
          <StudentInfo />
        </Card>
        <Card
          padding="25px 30px 15px 15px;"
        >
          {/* Title */}
          <Title
            size={18}
            fontFamily={theme.fontFamilies.roboto.bold}
            color={theme.colors.dark}
            weight={theme.fontWeights.bold}
            className="mb-4 ml-3"
          >
            {t('NEW_STUDENT_PAGE.PARENT_INFORMATION')}
          </Title>
          <ParentInfo />
        </Card>
        <Card
          padding="25px 30px 15px 15px;"
        >
          {/* Title */}
          <Title
            size={18}
            fontFamily={theme.fontFamilies.roboto.bold}
            color={theme.colors.dark}
            weight={theme.fontWeights.bold}
            className="mb-4 ml-3"
          >
            {t('NEW_STUDENT_PAGE.INSURANCE_INFORMATION')}
          </Title>
          <InsuranceInfo
            school={school}
            student={dataGetStudent ? dataGetStudent.students_by_pk : undefined}
            setProvidersToDelete={setProvidersToDelete}
            refetch={refetchGetStudent}
          />
          <div className="d-flex justify-content-end mt-4">
            <StyledButton
              type="SECONDARY"
              onClick={handleCancel}
            >
              {t('NEW_STUDENT_PAGE.CANCEL')}
            </StyledButton>
            <StyledButton
              type="PRIMARY"
              onClick={handleSubmit(handleAdd)}
              className="ml-3"
              loading={loadingUpdateStundetProvider}
            >
              {studentId ? t('NEW_STUDENT_PAGE.UPDATE') : t('NEW_STUDENT_PAGE.SUBMIT')}
            </StyledButton>
          </div>
        </Card>
      </Container>
    </PageLayout>
  );
});

export default () => {
  const studentCtx = useProvideStudent();
  return (
    <StudentContext.Provider value={studentCtx}>
      <NewStudentPage />
    </StudentContext.Provider>
  );
};
