import styled from 'styled-components';

// Components
import { CustomInput } from 'reactstrap';
import Text from 'components/Text';

// Theme
import { theme } from 'theme/theme';

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
`;

export const StyledSwitch = styled(CustomInput)`
  &.custom-switch {
    padding-left: 40px;
    min-height: 22px;
    height: 22px;
    z-index: 0;

    .custom-control-input {
      &:checked {
        ~ .custom-control-label {
          &:before {
            border-color: ${theme.colors.green} !important;
            background-color: ${theme.colors.green} !important;
          }

          &:after {
            left: calc(-40px + 9px);
            background-color: ${theme.colors.white} !important;
          }
        }
      }
    }

    .custom-control-label {
      cursor: pointer;

      &:before {
        top: 0;
        left: -40px;
        box-shadow: none !important;
        outline: none !important;
        width: 40px;
        height: 22px;
        border-radius: 12px;
        background-color: ${theme.colors.gallery} !important;
        border-color: ${theme.colors.gallery} !important;
      }

      &:after {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        top: 3px;
        left: calc(-40px + 4px);
        background-color: ${theme.colors.gray50} !important;
      }
    }
  }
`;

export const StyledLabel = styled(Text)`
  margin-left: 9px;
`;
