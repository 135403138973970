import styled from 'styled-components';

// Components
import Button from 'components/Button';
import Text from 'components/Text';
import { theme } from 'theme/theme';

export const Container = styled.div`
`;

export const Title = styled(Text)``;

export const EditStudentButton = styled(Button)<{ $isMobile: boolean}>`
${({ $isMobile }) => ($isMobile ? 'padding: 12px 12px;'
    : 'padding: 0px 12px;'
  )}
  line-height: 36px;
`;

export const Label = styled(Text)`
  color: ${theme.colors.grey}
`;

export const Value = styled(Text)``;

export const EditStudentButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 0px;
  display: flex;
`;

export const TitleContainer = styled.div`
  position: relative;
`;

export const InfoContainer = styled.div`
  padding: 42px 36px 50px;
  min-height: 50px;
`;

export const OptionalLabelContainer = styled.div`
  background-color: ${theme.colors.disabledGrey};
  padding: 7px 12px;
  margin-bottom: 40px;
`;
