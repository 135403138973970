import styled from 'styled-components';
import { theme } from 'theme/theme';

// Components
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import InsuranceProviderStatus from 'components/InsuranceProviderStatus';

export const GreyDiv = styled.div`
  display:flex;
  margin: 0px 1rem;
  background-color: ${theme.colors.softGrey};
  width:100%;
`;

export const Container = styled.div`
  display:flex;
  width:100%;
  padding: 0px .5rem;
  justify-content: space-between;
`;

export const MainContainer = styled.div<{ $isMobile: boolean}>`
  width: 100%;
  padding: 0px 15px 0px;
  display:flex;
`;
export const PrimaryContainer = styled.div<{ $isMobile: boolean}>`
  width: 100%;
  padding: 24px 15px 20px;
  display: grid;
  grid-template-columns: ${({ $isMobile }) => ($isMobile ? '1fr' : '1fr 1fr 1fr')};
  grid-template-rows: ${({ $isMobile }) => ($isMobile ? '1fr 1fr 1fr' : '1fr 1fr')};
  gap: ${({ $isMobile }) => ($isMobile ? '15px 40px' : '15px 40px')};
  grid-template-areas:
    "area-insurance-company area-other-insurance-name area-nothing"
    "area-insurance-member-id area-insurance-group-id area-phone";
`;

export const GridItem = styled.div`
  width: 100%;

  &.area-insurance-company {
    grid-area: area-insurance-company;
  }

  &.area-other-insurance-name { 
    grid-area: area-other-insurance-name;
  }

  &.area-insurance-member-id { 
    grid-area: area-insurance-member-id;
  }

  &.area-insurance-group-id { 
    grid-area: area-insurance-group-id;
  }

  &.area-nothing { 
    grid-area: area-nothing;
  }
`;

export const TextInput = styled(Input)`
  width: 100%;
  margin-top: 20px;
  
  input {
    background-image: none !important;
  }

  &:first-of-type {
    margin-top: 0px;
  }
`;

export const StyledDropdown = styled(Dropdown)``;

export const StyledButton = styled(Button)`
  margin-left: 15px;
  width: 128px;
  height: 46px;
`;

export const StyledDeleteButton = styled(Button)`
  margin-left: 15px;
  width: 46px;
  height: 46px;
`;

export const BottomContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const AddButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: flex-end;
`;

export const AddButton = styled(Button)<{ $isMobile: boolean}>`
  ${({ $isMobile }) => ($isMobile ? `
    width: 40px;
    padding: 12px 12px;
  `
    : ` width: 100%;
    padding: 0px 12px;
  `
  )}
  max-width: 95px;
  line-height: 40px;
  margin-left: 2rem;
`;

export const StyledAddIcon = styled.i`
  margin-right: 10px;
  font-size: 16px;
`;

export const StyledTrashIcon = styled.i`
  font-size: 20px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const StyledInsuranceProviderStatus = styled(InsuranceProviderStatus)`
  width: 116px !important;
`;
