/* eslint-disable no-console */
import React from 'react';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

// Components
import Text from 'components/Text';

// Styles
import { theme } from 'theme/theme';
import ImagesHelper from 'utils/fileHelper';
import { Toaster } from 'utils/toaster';
import {
  MainContainer,
  Root,
  Input,
  DropBox,
  Placeholder,
  PlaceholderDivider,
  StyledIcon,
  ConstraintLabel,
  ConstraintFileSizeLabel,
  BottomContainer,
  Constraints,
  RemoveButton,
  StyledRemoveIcon,
  RemoveLabel,
  EditImage,
} from './style';

export interface ImageDropzoneSizeDimensionLimit {
  width: number;
  height: number;
}

export interface EditImage {
  file?: File;
  url?: string;
}
export interface ImageDropZoneProps {
  className?: string;
  title?: string;
  required?: string;
  placeholder?: string;
  icon?: string;
  mimeTypes?: string[];
  sizeDimensionsLimit?: ImageDropzoneSizeDimensionLimit;
  constraintsLabel?: string;
  constraintsFileSizeLabel?: string;
  removeLabel?: string;
  value: EditImage;
  onChange: (newEditImage?: EditImage) => void;
}

const imagesMimeTypes = [
  'image/jpeg', // JPEG
  'image/png', // PNG
  'image/gif', // GIF
  'image/svg+xml', // SVG
];

const ImageDropZone = ({
  className,
  title,
  required,
  placeholder,
  icon,
  mimeTypes = imagesMimeTypes,
  sizeDimensionsLimit,
  constraintsLabel,
  constraintsFileSizeLabel,
  removeLabel,
  value,
  onChange,
}: ImageDropZoneProps) => {
  // States
  // const [editImage, setEditImage] = React.useState<EditImage>({ file: undefined, blobUrl: undefined });

  const { t } = useTranslation();
  // Handlers
  const handleRemove = React.useCallback(() => {
    onChange({ file: undefined, url: undefined });
  }, [
    onChange,
  ]);

  // DND
  const handleOnDropRejected = React.useCallback(() => {
    const errorMessage = `${t('TOASTER.ERROR.FILE_TOO_BIG')} ${ImagesHelper.SCHOOL_LOGO_FILE_MAX_FILESIZE_MB}MB`;
    Toaster('error', errorMessage);
  }, []);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length === 1) {
      const newFile = acceptedFiles[0];

      if (sizeDimensionsLimit) {
        ImagesHelper.checkFileDimensions(newFile, sizeDimensionsLimit.width, sizeDimensionsLimit.height)
          .then(isValid => {
            if (isValid) {
              const imageUrl = URL.createObjectURL(newFile);
              onChange({ file: newFile, url: imageUrl });
            } else {
              Toaster('error', t('TOASTER.ERROR.IMAGE_DIMENSIONS'));
            }
          });
      } else {
        const imageUrl = URL.createObjectURL(newFile);
        onChange({ file: newFile, url: imageUrl });
      }
    }
  }, [
    sizeDimensionsLimit,
    onChange,
  ]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: !!value.url,
    noDrag: !!value.url,
    multiple: false,
    accept: mimeTypes,
    maxSize: ImagesHelper.SCHOOL_LOGO__FILE_MAX_FILESIZE_BYTES, // 10mb
    onDropRejected: handleOnDropRejected,
  });

  return (
    <MainContainer
      className={classnames(className)}
    >
      {(title || required) && (
      <Text
        size={14}
        color={theme.colors.darkGrey}
        className="mb-2 d-flex"
      >
        {title}
        {
          required && (
            <Text
              size={14}
              color={theme.colors.red}
              className="ml-1"
            >
              *
            </Text>
          )
        }
      </Text>
      )}

      {/* Root */}
      <Root
        {...getRootProps()}
      >
        <Input
          {...getInputProps()}
        />

        {/* Drop Box */}
        <DropBox
          $dragActive={isDragActive}
          $withContent={!!(value.url)}
        >
          {
            (value.url) ? (
              <EditImage
                src={value.url}
              />
            ) : (
              <>
                {
                  (placeholder || icon) && (
                    <>
                      {
                        icon && (
                          <StyledIcon
                            className={classnames('styled-icon', icon)}
                          />
                        )
                      }
                      {
                        icon && placeholder && (
                          <PlaceholderDivider />
                        )
                      }
                      {
                        placeholder && (
                          <Placeholder
                            className="placeholder"
                            size={12}
                            weight={theme.fontWeights.black}
                            color={theme.colors.darkGrey}
                          >
                            {placeholder}
                          </Placeholder>
                        )
                      }
                    </>
                  )
                }
              </>
            )
          }
        </DropBox>
      </Root>

      {/* Bottom Container */}
      <BottomContainer>
        {/* Constraints */}
        <Constraints>
          {
            constraintsLabel && (
              <ConstraintLabel
                size={14}
                weight={theme.fontWeights.normal}
                color={theme.colors.darkGrey}
              >
                {constraintsLabel}
              </ConstraintLabel>
            )
          }
          {
            constraintsFileSizeLabel && (
              <ConstraintFileSizeLabel
                size={12}
                weight={theme.fontWeights.normal}
                color={theme.colors.grey}
              >
                {`${constraintsFileSizeLabel}: ${ImagesHelper.SCHOOL_LOGO_FILE_MAX_FILESIZE_MB}MB`}
              </ConstraintFileSizeLabel>
            )
          }
        </Constraints>

        {/* Remove Button */}
        <RemoveButton
          $show={!!value.url}
          onClick={handleRemove}
        >
          <StyledRemoveIcon
            className="las la-trash-alt"
          />
          {
            removeLabel && (
              <RemoveLabel
                size={12}
                weight={theme.fontWeights.black}
                fontFamily={theme.fontFamilies.roboto.bold}
                color={theme.colors.grey}
              >
                {removeLabel}
              </RemoveLabel>
            )
          }
        </RemoveButton>
      </BottomContainer>

    </MainContainer>
  );
};

export default React.memo(ImageDropZone);
