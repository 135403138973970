/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-console */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Hooks
import { useResponsive } from 'hooks/useResponsive';
import useStateContext from 'hooks/useStateContext';
import { useModal } from 'hooks/useModal';

// Theme
import { theme } from 'theme/theme';

// Modals
import StudentInfoModal from 'modals/StudentInfoModal';

// Styles
import Icon from 'components/Icon';
import {
  Label,
  Value,
  Title,
  EditStudentButton,
  EditStudentButtonContainer,
  TitleContainer,
  InfoContainer,
} from './style';

const StudentView = React.memo(() => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const {
    data,
    setData,
  } = useStateContext();
  // Modals
  const {
    isOpen: isOpenStudentInfoModal,
    toggleModal: toggleStudentInfoModal,
    openModal: openStudentInfoModal,
  } = useModal();

  const handleChangeStudent = useCallback((student: CommonJSON) => {
    setData((d: ReportNewClaimWizard) => ({
      ...d,
      fromStateViewStudent: {
        student: {
          ...d.fromStateViewStudent.student,
          ...student,
        },
        studentUpdated: true,
      },
    }));
  }, []);

  if (data?.fromStateViewStudent) {
    return (
      <div>
        <StudentInfoModal
          isOpen={isOpenStudentInfoModal}
          toggle={toggleStudentInfoModal}
          student={data?.fromStateViewStudent.student}
          onUpdate={handleChangeStudent}
        />
        <TitleContainer>
          <Title
            size={18}
            fontFamily={theme.fontFamilies.roboto.bold}
            weight={theme.fontWeights.bold}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.TITLE')}
          </Title>

          <EditStudentButtonContainer>
            <EditStudentButton
              $isMobile={isMobile}
              type="SECONDARY"
              onClick={openStudentInfoModal}
            >
              <Icon className={`las la-edit ${isMobile ? 'mr-0' : ''} `} />
              {!isMobile && t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.EDIT_BUTTON')}
            </EditStudentButton>
          </EditStudentButtonContainer>
        </TitleContainer>

        <InfoContainer>
          {/* First Name */}
          <div className={`row ${isMobile ? 'mb-2' : ''} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.FIRST_NAME_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col'} `}>
              <Value>{data?.fromStateViewStudent.student.first_name}</Value>
            </div>
          </div>

          {/* Last Name */}
          <div className={`row ${isMobile ? 'mb-2' : ''} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.LAST_NAME_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col'} `}>
              <Value>{data?.fromStateViewStudent.student.last_name}</Value>
            </div>
          </div>

          {/* Student Id */}
          <div className={`row ${isMobile ? 'mb-2' : ''} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.ACADEMIC_ID_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col'} `}>
              <Value>{data?.fromStateViewStudent.student.academic_id}</Value>
            </div>
          </div>

          {/* Email */}
          <div className={`row ${isMobile ? 'mb-2' : ''} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.EMAIL_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col'} `}>
              <Value>{data?.fromStateViewStudent.student.email_address}</Value>
            </div>
          </div>

          {/* Gender */}
          <div className={`row ${isMobile ? 'mb-2' : ''} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.GENDER_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col'} `}>
              <Value>{data?.fromStateViewStudent.student.gender}</Value>
            </div>
          </div>

          {/* DOB */}
          <div className={`row ${isMobile ? 'mb-2' : ''} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.DOB_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col'} `}>
              <Value>{moment(data?.fromStateViewStudent.student.date_of_birth).format('MMMM DD, YYYY')}</Value>
            </div>
          </div>

          {/* Phone */}
          <div className={`row ${isMobile ? 'mb-2 mt-5' : 'mt-4'} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.PHONE_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
              <Value>{data?.fromStateViewStudent.student.primary_phone}</Value>
            </div>
          </div>

          {/* Address 1 */}
          <div className={`row ${isMobile ? 'mb-2' : ''} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.ADDRESS_1_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
              <Value>{data?.fromStateViewStudent.student.address_1}</Value>
            </div>
          </div>

          {/* Address 2 */}
          <div className={`row ${isMobile ? 'mb-2' : ''} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.ADDRESS_2_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
              <Value>{data?.fromStateViewStudent.student.address_2}</Value>
            </div>
          </div>

          {/* City */}
          <div className={`row ${isMobile ? 'mb-2' : ''} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.CITY_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
              <Value>{data?.fromStateViewStudent.student.city}</Value>
            </div>
          </div>

          {/* State */}
          <div className={`row ${isMobile ? 'mb-2' : ''} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.STATE_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
              <Value>{data?.fromStateViewStudent.student.state_prov}</Value>
            </div>
          </div>

          {/* Zip code */}
          <div className={`row ${isMobile ? 'mb-2' : ''} `}>
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.ZIP_LABEL')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
              <Value>{data?.fromStateViewStudent.student.postal_code}</Value>
            </div>
          </div>

          {/* Canadian address */}
          <div className="row mb-4">
            <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
              <Label>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT.CANADIAN_ADDRESS')}
                :
              </Label>
            </div>
            <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
              <Value>{data?.fromStateViewStudent.student.country === 'Canada' ? t('YES') : t('NO')}</Value>
            </div>
          </div>
        </InfoContainer>
      </div>
    );
  }
  return null;
});

export default StudentView;
