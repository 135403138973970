import styled from 'styled-components';

// Components
import Text from 'components/Text';
import Icon from 'components/Icon';

// Theme
import { theme } from '../../theme/theme';

export const AccordionContainer = styled.div`
  display: block;
  width: 100%;
`;

export const AccordionHeader = styled.div<{ isOdd: boolean }>`
  background-color: ${props => (props.isOdd ? theme.colors.white : theme.colors.disabledGrey)};
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 10px;
  margin-bottom: 1rem;
`;

export const StyledText = styled(Text) <{ $uppercase?: boolean, $bold?: boolean }>`
  word-break: break-all;
  ${({ $uppercase }) => ($uppercase ? `
    text-transform: uppercase;
  ` : '')}

  ${({ $bold }) => ($bold ? `
    font-family: 'Roboto-Medium';
    font-weigth: ${theme.fontWeights.medium};
  ` : '')}
`;

export const StyledIcon = styled(Icon)``;
