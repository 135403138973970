/* eslint-disable no-console */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';
/* import { boolean } from 'yup/lib/locale'; */

// Contexts
import { StateContextProvider } from 'context/StateContext';

// Data
import { ReportNewClaimSteps } from 'data/static/ReportNewClaimSteps';
import states from 'data/static/states.json';
import provinces from 'data/static/provinces.json';

// Query
import { getClaimById } from 'data/claim';

// Components
import PageLayout from 'components/PageLayout';
import StepSelectStudent from './StepSelectStudent';
import StepInsuranceInformation from './StepInsuranceInformation';
import StepClaimInformation from './StepClaimInformation';
import StepSignature from './StepSignature';
import StepViewStudent from './StepViewStudent';
import StepClaimPreview from './StepClaimPreview';

import {
  StepContainer,
} from './style';

export type ReportStep =
  ReportNewClaimSteps.STEP_SELECT_STUDENT
  | ReportNewClaimSteps.STEP_VIEW_STUDENT
  | ReportNewClaimSteps.STEP_FILL_INSURANCE_INFORMATION
  | ReportNewClaimSteps.STEP_FILL_CLAIM_INFORMATION
  | ReportNewClaimSteps.STEP_FILL_SIGNATURE
  | ReportNewClaimSteps.STEP_CLAIM_PREVIEW;

export type ReportClaimSteps = {
  step: ReportStep,
  editMode: boolean,
};

const ReportNewClaimPage = React.memo(() => {
  // Hooks
  const { t } = useTranslation();
  const match = useRouteMatch<{ id?: string, claimId?: string }>();
  const { params } = match;
  // NOTE: If we have an claimId then we are editing, else we are creating a new claim
  const { id: schoolId, claimId } = params;

  type StateKey = keyof typeof states;
  type ProvinceKey = keyof typeof provinces;

  const stateOptions: OptionsType<OptionType> = Object.keys(states)
    // @ts-ignore
    .map((key: StateKey) => ({ value: key, label: states[key] }));

  const provinceOptions: OptionsType<OptionType> = Object.keys(provinces)
    // @ts-ignore
    .map((key: ProvinceKey) => ({ value: key, label: provinces[key] }));

  // States
  const [step, setStep] = React.useState<ReportClaimSteps>({
    step: claimId
      ? ReportNewClaimSteps.STEP_VIEW_STUDENT
      : ReportNewClaimSteps.STEP_SELECT_STUDENT,
    editMode: false,
  });
  const [data, setData] = React.useState<ReportNewClaimWizard>();

  // Queries
  const [callGetClaim, {
    loading: loadingGetClaim,
    data: dataGetClaim,
  }] = useLazyQuery<GetClaimResponse, GetClaimVariables>(getClaimById);

  // Effects
  React.useEffect(() => {
    if (claimId) {
      callGetClaim({
        variables: { id: claimId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  React.useEffect(() => {
    if (!loadingGetClaim && dataGetClaim) {
      const stateProv = dataGetClaim.claims_by_pk.student.country === 'Canada'
        ? provinceOptions.find(prov => prov.value === dataGetClaim.claims_by_pk.student.state_prov
          || prov.label === dataGetClaim.claims_by_pk.student.state_prov)
        : stateOptions.find(state => state.value === dataGetClaim.claims_by_pk.student.state_prov
          || state.label === dataGetClaim.claims_by_pk.student.state_prov);
      setData({
        formStepSelectStudent: { studentId: dataGetClaim.claims_by_pk.student.id },
        fromStateViewStudent: {
          student: {
            ...dataGetClaim.claims_by_pk.student,
            state_prov: stateProv?.label,
          },
          studentUpdated: false,
          unmodifiedData: {
            student: {
              ...dataGetClaim.claims_by_pk.student,
              state_prov: stateProv?.label,
            },
            studentUpdated: false,
          },
        },
        formStepInsuranceInformation: {
          studentsProvider: dataGetClaim.claims_by_pk.claims_providers.map((insurance, index) => ({
            formId: index, // Irrelevant for submission
            insuranceCompanyId: insurance.provider === null ? 'OTHER' : insurance.insurance_provider_id || '', // KEY
            insuranceCompanyLabel: insurance.is_other_provider ? 'Other' : insurance.provider?.name || '', // VALUE
            providerName: insurance.provider_name || '', // if key === 'OTHER'
            insuranceCompanyIsOther: insurance.is_other_provider || false,
            insuranceMemberId: insurance.insurance_member_id || '',
            insuranceGroupNumber: insurance.insurance_group_id,
            phone: insurance.phone,
          })),
        },
        formStepClaimInformation: {
          tpaId: dataGetClaim.claims_by_pk.schools_third_party_administrator.id,
          benefitsDueAnotherProvider: dataGetClaim.claims_by_pk.benefits_due_another_provider,
          injuryDate: dataGetClaim.claims_by_pk.injury_date ? new Date(`${moment(dataGetClaim?.claims_by_pk.injury_date).format('YYYY-MM-DD')}T00:00:00`) : undefined,
          injuryReportedDate: dataGetClaim.claims_by_pk.injury_reported_date ? new Date(`${moment(dataGetClaim.claims_by_pk.injury_reported_date).format('YYYY-MM-DD')}T00:00:00`) : undefined,
          activity: dataGetClaim.claims_by_pk.activity,
          activityType: dataGetClaim.claims_by_pk.activity_type,
          activityLevel: dataGetClaim.claims_by_pk.activity_level,
          isWorkRelated: dataGetClaim.claims_by_pk.is_work_related,
          isPreviousInjury: dataGetClaim.claims_by_pk.is_previous_injury,
          locationOfInjury: dataGetClaim.claims_by_pk.location_of_injury,
          injuryDescription: dataGetClaim.claims_by_pk.injury_description,
          injuryType: dataGetClaim.claims_by_pk.injury_type,
          firstTreatmentDate: dataGetClaim.claims_by_pk.first_treatment_date ? new Date(`${moment(dataGetClaim.claims_by_pk.first_treatment_date).format('YYYY-MM-DD')}T00:00:00`) : undefined,
        },
        formStepSignature: { checked: true },
        formStepClaimPreview: {
          claim_id: dataGetClaim.claims_by_pk.claim_id,
          user: dataGetClaim.claims_by_pk.user,
          submitted_to_tpa_at: dataGetClaim.claims_by_pk.submitted_to_tpa_at,
          changes_log: dataGetClaim.claims_by_pk.changes_log,
          claims_change_logs: dataGetClaim.claims_by_pk.claims_change_logs,
        },
      });
    }
  }, [loadingGetClaim, dataGetClaim]);

  if (!schoolId) {
    return null;
  }

  const currentTPAId = dataGetClaim?.claims_by_pk?.schools_third_party_administrator?.id || '';

  return (
    <PageLayout
      crumbs={[
        {
          title: t('REPORT_NEW_CLAIM_PAGE.BREADCRUMBS.SERVICE_CENTER'),
          to: '/dashboard',
          state: {
            school: {
              value: schoolId,
            },
          },
        },
        {
          title: claimId ? t('REPORT_NEW_CLAIM_PAGE.BREADCRUMBS.EDIT_CLAIM')
            : t('REPORT_NEW_CLAIM_PAGE.BREADCRUMBS.REPORT_NEW_CLAIM'),
          to: '',
        },
      ]}
    >
      {/* Context */}
      <StateContextProvider
        data={data}
        step={step}
        setData={setData}
        setStep={setStep}
      >
        {/* Step Container */}
        <StepContainer>
          {
            step.step === ReportNewClaimSteps.STEP_SELECT_STUDENT && (
              <StepSelectStudent
                schoolId={schoolId}
              />
            )
          }
          {
            step.step === ReportNewClaimSteps.STEP_VIEW_STUDENT && (
              <StepViewStudent />
            )
          }
          {
            step.step === ReportNewClaimSteps.STEP_FILL_INSURANCE_INFORMATION && (
              <StepInsuranceInformation
                schoolId={schoolId}
              />
            )
          }
          {
            step.step === ReportNewClaimSteps.STEP_FILL_CLAIM_INFORMATION && (
              <StepClaimInformation
                schoolId={schoolId}
                currentTPAId={currentTPAId}
              />
            )
          }
          {
            step.step === ReportNewClaimSteps.STEP_FILL_SIGNATURE && (
              <StepSignature
                schoolId={schoolId}
              />
            )
          }
          {
            step.step === ReportNewClaimSteps.STEP_CLAIM_PREVIEW && (
              <StepClaimPreview />
            )
          }
        </StepContainer>
      </StateContextProvider>
    </PageLayout>
  );
});
export default ReportNewClaimPage;
