import { gql } from '@apollo/client';

// Fragments
export const providersFragment = gql`
  fragment provider on providers {
    id
    name
    address
    city
    is_active
    postal_code
    state
    created_at
    updated_at
    deleted_at
  }
`;
