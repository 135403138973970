import React, { useEffect } from 'react';
import classnames from 'classnames';

// Styles
import {
  MainContainer,
  StyledImage,
  Placeholder,
  StyledIcon,
} from './style';

interface SchoolLogoProps {
  className?: string;
  src?: string;
  alt?: string;
  children?: any;
}

const SchoolLogo = React.memo(({
  className,
  src,
  alt,
  children,
}: SchoolLogoProps) => {
  // States
  const [showPlaceholder, setShowPlaceholder] = React.useState<boolean>(!src || !src.trim());

  useEffect(() => {
    if (src) {
      setShowPlaceholder(false);
    }
  }, [src]);

  // Handlers
  const handleOnError = React.useCallback(() => {
    setShowPlaceholder(true);
  }, []);

  return (
    <MainContainer
      className={classnames(className)}
    >
      {
        showPlaceholder ? (
          <Placeholder>
            <StyledIcon
              className="las la-landmark"
            />
          </Placeholder>
        ) : (
          <StyledImage
            src={src}
            alt={alt}
            draggable="false"
            loading="lazy"
            onError={handleOnError}
          />
        )
      }
      {children}
    </MainContainer>
  );
});

export default SchoolLogo;
