import { gql } from '@apollo/client';

import {
  claimSoftFragment,
  claimAdminFragment,
  claimFragment,
  claimIdFragment,
} from 'data/fragments';

export const getClaims = gql`
  query getClaims(
    $where: claims_bool_exp,
    $offset: Int,
    $limit: Int,
    $order_by: [claims_order_by!],) {
    claims (where: $where, offset: $offset, limit: $limit, order_by: $order_by){
      ...claim
    }
    claims_aggregate(where : $where) {
      aggregate{
        totalCount: count
      }
    }
  }
  ${claimSoftFragment}  
`;

export const getClaimsAdmin = gql`
  query getClaims(
    $where: claims_bool_exp,
    $offset: Int,
    $limit: Int,
    $order_by: [claims_order_by!],) {
    claims (where: $where, offset: $offset, limit: $limit, order_by: $order_by){
      ...claim
    }
    claims_aggregate(where : $where) {
      aggregate{
        totalCount: count
      }
    }
  }
  ${claimAdminFragment}  
`;

export const getClaimById = gql`
  query getClaim($id: uuid!) {
    claims_by_pk(id: $id) {
      ...claim
    }
  }
  ${claimFragment}
`;

export const getClaimIdById = gql`
  query getClaim($id: uuid!) {
    claims_by_pk(id: $id) {
      ...claim
    }
  }
  ${claimIdFragment}
`;
