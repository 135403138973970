import React from 'react';

// Components
import Text from 'components/Text';

// Theme
import { colors } from 'theme/theme';

interface AccordionLineProps {
  field: string,
  value: string,
}

const AccordionLine = ({
  field,
  value,
}: AccordionLineProps) => (
  <div className="row m-1">
    <div className="col">
      <Text size={14} color={colors.grey} lineHeight="18px">{field}</Text>
    </div>
    <div className="col">
      <Text size={14} color={colors.dark} lineHeight="18px">{value}</Text>
    </div>
  </div>
);

export default AccordionLine;
