import styled from 'styled-components';

// Components
import CustomModal from 'modals/CustomModal';
import Button from 'components/Button';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';

export const StyledModal = styled(CustomModal)<{ $isMobile: boolean}>`
max-width: ${({ $isMobile }) => ($isMobile ? '346px' : '738px')};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const TopContainer = styled.div<{ $isCreateModal: boolean, $showCompleteForm?: boolean, $isMobile: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $isMobile }) => ($isMobile ? '1fr' : '1fr 1fr 1fr 1fr')};
  grid-template-rows: ${({ $isMobile }) => ($isMobile ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr')};
  gap: ${({ $isMobile }) => ($isMobile ? '20px 10px' : '20px 20px')};
  ${({ $isCreateModal, $isMobile }) => ($isCreateModal && $isMobile ? `
    grid-template-areas:
    "area-insurance-company"
    "area-other-insurance-name"
    "area-insurance-member-id"
    "area-phone"
    "area-insurance-group-id";`
    : '')};
  ${({ $isCreateModal, $isMobile }) => (!$isCreateModal && $isMobile ? `
    grid-template-areas:
    "area-insurance-company"
    "area-insurance-member-id"
    "area-phone"
    "area-insurance-group-id";`
    : '')};
  ${({ $isMobile }) => (!$isMobile ? `
    grid-template-areas:
    "area-insurance-company area-insurance-company area-other-insurance-name area-other-insurance-name"
    "area-insurance-member-id area-insurance-member-id area-phone area-phone"
    "area-insurance-group-id area-insurance-group-id . . ";`
    : '')};
  
/*   
  grid-template-areas:
    "area-insurance-company area-insurance-company area-other-insurance-name area-other-insurance-name"
    "area-insurance-member-id area-insurance-member-id area-address1 area-address1"
    "area-insurance-member-id area-insurance-member-id area-phone area-phone"
    "area-insurance-group-id area-insurance-group-id area-address2 area-address2"
    "area-phone area-phone area-city area-city" 
    ". . area-state area-zip"
    ". . area-country area-country"; */

  ${({ $showCompleteForm }) => ($showCompleteForm ? '' : `
    .area-other-insurance-name,
    .area-insurance-member-id,
    .area-address1,
    .area-address2,
    .area-insurance-group-id,
    .area-phone,
    .area-city,
    .area-gender,
    .area-state,
    .area-zip,
    .area-country {
      display: none;
    }
  `)}
`;

export const GridItem = styled.div`
  width: 100%;

  &.area-insurance-company {
    grid-area: area-insurance-company;
  }

  &.area-other-insurance-name { 
    grid-area: area-other-insurance-name;
  }

  &.area-insurance-member-id { 
    grid-area: area-insurance-member-id;
  }

  &.area-address1 { 
    grid-area: area-address1;
  }

  &.area-address2 { 
    grid-area: area-address2;
  }

  &.area-insurance-group-id { 
    grid-area: area-insurance-group-id;
  }

  &.area-phone { 
    grid-area: area-phone;
  }

  &.area-city { 
    grid-area: area-city;
  }

  &.area-gender { 
    grid-area: area-gender;
  }

  &.area-dob { 
    grid-area: area-dob;
  }

  &.area-state { 
    grid-area: area-state;
  }

  &.area-zip { 
    grid-area: area-zip;
  }

  &.area-country { 
    grid-area: area-country;
  }
`;

export const TextInput = styled(Input)`
  width: 100%;
  margin-top: 20px;
  
  input {
    background-image: none !important;
  }

  &:first-of-type {
    margin-top: 0px;
  }
`;

export const StyledDropdown = styled(Dropdown)``;

export const StyledCheckbox = styled(Checkbox)``;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const AddButton = styled(Button)`
  width: 100%;
  max-width: 124px;
`;
