import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

// Components
import PageLayout from 'components/PageLayout';
import { getPagination } from 'utils/paginationHelper';
import SearchSubmittedClaims from 'components/SearchSubmittedClaims';
import ClaimsListCard from 'components/ClaimsListCard';

// Queries
import { getClaims } from 'data/claim';

const ReportedClaimsPage = React.memo(() => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<ClaimFilters>();
  const [pageSize, setPageSize] = useState(23);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const params = useParams<URLParams>();
  const [sortByGql, setSortByGql] = useState<any>([
    { submitted_to_tpa_at: 'desc' },
  ]);
  const { id: schoolId } = params;
  // Queries
  const [claimsQuery, {
    data: claimsData,
  }] = useLazyQuery<GetClaimsResponse, GetClaimsVariables>(getClaims, {
    fetchPolicy: 'network-only',
  });
  // Effects
  React.useEffect(() => {
    const and: CommonJSON[] = [];
    if (filters) {
      const {
        firstName,
        lastName,
        academicId,
        tpa,
        submittedBy,
      } = filters;

      if (firstName || lastName || academicId) {
        const student: any = {};
        if (firstName) {
          student.first_name = { _ilike: `%${firstName}%` };
        }

        if (lastName) {
          student.last_name = { _ilike: `%${lastName}%` };
        }

        if (academicId) {
          student.academic_id = { _ilike: `%${academicId}%` };
        }

        and.push({
          student,
        });
      }
      if (tpa) {
        and.push({
          _or: [
            {
              schools_third_party_administrator: {
                third_party_administrator: {
                  name: { _ilike: `%${tpa}%` },
                },
              },
            },
            {
              schools_third_party_administrator: {
                third_party_administrator: {
                  code: { _ilike: `%${tpa}%` },
                },
              },
            },
          ],
        });
      }

      if (submittedBy && submittedBy.value !== '') {
        and.push({
          user: {
            id: {
              _eq: `${submittedBy.value}`,
            },
          },
        });
      }
    }

    and.push({
      school_id: { _eq: params.id! },
    });

    and.push({
      deleted_at: { _is_null: true },
    });

    const where = {
      _and: and,
    };

    const variables = {
      where,
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
      order_by: sortByGql,
    };

    claimsQuery({ variables });
  }, [
    pageSize,
    currentPage,
    filters,
    claimsQuery,
    sortByGql,
  ]);

  // Handlers
  const handleOnSearch = React.useCallback((newFilters?: ClaimFilters) => {
    setFilters(newFilters);
  }, []);

  const handleOnPageChange = React.useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  const handleOnPageSizeChange = React.useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  const handleSort = React.useCallback(column => {
    let auxSortGql = {};
    switch (column) {
      case 'claimant_name':
        auxSortGql = [
          { student: { last_name: (sortByGql[0] && sortByGql[0].student && sortByGql[0].student.last_name && sortByGql[0].student.last_name === 'desc') ? 'asc' : 'desc' } },
          { student: { first_name: (sortByGql[1] && sortByGql[1].student && sortByGql[1].student.first_name && sortByGql[1].student.first_name === 'desc') ? 'asc' : 'desc' } },
        ];
        break;
      case 'student_id':
        auxSortGql = { student: { academic_id: (sortByGql.student && sortByGql.student.academic_id && sortByGql.student.academic_id === 'desc') ? 'asc' : 'desc' } };
        break;
      case 'tpa':
        auxSortGql = {
          schools_third_party_administrator: {
            third_party_administrator: {
              code: (sortByGql.schools_third_party_administrator
                && sortByGql.schools_third_party_administrator.third_party_administrator
                && sortByGql.schools_third_party_administrator.third_party_administrator.code === 'desc') ? 'asc' : 'desc',
            },
          },
        };
        break;
      case 'submitted_by':
        auxSortGql = [
          { user: { last_name: (sortByGql[0] && sortByGql[0].user && sortByGql[0].user.last_name && sortByGql[0].user.last_name === 'desc') ? 'asc' : 'desc' } },
          { user: { first_name: (sortByGql[1] && sortByGql[1].user && sortByGql[1].user.first_name && sortByGql[1].user.first_name === 'desc') ? 'asc' : 'desc' } },
        ];
        break;
      default: // injury_date
        auxSortGql = { [column]: (sortByGql[column] && sortByGql[column] === 'desc') ? 'asc' : 'desc' };
        break;
    }
    setSortByGql(auxSortGql);
  }, [sortByGql]);

  const {
    claims,
    pagination,
  } = React.useMemo(() => {
    const out: {
      claims?: Claim[],
      pagination?: Pagination,
    } = {
      claims: [],
      pagination: undefined,
    };
    if (claimsData) {
      out.claims = claimsData.claims;
      const totalItems = claimsData.claims_aggregate.aggregate.totalCount;
      out.pagination = getPagination(currentPage, totalItems, pageSize);
    }
    return out;
  }, [
    claimsData,
    currentPage,
    pageSize,
  ]);

  return (
    <PageLayout
      crumbs={[
        {
          title: t('SERVICE_CENTER'),
          to: '/dashboard',
          state: {
            school: {
              value: schoolId,
            },
          },
        },
        { title: t('REPORTED_CLAIMS'), to: '' },
      ]}
    >
      <SearchSubmittedClaims
        onSearch={handleOnSearch}
      />

      <ClaimsListCard
        items={claims}
        pageSize={pageSize}
        pagination={pagination}
        setPageSize={handleOnPageSizeChange}
        onChangePage={handleOnPageChange}
        sortBy={sortByGql}
        onSort={handleSort}
      />
    </PageLayout>
  );
});
export default ReportedClaimsPage;
