import { gql } from '@apollo/client';
import { tpaElementFragmentWithOrder } from './tpaElements';
import { schoolDashboardContactFragmentWithOrder } from './schools_dashboard_contacts';
// Fragments
export const schoolSoftFragment = gql`
  fragment school on schools {
    id
    name
    is_active
    # Sales Rep
    sales_rep_id
    sales_rep {
      id
      first_name
      last_name
    }
    # Notes
    schools_notes {
      id
      note
    }
    # TPA
    schools_third_party_administrators(where: { deleted_at: { _is_null: true }}) {
      id
      third_party_administrator {
        code
        id
        name
      }
    }
    # Dashboard Elements
    schools_dashboard_elements(where: { deleted_at: { _is_null: true }}) {
      id
      type
      title
      component
      filename
    }
    created_at
    updated_at
    deleted_at
  }
`;

export const schoolComplexFragment = gql`
  fragment school on schools {
    id
    name
    address_1
    address_2
    city
    state
    contact_name
    contact_email_address
    contact_phone
    postal_code
    is_active
    coverage_start_date
    # Sales rep
    sales_rep_id
    sales_rep {
      id
      first_name
      last_name
    }
    # Notes
    schools_notes {
      id
      note
    }
    # TPA
    schools_third_party_administrators(where: { deleted_at: { _is_null: true }}) {
      id
      third_party_administrator {
        code
        id
        name
      }
    }
    # Dashboard Elements
    schools_dashboard_elements(where: { deleted_at: { _is_null: true }}) {
      id
      type
      title
      component
      filename
      schools_dashboard_elements_orders{
        order
      }
    }
    allow_piv
    allow_students_import
    show_verification_status
    created_at
    updated_at
    deleted_at
  }
`;

export const schoolDashboardFragment = gql`
  fragment school on schools {
    id
    name
    # TPA
    schools_third_party_administrators(where:  {
      _and: [
        { deleted_at: { _is_null: true }},
        { is_active: { _eq: true }}
      ],
    },
    ) {
      id
      is_default
      third_party_administrator {
        id
        address_1
        city
        code
        fax
        phone
        name
        postal_code
        state
        claims_portal_url
        # TPA Elements
        third_party_administrators_dashboard_elements (where: { deleted_at: { _is_null: true }}){
          ...tpaElement
        }
      }
    }
    # Dashboard Elements
    schools_dashboard_elements(where: { deleted_at: { _is_null: true }}) {
      component
      filename
      id
      url
      title
      type
      schools_dashboard_elements_orders{
        order
      }
    }
    # Dashboard Contact Forms
    schools_dashboard_contacts(where: { deleted_at: { _is_null: true }}) {
      ...schoolDashboardContact
    }
    # Sales Rep
    sales_rep {
      address_1
      city
      email_address
      first_name
      last_name
      phone
      postal_code
      state
    }
  }
  ${tpaElementFragmentWithOrder}
  ${schoolDashboardContactFragmentWithOrder}
`;

export const schoolAllowImportFragment = gql`
  fragment school on schools {
    id
    name
    allow_piv
    allow_students_import
    show_verification_status
    created_at
    updated_at
    deleted_at
  }
`;
