import styled from 'styled-components';

// Components
import Text from 'components/Text';
import Button from 'components/Button';
import InsuranceInfoRow from './InsuranceInfoRow';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-height: 500px;
`;

export const ListOfItems = styled.div`
  width: 100%;
`;

export const RowItem = styled(InsuranceInfoRow)`
  width: 100%;
  margin-top: 30px;
  
  &:first-of-type {
    margin-top: 0px;
  }
`;

export const StyledLabel = styled(Text)``;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const AddButton = styled(Button)`
  max-width: 50px;
`;

export const StyledAddCircleIcon = styled.i`
  font-size: 16px;
  min-width: 50px;
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 50px;
`;

export const UpdateButton = styled(Button)`
  width: 100%;
  max-width: 135px;
`;
