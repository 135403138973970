/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';

// Data
import {
  // createUser,
  updateUser,
} from 'data/user';

// Utils
import {
  createUserForSchool,
} from 'utils/userHelper';
import {
  Toaster,
} from 'utils/toaster';

// Styles
import { theme } from 'theme/theme';
import {
  StyledModal,
  TopContainer,
  BottomContainer,
  TextInput,
  DeleteButton,
  AddOrEditButton,
  ToggleContainer,
  StyledLabel,
  StyledToggle,
} from './style';

export interface UserModalProps {
  isOpen: boolean;
  schoolId: string;
  toggle: () => void;
  user?: User;
  onUserCreate: () => void;
  onUserDelete: (userId: string) => void;
}

const getDefaultValues = function getDefaultValues(user?: User): CommonJSON {
  return {
    firstName: user ? user.first_name : '',
    lastName: user ? user.last_name : '',
    email: user ? user.email_address : '',
    isActive: user ? user.is_active : true,
  };
};

const userSchema = Yup.object({
  firstName: Yup.string().required('FIRST_NAME_REQUIRED').max(75, 'FIRST_NAME_MAX'),
  lastName: Yup.string().required('LAST_NAME_REQUIRED').max(75, 'LAST_NAME_MAX'),
  email: Yup.string().email('INVALID_EMAIL').required('EMAIL_REQUIRED').max(100, 'EMAIL_MAX'),
  isActive: Yup.boolean().required('IS_ACTIVE_REQUIRED'),
}).defined();

const UserModal = React.memo(({
  user,
  schoolId,
  isOpen,
  toggle,
  onUserCreate,
  onUserDelete,
}: UserModalProps) => {
  // Hooks
  const { t } = useTranslation();
  const {
    register,
    errors,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(userSchema),
    defaultValues: getDefaultValues(),
  });

  // States
  const [loadingCreateUser, setLoadingCreateUser] = React.useState<boolean>();

  // Mutations
  const [callEditUser, {
    loading: loadingEditUser,
  }] = useMutation<UpdateUserResponse, UpdateUserVariables>(updateUser, {
    onError: _error => {
      console.error('ERROR [editUser]', _error);
      Toaster('error', t('TOASTER.ERROR.USER_UPDATE_FAILED'));
    },
    onCompleted: () => {
      Toaster('success', t('TOASTER.SUCCESS.USER_UPDATED'));
      toggle();
    },
  });

  // Effects
  React.useEffect(() => {
    if (isOpen) {
      reset(getDefaultValues(user));
    } else {
      reset(getDefaultValues());
    }
  }, [isOpen, user, reset]);

  // Handlers

  const handleDelete = React.useCallback(() => {
    if (user) {
      onUserDelete(user.id);
    }
  }, [user, onUserDelete]);

  const handleAddOrEdit = React.useCallback(async (data: CommonJSON) => {
    if (!user) {
      // Create

      const dataToSend = {
        email: data.email || '',
        first_name: data.firstName || '',
        last_name: data.lastName || '',
        school_id: schoolId,
        type: 'user',
      };

      setLoadingCreateUser(true);

      const { success } = await createUserForSchool(dataToSend);
      setLoadingCreateUser(false);

      if (success) {
        Toaster('success', t('TOASTER.SUCCESS.USER_CREATED'));
        onUserCreate();
        toggle();
      } else {
        Toaster('error', t('TOASTER.ERROR.USER_CREATION_FAILED'));
      }
    } else {
      // Edit
      const variables: UpdateUserVariables = {
        userId: user.id,
        input: {
          first_name: data.firstName || '',
          last_name: data.lastName || '',
          email_address: data.email || '',
          is_active: data.isActive,
          updated_at: new Date(),
        },
      };

      callEditUser({ variables });
    }
  }, [
    user,
    schoolId,
    toggle,
    onUserCreate,
    callEditUser,
  ]);

  // Memos
  const loading = React.useMemo(
    () => loadingCreateUser || loadingEditUser,
    [loadingCreateUser, loadingEditUser],
  );

  return (
    <StyledModal
      isOpen={isOpen}
      toggle={toggle}
      title={t(`USER_MODAL.${user ? 'EDIT_USER_TITLE' : 'ADD_USER_TITLE'}`)}
    >
      {/* Top Container */}
      <TopContainer>
        <ToggleContainer
          $show={!!user}
        >
          {/* Label */}
          <StyledLabel
            size={14}
            color={theme.colors.grey}
            weight={theme.fontWeights.normal}
            fontFamily={theme.fontFamilies.roboto.regular}
          >
            {`${t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.STATUS.LABEL')}:`}
          </StyledLabel>

          {/* Toggle */}
          <StyledToggle
            ref={register()}
            id="school-user-tab-user-status"
            defaultChecked={user ? user.is_active : true}
            name="isActive"
          />
        </ToggleContainer>

        {/* Field: First Name */}
        <TextInput
          ref={register()}
          name="firstName"
          title={t('USER_MODAL.FIRST_NAME_LABEL')}
          icon=""
          maxLength={75}
          error={errors.firstName !== undefined}
        />

        {/* Field: Last Name */}
        <TextInput
          ref={register()}
          name="lastName"
          title={t('USER_MODAL.LAST_NAME_LABEL')}
          icon=""
          maxLength={75}
          error={errors.lastName !== undefined}
        />

        {/* Field: Email */}
        <TextInput
          ref={register()}
          name="email"
          title={t('USER_MODAL.EMAIL_LABEL')}
          icon="las la-envelope-open"
          maxLength={100}
          error={errors.email !== undefined}
        />
      </TopContainer>

      {/* Bottom Container */}
      <BottomContainer>
        {/* Delete Button */}
        {
          user ? (
            <DeleteButton
              type="SECONDARY"
              onClick={handleDelete}
            >
              {t('USER_MODAL.DELETE_CTA_BUTTON_LABEL')}
            </DeleteButton>
          ) : (
            <div />
          )
        }

        {/* Add or Edit Button */}
        <AddOrEditButton
          type="PRIMARY"
          disabled={loading}
          onClick={handleSubmit(handleAddOrEdit)}
        >
          {t(`USER_MODAL.${user ? 'EDIT_CTA_BUTTON_LABEL' : 'ADD_CTA_BUTTON_LABEL'}`)}
        </AddOrEditButton>
      </BottomContainer>
    </StyledModal>
  );
});

export default UserModal;
