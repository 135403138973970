import { gql } from '@apollo/client';

export const createStudentProviders = gql`
  mutation createStudentProviders($objects: [students_providers_insert_input!]! ) {
  insert_students_providers(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const updateStudentProvider = gql`
  mutation updateStudentProvider($studentProviderId: uuid!, $input: students_providers_set_input! ) {
    update_students_providers_by_pk(pk_columns: { id: $studentProviderId }, _set: $input) {
      id
    }
  }
`;

export const deleteStudentProvider = gql`
mutation deleteStudentProvider($id: uuid!) {
  delete_students_providers_by_pk(id: $id){
    id
  }
}
`;

export const deleteSoftStudentProvider = gql`
  mutation deleteSoftStudentProvider($id: uuid!) {
    update_students_providers_by_pk (
      pk_columns: { id: $id }, _set: { deleted_at: "now()" },
    ) {
      id
    }
  }
`;
