import React from 'react';

// Component
import BreadCrumb from 'components/BreadCrumb';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

// Styles
import {
  Container,
  Wrapper,
} from './style';

interface PageLayoutProps {
  children: any,
  crumbs?: any,
}

const PageLayout = React.memo(({ children, crumbs }: PageLayoutProps) => {
  const { isMobile } = useResponsive();
  return (
    <Wrapper className={`site-container ${isMobile ? 'mt-1 row' : 'mt-5'}`}>
      {
        crumbs && crumbs.length > 0 && (
          <BreadCrumb crumbs={crumbs} className={isMobile ? 'pl-3' : ''} />
        )
      }
      <Container>
        {children}
      </Container>
    </Wrapper>
  );
});

export default PageLayout;
