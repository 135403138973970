import styled from 'styled-components';

// Components
import Button from 'components/Button';
import Table from 'components/Table';
import TableRow from 'components/Table/TableRow';
import Column from 'components/Table/Column';
import Text from 'components/Text';
import Icon from 'components/Icon';

export const ResendButton = styled(Button)`
  width: 100%;
  max-width: 77px;
  max-height: 27px;
`;

export const StyledTable = styled(Table)``;

export const StyledTableRow = styled(TableRow)``;

export const StyledColumn = styled(Column)``;

export const StyledText = styled(Text)``;

export const StyledIcon = styled(Icon)`
  margin-right: 21px;
`;
