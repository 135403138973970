import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: ${props => props.color};
  border-radius: 4px;
  opacity: 0.8;
  font-family: Lato;
  height: 25px;
  display: flex;
  align-items: center;
  width: 100%; 
`;
