/* eslint-disable no-console */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components
import Card from 'components/Card';
import Table from 'components/Table';

// Styles
import {
  MiddleContainer,
  BottomContainer,
  StyledTableRow,
  StyledColumn,
  TableText,
  TableStatusBox,
  TableLogo,
  ActionsContainer,
  ActionContainer,
  StyledPageSizeSelector,
  StyledDataTablePaginator,
} from './style';

export interface UserResultsProps {
  className?: string;
  items?: User[];
  pageSize: number;
  pagination?: Pagination;
  setPageSize: (newPageSize: number) => void;
  onChangePage: (pageNumber: number) => void;
  onActionEdit: (userId: string) => void;
  onActionDelete: (userId: string) => void;
}

const UserResults = React.memo(({
  className,
  items,
  pageSize,
  pagination,
  setPageSize,
  onChangePage,
  onActionEdit,
  onActionDelete,
}: UserResultsProps) => {
  // Hooks
  const { t } = useTranslation();

  // Handlers
  const handleEditUser = React.useCallback((userId: string) => {
    onActionEdit(userId);
  }, [onActionEdit]);

  const handleDeleteUser = React.useCallback((userId: string) => {
    onActionDelete(userId);
  }, [onActionDelete]);

  // Memos
  const options: SizeOptionType[] = React.useMemo(() => {
    const temp: SizeOptionType[] = [
      { value: 25, label: 25 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
    ];
    return temp;
  }, []);

  const columns: TableColumn[] = React.useMemo(() => {
    // Total width 1100px
    const totalWidth = 1100;
    const temp: TableColumn[] = [
      {
        title: '',
        sorteable: false,
        width: (55 / totalWidth) * 100,
      },
      {
        title: t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.USER_RESULT.TABLE.COLUMN_FIRST_NAME'),
        sorteable: true,
        order: 'ASC',
        width: (170 / totalWidth) * 100,
        justifyContent: 'flex-start',
      },
      {
        title: t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.USER_RESULT.TABLE.COLUMN_LAST_NAME'),
        sorteable: true,
        order: 'ASC',
        width: (170 / totalWidth) * 100,
        justifyContent: 'center',
      },
      {
        title: t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.USER_RESULT.TABLE.COLUMN_EMAIL'),
        sorteable: true,
        order: 'ASC',
        width: (200 / totalWidth) * 100,
        justifyContent: 'center',
      },
      {
        title: t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.USER_RESULT.TABLE.COLUMN_DATE_CREATED'),
        sorteable: true,
        order: 'ASC',
        width: (140 / totalWidth) * 100,
        justifyContent: 'center',
      },
      {
        title: t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.USER_RESULT.TABLE.COLUMN_LAST_ACTIVITY'),
        sorteable: true,
        order: 'ASC',
        width: (140 / totalWidth) * 100,
        justifyContent: 'center',
      },
      {
        title: t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.USER_RESULT.TABLE.COLUMN_STATUS'),
        width: (125 / totalWidth) * 100,
        sorteable: true,
        order: 'ASC',
        justifyContent: 'center',
      },
      {
        title: t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.USER_RESULT.TABLE.COLUMN_ACTIONS'),
        width: (100 / totalWidth) * 100,
        sorteable: false,
        justifyContent: 'flex-end',
      },
    ];
    return temp;
  }, [t]);

  const rows = React.useMemo(() => {
    if (items) {
      return items.map(item => ({
        id: item.id,
        firstName: item.first_name,
        lastName: item.last_name,
        email: item.email_address,
        status: item.is_active,
        createdAt: item.created_at,
        lastActivity: item.updated_at,
      }));
    }
    return [];
  }, [items]);

  return (
    <Card
      padding="25px 10px 20px;"
      className={classnames(className)}
    >

      {/* Middle Container */}
      <MiddleContainer>
        <Table
          columns={columns}
          rows={rows.map((row, index) => (
            <StyledTableRow
              isOdd={index % 2 === 0}
            >
              {/* Column: Logo */}
              <StyledColumn
                width={columns[0].width}
                $justifyContent={columns[0].justifyContent}
              >
                <TableLogo
                  text={row.firstName.charAt(0)}
                />
              </StyledColumn>

              {/* Column: First Name */}
              <StyledColumn
                width={columns[1].width}
                $justifyContent={columns[1].justifyContent}
              >
                <TableText>
                  {row.firstName}
                </TableText>
              </StyledColumn>

              {/* Column: Last Name */}
              <StyledColumn
                width={columns[2].width}
                $justifyContent={columns[2].justifyContent}
              >
                <TableText>
                  {row.lastName}
                </TableText>
              </StyledColumn>

              {/* Column: Email */}
              <StyledColumn
                width={columns[3].width}
                $justifyContent={columns[3].justifyContent}
              >
                <TableText>
                  {row.email}
                </TableText>
              </StyledColumn>

              {/* Column: Date Created */}
              <StyledColumn
                width={columns[4].width}
                $justifyContent={columns[4].justifyContent}
              >
                <TableText>
                  {moment(row.createdAt).format('MM/DD/YYYY')}
                </TableText>
              </StyledColumn>

              {/* Column: Last Activity */}
              <StyledColumn
                width={columns[5].width}
                $justifyContent={columns[5].justifyContent}
              >
                <TableText>
                  {moment(row.lastActivity).format('MM/DD/YYYY')}
                </TableText>
              </StyledColumn>

              {/* Column: Status */}
              <StyledColumn
                width={columns[6].width}
                $justifyContent={columns[6].justifyContent}
              >
                <TableStatusBox
                  status={row.status}
                  truthyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.USER_RESULT.TABLE.LABEL_STATUS_ACTIVE')}
                  falsyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.USER_RESULT.TABLE.LABEL_STATUS_INACTIVE')}
                />
              </StyledColumn>

              {/* Column: Actions */}
              <StyledColumn
                width={columns[7].width}
                $justifyContent={columns[7].justifyContent}
              >
                <ActionsContainer>
                  {/* Action Delete */}
                  <ActionContainer
                    onClick={() => handleDeleteUser(row.id)}
                  >
                    <i className="las la-trash-alt" />
                  </ActionContainer>
                  <ActionContainer
                    onClick={() => handleEditUser(row.id)}
                  >
                    <i className="las la-edit" />
                  </ActionContainer>
                </ActionsContainer>
              </StyledColumn>

            </StyledTableRow>
          ))}
        />
      </MiddleContainer>

      {/* Bottom Container */}
      <BottomContainer>
        <StyledPageSizeSelector
          pageSize={pageSize}
          setPageSize={setPageSize}
          options={options}
        />
        {
          pagination && (
            <StyledDataTablePaginator
              pagination={pagination}
              onPage={onChangePage}
            />
          )
        }
      </BottomContainer>
    </Card>
  );
});
export default UserResults;
