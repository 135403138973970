import React from 'react';
import styled from 'styled-components';

// Theme
import { colors } from '../../theme/theme';

interface IconProps {
  icon?: string,
  color?: string,
  size?: number,
  className?: string,
  onClick?: () => void,
}

const CustomIcon = styled.i`
  font-size:${(props: IconProps) => props.size}px;
  color: ${(props: IconProps) => props.color};
`;

const Icon = React.memo(({
  icon,
  color = colors.white,
  size = 17,
  className = '',
  onClick,
}: IconProps) => (
  <CustomIcon
    className={`${icon} ${className}`}
    color={color}
    size={size}
    onClick={onClick}
  />
));

export default Icon;
