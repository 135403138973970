import React from 'react';

// Reacstrap
import { Spinner } from 'reactstrap';

// CSSS
import './index.css';

const loading = () => (
  <Spinner
    className="Loading"
    size="sm"
    color="#E07A5F"
  />
);

export default loading;
