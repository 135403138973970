import { gql } from '@apollo/client';

// Fragments
export const providerFragment = gql`
  fragment provider on providers {
    id
    name
    address
    city
    postal_code
    policy_or_certificate_number
  }
`;
