import styled from 'styled-components';

// Theme
import { theme } from 'theme/theme';

// Components
import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import TagSelect from 'components/TagSelect';
import Toggle from 'components/Toggle';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid ${theme.colors.gallery};
`;

export const Title = styled(Text)`
  width: fit-content;
`;

export const GridUp = styled.div`
  display: grid;
  grid-template-columns: minmax(25%,1fr) minmax(25%,1fr) minmax(25%,1fr) minmax(25%,1fr);
  grid-template-rows: 1fr;
  gap: 0px opx;
  margin-top: 30px;
`;

export const GridDown = styled.div`
  display: grid;
  grid-template-columns: minmax(43%, 1fr) minmax(12.5%,1fr) minmax(12.5%,1fr) minmax(94px, 1fr);
  grid-template-rows: 1fr;
  gap: 0px 55px;
  margin-top: 30px;
`;

export const GridItem = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const GridItemDelete = styled.div`
align-items: flex-end;
display: flex;
  justify-content: flex-end;
`;

export const TextInput = styled(Input)`
  input {
    background-image: none !important;
  }
`;

export const StyledDatePicker = styled(DatePicker)``;

export const StyledTagSelect = styled(TagSelect)``;

export const DeleteButton = styled(Button)`
  padding: 4px 12px;
  line-height: 36px;
`;

export const StyledTrashIcon = styled.i`
  font-size: 20px;
  margin-right: 10px;
`;

export const StyledLabel = styled(Text)`
  margin-bottom: 15px;
`;

export const StyledToggle = styled(Toggle)``;
