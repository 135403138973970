import { gql } from '@apollo/client';

import {
  claimEmailLogFragment,
  claimEmailLogIdFragment,
} from 'data/fragments';

export const getClaimEmailLog = gql`
  query getClaimEmailLog(
    $claimId: uuid!,
    $offset: Int,
    $limit: Int,
    $order_by: [claims_email_logs_order_by!],) {
    claims_email_logs(where: { claim_id: {_eq: $claimId}}, offset: $offset, limit: $limit, order_by: $order_by) {
      ...claim_email_log
    }
    claims_email_logs_aggregate(where: { claim_id: {_eq: $claimId}}) {
      aggregate{
        totalCount: count
      }
    }
  }
  ${claimEmailLogFragment}
`;

export const getClaimEmailById = gql`
  query getClaimEmailById($id: uuid!) {
    claims_email_logs_by_pk(id: $id) {
      ...claim_email_log
    }
  }
  ${claimEmailLogIdFragment}
`;
