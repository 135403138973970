import { gql } from '@apollo/client';

import {
  schoolsTPAFragment,
  tpaFragment,
} from 'data/fragments';

export const ADD_TPA = gql`
  mutation insert_third_party_administrators_one($object: third_party_administrators_insert_input!) {
    insert_third_party_administrators_one(object: $object) {
      id
      ...tpa
    }
  }
  ${tpaFragment}
`;

export const updateTpa = gql`
  mutation updateTpa($tpaId: uuid!, $input: third_party_administrators_set_input) {
    update_third_party_administrators_by_pk (pk_columns: { id: $tpaId }, _set: $input) {
      ...tpa
    }
  }
  ${tpaFragment}
`;

export const deleteTpa = gql`
  mutation deleteTpa($tpaId: uuid!) {
    delete_third_party_administrators_by_pk(id: $tpaId) {
      ...tpa
    }
  }
  ${tpaFragment}
`;

export const associationSchoolTPA = gql`
  mutation insert_schools_third_party_administrators_one(
    $object: insert_schools_third_party_administrators_insert_one!,
  ) {
    insert_schools_third_party_administrators_one(object: $object) {
      ...tpa
    }
  }
  ${tpaFragment}
`;

export const deleteSoftTpa = gql`
  mutation deleteSoftTpa($tpaId: uuid!) {
    update_third_party_administrators_by_pk (pk_columns: { id: $tpaId }, _set: { deleted_at: "now()" }) {
      ...tpa
    }
     # Soft Delete TPAS Schools relation
    update_schools_third_party_administrators (
      where: { third_party_administrator_id: { _eq: $tpaId }}, _set: { deleted_at: "now()" }
      ) {
      returning {
        ...schoolsTPA
      }
    }
    # Soft Delete TPA elements
    update_schools_third_party_administrators (
      where: { third_party_administrator_id: { _eq: $tpaId }}, _set: { deleted_at: "now()" }
      ) {
      returning {
        ...schoolsTPA
      }
    }
    update_third_party_administrators_dashboard_elements (
      where: { third_party_administrator_id: { _eq: $tpaId }}, _set: { deleted_at: "now()" },
    ) {
      returning {
        id
      }
    }

  }
  ${tpaFragment}
  ${schoolsTPAFragment}
`;
