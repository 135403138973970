import React from 'react';
import { Link } from 'react-router-dom';

// CSSS
import './BreadCrumb.css';

interface BreadCrumbProps {
  crumbs: any,
  className?: string,
}

const BreadCrumb = React.memo(({ crumbs, className = '' }: BreadCrumbProps) => (
  <header className={`topbar topbar-expand-lg topbar-inverse p-0 ${className}`}>
    <header className="header header-inverse w-100">
      <div className="header-info mt-20 mb-40">
        <div className="left">
          <ol className="breadcrumb mb-0 pl-0">
            {crumbs
              && crumbs.map((crumb: any, index: number) => (
                index === crumbs.length - 1 ? (
                  <li
                    key={`crumb-${crumb.title}`}
                    className="breadcrumb-item active"
                  >
                    {crumb.title}
                  </li>
                )
                  : (
                    <li key={`crumb-${crumb.title}`} className="breadcrumb-item">
                      <Link to={{ pathname: crumb.to, state: crumb.state }}>{crumb.title}</Link>
                    </li>
                  )
              ))}
          </ol>
        </div>
      </div>
    </header>
  </header>
));

export default BreadCrumb;
