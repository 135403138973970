import React from 'react';

export const StateContext = React.createContext<StateContextProps>({
  children: undefined,
  data: undefined,
  setData: () => {},
  step: undefined,
  setStep: () => {},
});

export const StateContextProvider = function WizardProvider({
  children,
  data,
  setData,
  step,
  setStep,
}: StateContextProps) {
  return (
    <StateContext.Provider
      value={{
        data,
        setData,
        step,
        setStep,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
