import { gql } from '@apollo/client';

import {
  tpaFragment,
  tpaSoftFragment,
  tpaSchoolsFragment,
} from 'data/fragments';

export const getPaginatedTpas = gql`
  query getPaginatedTpas(
    $where: third_party_administrators_bool_exp,
    $offset: Int,
    $limit: Int,
    $order_by: [third_party_administrators_order_by!],
  ) {
    # Aggregation
    third_party_administrators_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    third_party_administrators(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...tpa
    }
  }
  ${tpaFragment}
`;

export const getTpaById = gql`
query getTpaById($id: uuid!) {
  third_party_administrators_by_pk(id: $id) {
    ...tpa
  }
}
${tpaFragment}
`;

export const getTPAsForDropdown = gql`
  query getTPAsForDropdown {
    third_party_administrators {
      ...tpa
    }
  }
  ${tpaSoftFragment}
`;

export const getSchoolsTpaById = gql`
  query getTpaById($id: uuid!) {
    third_party_administrators_by_pk(id: $id) {
      ...tpa
    }
  }
  ${tpaSchoolsFragment}
`;
