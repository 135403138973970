import React from 'react';
import styled, { keyframes } from 'styled-components';

// Theme
import { colors } from '../../theme/theme';

export enum ButtonTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  OUTLINE = 'OUTLINE',
}

interface ButtonProps {
  children: any,
  type: string,
  loading?: boolean,
  disabled?: boolean,
  onClick: () => void,
  className?: string,
  minWidth?: string,
}

interface ButtonStyleProps {
  loading?: boolean,
  customStyle: {
    loading?: string,
    active: {
      border: string,
      backgound: string,
      color: string,
    },
    hover: {
      border: string,
      backgound: string,
      color: string,
    },
    disabled: {
      border: string,
      backgound: string,
      color: string,
    },
  },
  minWidth?: string,
}

const CustomButton = styled.button<ButtonStyleProps>`
  padding: 12px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto-Regular;
  color: ${(props: ButtonStyleProps) => props.customStyle.active.color} ;
  letter-spacing: 0.5px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props: ButtonStyleProps) => props.customStyle.active.backgound};
  border: solid 1px ${(props: ButtonStyleProps) => props.customStyle.active.border};
  min-width: ${(props: ButtonStyleProps) => props.minWidth};
  &:hover {
    color: ${(props: ButtonStyleProps) => props.customStyle.hover.color};
    background-color: ${(props: ButtonStyleProps) => props.customStyle.hover.backgound};
    border: solid 1px ${(props: ButtonStyleProps) => props.customStyle.hover.border};
    i {
      color: ${(props: ButtonStyleProps) => props.customStyle.hover.color};
    }
  }
  &:disabled{
    background-color: ${(props: ButtonStyleProps) => props.customStyle.disabled.backgound};
    border: solid 1px ${(props: ButtonStyleProps) => props.customStyle.disabled.border};
    cursor: not-allowed;
    color: ${(props: ButtonStyleProps) => props.customStyle.disabled.color};
    i {
      color: ${(props: ButtonStyleProps) => props.customStyle.disabled.color};
    }
  }
  &:focus{
    outline: none;
  }
  i {
    color: ${(props: ButtonStyleProps) => props.customStyle.active.color} ;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

// Here we create a component that will rotate everything we pass in over two seconds
const Rotate = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
  font-size: 12px;
  margin-right: 6px;
`;

const primary = {
  active: {
    backgound: colors.lightBlue,
    color: colors.white,
    border: 'transparent',
  },
  hover: {
    backgound: colors.darkBlue,
    color: colors.white,
    border: 'transparent',
  },
  disabled: {
    backgound: colors.disabledGrey,
    color: colors.grey,
    border: 'transparent',
  },
};

const secondary = {
  active: {
    backgound: colors.lightBlue100,
    color: colors.lightBlue,
    border: 'transparent',
  },
  hover: {
    backgound: colors.darkBlue,
    color: colors.white,
    border: 'transparent',
  },
  disabled: {
    backgound: colors.disabledGrey,
    color: colors.grey,
    border: 'transparent',
  },
};

const outline = {
  active: {
    backgound: colors.white,
    color: colors.lightBlue,
    border: colors.lightBlue,
  },
  hover: {
    backgound: colors.white,
    color: colors.dark,
    border: colors.darkBlue,
  },
  disabled: {
    backgound: colors.white,
    color: colors.grey,
    border: colors.lightGrey,
  },
};

const tertiary = {
  active: {
    backgound: colors.grey100,
    color: colors.grey,
    border: 'transparent',
  },
  hover: {
    backgound: colors.grey100,
    color: colors.grey,
    border: colors.grey,
  },
  disabled: {
    backgound: colors.white,
    color: colors.grey,
    border: colors.lightGrey,
  },
};

const Button = React.memo(({
  children,
  type = ButtonTypes.PRIMARY,
  loading = false,
  disabled,
  onClick,
  className = '',
  minWidth = 'unset',
}: ButtonProps) => {
  let style = primary;
  switch (type) {
    case ButtonTypes.PRIMARY:
      style = primary;
      break;
    case ButtonTypes.SECONDARY:
      style = secondary;
      break;
    case ButtonTypes.OUTLINE:
      style = outline;
      break;
    case ButtonTypes.TERTIARY:
      style = tertiary;
      break;

    default:
      break;
  }

  return (
    <CustomButton
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      customStyle={style}
      className={className}
      minWidth={minWidth}
    >
      {loading === true && <Rotate><i className="fa fa-spinner" /></Rotate>}
      {children}
    </CustomButton>
  );
});

export default Button;
