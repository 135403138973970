import React from 'react';
import classnames from 'classnames';

// Styles
import {
  MainContainer,
  StyledCircle,
} from './style';

interface UserAvatarProps {
  className?: string;
  text: string;
}

const UserAvatar = React.memo(({
  className,
  text,
}: UserAvatarProps) => (
  <MainContainer
    className={classnames(className)}
  >
    <StyledCircle>
      {text}
    </StyledCircle>
  </MainContainer>
));

export default UserAvatar;
