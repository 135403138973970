/* eslint-disable no-console */
import axios from 'axios';
// import FileSaver from 'file-saver';
import { SIS_TOKEN } from 'context/Auth';

// eslint-disable-next-line consistent-return
async function getJwtClaimPDF(claimId: string): Promise<string | undefined> {
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }

    const targetUrl = `${process.env.REACT_APP_API}/v1/claims/jwt-pdf/${claimId}`;
    const response = await axios(targetUrl, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });
    if (response && response.data && response.data.success) {
      const { token } = response.data;
      return token || '';
    }
    return undefined;
  } catch (error) {
    console.error('ERROR [getJwtClaimPDF]', error);
  }
}

export const downloadClaimPDF = async function downloadClaimPDF(claimId: string) {
  try {
    const windowReference = window.open();
    if (windowReference) {
      getJwtClaimPDF(claimId).then(jwt => {
        if (jwt) {
          windowReference.location.href = `${process.env.REACT_APP_API}/v1/pdf/claim?jwt=${jwt}`;
        }
      });
    }
  } catch (error) {
    console.error('ERROR [downloadClaimPDF]', error);
  }
};

// eslint-disable-next-line consistent-return
async function getJwtClaimEmailPDF(emailId: string): Promise<string | undefined> {
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }

    const targetUrl = `${process.env.REACT_APP_API}/v1/claims/email/${emailId}/jwt-pdf`;
    const response = await axios(targetUrl, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });
    if (response && response.data && response.data.success) {
      const { token } = response.data;
      return token || '';
    }
    return undefined;
  } catch (error) {
    console.error('ERROR [getJwtClaimEmailPDF]', error);
  }
}

export const downloadClaimEmailPDF = function downloadClaimEmailPDF(emailId: string) {
  try {
    const windowReference = window.open();
    if (windowReference) {
      getJwtClaimEmailPDF(emailId).then(jwt => {
        if (jwt) {
          windowReference.location.href = `${process.env.REACT_APP_API}/v1/pdf/email?jwt=${jwt}`;
        }
      });
    }
  } catch (error) {
    console.error('ERROR [downloadClaimEmailPDF]', error);
  }
};

const DownloadHelper = {
  downloadClaimPDF,
  downloadClaimEmailPDF,
};

export default DownloadHelper;
