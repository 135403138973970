import styled from 'styled-components';

// Components
import CustomModal from 'modals/CustomModal';
import Text from 'components/Text';

export const StyledModal = styled(CustomModal)<{ $isMobile: boolean}>`
  max-width: ${({ $isMobile }) => ($isMobile ? '400px' : '738px')};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const EmailContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto; 
  html {
    width: 100%;
  }
  body { 
    width: 100%;
  }
`;

export const StyledText = styled(Text)`
`;

export const StyledButton = styled.button`
  background: none;
  border: none;
  margin-left: .5em;
  padding-left: 0px;
`;
