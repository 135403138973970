import React from 'react';

// ReactRouter
import { Route, Switch } from 'react-router-dom';

// Utils
import { routes } from 'utils/routes';

// Private Pages
import AppLogged from 'containers/AppLogged';

// Styles
import './App.css';

export interface AppProps { }

export interface AppState {
  loading: boolean;
}

export const App = () => (
  <div className="SCApp">
    <Switch>
      {
        routes.public.map(routeItem => (
          <Route
            key={routeItem.route}
            path={routeItem.route}
            component={routeItem.container}
          />
        ))
      }
      <Route component={AppLogged} />
    </Switch>
  </div>
);
