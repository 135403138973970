/* eslint-disable no-console */
/* eslint-disable*/
import React from 'react';
import useAxios from 'axios-hooks';

export const COUNT = 'sis-count';
export const PAGE_ACTIVE = 'sis-page-active';
export const SIS_TOKEN = 'sis-token';
export const SIS_USER_REMEMBER = 'sis-user-remember';
const REMEMBER_ME = 'sis-remember-me';
const TIME_REFETCH_ME = 300000;

/**
 * Initial State
 */
const initialState: AuthState = {
  loading: false,
  isAuthenticated: false,
  isSignOut: false,
  user: undefined,
  rememberMe: false,
  loginSuccess: false,
  no2fa: false,
};

// Default function
const noop = () => {};

/**
 * Context creation
 */
export const authContext = React.createContext<AuthContext>({
  ...initialState,
  signIn: noop,
  signOut: noop,
  resetErrors: noop,
  updateToken: noop,
  isUserType: () => false,
  userRemembered: () => '',
  getMe: noop,
  twoFactor: noop,
  errors: [],
  user: undefined,
  schools: undefined,
});

export const externalAuthOptions = {
  signOut: () => {},
};

/**
 * Reducer Function
 */
function reducer(state: any, action: any) {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: true,
        isSignOut: false,
        errors: [],
      };
    case 'SIGN_IN':
      return {
        ...state,
        rememberMe: action.rememberMe,
        loading: false,
        loginSuccess: true,
        errors: [],
      };
    case 'TWO_FACTOR':
      localStorage.setItem(REMEMBER_ME, state.rememberMe);
      return {
        ...state,
        user: action.user,
        schools: action.schools,
        loading: false,
        isAuthenticated: true,
        errors: [],
      };
    case 'SIGN_OUT':
      localStorage.removeItem(SIS_TOKEN);
      return {
        ...state,
        user: undefined,
        loading: false,
        isAuthenticated: false,
        isSignOut: true,
        rememberMe: false,
        loginSuccess: false,
        errors: [],
        no2fa: false,
      };
    case 'NO_AUTH':
      localStorage.removeItem(SIS_TOKEN);
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        rememberMe: false,
        loginSuccess: false,
        errors: action.errors || [],
        no2fa: false,
      };
    case 'RESET_ERRORS':
      return {
        ...state,
        errors: [],
      };
    case 'SET_REMEMBER_ME':
      return {
        ...state,
        rememberMe: action.rememberMe,
      };
    case 'LOADING_2FA':
      return {
        ...state,
        loading: true,
        isSignOut: false,
        errors: [],
        no2fa: false,
      };
    case 'NO_2FA':
      return {
        ...state,
        loading: true,
        isSignOut: false,
        errors: [],
        no2fa: true,
      };
    default:
      return state;
  }
}

/**
 * Hook for auth functions and reducer
 */
export function useProvideAuth() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const refInterval = React.useRef<NodeJS.Timeout | null>(null);
  const [, executeLogin] = useAxios(
    {
      baseURL: process.env.REACT_APP_API,
      url: '/v1/auth/login',
      method: 'POST',
    },
    { manual: true }
  );

  const [, executeAuthMe] = useAxios(
    {
      baseURL: process.env.REACT_APP_API,
      url: '/v1/auth/me',
      method: 'POST',
    },
    { manual: true }
  );

  const [, executeTwoFactor] = useAxios(
    {
      url: `${process.env.REACT_APP_API}/v1/auth/verify`,
      method: 'POST',
    },
    {
      manual: true,
    }
  );

  const updateToken = React.useCallback(async (result) => {
    try {
      const { token, user, schools } = result.data;

      localStorage.setItem(SIS_TOKEN, token);
      dispatch({ type: 'TWO_FACTOR', user, schools });
    } catch (err) {
      dispatch({ type: 'NO_AUTH' });
    }
  }, []);

  const signOut = React.useCallback(async () => {
    dispatch({ type: 'LOADING' });
    dispatch({ type: 'SIGN_OUT' });
    if (refInterval.current) {
      clearInterval(refInterval.current);
    }
  }, [refInterval]);

  const getMe = React.useCallback(async () => {
    dispatch({ type: 'LOADING' });
    try {
      const tokenCredentials = localStorage.getItem(SIS_TOKEN);
      const result = await executeAuthMe({
        headers: {
          Authorization: `Bearer ${tokenCredentials}`,
        },
      });
      if (result.data) {
        if (refInterval.current) {
          clearInterval(refInterval.current);
        }
        const interval = setInterval(() => {
          getMe();
        }, TIME_REFETCH_ME);
        refInterval.current = interval;
        updateToken(result);
      } else {
        signOut();
      }
    } catch (err) {
      signOut();
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      const tokenCredentials = localStorage.getItem(SIS_TOKEN);
      const count = localStorage.getItem(COUNT);
      const pageActive = sessionStorage.getItem(PAGE_ACTIVE);
      const rememberMe = localStorage.getItem(REMEMBER_ME);
      dispatch({ type: 'SET_REMEMBER_ME', rememberMe });
      const isAuth =
        tokenCredentials &&
        ((count && parseInt(count, 10) > 0) || pageActive === 'true' || rememberMe === 'true');
      if (!isAuth) {
        dispatch({ type: 'NO_AUTH' });
      } else {
        getMe();
      }
    })();
  }, []);

  const signIn = React.useCallback(
    async (email, password, rememberMe) => {
      try {
        const userRemembered = rememberMe ? localStorage.getItem(SIS_USER_REMEMBER) : null;
        if (userRemembered === email && rememberMe) {
          dispatch({ type: 'NO_2FA' });
        } else {
          dispatch({ type: 'LOADING_2FA' });
        }
        const result = await executeLogin({
          data: {
            email,
            password,
            user_remembered: userRemembered,
          },
        });
        if (result.data) {
          dispatch({ type: 'SIGN_IN', rememberMe });
          if (result.data.token) {
            localStorage.setItem(SIS_TOKEN, result.data.token);
            await getMe();
          }
        } else {
          dispatch({ type: 'NO_AUTH' });
        }
      } catch (err) {
        const errors = [err];
        dispatch({ type: 'NO_AUTH', errors });
      }
    },
    [updateToken, executeLogin]
  );

  const twoFactor = React.useCallback(
    async (email, code, rememberMe) => {
      dispatch({ type: 'LOADING' });
      try {
        const result = await executeTwoFactor({
          data: {
            email,
            code,
          },
        });
        if (result.data) {
          localStorage.setItem(SIS_TOKEN, result.data.token);
          if (rememberMe) {
            localStorage.setItem(SIS_USER_REMEMBER, result.data.email_address);
          } else {
            localStorage.removeItem(SIS_USER_REMEMBER);
          }
          await getMe();
        } else {
          dispatch({ type: 'NO_AUTH' });
        }
      } catch (err) {
        const errors = [err];
        dispatch({ type: 'NO_AUTH', errors });
      }
    },
    [updateToken, executeTwoFactor]
  );

  const resetErrors = React.useCallback(() => {
    dispatch({ type: 'RESET_ERRORS' });
  }, []);

  const isUserType = React.useCallback(
    (type: string) => state.isAuthenticated && state.user.type === type,
    [state]
  );

  const userRemembered = React.useCallback(() => localStorage.getItem(SIS_USER_REMEMBER) || '', []);

  externalAuthOptions.signOut = signOut;

  return {
    ...state,
    signIn,
    signOut,
    resetErrors,
    updateToken,
    isUserType,
    twoFactor,
    getMe,
    userRemembered,
  };
}
