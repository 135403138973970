/* eslint-disable no-console */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

// Styles
import {
  Grid,
  GridItem,
  BottomContainer,
  TextInput,
  SearchButton,
} from './style';

export interface StepSelectStudentFilterProps {
  onSearch: (filters: FilterStudents) => void;
}

const initialValues: FilterStudents = {
  studentName: '',
  academicId: '',
};

const StepSelectStudentFilter = React.memo(({
  onSearch,
}: StepSelectStudentFilterProps) => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const {
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...initialValues,
    },
  });

  // Handlers
  const handleSearch = React.useCallback((data: CommonJSON) => {
    if (data) {
      const {
        studentName,
        academicId,
      } = data;

      const out: FilterStudents = {
        studentName,
        academicId,
      };

      onSearch(out);
    }
  }, [onSearch]);

  return (
    <>
      {/* Grid */}
      <Grid $isMobile={isMobile}>
        {/* Grid Item: Student Name */}
        <GridItem>
          <TextInput
            ref={register({ required: false })}
            name="studentName"
            title={t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SELECT_STUDENT.FORM.STUDENT_NAME_LABEL')}
            icon="las la-user"
            ignoreLastPass
          />
        </GridItem>
        {/* Bottom Container */}
        <BottomContainer>
          {/* Search Button */}
          <SearchButton
            type="PRIMARY"
            onClick={handleSubmit(handleSearch)}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SELECT_STUDENT.FORM.CTA_SEARCH')}
          </SearchButton>
        </BottomContainer>
      </Grid>
    </>
  );
});

export default StepSelectStudentFilter;
