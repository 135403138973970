import { gql } from '@apollo/client';

import { tpaElementFragment } from './tpaElements';

// Fragments
export const tpaFragment = gql`
  fragment tpa on third_party_administrators {
    address_1
    address_2
    city
    claims_email_address
    claims_portal_url
    code
    created_at
    created_by
    deleted_at
    delivery_method
    electronic_claims_email_address
    fax
    id
    name
    phone
    postal_code
    state
    updated_at
    third_party_administrators_dashboard_elements (where: { deleted_at: { _is_null: true }}){
      ...tpaElement
    }
    schools_third_party_administrators{
      id
      school_id
    }
  }
  ${tpaElementFragment}
`;

export const tpaSoftFragment = gql`
  fragment tpa on third_party_administrators {
    id
    name
    code
  }
`;

export const tpaSchoolsFragment = gql`
  fragment tpa on third_party_administrators {
    id
    schools_third_party_administrators{
      id
      school_id
    }
  }
`;
