import React from 'react';

// Components
import Text from 'components/Text';

// Theme
import { colors } from 'theme/theme';

// style
import {
  GreyDiv,
  Container,
  ActionContainer,
} from './style';

interface ClaimViewTitleProps {
  title: string,
  handleEditStep?: () => void,
}

const ClaimViewTitle = ({
  title,
  handleEditStep,
}: ClaimViewTitleProps) => (
  <div className="row mt-3 mb-2">
    <GreyDiv>
      <Container>
        <Text size={18} color={colors.grey} fontFamily="BOLD" lineHeight="27px" className="p-2">{title}</Text>
        {handleEditStep
          && (
            <ActionContainer
              onClick={() => handleEditStep()}
            >
              <i className="las la-edit" />
            </ActionContainer>
          )}
      </Container>
    </GreyDiv>
  </div>
);

export default ClaimViewTitle;
