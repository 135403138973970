export const colors = {
  grey: '#8181a5',
  darkGrey: '#292929',
  grey100: 'rgba(129, 129, 165, 0.1)',
  lightGrey: '#f0f0f3',
  softGrey: '#f5f5fa',
  mediumGrey: '#b0b0b0',
  brownGrey: '#707070',
  disabledGrey: '#f6f6f6',
  lightBlue: '#5e81f4',
  lightBlue100: 'rgba(94, 129, 244, 0.1)',
  lightBlue400: 'rgba(94, 129, 244, 0.4)',
  lightBlue800: 'rgba(94, 129, 244, 0.8)',
  darkBlue: '#465eaa',
  statusBlue: '#6e8df5',
  dark: '#1c1d21',
  white500: 'rgba(255,255,255, 0.5)',
  white: '#ffffff',
  red: '#ff808b',
  lightRed: '#fff2f3',
  green: '#7ce7ac',
  gallery: '#EEEEEE',
  gray50: 'rgba (128 130 163, 0.5)',
  successGreen: '#4bc481',
  softGreen: '#e8fff2',
  softRed: '#ffeff0',
  yellow: '#f4be5e',
  softYellow: '#fffdf8',
  greyHover: '#eeeeee',
};

export const fontWeights = {
  normal: '400',
  medium: '500',
  bold: '700',
  black: '900',
};

export const fontFamilies = {
  roboto: {
    regular: 'Regular',
    medium: 'Medium',
    bold: 'Bold',
  },
  lato: {
    regular: '',
    bold: '',
    black: '',
  },
};

export const theme = {
  colors,
  fontWeights,
  fontFamilies,
};
