/* eslint-disable no-console */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

// Components
import Card from 'components/Card';
import Table from 'components/Table';
import ConfirmationModal from 'modals/ConfirmationModal';

// Data
import {
  deleteSoftSchool, getListSchools,
} from 'data/school';

// Hooks
import { useModal } from 'hooks/useModal';

// Utils
import { availableRoutes } from 'utils/routesList';
import { getSchoolLogo } from 'utils/fileHelper';
import { Toaster } from 'utils/toaster';

// Styles
import { theme } from 'theme/theme';
import {
  TopContainer,
  MiddleContainer,
  BottomContainer,
  Title,
  AddSchoolButton,
  StyledAddCircleIcon,
  StyledTableRow,
  StyledColumn,
  TableText,
  TableStatusBox,
  TableLogo,
  StyledNotes,
  ActionsContainer,
  ActionContainer,
  StyledPageSizeSelector,
  StyledDataTablePaginator,
} from './style';

export interface SchoolResultsProps {
  className?: string;
  items?: School[];
  pageSize: number;
  pagination?: Pagination;
  onRefetch: Function;
  setPageSize: (newPageSize: number) => void;
  onChangePage: (pageNumber: number) => void;
  sortBy: CommonJSON;
  onSort: (column: string) => void;
}

const SchoolResults = React.memo(({
  className,
  items,
  pageSize,
  pagination,
  onRefetch,
  setPageSize,
  onChangePage,
  sortBy,
  onSort,
}: SchoolResultsProps) => {
  // Hooks
  const { t } = useTranslation();
  const history = useHistory();
  const {
    isOpen: isOpenDeleteSchoolConfirmationModal,
    toggleModal: toggleModalDeleteSchool,
  } = useModal();

  // States
  const [schoolToDelete, setSchoolToDelete] = React.useState<School>();

  // Mutations
  const [callDeleteSchool] = useMutation<DeleteSoftSchoolResponse, DeleteSoftSchoolVariables>(deleteSoftSchool, {
    update: (cache, result: { data?: DeleteSoftSchoolResponse | null }) => {
      if (result.data && result.data.update_schools_by_pk) {
        toggleModalDeleteSchool();
        setSchoolToDelete(undefined);
        onRefetch();
      }
    },
    refetchQueries: [{ query: getListSchools }],
    onError: _error => {
      console.error('Error [deleteSoftSchool]', _error);
      Toaster('error', t('TOASTER.ERROR.SCHOOL_DELETION_FAILED'));
    },
  });

  // Handlers
  const handleAddSchool = React.useCallback(() => {
    history.push(availableRoutes.addSchool);
  }, [history]);

  const handleEditSchool = React.useCallback((schoolId: string) => {
    history.push(availableRoutes.editSchool.replace(':id', schoolId));
  }, [history]);

  const handleOnDeleteSchool = React.useCallback(() => {
    if (schoolToDelete) {
      const variables: DeleteSoftSchoolVariables = {
        schoolId: schoolToDelete.id,
      };

      callDeleteSchool({ variables });
    }
  }, [schoolToDelete, callDeleteSchool]);

  const handleDeleteSchool = React.useCallback((schoolId: string) => {
    if (items) {
      const targetSchool = items.find(item => item.id === schoolId);
      if (targetSchool) {
        setSchoolToDelete(targetSchool);
        toggleModalDeleteSchool();
      }
    }
  }, [items, toggleModalDeleteSchool]);

  const handleCloseDeleteSchoolConfirmationModal = React.useCallback(() => {
    toggleModalDeleteSchool();
    setSchoolToDelete(undefined);
  }, [toggleModalDeleteSchool]);

  // Memos
  const options: SizeOptionType[] = React.useMemo(() => {
    const temp: SizeOptionType[] = [
      { value: 25, label: 25 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
    ];
    return temp;
  }, []);

  const totalWidth = 1100;
  const columns: TableColumn[] = [ // React.useMemo(() => {
    // {
    // Total width 1100px
    // const temp: TableColumn[] = [
    {
      title: '',
      sorteable: false,
      width: (55 / totalWidth) * 100,
    },
    {
      title: t('SCHOOLS_PAGE.SCHOOL_RESULT.TABLE.COLUMN_SCHOOL_NAME'),
      sorteable: true,
      order: sortBy.name ? sortBy.name.toUpperCase() : 'ASC',
      width: (305 / totalWidth) * 100,
      justifyContent: 'flex-start',
      onClick: () => { onSort('name'); },
    },
    {
      title: t('SCHOOLS_PAGE.SCHOOL_RESULT.TABLE.COLUMN_SALES_REP'),
      sorteable: true,
      order: sortBy.sales_rep && sortBy.sales_rep.first_name ? sortBy.sales_rep.first_name.toUpperCase() : 'ASC',
      width: (145 / totalWidth) * 100,
      justifyContent: 'flex-start',
      onClick: () => { onSort('sales_rep_name'); },
    },
    {
      title: t('SCHOOLS_PAGE.SCHOOL_RESULT.TABLE.COLUMN_TPA'),
      sorteable: false,
      width: (135 / totalWidth) * 100,
      justifyContent: 'center',
    },
    {
      title: t('SCHOOLS_PAGE.SCHOOL_RESULT.TABLE.COLUMN_NOTES'),
      sorteable: false,
      width: (160 / totalWidth) * 100,
      justifyContent: 'center',
    },
    {
      title: t('SCHOOLS_PAGE.SCHOOL_RESULT.TABLE.COLUMN_STATUS'),
      sorteable: false,
      width: (95 / totalWidth) * 100,
      justifyContent: 'center',
    },
    {
      title: t('SCHOOLS_PAGE.SCHOOL_RESULT.TABLE.COLUMN_LAST_MODIFIED'),
      width: (115 / totalWidth) * 100,
      sorteable: true,
      order: sortBy && sortBy.updated_at ? sortBy.updated_at.toUpperCase() : 'ASC',
      justifyContent: 'center',
      onClick: () => { onSort('updated_at'); },
    },
    {
      title: t('SCHOOLS_PAGE.SCHOOL_RESULT.TABLE.COLUMN_ACTIONS'),
      width: (90 / totalWidth) * 100,
      sorteable: false,
      justifyContent: 'flex-end',
    },
  ];
  /*  return temp;
 }, []); */

  const rows = React.useMemo(() => {
    if (items) {
      return items.map(item => ({
        id: item.id,
        schoolName: item.name,
        schoolLogoUrl: getSchoolLogo(item),
        salesRep: item.sales_rep ? `${item.sales_rep.first_name} ${item.sales_rep.last_name}` : '-',
        tpa: (
          item.schools_third_party_administrators
          && item.schools_third_party_administrators.length > 0
        )
          ? item
            .schools_third_party_administrators
            .map(schoolTPA => ((schoolTPA.third_party_administrator) ? schoolTPA.third_party_administrator.code : ''))
            .join(', ')
          : '-',
        notes: (item.schools_notes && item.schools_notes[0]) ? item.schools_notes[0].note : '-',
        status: item.is_active,
        lastModified: item.updated_at,
      }));
    }
    return [];
  }, [items]);

  return (
    <>
      <Card
        padding="25px 10px 20px;"
        className={classnames(className)}
      >
        {/* Top Container */}
        <TopContainer>
          {/* Title */}
          <Title
            size={18}
            fontFamily={theme.fontFamilies.roboto.bold}
            color={theme.colors.dark}
            weight={theme.fontWeights.bold}
          >
            {t('SCHOOLS_PAGE.SCHOOL_RESULT.CARD_TITLE')}
          </Title>

          {/* Add School Button */}
          <AddSchoolButton
            type="SECONDARY"
            onClick={handleAddSchool}
          >
            <StyledAddCircleIcon
              className="las la-plus-circle"
            />
            {t('SCHOOLS_PAGE.SCHOOL_RESULT.CTA_BUTTON_ADD_SCHOOL_LABEL')}
          </AddSchoolButton>
        </TopContainer>

        {/* Middle Container */}
        <MiddleContainer>
          <Table
            columns={columns}
            rows={rows.map((row, index) => (
              <StyledTableRow
                isOdd={index % 2 === 0}
              >
                {/* Column: School Logo */}
                <StyledColumn
                  width={columns[0].width}
                  $justifyContent={columns[0].justifyContent}
                >
                  <TableLogo
                    src={row.schoolLogoUrl}
                  />
                </StyledColumn>

                {/* Column: School Name */}
                <StyledColumn
                  width={columns[1].width}
                  $justifyContent={columns[1].justifyContent}
                >
                  <TableText
                    $uppercase
                  >
                    {row.schoolName}
                  </TableText>
                </StyledColumn>

                {/* Column: Sales Rep */}
                <StyledColumn
                  width={columns[2].width}
                  $justifyContent={columns[2].justifyContent}
                >
                  <TableText>
                    {row.salesRep}
                  </TableText>
                </StyledColumn>

                {/* Column: TPA */}
                <StyledColumn
                  width={columns[3].width}
                  $justifyContent={columns[3].justifyContent}
                >
                  <TableText>
                    {row.tpa}
                  </TableText>
                </StyledColumn>

                {/* Column: Notes */}
                <StyledColumn
                  width={columns[4].width}
                  $justifyContent={columns[4].justifyContent}
                >
                  <TableText
                    className="text-notes"
                  >
                    <StyledNotes
                      className="w-line-clamp line-clamp-3"
                      title={row.notes}
                    >
                      {row.notes}
                    </StyledNotes>
                  </TableText>
                </StyledColumn>

                {/* Column: Status */}
                <StyledColumn
                  width={columns[5].width}
                  $justifyContent={columns[5].justifyContent}
                >
                  <TableStatusBox
                    status={row.status}
                    truthyLabel={t('SCHOOLS_PAGE.SCHOOL_RESULT.TABLE.LABEL_STATUS_ACTIVE')}
                    falsyLabel={t('SCHOOLS_PAGE.SCHOOL_RESULT.TABLE.LABEL_STATUS_INACTIVE')}
                  />
                </StyledColumn>

                {/* Column: Last Modified */}
                <StyledColumn
                  width={columns[6].width}
                  $justifyContent={columns[6].justifyContent}
                >
                  <TableText>
                    {moment(row.lastModified).format('MM/DD/YYYY')}
                  </TableText>
                </StyledColumn>

                {/* Column: Actions */}
                <StyledColumn
                  width={columns[7].width}
                  $justifyContent={columns[7].justifyContent}
                >
                  <ActionsContainer>
                    {/* Action Delete */}
                    <ActionContainer
                      onClick={() => handleDeleteSchool(row.id)}
                    >
                      <i className="las la-trash-alt" />
                    </ActionContainer>
                    <ActionContainer
                      onClick={() => handleEditSchool(row.id)}
                    >
                      <i className="las la-edit" />
                    </ActionContainer>
                  </ActionsContainer>
                </StyledColumn>

              </StyledTableRow>
            ))}
          />
        </MiddleContainer>

        {/* Bottom Container */}
        <BottomContainer>
          <StyledPageSizeSelector
            pageSize={pageSize}
            setPageSize={setPageSize}
            options={options}
          />
          {
            pagination && (
              <StyledDataTablePaginator
                pagination={pagination}
                onPage={onChangePage}
              />
            )
          }
        </BottomContainer>
      </Card>

      {/* Modals */}
      <ConfirmationModal
        isOpen={isOpenDeleteSchoolConfirmationModal}
        toggle={handleCloseDeleteSchoolConfirmationModal}
        title={t('SCHOOLS_PAGE.SCHOOL_RESULT.DELETE_CONFIRMATION_MODAL.TITLE')}
        text={t('SCHOOLS_PAGE.SCHOOL_RESULT.DELETE_CONFIRMATION_MODAL.MESSAGE')}
        buttonText={t('SCHOOLS_PAGE.SCHOOL_RESULT.DELETE_CONFIRMATION_MODAL.CONFIRM_LABEL')}
        onConfirm={handleOnDeleteSchool}
      />
    </>
  );
});
export default SchoolResults;
