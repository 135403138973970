/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';
import moment from 'moment';

// Data
import states from 'data/static/states.json';
import provinces from 'data/static/provinces.json';
import genders from 'data/static/genders.json';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

// Styles
import {
  StyledModal,
  TopContainer,
  GridItem,
  BottomContainer,
  TextInput,
  AddButton,
  StyledDropdown,
  StyledCheckbox,
  StyledDatePicker,
} from './style';

export interface StudentInfoModalProps {
  isOpen: boolean;
  toggle: () => void;
  student: Student;
  onUpdate: (value: CommonJSON) => void;
}

type StateKey = keyof typeof states;
type ProvinceKey = keyof typeof provinces;
type GenderKey = keyof typeof genders;

const stateOptions: OptionsType<OptionType> = Object.keys(states)
  // @ts-ignore
  .map((key: StateKey) => ({ value: key, label: states[key] }));

const provinceOptions: OptionsType<OptionType> = Object.keys(provinces)
  // @ts-ignore
  .map((key: ProvinceKey) => ({ value: key, label: provinces[key] }));

const genderOptions: OptionsType<OptionType> = Object.keys(genders)
  // @ts-ignore
  .map((key: GenderKey) => ({ value: key, label: genders[key] }));

const getDefaultValues = function getDefaultValues(props?: CommonJSON): CommonJSON {
  const out: any = {
    firstName: '',
    lastName: '',
    academicId: '',
    dob: undefined,
    gender: undefined,
    address1: '',
    address2: '',
    city: '',
    state: null,
    province: null,
    zip: '',
    email: '',
    phone: '',
    canadianAddress: false,
  };

  if (props?.first_name) {
    out.firstName = props.first_name;
  }

  if (props?.last_name) {
    out.lastName = props.last_name;
  }

  if (props?.academic_id) {
    out.academicId = props.academic_id;
  }

  if (props?.date_of_birth) {
    out.dob = new Date(moment(props.date_of_birth).format('MMMM DD, YYYY'));
  }

  if (props?.gender) {
    if (props?.gender === 'X') {
      out.gender = genderOptions.find(option => option.label === 'Neutral');
    } else {
      out.gender = genderOptions.find(option => option.label === props?.gender);
    }
  }

  if (props?.address_1) {
    out.address1 = props.address_1;
  }

  if (props?.address_2) {
    out.address2 = props.address_2;
  }

  if (props?.city) {
    out.city = props.city;
  }

  if (props?.postal_code) {
    out.zip = props.postal_code;
  }

  if (props?.email_address) {
    out.email = props.email_address;
  }

  if (props?.primary_phone) {
    out.phone = props.primary_phone;
  }

  if (props?.country === 'Canada') {
    out.canadianAddress = true;
    if (props.state_prov) {
      out.province = provinceOptions.find(province => province.value === props.state_prov
        || province.label === props.state_prov);
    }
  } else if (props?.state_prov) {
    out.state = stateOptions.find(state => state.value === props.state_prov
      || state.label === props.state_prov);
  }
  return out;
};

const inputMaxSizes = {
  firstName: 75,
  lastName: 75,
  academicId: 20,
  gender: 30,
  address1: 100,
  address2: 100,
  city: 75,
  state: 50,
  zip: 10,
  country: 20,
  email: 100,
  phone: 30,
};

const studentSchema = Yup.object().shape({
  firstName: Yup.string().required('FIRST_NAME_REQUIRED').max(inputMaxSizes.firstName, 'FIRST_NAME_MAX'),
  lastName: Yup.string().required('LAST_NAME_REQUIRED').max(inputMaxSizes.lastName, 'LAST_NAME_MAX'),
  academicId: Yup.string().max(inputMaxSizes.academicId, 'ACADEMIC_ID_INVALID'),
  dob: Yup.date().required('DOB_REQUIRED'),
  gender: Yup.object({
    value: Yup.string().required(),
    label: Yup.string().required(),
  }).required(),
  address1: Yup.string().required().max(inputMaxSizes.address1, 'ADDRESS_1_INVALID'),
  address2: Yup.string().notRequired().max(inputMaxSizes.address2, 'ADDRESS_2_INVALID'),
  city: Yup.string().required().max(inputMaxSizes.city, 'CITY_INVALID'),
  state: Yup.object({
    value: Yup.string(),
    label: Yup.string(),
  }).when('canadianAddress', (canadianAddress: boolean, schema: Yup.AnySchema) => {
    if (!canadianAddress) {
      return schema.required('STATE_REQUIRED');
    }
    return schema;
  }),
  province: Yup.object({
    value: Yup.string(),
    label: Yup.string(),
  }).when('canadianAddress', (canadianAddress: boolean, schema: Yup.AnySchema) => {
    if (canadianAddress) {
      return schema.required('PROVINCE_REQUIRED');
    }
    return schema;
  }),
  zip: Yup.string().required().max(inputMaxSizes.zip, 'ZIP_INVALID'),
  email: Yup.string().email('INVALID_EMAIL').notRequired().max(inputMaxSizes.email, 'EMAIL_MAX'),
  phone: Yup.string().notRequired().max(inputMaxSizes.phone, 'PHONE_INVALID'),
  canadianAddress: Yup.bool().notRequired(), // Turns to "country"
}, [['state', 'province']]).defined();

const StudentInfoModal = React.memo(({
  isOpen,
  toggle,
  student,
  onUpdate,
}: StudentInfoModalProps) => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const {
    register,
    control,
    errors,
    watch,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(studentSchema),
    defaultValues: getDefaultValues(),
  });

  // States

  // Effects
  React.useEffect(() => {
    reset(getDefaultValues(student));
  }, [isOpen, reset, student]);

  // Handlers
  const handleUpdate = React.useCallback(async (data: CommonJSON) => {
    if (data) {
      const variables = {
        ...student,
        first_name: data.firstName,
        last_name: data.lastName,
        academic_id: data.academicId,
        gender: data.gender
          ? data.gender.value === 'N'
            ? 'X'
            : data.gender.label
          : 'X',
        date_of_birth: data.dob,
        email_address: data.email,
        primary_phone: data.phone,
        address_1: data.address1,
        address_2: data.address2,
        city: data.city,
        state_prov: data.canadianAddress
          ? (data.province ? data.province.label : undefined)
          : (data.state ? data.state.label : undefined),
        country: data.canadianAddress ? 'Canada' : 'United States',
        postal_code: data.zip,
      };
      onUpdate(variables);
      toggle();
    }
  }, [onUpdate, student, toggle]);

  const now = React.useMemo(() => new Date(), []);

  const isCanadianAddress: boolean = watch('canadianAddress', false);

  return (
    <StyledModal
      $isMobile={isMobile}
      isOpen={isOpen}
      toggle={toggle}
      title={t('STUDENT_INFO_MODAL.UPDATE_STUDENT_TITLE')}
    >
      {/* Top Container */}
      <TopContainer $isMobile={isMobile}>
        {/* Field: First Name */}
        <GridItem
          className="area-firstname"
        >
          <TextInput
            ref={register()}
            name="firstName"
            title={t('STUDENT_INFO_MODAL.FIRST_NAME_LABEL')}
            icon=""
            maxLength={inputMaxSizes.firstName}
            error={errors.firstName !== undefined}
            ignoreLastPass
            tabIndex={1}
            required
          />
        </GridItem>

        {/* Field: Phone */}
        <GridItem
          className="area-phone"
        >
          <TextInput
            ref={register()}
            name="phone"
            title={t('STUDENT_INFO_MODAL.PHONE_LABEL')}
            icon=""
            maxLength={inputMaxSizes.phone}
            error={errors.phone !== undefined}
            ignoreLastPass
            tabIndex={5}
          />
        </GridItem>

        {/* Field: Last Name */}
        <GridItem
          className="area-lastname"
        >
          <TextInput
            ref={register()}
            name="lastName"
            title={t('STUDENT_INFO_MODAL.LAST_NAME_LABEL')}
            icon=""
            maxLength={inputMaxSizes.lastName}
            error={errors.lastName !== undefined}
            ignoreLastPass
            tabIndex={2}
            required
          />
        </GridItem>

        {/* Field: Address 1 */}
        <GridItem
          className="area-address1"
        >
          <TextInput
            ref={register()}
            name="address1"
            title={t('STUDENT_INFO_MODAL.ADDRESS_1_LABEL')}
            icon=""
            maxLength={inputMaxSizes.address1}
            error={errors.address1 !== undefined}
            ignoreLastPass
            tabIndex={6}
            required
          />
        </GridItem>

        {/* Field: Academic ID */}
        <GridItem
          className="area-student-id"
        >
          <TextInput
            ref={register()}
            name="academicId"
            title={t('STUDENT_INFO_MODAL.ACADEMIC_ID_LABEL')}
            icon=""
            maxLength={inputMaxSizes.academicId}
            error={errors.academicId !== undefined}
            ignoreLastPass
            tabIndex={3}
          />
        </GridItem>

        {/* Field: Address 2 */}
        <GridItem
          className="area-address2"
        >
          <TextInput
            ref={register()}
            name="address2"
            title={t('STUDENT_INFO_MODAL.ADDRESS_2_LABEL')}
            icon=""
            maxLength={inputMaxSizes.address2}
            error={errors.address2 !== undefined}
            ignoreLastPass
            tabIndex={7}
          />
        </GridItem>

        {/* Field: Email */}
        <GridItem
          className="area-email"
        >
          <TextInput
            ref={register()}
            name="email"
            title={t('STUDENT_INFO_MODAL.EMAIL_LABEL')}
            icon=""
            maxLength={inputMaxSizes.email}
            error={errors.email !== undefined}
            ignoreLastPass
            tabIndex={4}
          />
        </GridItem>

        {/* Field: City */}
        <GridItem
          className="area-city"
        >
          <TextInput
            ref={register()}
            name="city"
            title={t('STUDENT_INFO_MODAL.CITY_LABEL')}
            icon=""
            maxLength={inputMaxSizes.city}
            error={errors.city !== undefined}
            ignoreLastPass
            tabIndex={8}
            required
          />
        </GridItem>

        {/* Field: Gender */}
        <GridItem
          className="area-gender"
        >
          <Controller
            control={control}
            name="gender"
            render={({ onChange, value, name }) => (
              <StyledDropdown
                title={t('STUDENT_INFO_MODAL.GENDER_LABEL')}
                name={name}
                selected={value}
                options={genderOptions}
                onSelect={(key, v) => onChange(v)}
                error={errors[name] !== undefined}
                required
              />
            )}
          />
        </GridItem>

        {/* Field: DOB */}
        <GridItem
          className="area-dob"
        >
          <Controller
            control={control}
            name="dob"
            render={({
              onChange, value, name,
            }) => (
              <StyledDatePicker
                title={t('STUDENT_INFO_MODAL.DOB_LABEL')}
                name={name}
                startDate={value}
                setStartDate={(key: string, date: Date | null | [Date, Date]) => onChange(date)}
                maxDate={now}
                showMonthDropdown
                showYearDropdown
                error={errors[name] !== undefined}
                required
              />
            )}
          />
        </GridItem>

        {/* Field Item: State/Province */}
        <GridItem
          className="area-state"
          key={isCanadianAddress ? 1 : 2}
        >
          {
            isCanadianAddress ? (
              <Controller
                control={control}
                name="province"
                render={({ onChange, value, name }) => (
                  <StyledDropdown
                    title={t('STUDENT_INFO_MODAL.PROVINCE_LABEL')}
                    name={name}
                    selected={value}
                    options={provinceOptions}
                    onSelect={(key, v) => onChange(v)}
                    error={errors[name] !== undefined}
                    required
                  />
                )}
              />
            )
              : (
                <Controller
                  control={control}
                  name="state"
                  render={({ onChange, value, name }) => (
                    <StyledDropdown
                      title={t('STUDENT_INFO_MODAL.STATE_LABEL')}
                      name={name}
                      selected={value}
                      options={stateOptions}
                      onSelect={(key, v) => onChange(v)}
                      error={errors[name] !== undefined}
                      required
                    />
                  )}
                />
              )
          }

        </GridItem>

        {/* Field Item: Zip */}
        <GridItem
          className="area-zip"
        >
          <TextInput
            ref={register()}
            name="zip"
            title={t('STUDENT_INFO_MODAL.ZIP_LABEL')}
            icon=""
            maxLength={inputMaxSizes.zip}
            error={errors.zip !== undefined}
            ignoreLastPass
            tabIndex={8}
            required
          />
        </GridItem>

        <GridItem
          className="area-country"
        >
          <Controller
            control={control}
            name="canadianAddress"
            render={({ onChange, value }) => (
              <StyledCheckbox
                checked={value}
                onClick={() => onChange(!value)}
                label={t('STUDENT_INFO_MODAL.CANADIAN_ADDRESS')}
                alternativeStyle
              />
            )}
          />
        </GridItem>

      </TopContainer>

      {/* Bottom Container */}
      <BottomContainer $isMobile={isMobile}>
        {/* Add Button */}
        <AddButton
          type="PRIMARY"
          onClick={handleSubmit(handleUpdate)}
        >
          {t('STUDENT_INFO_MODAL.UPDATE_BUTTON_LABEL')}
        </AddButton>
      </BottomContainer>
    </StyledModal>
  );
});

export default StudentInfoModal;
