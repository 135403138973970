/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';

// ReactStrap
import { Modal } from 'reactstrap';

// Components
import Icon from 'components/Icon';
import Text from 'components/Text';

// Styles
import './CustomModal.css';

// Theme
import { colors } from 'theme/theme';

export interface CustomModalProps {
  className?: string;
  isOpen: boolean;
  toggle: () => void;
  hideClose?: boolean;
  children: any;
  title?: string;
}

const CustomModal = React.memo(({
  className,
  isOpen,
  toggle,
  children,
  hideClose = false,
  title,
}: CustomModalProps) => (
  <Modal
    className={classnames(className, 'CustomModal', 'modal-dialog-centered')}
    isOpen={isOpen}
    toggle={toggle}
  >
    {!hideClose && (
      <div className="text-right">
        <button type="button" className="btn modal-close-button" onClick={toggle}>
          <Icon icon="las la-times-circle" size={24} color={colors.grey} />
        </button>
      </div>
    )}
    {title && <Text fontFamily="Bold" weight="bold" lineHeight="1.5" size={18} className="px-4 pt-2">{title}</Text>}
    <div className="p-4">
      {children}
    </div>
  </Modal>
));

export default CustomModal;
