import { gql } from '@apollo/client';

import {
  userSoftFragment,
} from 'data/fragments';

export const createUser = gql`
  mutation createUser($object: users_insert_input!) {
    insert_users_one(object: $object) {
      ...user
    }
  }
  ${userSoftFragment}
`;

export const updateUser = gql`
  mutation updateUser($userId: uuid!, $input: users_set_input) {
    update_users_by_pk (pk_columns: { id: $userId }, _set: $input) {
      ...user
    }
  }
  ${userSoftFragment}
`;

export const deleteUser = gql`
  mutation deleteUser($userId: uuid!) {
    delete_users_by_pk(id: $userId) {
      ...user
    }
  }
  ${userSoftFragment}
`;

export const deleteSoftUser = gql`
  mutation deleteSoftUser($userId: uuid!) {
    update_users_by_pk (pk_columns: { id: $userId }, _set: { deleted_at: "now()" }) {
      ...user
    }
  }
  ${userSoftFragment}
`;
