import { gql } from '@apollo/client';

// Fragments
export const tpaElementFragment = gql`
  fragment tpaElement on third_party_administrators_dashboard_elements {
    url
    updated_at
    type
    title
    third_party_administrator_id
    id
    created_at
    component
    filename
  }
`;

export const tpaElementFragmentWithOrder = gql`
  fragment tpaElement on third_party_administrators_dashboard_elements {
    url
    updated_at
    type
    title
    third_party_administrator_id
    id
    created_at
    component
    filename
    schools_dashboard_elements_orders(where: {school_id: {_eq: $schoolId}}){
      order
    }
  }
`;
