import { gql } from '@apollo/client';

// Fragments

export const schoolDashboardElementOrderFragment = gql`
  fragment schoolDashboardElementOrder on schools_dashboard_elements_order {
    id
    component
    order
    school_id
  }
`;
