import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

// Components
import Card from 'components/Card';
import CardTitle from 'components/Card/CardTitle';

// Styles
import {
  Grid,
  GridItem,
  BottomContainer,
  TextInput,
  SearchButton,
  ClearButton,
} from './style';

interface SearchSalesRepCardProps {
  onSearch: (filters?: SalesRepFilters) => void;
}

const initialValues = {
  firstName: '',
  lastName: '',
  emailAddress: '',
};

const SearchSalesRepCard = React.memo(({
  onSearch,
}: SearchSalesRepCardProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    formState,
    getValues,
  } = useForm({
    defaultValues: {
      ...initialValues,
    },
  });

  // Handlers
  const handleSearch = React.useCallback((data: CommonJSON) => {
    if (data) {
      const {
        firstName,
        lastName,
        emailAddress,
      } = data;

      const out = {
        firstName,
        lastName,
        emailAddress,
      };

      onSearch(out);
    } else {
      onSearch(undefined);
    }
  }, [onSearch]);

  const handleClear = React.useCallback(() => {
    reset({
      ...initialValues,
    });
    onSearch(initialValues);
  }, [reset, onSearch]);

  const handlePressEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      padding="25px 30px 20px 30px;"
      className="mb-4 mt-5"
      title={(
        <CardTitle>{t('SALES_REPS_PAGE.SALES_REPS_FILTER.CARD_TITLE')}</CardTitle>
      )}
    >
      {/* Grid */}
      <Grid>
        {/* Grid Item: First Name */}
        <GridItem>
          <TextInput
            ref={register({ required: false })}
            name="firstName"
            title={t('SALES_REPS_PAGE.SALES_REPS_FILTER.FIRST_NAME_LABEL')}
            icon="las la-user"
            onKeyDown={handlePressEnter}
          />
        </GridItem>
        {/* Grid Item: Last Name */}
        <GridItem>
          <TextInput
            ref={register({ required: false })}
            name="lastName"
            title={t('SALES_REPS_PAGE.SALES_REPS_FILTER.LAST_NAME_LABEL')}
            icon="las la-user"
            onKeyDown={handlePressEnter}
          />
        </GridItem>
        {/* Grid Item: Email Address */}
        <GridItem>
          <TextInput
            ref={register({ required: false })}
            name="emailAddress"
            title={t('SALES_REPS_PAGE.SALES_REPS_FILTER.EMAIL_LABEL')}
            icon="las la-envelope-open"
            onKeyDown={handlePressEnter}
          />
        </GridItem>
      </Grid>

      {/* Bottom Container */}
      <BottomContainer>
        {/* Clear Button */}
        <ClearButton
          type="SECONDARY"
          disabled={!formState.isDirty}
          onClick={handleClear}
        >
          {t('SALES_REPS_PAGE.SALES_REPS_FILTER.CTA_CLEAR_BUTTON_LABEL')}
        </ClearButton>

        {/* Search Button */}
        <SearchButton
          type="SECONDARY"
          onClick={handleSubmit(handleSearch)}
        >
          {t('SALES_REPS_PAGE.SALES_REPS_FILTER.CTA_SEARCH_BUTTON_LABEL')}
        </SearchButton>
      </BottomContainer>
    </Card>
  );
});

export default SearchSalesRepCard;
