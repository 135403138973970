import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const StudentContext = React.createContext<StudentContextProps>({
  children: undefined,
  getValues: () => { },
  setValue: () => { },
  register: () => { },
  control: undefined,
  errors: undefined,
  watch: () => { },
  handleSubmit: () => { },
  reset: () => { },
  inputMaxSizes: {},
  studentProviders: [],
  setStudentProviders: () => { },
  formState: () => { },
});

const getDefaultValues = function getDefaultValues(): CommonJSON {
  return {
    student: {
      firstName: '',
      lastName: '',
      academicId: '',
      dob: undefined,
      gender: undefined,
      address1: '',
      address2: '',
      city: '',
      state: null,
      province: null,
      zip: '',
      email: '',
      phone: '',
      canadianAddress: false,
    },
    parent: {
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: undefined,
      province: undefined,
      zip: '',
      employerName: '',
      employerPhone: '',
      canadianAddress: false,
    },
    providers: [{
      insuranceCompany: undefined,
      providerName: '',
      insuranceGroupId: '',
      insuranceMemberId: '',
      phone: '',
      primary: null,
      status: null,
    }],
  };
};

const inputMaxSizes = {
  firstName: 75,
  lastName: 75,
  academicId: 20,
  gender: 30,
  address1: 100,
  address2: 100,
  city: 75,
  state: 50,
  zip: 10,
  country: 20,
  email: 100,
  phone: 30,
  providerName: 50,
  insuranceMemberId: 50,
  insuranceGroupId: 50,
};

const studentSchema = Yup.object().shape({
  student: Yup.object().shape({
    firstName: Yup.string().required('FIRST_NAME_REQUIRED').max(inputMaxSizes.firstName, 'FIRST_NAME_MAX'),
    lastName: Yup.string().required('LAST_NAME_REQUIRED').max(inputMaxSizes.lastName, 'LAST_NAME_MAX'),
    academicId: Yup.string().max(inputMaxSizes.academicId, 'ACADEMIC_ID_INVALID'),
    dob: Yup.date().required('DOB_REQUIRED'),
    gender: Yup.object({
      value: Yup.string().required(),
      label: Yup.string().required(),
    }).required(),
    address1: Yup.string().required().max(inputMaxSizes.address1, 'ADDRESS_1_INVALID'),
    address2: Yup.string().notRequired().max(inputMaxSizes.address2, 'ADDRESS_2_INVALID'),
    city: Yup.string().required().max(inputMaxSizes.city, 'CITY_INVALID'),
    state: Yup.object({
      value: Yup.string(),
      label: Yup.string(),
    }).when('canadianAddress', (canadianAddress: boolean, schema: Yup.AnySchema) => {
      if (!canadianAddress) {
        return schema.required('STATE_REQUIRED');
      }
      return schema;
    }),
    province: Yup.object({
      value: Yup.string(),
      label: Yup.string(),
    }).when('canadianAddress', (canadianAddress: boolean, schema: Yup.AnySchema) => {
      if (canadianAddress) {
        return schema.required('PROVINCE_REQUIRED');
      }
      return schema;
    }),
    zip: Yup.string().required().max(inputMaxSizes.zip, 'ZIP_INVALID'),
    email: Yup.string().email('INVALID_EMAIL').notRequired().max(inputMaxSizes.email, 'EMAIL_MAX'),
    phone: Yup.string().notRequired().max(inputMaxSizes.phone, 'PHONE_INVALID'),
    canadianAddress: Yup.bool().notRequired(), // Turns to "country"
  }),
  parent: Yup.object().shape({
    firstName: Yup.string().notRequired().max(inputMaxSizes.firstName, 'FIRST_NAME_MAX'),
    lastName: Yup.string().notRequired().max(inputMaxSizes.lastName, 'LAST_NAME_MAX'),
    address1: Yup.string().notRequired().max(inputMaxSizes.address1, 'ADDRESS_1_INVALID'),
    address2: Yup.string().notRequired().max(inputMaxSizes.address2, 'ADDRESS_2_INVALID'),
    city: Yup.string().notRequired().max(inputMaxSizes.city, 'CITY_INVALID'),
    state: Yup.object({
      value: Yup.string().notRequired(),
      label: Yup.string().notRequired(),
    }).notRequired(),
    province: Yup.object({
      value: Yup.string().notRequired(),
      label: Yup.string().notRequired(),
    }).notRequired(),
    zip: Yup.string().notRequired().max(inputMaxSizes.zip, 'ZIP_INVALID'),
    employerName: Yup.string().notRequired().max(inputMaxSizes.firstName, 'EMPLOYER_NAME_MAX'),
    employerPhone: Yup.string().notRequired().max(inputMaxSizes.phone, 'PHONE_INVALID'),
    canadianAddress: Yup.bool().notRequired(), // Turns to "country"
  }),
  providers: Yup.array().of(
    Yup.object().shape({
      insuranceCompany: Yup.object({
        value: Yup.string().notRequired(), // required('INSURANCE_COMPANY_VALUE_REQUIRED'),
        label: Yup.string().notRequired(), // required('INSURANCE_COMPANY_LABEL_REQUIRED'),
      }).notRequired().nullable(), // required('INSURANCE_COMPANY_REQUIRED'),
      providerName: Yup.string().notRequired().max(inputMaxSizes.providerName, 'INSURANCE_NAME_MAX'),
      insuranceMemberId: Yup.string().notRequired()
        .max(inputMaxSizes.insuranceMemberId, 'INSURANCE_MEMBER_ID_MAX'),
      insuranceGroupId: Yup.string().notRequired().max(inputMaxSizes.insuranceMemberId, 'INSURANCE_GROUP_ID_MAX'),
      primary: Yup.bool().notRequired(),
      status: Yup.string().notRequired().nullable(),
    }),
  ),
}, [['state', 'province']]).defined();

export const useProvideStudent = () => {
  const {
    getValues,
    setValue,
    register,
    control,
    errors,
    watch,
    handleSubmit,
    reset,
    formState,
  } = useForm({
    resolver: yupResolver(studentSchema),
    defaultValues: getDefaultValues(),
  });
  const [studentProviders, setStudentProviders] = useState([]);

  return {
    getValues,
    setValue,
    register,
    control,
    errors,
    watch,
    handleSubmit,
    reset,
    inputMaxSizes,
    studentProviders,
    setStudentProviders,
    formState,
  };
};
