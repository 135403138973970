/* eslint-disable no-console */
import axios from 'axios';
import { SIS_TOKEN } from 'context/Auth';

export const getClaimEmail = async function getClaimEmail(emailId: string) {
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }
    const targetUrl = `${process.env.REACT_APP_API}/v1/claims/email/${emailId}`;

    const response = await axios(targetUrl, {
      method: 'get',
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });

    if (response && response.data) {
      return response.data.email;
    }
    return null;
  } catch (error) {
    console.error('ERROR [getClaimEmail]', error);
    return null;
  }
};

export const resendClaimEmail = async function resendClaimEmail(emailId: string) {
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }
    const targetUrl = `${process.env.REACT_APP_API}/v1/claims/email/${emailId}`;

    const response = await axios(targetUrl, {
      method: 'post',
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });
    if (response) {
      return {
        success: true,
      };
    }
  } catch (error) {
    console.error('ERROR [getClaimEmail]', error);
    return {
      success: false,
    };
  }
  return {
    success: false,
  };
};

export const resendClaim = async function resendClaim(claimId: string) {
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }
    const targetUrl = `${process.env.REACT_APP_API}/v1/claims/${claimId}/resend-email`;

    const response = await axios(targetUrl, {
      method: 'post',
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });
    if (response) {
      return {
        success: true,
      };
    }
  } catch (error) {
    console.error('ERROR [getClaimEmail]', error);
    return {
      success: false,
    };
  }
  return {
    success: false,
  };
};

const EmailHelper = {
  getClaimEmail,
  resendClaimEmail,
  resendClaim,
};

export default EmailHelper;
