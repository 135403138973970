import React from 'react';

// Theme
import { colors } from 'theme/theme';

// Components
import Text from 'components/Text';
import Divider from 'components/Divider';
import TableHeader from './TableHeader';

interface TableProps {
  columns: TableColumn[],
  rows: any,
  emptyMessage?: string,
}

const Table = React.memo(({ columns, rows, emptyMessage }: TableProps) => (
  <div className="w-100">
    <TableHeader columns={columns} />
    {emptyMessage && (
      <>
        <Text color={colors.grey} className="my-2">{emptyMessage}</Text>
        <Divider />
      </>
    )}
    {rows}
  </div>
));

export default Table;
