import { gql } from '@apollo/client';

// Fragments
import {
  schoolSoftFragment,
  schoolComplexFragment,
  schoolDashboardFragment,
  schoolAllowImportFragment,
} from 'data/fragments';

export const getPaginatedSchools = gql`
  query getPaginatedSchools(
    $where: schools_bool_exp,
    $offset: Int,
    $limit: Int,
    $order_by: [schools_order_by!],
  ) {
    # Aggregation
    schools_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    schools(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...school
    }
  }
  ${schoolSoftFragment}
`;

export const getSchoolById = gql`
  query getSchoolById($schoolId: uuid!) {
    schools_by_pk(id: $schoolId) {
      ...school
    }
  }
  ${schoolComplexFragment}
`;

export const getListSchools = gql`
  query getDashboardSchools {
    schools(where: { deleted_at: { _is_null: true }, is_active: {_eq: true}}, order_by: {name: asc}) {
      id
      name
    }
  }
`;

export const getSchoolDashboardById = gql`
  query getSchoolDashboardById($schoolId: uuid!) {
    schools_by_pk(id: $schoolId) {
      ...school
    }
  }
  ${schoolDashboardFragment}
`;

export const getSchoolAllowImportById = gql`
  query getSchoolDashboardById($schoolId: uuid!) {
      schools_by_pk(id: $schoolId) {
        ...school
      }
    }
  ${schoolAllowImportFragment}
`;
