import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 8px;
  background-color: #f5f5fa;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  background-color: #f5f5fa;
`;
