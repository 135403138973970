import { gql } from '@apollo/client';
import { studentComplexFragment } from './student';
import { userSoftFragment } from './user';
import { providerFragment } from './provider';
import { claimProviderFragment } from './claims_providers';
import { claimChangeLogsFragment } from './claims_change_logs';

// Fragments
export const claimSoftFragment = gql`
  fragment claim on claims{
    id
    student{
      academic_id
      first_name
      last_name
    }
    schools_third_party_administrator{
      third_party_administrator{
        code
      }
    }
    user{
      id
      first_name
      last_name
    } 
    injury_date
    submitted_to_tpa_at
    status
  }
`;

// Fragments
export const claimAdminFragment = gql`
  fragment claim on claims{
    id
    student{
      academic_id
      first_name
      last_name
    }
    schools_third_party_administrator{
      third_party_administrator{
        code
      }
    }
    user{
      id
      first_name
      last_name
    }
    school{
      id
      name
    }
    injury_date
    submitted_to_tpa_at
    status
  }
`;

export const claimFragment = gql`
  fragment claim on claims {
    id
    claim_id
    created_at
    activity
    activity_level
    activity_type
    benefits_due_another_provider
    deleted_at
    first_treatment_date
    injury_date
    injury_description
    injury_reported_date
    injury_type
    is_insured
    is_previous_injury
    is_work_related
    location_of_injury
    changes_log
    school {
      name
    }
    schools_third_party_administrator {
      id
      policy_number
      third_party_administrator {
        name
      }
    }
    student {
      ...complexStudent
    }
    submitted_to_tpa_at
    updated_at
    user {
      ...user
    }
    claims_providers(where:{deleted_at: {_is_null: true}}){
      ...claim_provider
      provider{
        ...provider
      }
    }
    claims_change_logs(order_by: {submitted_to_tpa_at: desc}) {
      ...claim_change_logs
    }
  }
  ${studentComplexFragment}
  ${userSoftFragment}
  ${claimProviderFragment}
  ${providerFragment}
  ${claimChangeLogsFragment}
`;

export const claimIdFragment = gql`
  fragment claim on claims {
    id
    claim_id
  }
`;
