import React from 'react';

// Theme
import { colors } from 'theme/theme';

// Components
import Icon from 'components/Icon';
import Text from 'components/Text';
import Column from './Column';

interface ColumnHeaderProps {
  width: number,
  title?: string,
  sorteable?: boolean,
  order?: string,
  justifyContent?: string;
  onClick?: () => void;
}

const ColumnHeader = React.memo(({
  width, title = '',
  sorteable = false,
  order,
  justifyContent = 'flex-start',
  onClick,
}: ColumnHeaderProps) => (
  <Column
    width={width}
    className="cursor-pointer"
    justifyContent={justifyContent}
    onClick={onClick}
  >
    {sorteable && (
      <Icon
        icon={order === 'ASC' ? 'las la-sort-alpha-up' : 'las la-sort-alpha-down'}
        className="mr-1"
        color={colors.grey}
      />
    )}
    {' '}
    <Text color={colors.grey} size={12} fontFamily="Bold">
      {title}
    </Text>
  </Column>
));

export default ColumnHeader;
