import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

// Components
import Card from 'components/Card';
import CardTitle from 'components/Card/CardTitle';
import Text from 'components/Text';
import Button, { ButtonTypes } from 'components/Button';
import Table from 'components/Table';
import TableRow from 'components/Table/TableRow';
import Column from 'components/Table/Column';
import Icon from 'components/Icon';
import DataTablePaginator from 'components/Paginator';

// Utils
import { availableRoutes } from 'utils/routesList';
import { Toaster } from 'utils/toaster';

// Mutation
import { DELETE_SALES_REP } from 'data/salesRep/salesRep.mutation';

// Theme
import { colors } from 'theme/theme';
import PageSizeSelector from 'components/PageSizeSelector';

export interface SalesRepsListCardProps {
  items?: SalesRepGrid[],
  pageSize: number,
  pagination?: Pagination;
  setPageSize: (newPageSize: number) => void;
  onChangePage: (pageNumber: number) => void;
  lastPaginationVariables?: GetSalesRepsGridVariables;
  refetchSalesRepsData?: (vars: GetSalesRepsGridVariables) => void;
  sortBy: CommonJSON;
  onSort: (column: string) => void;
}

const SalesRepsListCard = React.memo(({
  items,
  pageSize,
  pagination,
  setPageSize,
  onChangePage,
  lastPaginationVariables,
  refetchSalesRepsData,
  sortBy,
  onSort,
}: SalesRepsListCardProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [deleteSalesRep] = useMutation<DeleteSalesRepsResponse, DeleteSalesRepsVariables>(DELETE_SALES_REP, {
    onError: () => {
      Toaster('error', t('TOASTER.ERROR.SALES_REP_DELETION_FAILED'));
    },
    onCompleted: () => {
      Toaster('success', t('TOASTER.SUCCESS.SALES_REP_DELETED'));
      // Refresh the current Pagination
      if (lastPaginationVariables && refetchSalesRepsData) {
        refetchSalesRepsData(lastPaginationVariables);
      }
    },
  });

  const options: SizeOptionType[] = React.useMemo(() => {
    const temp: SizeOptionType[] = [
      { value: 20, label: 20 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
      { value: 200, label: 200 },
    ];
    return temp;
  }, []);

  const columns: TableColumn[] = [
    {
      title: t('SALES_REPS_PAGE.SALES_REPS_RESULT.TABLE.COLUMN_FULL_NAME'),
      sorteable: true,
      order: sortBy.first_name ? sortBy.first_name.toUpperCase() : 'ASC',
      width: 30,
      onClick: () => { onSort('name'); },
    },
    {
      title: t('SALES_REPS_PAGE.SALES_REPS_RESULT.TABLE.COLUMN_EMAIL'),
      sorteable: true,
      order: sortBy.email_address ? sortBy.email_address.toUpperCase() : 'ASC',
      width: 50,
      onClick: () => { onSort('email_address'); },
    },
    {
      title: t('SALES_REPS_PAGE.SALES_REPS_RESULT.TABLE.COLUMN_LAST_MODIFIED'),
      sorteable: true,
      order: sortBy.updated_at ? sortBy.updated_at.toUpperCase() : 'ASC',
      width: 15,
      onClick: () => { onSort('updated_at'); },
    },
    {
      title: t('SALES_REPS_PAGE.SALES_REPS_RESULT.TABLE.ACTIONS'),
      width: 5,
    },
  ];

  // Handlers
  const handleDelete = useCallback((salesRepId: string) => {
    const variables: DeleteSalesRepsVariables = {
      salesRepId,
      deleted_at: new Date(),
    };
    deleteSalesRep({ variables });
  }, []);

  const handleEdit = useCallback((salesRepId: string) => {
    history.push(availableRoutes.editSalesReps.replace(':id', salesRepId));
  }, []);

  const handleNewSalesRep = useCallback(() => {
    history.push(availableRoutes.newSalesReps);
  }, []);

  const rows = React.useMemo(() => {
    if (items) {
      return items.map(item => ({
        id: item.id,
        fullName: `${item.last_name} ${item.first_name}`,
        emailAddress: item.email_address,
        lastModified: item.updated_at,
      }));
    }
    return [];
  }, [items]);

  return (
    <Card
      padding="25px 30px 20px 30px;"
      className="mb-4"
      title={(
        <div className="d-flex justify-content-between align-items-center">
          <CardTitle>{t('SALES_REPS_PAGE.SALES_REPS_RESULT.CARD_TITLE')}</CardTitle>
          <Button onClick={handleNewSalesRep} type={ButtonTypes.SECONDARY}>
            <Icon
              icon="las la-plus-circle"
              size={18}
              className="mr-2"
            />
            {t('SALES_REPS_PAGE.SALES_REPS_RESULT.CTA_BUTTON_NEW_SALES_REP_LABEL')}
          </Button>
        </div>
      )}
    >
      <Table
        columns={columns}
        rows={rows.map((row, index) => (
          <TableRow isOdd={index % 2 === 0}>
            <Column width={columns[0].width}><Text>{row.fullName}</Text></Column>
            <Column width={columns[1].width}><Text>{row.emailAddress}</Text></Column>
            <Column width={columns[2].width}><Text>{moment(row.lastModified).format('MM/DD/YYYY')}</Text></Column>
            <Column width={columns[3].width} className="d-flex justify-content-center">
              <Icon icon="las la-trash-alt" onClick={() => { handleDelete(row.id); }} size={20} color={colors.grey} className="cursor-pointer" />
              <Icon icon="las la-edit" onClick={() => { handleEdit(row.id); }} size={20} color={colors.grey} className="cursor-pointer" />
            </Column>
          </TableRow>
        ))}
      />
      <div className="d-flex justify-content-between my-4">
        <PageSizeSelector
          pageSize={pageSize}
          setPageSize={setPageSize}
          options={options}
        />
        {
          pagination && (
            <DataTablePaginator
              onPage={onChangePage}
              pagination={pagination}
            />
          )
        }
      </div>
    </Card>
  );
});

export default SalesRepsListCard;
