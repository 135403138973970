/* eslint-disable no-console */
import React from 'react';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { get } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';

// Hooks
import { useResponsive } from 'hooks/useResponsive';
import useStateContext from 'hooks/useStateContext';
import useAuth from 'hooks/useAuth';

// Data
import { ReportNewClaimSteps } from 'data/static/ReportNewClaimSteps';
import states from 'data/static/states.json';
import provinces from 'data/static/provinces.json';

// Components
import Card from 'components/Card';
import Text from 'components/Text';
import ClaimViewTitle from 'components/ClaimViewTitle';
import ClaimViewLine from 'components/ClaimViewLine';
import ClaimFraudText from 'components/ClaimFraudText';
import { Status } from 'components/ThreeStatusBox/ThreeStatusBox';

// Theme
import { theme } from 'theme/theme';

// Mutation
import { updateStudent } from 'data/student';

// Query
import { getActivities } from 'data/activity';
import { getSchoolById } from 'data/school';
import { getTPAById } from 'data/schools_tpa';

// Utils
import { Toaster } from 'utils/toaster';

// Const
import { SIS_TOKEN } from 'context/Auth';

// style
import {
  Title,
  TopContainer,
  ProviderContainer,
  ClaimFraudContainer,
  StyledStepButtons,
  OverlayContainer,
} from './style';

const StepClaimPreview = React.memo(() => {
  const {
    data,
    setStep,
    setData,
  } = useStateContext();
  const { t } = useTranslation();
  const { user } = useAuth();
  const params = useParams<URLParams>();
  // NOTE: If we have an claimId then we are editing, else we are creating a new claim
  // const { claimId } = params;
  const history = useHistory();
  const { isMobile } = useResponsive();
  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  type StateKey = keyof typeof states;
  type ProvinceKey = keyof typeof provinces;

  const stateOptions: OptionsType<OptionType> = Object.keys(states)
    // @ts-ignore
    .map((key: StateKey) => ({ value: key, label: states[key] }));

  const provinceOptions: OptionsType<OptionType> = Object.keys(provinces)
    // @ts-ignore
    .map((key: ProvinceKey) => ({ value: key, label: provinces[key] }));

  // Queries
  const {
    loading: loadingGetActivities,
    data: dataGetActivites,
  } = useQuery<GetActivitiesResponse, GetActivitiesVariables>(getActivities);

  const {
    data: dataGetSchool,
  } = useQuery<GetSchoolByIdResponse, GetSchoolByIdVariables>(getSchoolById, {
    variables: {
      schoolId: params.id || '',
    },
  });

  const {
    data: dataGetTPA,
  } = useQuery<GetSchoolTPAByIdResponse, GetSchoolTPAByIdVariables>(getTPAById, {
    variables: {
      tpaId: data?.formStepClaimInformation?.tpaId || '',
    },
  });

  // Mutation
  const [callUpdateStudent] = useMutation<UpdateStudentResponse, UpdateStudentVariables>(updateStudent, {
    onError: _error => {
      // TODO:
      console.error('ERROR [updateStudent]', _error);
    },
  });

  // Axios Call
  const tokenCredentials = localStorage.getItem(SIS_TOKEN);
  const [{
    data: dataSubmit, loading: loadingSubmit,
  }, executeSubmit] = useAxios(
    {
      url: `${process.env.REACT_APP_API}/v1/claims`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokenCredentials}`,
      },
    },
    {
      manual: true,
    },
  );

  const [{
    data: dataEdit, loading: loadingEdit,
  }, executeEdit] = useAxios(
    {
      url: `${process.env.REACT_APP_API}/v1/claims/${params.claimId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokenCredentials}`,
      },
    },
    {
      manual: true,
    },
  );

  // Memos
  const activity = React.useMemo(() => {
    if (data?.formStepClaimInformation
      && data?.formStepClaimInformation.activity
      && !loadingGetActivities
      && dataGetActivites
      && dataGetActivites.activities) {
      const act = dataGetActivites.activities.find(a => a.id === data?.formStepClaimInformation.activity);
      if (act) {
        return t(act.activity);
      }
    }
    return '';
  }, [data, loadingGetActivities, dataGetActivites]);

  React.useEffect(() => {
    if (dataSubmit && !loadingSubmit) {
      Toaster('success', t('TOASTER.SUCCESS.CLAIM_CREATED'));
      setSubmitDisabled(false);
      history.push('/');
    }
  }, [dataSubmit]);

  React.useEffect(() => {
    if (dataEdit && !loadingEdit) {
      Toaster('success', t('TOASTER.SUCCESS.CLAIM_UPDATED'));
      setSubmitDisabled(false);
      history.push('/');
    }
  }, [dataEdit]);

  // Handlers
  const handlePrev = React.useCallback(() => {
    setData((prevData?: ReportNewClaimWizard) => ({
      ...prevData,
    }));

    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_FILL_SIGNATURE,
    }));
  }, [setStep]);

  const handleNext = React.useCallback(() => {
    setSubmitDisabled(true);
    try {
      if (data?.fromStateViewStudent.studentUpdated) {
        // Update student if needed
        callUpdateStudent({
          variables: {
            studentId: data?.fromStateViewStudent.student.id,
            input: {
              first_name: data?.fromStateViewStudent.student.first_name,
              last_name: data?.fromStateViewStudent.student.last_name,
              gender: data?.fromStateViewStudent.student.gender,
              date_of_birth: data?.fromStateViewStudent.student.date_of_birth,
              email_address: data?.fromStateViewStudent.student.email_address,
              primary_phone: data?.fromStateViewStudent.student.primary_phone,
              address_1: data?.fromStateViewStudent.student.address_1,
              address_2: data?.fromStateViewStudent.student.address_2,
              city: data?.fromStateViewStudent.student.city,
              state_prov: data?.fromStateViewStudent.student.state_prov,
              country: data?.fromStateViewStudent.student.country,
              postal_code: data?.fromStateViewStudent.student.postal_code,
              parents_info: data?.fromStateViewStudent.student.parents_info,
              updated_at: new Date(),
              academic_id: data?.fromStateViewStudent.student.academic_id,
            },
          },
        });
      }

      if (data?.fromStateViewStudent
        && data?.formStepClaimInformation
        && data?.formStepInsuranceInformation) {
        const providers = data.formStepInsuranceInformation.studentsProvider || [];
        const input: CreateClaimVariables = {
          claim_id: params.claimId || undefined,
          school_id: params.id || '',
          school_third_party_administrator_id: data.formStepClaimInformation.tpaId || '',
          student_id: data.fromStateViewStudent.student.id,
          student_address: {
            address_1: data.fromStateViewStudent.student.address_1 || '',
            address_2: data.fromStateViewStudent.student.address_2 || '',
            city: data.fromStateViewStudent.student.city || '',
            state_prov: data.fromStateViewStudent.student.state_prov || '',
            postal_code: data.fromStateViewStudent.student.postal_code || '',
            country: data.fromStateViewStudent.student.country || '',
          },
          parent_info: data.fromStateViewStudent.student.parents_info ? {
            first_name: data.fromStateViewStudent.student.parents_info?.first_name || '',
            last_name: data.fromStateViewStudent.student.parents_info?.last_name || '',
            address_1: data.fromStateViewStudent.student.parents_info?.address_1 || '',
            address_2: data.fromStateViewStudent.student.parents_info?.address_2 || '',
            city: data.fromStateViewStudent.student.parents_info?.city || '',
            state: data.fromStateViewStudent.student.parents_info?.state_prov || '',
            postal_code: data.fromStateViewStudent.student.parents_info?.postal_code || '',
            country: data.fromStateViewStudent.student.parents_info?.country || '',
            employer_name: data.fromStateViewStudent.student.parents_info?.employer_name || '',
            employer_phone: data.fromStateViewStudent.student.parents_info?.employer_phone || '',
          } : {},
          injury_date: moment(data.formStepClaimInformation.injuryDate as Date).format('YYYY-MM-DD'),
          injury_reported_date: moment(data.formStepClaimInformation.injuryReportedDate as Date).format('YYYY-MM-DD'),
          first_treatment_date: moment(data.formStepClaimInformation.firstTreatmentDate as Date).format('YYYY-MM-DD'),
          activity,
          activity_type: data.formStepClaimInformation.activityType || '',
          activity_level: data.formStepClaimInformation.activityLevel || '',
          is_work_related: data.formStepClaimInformation.isWorkRelated as boolean,
          is_previous_injury: data.formStepClaimInformation.isPreviousInjury as boolean, // TOFIX
          location_of_injury: data.formStepClaimInformation.locationOfInjury || '',
          injury_description: data.formStepClaimInformation.injuryDescription || '',
          injury_type: data.formStepClaimInformation.injuryType || '',
          is_insured: true, // TO CHECK
          submitted_to_tpa_at: moment(new Date()).format('YYYY-MM-DD'), // TO CHECK
          benefits_due_another_provider: data.formStepClaimInformation.benefitsDueAnotherProvider || false,
          students_providers: providers.map(insurance => ({
            id: insurance.insuranceCompanyId !== 'OTHER' ? insurance.insuranceCompanyId : null,
            is_other_provider: insurance.insuranceCompanyId === 'OTHER',
            provider_name: insurance.insuranceCompanyId === 'OTHER' && insurance.providerName ? insurance.providerName : null,
            phone: insurance.phone || null,
            insurance_member_id: insurance.insuranceMemberId || null,
            insurance_group_id: insurance.insuranceGroupNumber || null,
          })),
          status: Status.STATUS_PENDING,
        };
        if (params.claimId) {
          executeEdit({
            data: input,
          });
        } else {
          executeSubmit({
            data: input,
          });
        }
      }
    } catch {
      setSubmitDisabled(false);
    }
  }, [data, params, activity]);

  const handleEditStep = React.useCallback((step: string) => {
    // Change the step
    setStep((s: Step) => ({
      ...s,
      step,
      editMode: true,
    }));
  }, [setStep]);

  return (
    <Card
      padding="25px 30px 20px;"
    >
      {/* Top Container (Title and CTA Button) */}
      <TopContainer>
        {/* Title */}
        <Title
          size={18}
          fontFamily={theme.fontFamilies.roboto.bold}
          color={theme.colors.dark}
          weight={theme.fontWeights.bold}
        >
          {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_PREVIEW.STEP_TITLE')}
        </Title>
      </TopContainer>
      {submitDisabled
        && (
          <OverlayContainer />
        )}
      {data
        && data?.fromStateViewStudent
        && data?.formStepClaimInformation
        && data?.formStepInsuranceInformation
        && (
          <>
            {data.formStepClaimPreview?.claim_id
              && (
                <Text size={18} color={theme.colors.dark} fontFamily="BOLD" className="text-left mt-2 mb-4 ml-2">
                  <div className="d-flex">
                    {t('CLAIM_ID')}
                    :
                    <Text size={18} color={theme.colors.dark} className="ml-2">{data.formStepClaimPreview?.claim_id}</Text>
                  </div>
                </Text>
              )}
            <ClaimViewTitle title={t('APPROVED')} />
            <ClaimViewLine field={`${t('POLICY_NUMBER')}:`} value={dataGetTPA?.schools_third_party_administrators_by_pk?.policy_number || ''} />
            <ClaimViewLine field={`${t('ORGANIZATION_SCHOOL_NAME')}:`} value={dataGetSchool?.schools_by_pk?.name || ''} />

            <ClaimViewTitle title={t('ACCIDENT_DETAILS')} handleEditStep={() => handleEditStep(ReportNewClaimSteps.STEP_FILL_CLAIM_INFORMATION)} />
            <ClaimViewLine field={`${t('DATE_TIME_ACCIDENT')}:`} value={moment(data.formStepClaimInformation.injuryDate as Date).format('YYYY-MM-DD')} />
            <ClaimViewLine field={`${t('INJURY_REPORTED_DATE')}:`} value={moment(data.formStepClaimInformation.injuryReportedDate as Date).format('YYYY-MM-DD')} />
            <ClaimViewLine field={`${t('ACTIVITY_SPORT_INJURY')}:`} value={activity} />
            <ClaimViewLine field={`${t('ACTIVITY_TYPE')}:`} value={data.formStepClaimInformation.activityType || ''} />

            <ClaimViewLine field={`${t('ACTIVITY_LEVEL')}:`} value={data.formStepClaimInformation.activityLevel || ''} />
            <ClaimViewLine field={`${t('ACCIDENT_DUE_EMPLOYMENT')}?:`} value={(data.formStepClaimInformation.isWorkRelated as boolean) ? t('YES') : t('NO')} />
            <ClaimViewLine field={`${t('HAVE_BEEN_TRATED_BEFORE')}:`} value={(data.formStepClaimInformation.isPreviousInjury as boolean) ? t('YES') : t('NO')} />
            <ClaimViewLine field={`${t('LOCATION_ACCIDENT')}:`} value={data.formStepClaimInformation.locationOfInjury || ''} />
            <ClaimViewLine field={`${t('DESCRIPTION_ACCIDENT')}:`} value={data.formStepClaimInformation.injuryDescription || ''} />
            <ClaimViewLine field={`${t('TYPE_INJURY')}:`} value={data.formStepClaimInformation.injuryType || ''} />
            <ClaimViewLine field={`${t('FIRST_TREATMENT_DATE')}:`} value={moment(data.formStepClaimInformation.firstTreatmentDate as Date).format('YYYY-MM-DD')} />

            <ClaimViewTitle title={t('CLAIMANT_INFORMATION')} handleEditStep={() => handleEditStep(ReportNewClaimSteps.STEP_VIEW_STUDENT)} />
            <ClaimViewLine field={`${t('FIRST_NAME')}:`} value={data?.fromStateViewStudent.student.first_name} />
            <ClaimViewLine field={`${t('LAST_NAME')}:`} value={data?.fromStateViewStudent.student.last_name} />
            <ClaimViewLine field={`${t('STUDENT_ID')}:`} value={data?.fromStateViewStudent.student.academic_id} />
            <ClaimViewLine field={`${t('EMAIL')}:`} value={data?.fromStateViewStudent.student.email_address || ''} />
            <ClaimViewLine field={`${t('GENDER')}:`} value={data?.fromStateViewStudent.student.gender || ''} />
            <ClaimViewLine className="mb-2" field={`${t('DATE_BIRTH')}:`} value={moment(data?.fromStateViewStudent.student.date_of_birth).format('MM/DD/YYYY')} />

            <ClaimViewLine field={`${t('PHONE')}:`} value={data?.fromStateViewStudent.student.primary_phone || ''} />
            <ClaimViewLine field={`${t('ADDRESS_1')}:`} value={data?.fromStateViewStudent.student.address_1 || ''} />
            <ClaimViewLine className="mb-2" field={`${t('ADDRESS_2')}:`} value={data?.fromStateViewStudent.student.address_2 || ''} />

            <ClaimViewLine field={`${t('CITY')}:`} value={data?.fromStateViewStudent.student.city || ''} />
            <ClaimViewLine
              field={`${t('STATE')}:`}
              value={
                data?.fromStateViewStudent.student.country === 'Canada'
                  ? provinceOptions.find(prov => prov.value === data?.fromStateViewStudent.student.state_prov
                    || prov.label === data?.fromStateViewStudent.student.state_prov)?.label || ''
                  : stateOptions.find(state => state.value === data?.fromStateViewStudent.student.state_prov
                    || state.label === data?.fromStateViewStudent.student.state_prov)?.label || ''
              }
            />
            <ClaimViewLine field={`${t('ZIP')}:`} value={data?.fromStateViewStudent.student.postal_code || ''} />
            <ClaimViewLine field={`${t('CANADIAN_ADDRESS')}:`} value={data?.fromStateViewStudent.student.country === 'United States' ? t('NO') : t('YES')} />

            {data?.fromStateViewStudent.student.parents_info && (
              <>
                <ClaimViewTitle title={t('PARENT_GUARDIAN')} handleEditStep={() => handleEditStep(ReportNewClaimSteps.STEP_VIEW_STUDENT)} />
                <ClaimViewLine field={`${t('FIRST_NAME')}:`} value={data.fromStateViewStudent.student.parents_info?.first_name || ''} />
                <ClaimViewLine className="mb-2" field={`${t('LAST_NAME')}:`} value={data.fromStateViewStudent.student.parents_info?.last_name} />

                <ClaimViewLine field={`${t('ADDRESS_1')}:`} value={data.fromStateViewStudent.student.parents_info?.address_1} />
                <ClaimViewLine field={`${t('ADDRESS_2')}:`} value={data.fromStateViewStudent.student.parents_info?.address_2} />
                <ClaimViewLine field={`${t('CITY')}:`} value={data.fromStateViewStudent.student.parents_info?.city || ''} />
                <ClaimViewLine field={`${t('STATE')}:`} value={data.fromStateViewStudent.student.parents_info?.state_prov || ''} />
                <ClaimViewLine field={`${t('ZIP')}:`} value={data.fromStateViewStudent.student.parents_info?.postal_code || ''} />
                <ClaimViewLine className="mb-2" field={`${t('CANADIAN_ADDRESS')}:`} value={data.fromStateViewStudent.student.parents_info?.country === 'United States' ? t('NO') : t('YES')} />

                <ClaimViewLine field={`${t('EMPLOYER_NAME')}:`} value={data.fromStateViewStudent.student.parents_info?.employer_name || ''} />
                <ClaimViewLine field={`${t('EMPLOYER_PHONE')}:`} value={data.fromStateViewStudent.student.parents_info?.employer_phone || ''} />
              </>
            )}
            <ClaimViewTitle title={t('NAME_OF_ALL_COMPANIES')} handleEditStep={() => handleEditStep(ReportNewClaimSteps.STEP_FILL_INSURANCE_INFORMATION)} />
            {data.formStepInsuranceInformation.studentsProvider
              && data.formStepInsuranceInformation.studentsProvider.map(insurance => (
                <ProviderContainer>
                  <ClaimViewLine field={`${t('INSURANCE_COMPANY_NAME')}:`} value={insurance.insuranceCompanyId === 'OTHER' && insurance.providerName ? insurance.providerName : insurance.insuranceCompanyLabel} />
                  <ClaimViewLine field={`${t('MEMBER_ID')}:`} value={insurance.insuranceMemberId} />
                  <ClaimViewLine field={`${t('GROUP_NUMBER')}:`} value={insurance.insuranceGroupNumber || ''} />
                  <ClaimViewLine field={`${t('PHONE')}:`} value={insurance.phone || ''} />
                </ProviderContainer>
              ))}

            <ClaimViewTitle title={t('ELECTRONIC_SIGNATURE')} />
            <ClaimViewLine field={`${t('DATED')}:`} value={moment(new Date()).format('YYYY-MM-DD')} />
            <ClaimViewLine field={`${t('SUBMITTED_BY')}:`} value={`${user?.first_name} ${user?.last_name}`} />
            <ClaimViewLine
              field={`${t('CLAIMS_ADMINISTRATOR')}:`}
              value={get(dataGetTPA, 'schools_third_party_administrators_by_pk.third_party_administrator.name', '')}
            />
            <ClaimViewTitle title={t('AUTHORIZATION_LANGUAGE')} />
            <ClaimFraudContainer $isMobile={isMobile}>
              <ClaimFraudText />
            </ClaimFraudContainer>

            {data.formStepClaimPreview
              && data.formStepClaimPreview.claims_change_logs
              && data.formStepClaimPreview.claims_change_logs.length > 0
              && (
                <>
                  <ClaimViewTitle title={t('CHANGES_LOG')} />
                  <ProviderContainer>
                    <ClaimViewLine field={`${t('DATED')}:`} value={data.formStepClaimPreview.submitted_to_tpa_at ? moment(data.formStepClaimPreview.submitted_to_tpa_at).format('MM/DD/YYYY') : ''} />
                    <ClaimViewLine field={`${t('SUBMITTED_BY')}:`} value={`${data.formStepClaimPreview.user.last_name} ${data.formStepClaimPreview.user.first_name}`} />
                    <ClaimViewLine field={`${t('CHANGES')}:`} value={data.formStepClaimPreview.changes_log || t('NO_CHANGES')} />
                  </ProviderContainer>
                </>
              )}

            {data.formStepClaimPreview
              && data.formStepClaimPreview.claims_change_logs
              && data.formStepClaimPreview.claims_change_logs.map(changeLog => (
                <ProviderContainer>
                  <ClaimViewLine field={`${t('DATED')}:`} value={changeLog.submitted_to_tpa_at ? moment(changeLog.submitted_to_tpa_at).format('MM/DD/YYYY') : ''} />
                  <ClaimViewLine field={`${t('SUBMITTED_BY')}:`} value={`${changeLog.user.last_name} ${changeLog.user.first_name}`} />
                  <ClaimViewLine field={`${t('CHANGES')}:`} value={changeLog.changes_log || t('NO_CHANGES')} />
                </ProviderContainer>
              ))}
            {/* Step Buttons */}
            <StyledStepButtons
              $isMobile={isMobile}
              showPrev
              showNext
              nextLabel={t('STEP_BUTTONS.SUBMIT_LABEL')}
              showNextIcon={false}
              nextDisabled={submitDisabled}
              onPrev={handlePrev}
              onNext={handleNext}
            />
          </>
        )}
    </Card>
  );
});

export default StepClaimPreview;
