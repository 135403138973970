/* eslint-disable no-console */
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

// Utils
import { Logger } from './utils/logger';

// Context
import { externalAuthOptions, SIS_TOKEN } from './context/Auth';

const logger = new Logger('client.ts');

const superLink = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        logger.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }
    if (networkError) {
      logger.log(`[Network error]: ${networkError}`);
      externalAuthOptions.signOut();
    }
  }),
  setContext((_, { headers }) => {
    const token = window.localStorage.getItem(SIS_TOKEN) || '';

    const out = {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };

    return out;
  }),
  new BatchHttpLink({
    credentials: 'same-origin',
    uri: `${process.env.REACT_APP_API_GRAPHQL || ''}/`,
  }),
]);
export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: superLink,
});
