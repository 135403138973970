import styled from 'styled-components';

// Components
import Card from 'components/Card';
import Button from 'components/Button';
import Text from 'components/Text';

// Theme
import { theme } from 'theme/theme';

export const MainCard = styled(Card)`
  margin-top: 40px;
`;

export const InnerHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const BackButton = styled(Button)`
  width: 40px;
  height: 40px;
`;

export const SchoolNameText = styled(Text)`
  margin-left: 20px;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
`;

export const TabButton = styled(Button)<{ $selected: boolean }>`
  margin-right: 20px;
  padding: 0 12px;
  line-height: 38px;

  &:last-of-type {
    margin-right: 0px;
  }

  ${({ $selected }) => ($selected ? `
    background-color: ${theme.colors.lightBlue};
    color: ${theme.colors.white};

    > i {
      color: ${theme.colors.white};
    }
  ` : '')}
`;

export const TabButtonIcon = styled.i`
  margin-right: 10px;
  font-size: 16px;
`;

export const TabContainer = styled.div`
  margin-top: 50px;
`;
