import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Component
import AccordionLine from 'components/AccordionLine';

// Theme
import { colors } from 'theme/theme';

// style
import {
  AccordionItemCard,
  StyledIcon,
  IconContainer,
} from './style';

interface ClaimItem {
  id?: string,
  fullName: string,
  schoolName?: string,
  academic_id: string,
  tpa?: string,
  submittedBy: string,
  dateOfAccident: Date,
}

interface ClaimAccordionItemProps {
  row: ClaimItem, // Claim row
  handleViewClaim: (claimId?: string) => void,
}

const ClaimAccordionItem = React.memo(({
  row,
  handleViewClaim,
}: ClaimAccordionItemProps) => {
  const { t } = useTranslation();
  return (
    <AccordionItemCard className="d-block">
      <AccordionLine field={`${t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.COLUMN_STUDENT_ID')}:`} value={row.academic_id} />
      <AccordionLine field={`${t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.COLUMN_TPA')}:`} value={row.tpa || ''} />
      <AccordionLine field={`${t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.COLUMN_SUBMITTED_BY')}:`} value={row.submittedBy} />
      <AccordionLine field={`${t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.COLUMN_DATE_OF_ACCIDENT')}:`} value={moment(row.dateOfAccident).format('MM/DD/YYYY')} />
      <IconContainer>
        <StyledIcon icon="las la-eye" onClick={() => { handleViewClaim(row.id); }} size={20} color={colors.grey} className="cursor-pointer" />
      </IconContainer>
    </AccordionItemCard>
  );
});

export default ClaimAccordionItem;
