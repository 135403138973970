import { gql } from '@apollo/client';

// Fragments

export const schoolNoteFragment = gql`
  fragment schoolNote on schools_notes {
    id
    note
    school_id
    created_by
    created_at
    updated_at
  }
`;
