import { gql } from '@apollo/client';

// Fragments

export const schoolDashboardContactFragment = gql`
  fragment schoolDashboardContact on schools_dashboard_contacts {
    id
    type
    title
    component
    company_name 
    representative_name
    mail_address
    phone
    fax
    school_id
    created_at
    updated_at
    email_address
  }
`;

export const schoolDashboardContactFragmentWithOrder = gql`
  fragment schoolDashboardContact on schools_dashboard_contacts {
    id
    type
    title
    component
    company_name 
    representative_name
    mail_address
    phone
    fax
    school_id
    created_at
    updated_at
    email_address
    schools_dashboard_elements_orders{
      order
    }
  }
`;
