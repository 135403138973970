/* eslint-disable no-console */
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Table from 'components/Table';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

// Styles
import {
  MiddleContainer,
  BottomContainer,
  SelectStudentButton,
  StyledTableRow,
  StyledColumn,
  TableText,
  StyledPageSizeSelector,
  StyledDataTablePaginator,
} from './style';

export interface StepSelectStudentResultsProps {
  className?: string;
  schoolId: string;
  items?: Student[];
  pageSize: number;
  pagination?: Pagination;
  onRefetch: Function;
  setPageSize: (newPageSize: number) => void;
  onChangePage: (pageNumber: number) => void;
  onSelectStudent: (studentId: string) => void;
  sortBy: CommonJSON;
  onSort: (column: string) => void;
}

const StepSelectStudentResults = React.memo(({
  items,
  pageSize,
  pagination,
  setPageSize,
  onChangePage,
  onSelectStudent,
  sortBy,
  onSort,
}: StepSelectStudentResultsProps) => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  // Handlers
  const handleSelectStudent = React.useCallback((studentId: string) => {
    onSelectStudent(studentId);
  }, [onSelectStudent]);

  // Memos
  const options: SizeOptionType[] = React.useMemo(() => {
    const temp: SizeOptionType[] = [
      { value: 25, label: 25 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
    ];
    return temp;
  }, []);

  const columns: TableColumn[] = React.useMemo(() => {
    // Total width 1100px
    const totalWidth = 1100;
    const temp: TableColumn[] = [
      {
        title: t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SELECT_STUDENT.RESULTS.TABLE.COLUMN_STUDENT_NAME'),
        sorteable: true,
        order: Array.isArray(sortBy) && sortBy[0] && sortBy[0].last_name ? sortBy[0].last_name.toUpperCase() : 'ASC',
        width: (800 / totalWidth) * 100,
        justifyContent: 'flex-start',
        onClick: () => { onSort('claimant_name'); },
      },
      {
        title: t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SELECT_STUDENT.RESULTS.TABLE.COLUMN_ACTIONS'),
        width: (300 / totalWidth) * 100,
        sorteable: false,
        justifyContent: 'flex-end',
      },
    ];
    return temp;
  }, [t, sortBy]);

  const rows = React.useMemo(() => {
    if (items) {
      return items.map(item => ({
        id: item.id,
        academicId: item.academic_id,
        studentName: `${item.last_name} ${item.first_name} `,
      }));
    }
    return [];
  }, [items]);

  return (
    <>
      {/* Middle Container */}
      <MiddleContainer>
        <Table
          columns={columns}
          rows={rows.map((row, index) => (
            <StyledTableRow
              isOdd={index % 2 === 0}
            >

              {/* Column: Student Name */}
              <StyledColumn
                width={columns[0].width}
                $justifyContent={columns[0].justifyContent}
              >
                <TableText
                  $uppercase
                  $bold
                >
                  {row.studentName}
                </TableText>
              </StyledColumn>

              {/* Column: Actions */}
              <StyledColumn
                width={columns[1].width}
                $justifyContent={columns[1].justifyContent}
              >
                <SelectStudentButton
                  type="OUTLINE"
                  onClick={() => handleSelectStudent(row.id)}
                >
                  {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SELECT_STUDENT.RESULTS.TABLE.CTA_SELECT')}
                </SelectStudentButton>
              </StyledColumn>

            </StyledTableRow>
          ))}
        />
      </MiddleContainer>

      {/* Bottom Container */}
      <BottomContainer $isMobile={isMobile}>
        {!isMobile && (
          <StyledPageSizeSelector
            pageSize={pageSize}
            setPageSize={setPageSize}
            options={options}
          />
        )}
        {
          pagination && (
            <StyledDataTablePaginator
              pagination={pagination}
              onPage={onChangePage}
            />
          )
        }
      </BottomContainer>
    </>
  );
});
export default StepSelectStudentResults;
