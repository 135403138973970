import styled from 'styled-components';

// Theme
// import { theme } from 'theme/theme';

// Components
import SchoolResult from './SchoolResults';

export const StyledSchoolResults = styled(SchoolResult)`
  margin-top: 20px;
`;
