/* eslint-disable no-console */
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { getSchoolActiveTPAById } from 'data/schools_tpa';

// Hooks
import { useResponsive } from 'hooks/useResponsive';
import useStateContext from 'hooks/useStateContext';

// Data
import { ReportNewClaimSteps } from 'data/static/ReportNewClaimSteps';

// Theme
import { theme } from 'theme/theme';

// Queries
import { getActivities } from 'data/activity';

// Components
import Card from 'components/Card';
import ClaimFraudText from 'components/ClaimFraudText';

// Hookes
import useAuth from 'hooks/useAuth';

// Styles
import {
  StyledStepButtons,
  Title,
  TopContainer,
  Container,
  StyledText,
  StyledCheckbox,
  ClaimFraudContainer,
} from './style';

export interface StepSignatureProps {
  className?: string;
  schoolId: string;
}

const StepSignature = React.memo(({
  className,
  schoolId,
}: StepSignatureProps) => {
  // Handlers
  const {
    data,
    setStep,
    setData,
  } = useStateContext();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { isMobile } = useResponsive();

  // Queries
  const {
    loading: loadingGetActivities,
    data: dataGetActivites,
  } = useQuery<GetActivitiesResponse, GetActivitiesVariables>(getActivities);

  const {
    data: schoolTpa,
  } = useQuery<GetSchoolTPAsResponse, GetSchoolActiveTPAByIdVariables>(getSchoolActiveTPAById, {
    variables: {
      schoolId,
      tpaId: data?.formStepClaimInformation?.tpaId || '',
    },
    fetchPolicy: 'network-only',
  });

  // Memos
  const submitDisabled = useMemo(() => {
    if (data?.formStepSignature && data.formStepSignature.checked) {
      return false;
    }
    return true;
  }, [data]);

  const tpaName = get(schoolTpa, 'schools_third_party_administrators[0].third_party_administrator.name', '-');

  const activity = useMemo(() => {
    if (data?.formStepClaimInformation
      && data?.formStepClaimInformation.activity
      && !loadingGetActivities
      && dataGetActivites
      && dataGetActivites.activities) {
      const act = dataGetActivites.activities.find(a => a.id === data?.formStepClaimInformation.activity);
      if (act) {
        return t(act.activity);
      }
    }
    return '';
  }, [data, loadingGetActivities, dataGetActivites]);

  // Handlers
  const handlePrev = React.useCallback(() => {
    setData((prevData?: ReportNewClaimWizard) => ({
      ...prevData,
      formStepSignature: {
        ...prevData?.formStepSignature,
        checked: false,
      },
    }));

    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_FILL_CLAIM_INFORMATION,
    }));
  }, [setStep]);

  const handleNext = React.useCallback(() => {
    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_CLAIM_PREVIEW,
    }));
  }, [setStep]);

  const handleCheck = React.useCallback(() => {
    setData((prevData?: ReportNewClaimWizard) => ({
      ...prevData,
      formStepSignature: {
        ...prevData?.formStepSignature,
        checked: prevData?.formStepSignature && prevData?.formStepSignature.checked
          ? !prevData?.formStepSignature.checked
          : true,
      },
    }));
  }, []);

  return (
    <Card
      padding="25px 30px 20px;"
      className={classnames(className)}
    >
      {/* Top Container (Title and CTA Button) */}
      <TopContainer>
        {/* Title */}
        <Title
          size={18}
          fontFamily={theme.fontFamilies.roboto.bold}
          color={theme.colors.dark}
          weight={theme.fontWeights.bold}
        >
          {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SIGNATURE.STEP_TITLE')}
        </Title>
      </TopContainer>
      <Container $isMobile={isMobile}>
        <StyledText color={theme.colors.grey} className="mb-2 d-flex">
          {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SIGNATURE.DATED')}
          :
          <StyledText className="ml-2">{moment().format('MM/DD/YYYY')}</StyledText>
        </StyledText>
        <StyledText color={theme.colors.grey} className="mb-2 d-flex">
          {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SIGNATURE.SUBMITTED_BY')}
          :
          <StyledText className="ml-2">{user && `${user.first_name} ${user.last_name}`}</StyledText>
        </StyledText>
        <StyledText color={theme.colors.grey} className="mb-2 d-flex">
          {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SIGNATURE.ACTIVITY_OR_SPORT')}
          :
          <StyledText className="ml-2">
            {activity}
          </StyledText>
        </StyledText>
        <StyledText color={theme.colors.grey} className="mb-5 d-flex">
          {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SIGNATURE.TPA_NAME')}
          :
          <StyledText className="ml-2">
            {tpaName}
          </StyledText>
        </StyledText>
      </Container>
      <div className="d-flex">
        <div className="mr-2">
          <StyledCheckbox
            checked={data?.formStepSignature && data.formStepSignature.checked
              ? data.formStepSignature.checked
              : false}
            onClick={handleCheck}
            alternativeStyle
          />
        </div>
        <StyledText className="ml-2" lineHeight="1.36">{t('AUTHORIZATION_TEXT')}</StyledText>
      </div>
      <ClaimFraudContainer $isMobile={isMobile}>
        <ClaimFraudText />
      </ClaimFraudContainer>
      {/* Step Buttons */}
      <StyledStepButtons
        $isMobile={isMobile}
        showPrev
        showNext
        nextDisabled={submitDisabled}
        onPrev={handlePrev}
        onNext={handleNext}
      />
    </Card>
  );
});
export default StepSignature;
