import { gql } from '@apollo/client';

export const studentProviderFragment = gql`
  fragment studentProvider on students_providers {
    id
    school_id
    student_id
    insurance_provider_id
    insurance_group_id
    insurance_member_id
    provider {
      id
      name
      address
      city
      postal_code
      policy_or_certificate_number
    }
    created_at
    updated_at
    deleted_at
    provider_name
    is_other_provider
    phone
    status
    primary
  }
`;

export const temporalStudentProviderFragment = gql`
  fragment temporalStudentProvider on temporal_students_providers {
    id
    school_id
    student_id
    insurance_provider_id
    insurance_group_id
    insurance_member_id
    provider {
      id
      name
      address
      city
      postal_code
      policy_or_certificate_number
    }
    created_at
    updated_at
    deleted_at
    provider_name
    is_other_provider
    phone
  }
`;
