/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';

// Data
import states from 'data/static/states.json';
import provinces from 'data/static/provinces.json';
import genders from 'data/static/genders.json';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

// Components
import Divider from 'components/Divider';

// Styles
import {
  StyledModal,
  TopContainer,
  GridItem,
  BottomContainer,
  TextInput,
  AddButton,
  StyledDropdown,
  StyledCheckbox,
  EmployerContainer,
} from './style';

export interface ParentInfoModalProps {
  isOpen: boolean;
  toggle: () => void;
  parentInfo?: CommonJSON;
  onUpdate: (info: CommonJSON) => void;
  editMode: boolean;
}

type StateKey = keyof typeof states;
type ProvinceKey = keyof typeof provinces;
type GenderKey = keyof typeof genders;

const stateOptions: OptionsType<OptionType> = Object.keys(states)
  // @ts-ignore
  .map((key: StateKey) => ({ value: key, label: states[key] }));

const provinceOptions: OptionsType<OptionType> = Object.keys(provinces)
  // @ts-ignore
  .map((key: ProvinceKey) => ({ value: key, label: provinces[key] }));

const inputMaxSizes = {
  firstName: 75,
  lastName: 75,
  address1: 100,
  address2: 100,
  city: 75,
  state: 50,
  zip: 10,
  country: 20,
  employerName: 75,
  employerPhone: 30,
};

const getDefaultValues = function getDefaultValues(props?: CommonJSON): CommonJSON {
  const out: any = {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: undefined,
    province: undefined,
    zip: '',
    employerName: '',
    employerPhone: '',
    canadianAddress: false,
  };

  if (props?.first_name) {
    out.firstName = props.first_name;
  }

  if (props?.last_name) {
    out.lastName = props.last_name;
  }

  if (props?.address_1) {
    out.address1 = props.address_1;
  }

  if (props?.address_2) {
    out.address2 = props.address_2;
  }

  if (props?.city) {
    out.city = props.city;
  }

  if (props?.postal_code) {
    out.zip = props.postal_code;
  }

  if (props?.employer_name) {
    out.employerName = props.employer_name;
  }

  if (props?.employer_phone) {
    out.employerPhone = props.employer_phone;
  }

  if (props?.country === 'Canada') {
    out.canadianAddress = true;
    if (props.state_prov) {
      out.province = provinceOptions.find(province => province.value === props.state_prov
        || province.label === props.state_prov);
    }
  } else if (props?.state_prov) {
    out.state = stateOptions.find(state => state.value === props.state_prov
      || state.label === props.state_prov);
  }
  return out;
};

const parentInfoSchema = Yup.object({
  firstName: Yup.string().notRequired().max(inputMaxSizes.firstName, 'FIRST_NAME_MAX'),
  lastName: Yup.string().notRequired().max(inputMaxSizes.lastName, 'LAST_NAME_MAX'),
  address1: Yup.string().notRequired().max(inputMaxSizes.address1, 'ADDRESS_1_INVALID'),
  address2: Yup.string().notRequired().max(inputMaxSizes.address2, 'ADDRESS_2_INVALID'),
  city: Yup.string().notRequired().max(inputMaxSizes.city, 'CITY_INVALID'),
  state: Yup.object({
    value: Yup.string().notRequired(),
    label: Yup.string().notRequired(),
  }).notRequired(),
  province: Yup.object({
    value: Yup.string().notRequired(),
    label: Yup.string().notRequired(),
  }).notRequired(),
  zip: Yup.string().notRequired().max(inputMaxSizes.zip, 'ZIP_INVALID'),
  employerName: Yup.string().notRequired().max(inputMaxSizes.employerName, 'EMPLOYER_NAME_MAX'),
  employerPhone: Yup.string().notRequired().max(inputMaxSizes.employerPhone, 'PHONE_INVALID'),
  canadianAddress: Yup.bool().notRequired(), // Turns to "country"
}).defined();

const ParentInfoModal = React.memo(({
  isOpen,
  toggle,
  parentInfo = {},
  onUpdate,
  editMode,
}: ParentInfoModalProps) => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const {
    register,
    control,
    errors,
    watch,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(parentInfoSchema),
    defaultValues: getDefaultValues(),
  });

  // States

  // Effects
  React.useEffect(() => {
    reset(getDefaultValues(parentInfo));
  }, [isOpen, reset, parentInfo]);

  // Handlers

  // const now = React.useMemo(() => new Date(), []);

  const isCanadianAddress: boolean = watch('canadianAddress', false);

  const handleUpdate = useCallback((out: CommonJSON) => {
    const info = {
      first_name: out.firstName,
      last_name: out.lastName,
      address_1: out.address1,
      address_2: out.address2,
      city: out.city,
      postal_code: out.zip,
      employer_name: out.employerName,
      employer_phone: out.employerPhone,
      country: out.canadianAddress ? 'Canada' : 'United States',
      state_prov: out.canadianAddress
        ? (out.province ? out.province.label : undefined)
        : (out.state ? out.state.label : undefined),
    };
    onUpdate(info);
    toggle();
  }, []);

  return (
    <StyledModal
      $isMobile={isMobile}
      isOpen={isOpen}
      toggle={toggle}
      title={t('PARENT_INFO_MODAL.TITLE')}
    >
      {/* Top Container */}
      <TopContainer $isMobile={isMobile}>
        {/* Field: First Name */}
        <GridItem
          className="area-firstname"
        >
          <TextInput
            ref={register()}
            name="firstName"
            title={t('PARENT_INFO_MODAL.FIRST_NAME_LABEL')}
            icon=""
            maxLength={inputMaxSizes.firstName}
            error={errors.firstName !== undefined}
            ignoreLastPass
            tabIndex={1}
          />
        </GridItem>

        {/* Field: Address 1 */}
        <GridItem
          className="area-address1"
        >
          <TextInput
            ref={register()}
            name="address1"
            title={t('PARENT_INFO_MODAL.ADDRESS_1_LABEL')}
            icon=""
            maxLength={inputMaxSizes.address1}
            error={errors.address1 !== undefined}
            ignoreLastPass
            tabIndex={5}
          />
        </GridItem>

        {/* Field: Last Name */}
        <GridItem
          className="area-lastname"
        >
          <TextInput
            ref={register()}
            name="lastName"
            title={t('PARENT_INFO_MODAL.LAST_NAME_LABEL')}
            icon=""
            maxLength={inputMaxSizes.lastName}
            error={errors.lastName !== undefined}
            ignoreLastPass
            tabIndex={2}
          />
        </GridItem>

        {/* Field: Address 2 */}
        <GridItem
          className="area-address2"
        >
          <TextInput
            ref={register()}
            name="address2"
            title={t('PARENT_INFO_MODAL.ADDRESS_2_LABEL')}
            icon=""
            maxLength={inputMaxSizes.address2}
            error={errors.address2 !== undefined}
            ignoreLastPass
            tabIndex={6}
          />
        </GridItem>

        {/* Field: City */}
        <GridItem
          className="area-city"
        >
          <TextInput
            ref={register()}
            name="city"
            title={t('PARENT_INFO_MODAL.CITY_LABEL')}
            icon=""
            maxLength={inputMaxSizes.city}
            error={errors.city !== undefined}
            ignoreLastPass
            tabIndex={3}
          />
        </GridItem>

        {/* Field Item: State/Province */}
        <GridItem
          className="area-state"
          key={isCanadianAddress ? 1 : 2}
        >
          {
            isCanadianAddress ? (
              <Controller
                control={control}
                name="province"
                render={({ onChange, value, name }) => (
                  <StyledDropdown
                    title={t('PARENT_INFO_MODAL.PROVINCE_LABEL')}
                    name={name}
                    selected={value}
                    options={provinceOptions}
                    onSelect={(key, v) => onChange(v)}
                    error={errors[name] !== undefined}
                  />
                )}
              />
            )
              : (
                <Controller
                  control={control}
                  name="state"
                  render={({ onChange, value, name }) => (
                    <StyledDropdown
                      title={t('PARENT_INFO_MODAL.STATE_LABEL')}
                      name={name}
                      selected={value}
                      options={stateOptions}
                      onSelect={(key, v) => onChange(v)}
                      error={errors[name] !== undefined}
                    />
                  )}
                />
              )
          }

        </GridItem>

        {/* Field Item: Zip */}
        <GridItem
          className="area-zip"
        >
          <TextInput
            ref={register()}
            name="zip"
            title={t('PARENT_INFO_MODAL.ZIP_LABEL')}
            icon=""
            maxLength={inputMaxSizes.zip}
            error={errors.zip !== undefined}
            ignoreLastPass
            tabIndex={8}
          />
        </GridItem>

        <GridItem
          className="area-country"
        >
          <Controller
            control={control}
            name="canadianAddress"
            render={({ onChange, value }) => (
              <StyledCheckbox
                checked={value}
                onClick={() => onChange(!value)}
                label={t('PARENT_INFO_MODAL.CANADIAN_ADDRESS')}
                alternativeStyle
              />
            )}
          />
        </GridItem>

      </TopContainer>

      <Divider className="mb-5" />

      <EmployerContainer $isMobile={isMobile}>
        {/* Field: Employer Name */}
        <GridItem
          className="area-employer-name"
        >
          <TextInput
            ref={register()}
            name="employerName"
            title={t('PARENT_INFO_MODAL.EMPLOYER_NAME_LABEL')}
            icon=""
            maxLength={inputMaxSizes.employerName}
            error={errors.employerName !== undefined}
            ignoreLastPass
            tabIndex={6}
          />
        </GridItem>

        {/* Field: Employer Phone */}
        <GridItem
          className="area-employer-phone"
        >
          <TextInput
            ref={register()}
            name="employerPhone"
            title={t('PARENT_INFO_MODAL.EMPLOYER_PHONE_LABEL')}
            icon=""
            maxLength={inputMaxSizes.employerPhone}
            error={errors.employerPhone !== undefined}
            ignoreLastPass
            tabIndex={6}
          />
        </GridItem>

      </EmployerContainer>

      {/* Bottom Container */}
      <BottomContainer $isMobile={isMobile}>
        {/* Add Button */}
        <AddButton
          type="PRIMARY"
          onClick={handleSubmit(handleUpdate)}
        >
          {editMode ? t('PARENT_INFO_MODAL.EDIT_CTA_BUTTON_LABEL') : t('PARENT_INFO_MODAL.ADD_CTA_BUTTON_LABEL')}
        </AddButton>
      </BottomContainer>
    </StyledModal>
  );
});

export default ParentInfoModal;
