/* eslint-disable no-console */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';

// Data
import {
  getSalesRepsForDropdown,
} from 'data/salesRep/salesRep.query';
import {
  createSchool,
  updateSchool,
} from 'data/school';
import {
  deleteSoftSchoolDashboardElement,
} from 'data/schools_dashboard_elements';
import states from 'data/static/states.json';

// Components
import LoadingComponent from 'components/Loading';

// Hooks
import useAuth from 'hooks/useAuth';

// Utils
import { Toaster } from 'utils/toaster';
import {
  getSchoolLogo,
  getSchoolLogoElement,
  uploadSchoolLogo,
  updateSchoolLogo,
  deleteSchoolLogo,
} from 'utils/fileHelper';
import {
  availableRoutes,
} from 'utils/routesList';

// Styles
import { theme } from 'theme/theme';
import {
  MainContainer,
  Grid,
  GridItem,
  StyledLabel,
  StyledToggle,
  TextInput,
  StyledImageDropZone,
  BottomContainer,
  CancelButton,
  UpdateButton,
  StyledDropdown,
} from './style';

export interface GeneralTabProps {
  className?: string;
  school?: School;
  refetchGetSchoolById: Function;
}

const initialValues = {
  is_active: false,
  name: '',
  schoolLogo: {
    file: undefined,
    url: undefined,
  },
  salesRep: undefined,
  address1: '',
  contactName: '',
  address2: '',
  phone: '',
  email: '',
  city: '',
  state: undefined,
  zip: '',
  allowPiv: false,
  allowStudentsImport: false,
};

const getInitialValues = function getInitialValues(school?: School) {
  if (school) {
    return {
      is_active: school.is_active,
      name: school.name,
      schoolLogo: {
        file: undefined,
        url: getSchoolLogo(school),
      },
      salesRep: school.sales_rep
        ? { value: school.sales_rep.id, label: `${school.sales_rep.first_name} ${school.sales_rep.last_name}` }
        : undefined,
      address1: school.address_1 || '',
      address2: school.address_2 || '',
      contactName: school.contact_name || '',
      phone: school.contact_phone || '',
      email: school.contact_email_address || '',
      city: school.city || '',
      state: school.state
        // @ts-ignore
        ? { value: school.state, label: states[school.state] }
        : undefined,
      zip: school.postal_code || '',
      allowPiv: school.allow_piv,
      allowStudentsImport: school.allow_students_import,
      showVerificationStatus: school.show_verification_status,
    };
  }
  return {
    ...initialValues,
  };
};

const schoolLogoDimensions = {
  width: 100,
  height: 100,
};

const inputMaxSizes = {
  name: 100,
  contactName: 100,
  address1: 100,
  address2: 100,
  city: 75,
  zip: 10,
};

const generalSchema = Yup.object({
  is_active: Yup.boolean().required('STATUS_REQUIRED'),
  name: Yup.string().required('NAME_REQUIRED').max(100, 'NAME_MAX'),
  schoolLogo: Yup.object({
    file: Yup.mixed<File>().notRequired(),
    url: Yup.string().notRequired(),
  }).notRequired(),
  salesRep: Yup.object({
    label: Yup.string().notRequired(),
    value: Yup.string().notRequired(),
  }).notRequired(),
  contactName: Yup.string().notRequired().max(100, 'CONTACT_NAME_MAX'),
  address1: Yup.string().notRequired().max(100, 'ADDRESS_1_MAX'),
  address2: Yup.string().notRequired().max(100, 'ADDRESS_2_MAX'),
  phone: Yup.string().notRequired(),
  email: Yup.string().email('EMAIL_INVALID'),
  city: Yup.string().notRequired().max(75, 'CITY_MAX'),
  state: Yup.object({
    label: Yup.string().notRequired(),
    value: Yup.string().notRequired(),
  }).notRequired(),
  zip: Yup.string().notRequired().max(10, 'ZIP_MAX'),
  allowPiv: Yup.boolean().required(),
  allowStudentsImport: Yup.boolean().required(),
  showVerificationStatus: Yup.boolean().required(),
}).defined();

const GeneralTab = React.memo(({
  className,
  school,
  refetchGetSchoolById,
}: GeneralTabProps) => {
  // Hooks
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useAuth();
  const {
    register,
    errors,
    control,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(generalSchema),
    defaultValues: getInitialValues(school),
  });

  // States
  const [loadingUploadLogo, setLoadingUploadLogo] = React.useState<boolean>(false);

  // Queries
  const {
    loading: loadingGetSalesReps,
    data: dataGetSalesReps,
  } = useQuery<GetSalesRepsResponse, GetSalesRepsVariables>(getSalesRepsForDropdown);

  const [callCreateSchool, {
    loading: loadingCreateSchool,
  }] = useMutation<CreateSchoolResponse, CreateSchoolVariables>(createSchool, {
    onError: () => {
      Toaster('error', t('TOASTER.ERROR.SCHOOL_CREATION_FAILED'));
    },
    onCompleted: () => {
      Toaster('success', t('TOASTER.SUCCESS.SCHOOL_CREATED'));
    },
  });

  const [callUpdateSchool, {
    loading: loadingUpdateSchool,
  }] = useMutation<UpdateSchoolResponse, UpdateSchoolVariables>(updateSchool, {
    onError: () => {
      Toaster('error', t('TOASTER.ERROR.SCHOOL_UPDATE_FAILED'));
    },
    onCompleted: () => {
      Toaster('success', t('TOASTER.SUCCESS.SCHOOL_UPDATED'));
    },
  });

  const [callDeleteSchoolDashboardElement] = useMutation<
  DeleteSoftSchoolDashboardElementResponse,
  DeleteSoftSchoolDashboardElementVariables
  >(deleteSoftSchoolDashboardElement, {
    onError: () => {
      Toaster('error', t('TOASTER.ERROR.SCHOOL_DELETION_FAILED'));
    },
    onCompleted: () => {
      Toaster('success', t('TOASTER.SUCCESS.SCHOOL_DELETED'));
    },
  });

  // Memos
  const loading = React.useMemo(
    () => loadingGetSalesReps || loadingCreateSchool || loadingUploadLogo || loadingUpdateSchool,
    [
      loadingGetSalesReps,
      loadingCreateSchool,
      loadingUploadLogo,
      loadingUpdateSchool,
    ],
  );

  // Handler
  const handleCancel = React.useCallback(() => {
    if (!loading) {
      reset(getInitialValues(school));
    }
  }, [loading, school, reset]);

  const handleUploadNewLogo = React.useCallback(async (schoolId: string, file: File) => {
    let out: string | null = null;
    try {
      setLoadingUploadLogo(true);
      const schoolLogoUrl = await uploadSchoolLogo(schoolId, file);
      out = schoolLogoUrl;
    } catch (error) {
      Toaster('error', t('TOASTER.ERROR.UPLOAD_NEW_LOGO_FAILED'));
    } finally {
      setLoadingUploadLogo(false);
    }
    return out;
  }, []);

  const handleUploadEditLogo = React.useCallback(async (elementId: string, file: File) => {
    let out: string | null = null;
    try {
      setLoadingUploadLogo(true);
      const schoolLogoUrl = await updateSchoolLogo(elementId, file);
      out = schoolLogoUrl;
    } catch (error) {
      Toaster('error', t('TOASTER.ERROR.EDIT_LOGO_FAILED'));
    } finally {
      setLoadingUploadLogo(false);
    }
    return out;
  }, []);

  const handleDeleteLogo = React.useCallback(async (elementId: string) => {
    let out: string | null = null;
    try {
      setLoadingUploadLogo(true);
      const schoolLogoUrl = await deleteSchoolLogo(elementId);
      out = schoolLogoUrl;

      // Soft delete the Element
      const variables: DeleteSoftSchoolDashboardElementVariables = {
        schoolDashboardElementId: elementId,
      };
      await callDeleteSchoolDashboardElement({ variables });
    } catch (error) {
      Toaster('error', t('TOASTER.ERROR.LOGO_DELETION_FAILED'));
    } finally {
      setLoadingUploadLogo(false);
    }
    return out;
  }, []);

  const handleRefetchSchool = React.useCallback(() => {
    refetchGetSchoolById();
  }, [refetchGetSchoolById]);

  const handleUpdate = React.useCallback(async (data: CommonJSON) => {
    if (user) {
      if (school) {
        // Update
        const variables : UpdateSchoolVariables = {
          schoolId: school.id,
          input: {
            is_active: data.is_active,
            name: data.name,
            address_1: data.address1,
            address_2: data.address2,
            contact_name: data.contactName,
            contact_email_address: data.email,
            contact_phone: data.phone,
            city: data.city,
            state: data.state ? data.state.value : undefined,
            postal_code: data.zip,
            sales_rep_id: data.salesRep ? data.salesRep.value : undefined,
            allow_piv: data.allowPiv,
            allow_students_import: data.allowStudentsImport,
            show_verification_status: data.showVerificationStatus,
            updated_at: new Date(),
          },
        };

        callUpdateSchool({ variables });
        if (data.schoolLogo) {
          let needsToRefecth = true;
          if (data.schoolLogo.file) {
            // User wants to update/add an image
            if (getSchoolLogo(school)) {
              // User wants to update the image
              const element = getSchoolLogoElement(school);
              if (element) {
                await handleUploadEditLogo(element.id, data.schoolLogo.file);
              }
            } else {
              // User wants to create the image
              await handleUploadNewLogo(school.id, data.schoolLogo.file);
            }
          } else {
            // User wants to delete/keep the same image
            // eslint-disable-next-line no-lonely-if
            if (getSchoolLogo(school) && !data.schoolLogo.file && !data.schoolLogo.url) {
              // User wants to delete the image
              const element = getSchoolLogoElement(school);
              if (element) {
                await handleDeleteLogo(element.id);
              }
            } else {
              needsToRefecth = false;
            }
          }

          // Refetch
          if (needsToRefecth) {
            handleRefetchSchool();
          }
        }
      } else {
        // Create
        const variables : CreateSchoolVariables = {
          object: {
            is_active: data.is_active,
            name: data.name,
            address_1: data.address1,
            address_2: data.address2,
            contact_name: data.contactName,
            contact_email_address: data.email,
            contact_phone: data.phone,
            city: data.city,
            state: data.state ? data.state.value : undefined,
            postal_code: data.zip,
            sales_rep_id: data.salesRep ? data.salesRep.value : undefined,
            coverage_start_date: new Date(),
            created_by: user.id,
            allow_piv: data.allowPiv,
            allow_students_import: data.allowStudentsImport,
            show_verification_status: data.showVerificationStatus,
            created_at: new Date(),
            updated_at: new Date(),
          },
        };
        // 1) Create the School
        const schoolCreationResult = await callCreateSchool({ variables });
        if (
          schoolCreationResult
          && schoolCreationResult.data
          && schoolCreationResult.data.insert_schools_one) {
          const { id: schoolId } = schoolCreationResult.data.insert_schools_one;
          if (data && data.schoolLogo && data.schoolLogo.file) {
            await handleUploadNewLogo(schoolId, data.schoolLogo.file);
          }

          // NOTE: By changing the route, we enter to the "edit" mode
          history.replace(availableRoutes.editSchool.replace(':id', schoolId));
        }
      }
    }
  }, [
    history,
    school,
    user,
    callCreateSchool,
    callUpdateSchool,
    handleUploadNewLogo,
    handleUploadEditLogo,
    handleDeleteLogo,
    handleRefetchSchool,
  ]);

  // Memos
  const statesList: OptionsType<OptionType> = React.useMemo(
    () => Object.keys(states)
      // @ts-ignore
      .map((key: StateKey) => ({ value: key, label: states[key] })),
    [],
  );

  const salesRepsOptions = React.useMemo(() => {
    if (dataGetSalesReps && dataGetSalesReps.sales_reps) {
      return dataGetSalesReps.sales_reps.map(item => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}`,
      }));
    }
    return [];
  }, [dataGetSalesReps]);

  return (
    <MainContainer
      className={classnames(className)}
    >
      {/* Grid */}
      <Grid>
        {/* Grid Item: Status */}
        <GridItem
          className="area-status"
        >
          {/* Label */}
          <StyledLabel
            size={14}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
            fontFamily={theme.fontFamilies.roboto.regular}
          >
            {`${t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.STATUS.LABEL')}:`}
          </StyledLabel>

          {/* Toggle */}
          <StyledToggle
            ref={register()}
            id="school-general-tab-status"
            defaultChecked={school ? school.is_active : false}
            name="is_active"
            truthyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.STATUS.LABEL_ACTIVE')}
            falsyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.STATUS.LABEL_INACTIVE')}
          />
        </GridItem>

        {/* Grid Item: School Name */}
        <GridItem
          className="area-name"
        >
          <TextInput
            ref={register()}
            maxLength={inputMaxSizes.name}
            name="name"
            title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.SCHOOL_NAME.LABEL')}
            icon="las la-landmark"
            error={errors.name !== undefined}
          />
        </GridItem>

        {/* Grid Item: School Logo */}
        <GridItem
          className="area-logo"
        >
          <Controller
            control={control}
            name="schoolLogo"
            // rules={{ required: true }}
            render={({ onChange, value }) => (
              <StyledImageDropZone
                title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.SCHOOL_LOGO.LABEL')}
                placeholder={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.SCHOOL_LOGO.PLACEHOLDER')}
                icon="las la-cloud-upload-alt"
                constraintsLabel={`JPG, PNG, GIF, SVG - ${schoolLogoDimensions.width} x ${schoolLogoDimensions.height}`}
                constraintsFileSizeLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.SCHOOL_LOGO.CONSTRAINTS_FILE_SIZE')}
                sizeDimensionsLimit={schoolLogoDimensions}
                removeLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.SCHOOL_LOGO.REMOVE_CTA')}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>

        {/* Grid Item: Sales Rep */}
        <GridItem>
          <Controller
            control={control}
            name="salesRep"
            render={({ onChange, value, name }) => (
              <>
                {
                  loadingGetSalesReps ? (
                    <LoadingComponent />
                  ) : (
                    <StyledDropdown
                      title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.SALES_REP.LABEL')}
                      name={name}
                      selected={value}
                      options={salesRepsOptions}
                      onSelect={(key, v) => onChange(v)}
                    />
                  )
                }
              </>
            )}
          />
        </GridItem>

        {/* Grid Item: Address 1 */}
        <GridItem>
          <TextInput
            ref={register()}
            name="address1"
            maxLength={inputMaxSizes.address1}
            title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.ADDRESS_1.LABEL')}
            icon="las la-map-marker-alt"
            error={errors.address1 !== undefined}
          />
        </GridItem>

        {/* Grid Item: Contact Name */}
        <GridItem>
          <TextInput
            ref={register()}
            name="contactName"
            maxLength={inputMaxSizes.contactName}
            title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.CONTACT_NAME.LABEL')}
            icon="las la-sms"
            error={errors.contactName !== undefined}
          />
        </GridItem>

        {/* Grid Item: Address 2 */}
        <GridItem>
          <TextInput
            ref={register()}
            name="address2"
            maxLength={inputMaxSizes.address2}
            title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.ADDRESS_2.LABEL')}
            icon="las la-map-marker-alt"
            error={errors.address2 !== undefined}
          />
        </GridItem>

        {/* Grid Item: Phone */}
        <GridItem>
          <TextInput
            ref={register()}
            name="phone"
            title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.PHONE.LABEL')}
            icon="las la-phone"
            error={errors.phone !== undefined}
          />
        </GridItem>

        {/* Grid Item: City */}
        <GridItem>
          <TextInput
            ref={register()}
            name="city"
            maxLength={inputMaxSizes.city}
            title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.CITY.LABEL')}
            icon="las la-map-marker-alt"
            error={errors.city !== undefined}
          />
        </GridItem>

        {/* Grid Item: Notes */}
        <GridItem
          className="area-notes"
        />

        {/* Grid Item: Email */}
        <GridItem>
          <TextInput
            ref={register()}
            name="email"
            title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.EMAIL.LABEL')}
            icon="las la-envelope-open"
            error={errors.email !== undefined}
          />
        </GridItem>

        {/* Grid Item: State and Zip */}
        <GridItem
          className="as-row"
        >
          {/* State */}
          <Controller
            control={control}
            name="state"
            // rules={{ required: true }}
            render={({ onChange, value, name }) => (
              <StyledDropdown
                title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.STATE.LABEL')}
                name={name}
                selected={value}
                options={statesList}
                onSelect={(key, v) => onChange(v)}
              />
            )}
          />

          {/* Zip */}
          <TextInput
            ref={register()}
            name="zip"
            maxLength={inputMaxSizes.zip}
            title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.ZIP.LABEL')}
            error={errors.zip !== undefined}
          />
        </GridItem>

        {/* Grid Item: Allow PIV */}
        <GridItem className="area-piv">
          {/* Label */}
          <StyledLabel
            size={14}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
            fontFamily={theme.fontFamilies.roboto.regular}
          >
            {`${t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.ALLOW_PIV.LABEL')}:`}
          </StyledLabel>

          {/* Toggle */}
          <StyledToggle
            ref={register()}
            id="school-general-tab-piv"
            defaultChecked={school && school.allow_piv ? school.allow_piv : false}
            name="allowPiv"
            truthyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.ALLOW_PIV.LABEL_ACTIVE')}
            falsyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.ALLOW_PIV.LABEL_INACTIVE')}
          />
        </GridItem>

        {/* Grid Item: Allow students import */}
        <GridItem className="area-students-import">
          {/* Label */}
          <StyledLabel
            size={14}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
            fontFamily={theme.fontFamilies.roboto.regular}
          >
            {`${t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.ALLOW_STUDENTS_IMPORT.LABEL')}:`}
          </StyledLabel>

          {/* Toggle */}
          <StyledToggle
            ref={register()}
            id="school-general-tab-students-import"
            defaultChecked={school && school.allow_students_import ? school.allow_students_import : false}
            name="allowStudentsImport"
            truthyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.ALLOW_STUDENTS_IMPORT.LABEL_ACTIVE')}
            falsyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.ALLOW_STUDENTS_IMPORT.LABEL_INACTIVE')}
          />
        </GridItem>

        {/* Grid Item: Show Verification Status */}
        <GridItem className="area-show-verification-status">
          {/* Label */}
          <StyledLabel
            size={14}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
            fontFamily={theme.fontFamilies.roboto.regular}
          >
            {`${t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.SHOW_VERIFICATION_STATUS.LABEL')}:`}
          </StyledLabel>

          {/* Toggle */}
          <StyledToggle
            ref={register()}
            id="show-verification-status"
            defaultChecked={school && school.show_verification_status ? school.show_verification_status : false}
            name="showVerificationStatus"
            truthyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.SHOW_VERIFICATION_STATUS.LABEL_ACTIVE')}
            falsyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.SHOW_VERIFICATION_STATUS.LABEL_INACTIVE')}
          />
        </GridItem>
      </Grid>

      {/* Bottom Container */}
      <BottomContainer>
        {/* Cancel */}
        <CancelButton
          type="SECONDARY"
          onClick={handleCancel}
        >
          {t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.CTA_BUTTON_CANCEL')}
        </CancelButton>

        {/* Update */}
        <UpdateButton
          type="PRIMARY"
          disabled={loading}
          onClick={handleSubmit(handleUpdate)}
        >
          {t('SCHOOLS_MANAGEMENT_PAGE.TAB_GENERAL.CTA_BUTTON_UPDATE')}
        </UpdateButton>
      </BottomContainer>
    </MainContainer>
  );
});
export default GeneralTab;
