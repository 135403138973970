/* eslint-disable no-console */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import useStateContext from 'hooks/useStateContext';

// Components
import Card from 'components/Card';

// Data
import {
  getPaginatedStudents,
} from 'data/student';
import { ReportNewClaimSteps } from 'data/static/ReportNewClaimSteps';

// Theme
import { theme } from 'theme/theme';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

// Utils
import { getPagination } from 'utils/paginationHelper';

// Steps
import StepSelectStudentFilter from './StepSelectStudentFilter';
import StepSelectStudentAdd from './StepSelectStudentAdd';

// Styles
import {
  StyledSelectStudentResults,
  Title,
} from './style';

export interface StepSelectStudentProps {
  schoolId: string;
}

const StepSelectStudent = React.memo(({
  schoolId,
}: StepSelectStudentProps) => {
  // Hooks
  const {
    setData,
    setStep,
  } = useStateContext();
  // Refs
  const lastPaginationVariables = React.useRef<GetPaginatedStudentsVariables | null>(null);
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  // States
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [filters, setFilters] = React.useState<FilterStudents>();
  const [sortByGql, setSortByGql] = React.useState<any>([{ last_name: 'asc' }, { first_name: 'asc' }]);

  // Queries
  const [callGetPaginatedStudents, {
    data: dataGetPaginatedStudents,
    refetch: refetchGetPaginatedStudents,
  }] = useLazyQuery<GetPaginatedStudentsResponse, GetPaginatedStudentsVariables>(getPaginatedStudents, {
    fetchPolicy: 'network-only',
  });

  // Effects
  React.useEffect(() => {
    const and: CommonJSON[] = [
      {
        deleted_at: { _is_null: true },
      },
      {
        school_id: { _eq: schoolId },
      },
    ];

    if (filters) {
      const {
        academicId,
        studentName,
      } = filters;

      if (academicId) {
        and.push({
          academic_id: { _ilike: `%${academicId}%` },
        });
      }

      if (studentName) {
        and.push({
          _or: [
            {
              first_name: { _ilike: `%${studentName}%` },
            },
            {
              last_name: { _ilike: `%${studentName}%` },
            },
          ],
        });
      }
    }

    const where = {
      _and: and,
    };

    const variables: GetPaginatedSchoolsVariables = {
      where,
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
      order_by: sortByGql,
    };

    lastPaginationVariables.current = variables;

    callGetPaginatedStudents({ variables });
  }, [
    schoolId,
    pageSize,
    currentPage,
    filters,
    callGetPaginatedStudents,
    sortByGql,
  ]);

  // Handlers
  const handleOnSearch = React.useCallback((newFilters?: FilterStudents) => {
    setFilters(newFilters);
  }, []);

  const handleOnPageChange = React.useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  const handleOnPageSizeChange = React.useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  }, []);

  const handleOnRefetch = React.useCallback(() => {
    if (lastPaginationVariables.current && refetchGetPaginatedStudents) {
      refetchGetPaginatedStudents(lastPaginationVariables.current);
    }
  }, [refetchGetPaginatedStudents]);

  // Memos

  const {
    students,
    pagination,
  } = React.useMemo(() => {
    const out: {
      students: Student[];
      pagination?: Pagination;
    } = {
      students: [],
      pagination: undefined,
    };
    if (dataGetPaginatedStudents) {
      out.students = dataGetPaginatedStudents.students;
      const totalItems = dataGetPaginatedStudents.students_aggregate.aggregate.totalCount;
      out.pagination = getPagination(currentPage, totalItems, pageSize);
    }
    return out;
  }, [
    dataGetPaginatedStudents,
    currentPage,
    pageSize,
  ]);

  // Handlers
  const handleOnSelectStudent = React.useCallback((studentId: string) => {
    setData((prevData?: ReportNewClaimWizard) => ({
      ...prevData,
      formStepSelectStudent: {
        ...prevData?.formStepSelectStudent,
        studentId,
      },
    }));
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_VIEW_STUDENT,
    }));
  }, [
    students,
    setData,
    setStep,
  ]);

  const handleSort = React.useCallback(column => {
    setSortByGql((val: any) => {
      switch (column) {
        case 'claimant_name':
          return [
            { last_name: (val[0] && val[0].last_name && val[0].last_name === 'desc') ? 'asc' : 'desc' },
            { first_name: (val[1] && val[1].first_name && val[1].first_name === 'desc') ? 'asc' : 'desc' },
          ];
        case 'student_id':
          return { academic_id: (val && val.academic_id && val.academic_id === 'desc') ? 'asc' : 'desc' };
        default: // injury_reported_date and status
          return { [column]: (val[column] && val[column] === 'desc') ? 'asc' : 'desc' };
      }
    });
  }, []);

  return (
    <>
      <Card
        padding="25px 30px 20px;"
        className="mb-3"
      >
        {/* Title */}
        <Title
          size={18}
          fontFamily={theme.fontFamilies.roboto.bold}
          color={theme.colors.dark}
          weight={theme.fontWeights.bold}
        >
          {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SELECT_STUDENT.FILTER_TITLE')}
        </Title>
      </Card>
      { isMobile
        ? (
          <div className="row">
            <div className="col-12 mb-3">
              <Card
                padding="25px 10px 20px;"
              >
                {/* Title */}
                <Title
                  size={18}
                  fontFamily={theme.fontFamilies.roboto.bold}
                  color={theme.colors.dark}
                  weight={theme.fontWeights.bold}
                  className="mb-4 ml-3"
                >
                  {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SELECT_STUDENT.FORM.ADD_STUDENTS')}
                </Title>
                <StepSelectStudentAdd
                  schoolId={schoolId}
                  onStudentCreate={handleOnSelectStudent}
                />
              </Card>
            </div>
            <div className="col-12">
              <Card
                padding="25px 10px 20px;"
              >
                {/* Title */}
                <Title
                  size={18}
                  fontFamily={theme.fontFamilies.roboto.bold}
                  color={theme.colors.dark}
                  weight={theme.fontWeights.bold}
                  className="ml-3"
                >
                  {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SELECT_STUDENT.FORM.STUDENTS')}
                </Title>
                {/* Student Filter */}
                <StepSelectStudentFilter
                  onSearch={handleOnSearch}
                />

                {/* Student Results */}
                <StyledSelectStudentResults
                  schoolId={schoolId}
                  items={students}
                  pageSize={pageSize}
                  pagination={pagination}
                  onRefetch={handleOnRefetch}
                  setPageSize={handleOnPageSizeChange}
                  onChangePage={handleOnPageChange}
                  onSelectStudent={handleOnSelectStudent}
                  sortBy={sortByGql}
                  onSort={handleSort}
                />
              </Card>
            </div>
          </div>
        )
        : (
          <div className="row">
            <div className="col-6">
              <Card
                padding="25px 10px 20px;"
              >
                {/* Title */}
                <Title
                  size={18}
                  fontFamily={theme.fontFamilies.roboto.bold}
                  color={theme.colors.dark}
                  weight={theme.fontWeights.bold}
                  className="mb-4 ml-3"
                >
                  {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SELECT_STUDENT.FORM.ADD_STUDENTS')}
                </Title>
                <StepSelectStudentAdd
                  schoolId={schoolId}
                  onStudentCreate={handleOnSelectStudent}
                />
              </Card>
            </div>
            <div className="col-6">
              <Card
                padding="25px 10px 20px;"
              >
                {/* Title */}
                <Title
                  size={18}
                  fontFamily={theme.fontFamilies.roboto.bold}
                  color={theme.colors.dark}
                  weight={theme.fontWeights.bold}
                  className="ml-3"
                >
                  {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_SELECT_STUDENT.FORM.STUDENTS')}
                </Title>
                {/* Student Filter */}
                <StepSelectStudentFilter
                  onSearch={handleOnSearch}
                />

                {/* Student Results */}
                <StyledSelectStudentResults
                  schoolId={schoolId}
                  items={students}
                  pageSize={pageSize}
                  pagination={pagination}
                  onRefetch={handleOnRefetch}
                  setPageSize={handleOnPageSizeChange}
                  onChangePage={handleOnPageChange}
                  onSelectStudent={handleOnSelectStudent}
                  sortBy={sortByGql}
                  onSort={handleSort}
                />
              </Card>
            </div>
          </div>
        )}
    </>
  );
});
export default StepSelectStudent;
