/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-console */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Icon from 'components/Icon';
import Text from 'components/Text';

// Hooks
import { useResponsive } from 'hooks/useResponsive';
import useStateContext from 'hooks/useStateContext';
import { useModal } from 'hooks/useModal';

// Theme
import { theme } from 'theme/theme';

// Modals
import ParentInfoModal from 'modals/ParentInfoModal';

// Styles
import {
  Label,
  Value,
  Title,
  EditStudentButton,
  EditStudentButtonContainer,
  TitleContainer,
  InfoContainer,
  OptionalLabelContainer,
} from './style';

const StudentOptionInformation = React.memo(() => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const {
    data,
    setData,
  } = useStateContext();

  // Modals
  const {
    isOpen: isOpenParentInfoModal,
    toggleModal: toggleParentInfoModal,
    openModal: openParentInfoModal,
  } = useModal();

  const hasAdditionalInfo = useMemo(() => {
    if (data?.fromStateViewStudent?.student.parents_info) {
      return true;
    }
    return false;
  }, [data]);

  const isCanadianAddress = useMemo(() => {
    if (data?.fromStateViewStudent?.student.parents_info?.canadian_address === 'Canada'
      || data?.fromStateViewStudent?.student.parents_info?.canadianAddress) {
      return true;
    }
    return false;
  }, [data]);

  const handleOnDelete = useCallback(() => {
    setData((d: ReportNewClaimWizard) => ({
      ...d,
      fromStateViewStudent: {
        student: {
          ...d.fromStateViewStudent.student,
          ...d.fromStateViewStudent,
          parents_info: null,
        },
        studentUpdated: true,
      },
    }));
  }, [setData]);

  const handleAdd = useCallback(() => {
    openParentInfoModal();
  }, [openParentInfoModal]);

  const handleEdit = useCallback(() => {
    openParentInfoModal();
  }, [openParentInfoModal]);

  const handleChangeParentInfo = useCallback((info: CommonJSON) => {
    setData((d: ReportNewClaimWizard) => ({
      ...d,
      fromStateViewStudent: {
        student: {
          ...d.fromStateViewStudent.student,
          parents_info: {
            ...d.fromStateViewStudent.student.parents_info,
            ...info,
          },
        },
        studentUpdated: true,
      },
    }));
  }, [setData]);

  if (data?.fromStateViewStudent) {
    return (
      <div>
        <ParentInfoModal
          isOpen={isOpenParentInfoModal}
          toggle={toggleParentInfoModal}
          parentInfo={data?.fromStateViewStudent.student.parents_info}
          onUpdate={handleChangeParentInfo}
          editMode={hasAdditionalInfo}
        />
        <OptionalLabelContainer>
          <Text>{t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.OPTINAL_INFO')}</Text>
        </OptionalLabelContainer>
        <TitleContainer>
          <Title
            size={18}
            fontFamily={theme.fontFamilies.roboto.bold}
            weight={theme.fontWeights.bold}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.TITLE')}
          </Title>

          {hasAdditionalInfo && (
            <EditStudentButtonContainer>
              <EditStudentButton
                $isMobile={isMobile}
                type="OUTLINE"
                onClick={handleOnDelete}
                className="mr-2"
              >
                <Icon className={`las la-trash-alt ${isMobile ? 'mr-0' : 'mr-2'} `} />
                {!isMobile && t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.DELETE_BUTTON')}
              </EditStudentButton>
              <EditStudentButton
                $isMobile={isMobile}
                type="SECONDARY"
                onClick={handleEdit}
              >
                <Icon className={`las la-edit ${isMobile ? 'mr-0' : 'mr-2'} `} />
                {!isMobile && t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.EDIT_BUTTON')}
              </EditStudentButton>
            </EditStudentButtonContainer>
          )}
        </TitleContainer>

        {hasAdditionalInfo
          ? (
            <InfoContainer>
              {/* First Name */}
              <div className={`row ${isMobile ? 'mb-2' : ''} `}>
                <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
                  <Label>
                    {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.FIRST_NAME_LABEL')}
                    :
                  </Label>
                </div>
                <div className={`${isMobile ? 'col-12' : 'col'} `}>
                  <Value>
                    {data?.fromStateViewStudent.student.parents_info?.first_name
                      || data?.fromStateViewStudent.student.parents_info?.firstName
                      || ''}
                  </Value>
                </div>
              </div>

              {/* Last Name */}
              <div className={`row ${isMobile ? 'mb-5' : 'mb-4'} `}>
                <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
                  <Label>
                    {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.LAST_NAME_LABEL')}
                    :
                  </Label>
                </div>
                <div className={`${isMobile ? 'col-12' : 'col'} `}>
                  <Value>
                    {data?.fromStateViewStudent.student.parents_info?.last_name
                      || data?.fromStateViewStudent.student.parents_info?.lastName
                      || ''}
                  </Value>
                </div>
              </div>

              {/* Address 1 */}
              <div className={`row ${isMobile ? 'mb-2' : ''} `}>
                <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
                  <Label>
                    {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.ADDRESS_1_LABEL')}
                    :
                  </Label>
                </div>
                <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
                  <Value>
                    {data?.fromStateViewStudent.student.parents_info?.address_1
                      || data?.fromStateViewStudent.student.parents_info?.address1
                      || ''}
                  </Value>
                </div>
              </div>

              {/* Address 2 */}
              <div className={`row ${isMobile ? 'mb-2' : ''} `}>
                <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
                  <Label>
                    {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.ADDRESS_2_LABEL')}
                    :
                  </Label>
                </div>
                <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
                  <Value>
                    {data?.fromStateViewStudent.student.parents_info?.address_2
                      || data?.fromStateViewStudent.student.parents_info?.address2
                      || ''}
                  </Value>
                </div>
              </div>

              {/* City */}
              <div className={`row ${isMobile ? 'mb-2' : ''} `}>
                <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
                  <Label>
                    {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.CITY_LABEL')}
                    :
                  </Label>
                </div>
                <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
                  <Value>
                    {data?.fromStateViewStudent.student.parents_info?.city
                      || ''}
                  </Value>
                </div>
              </div>

              {/* State */}
              <div className={`row ${isMobile ? 'mb-2' : ''} `}>
                <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
                  <Label>
                    {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.STATE_LABEL')}
                    :
                  </Label>
                </div>
                <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
                  <Value>
                    {data?.fromStateViewStudent.student.parents_info?.state_prov
                      || (isCanadianAddress && data?.fromStateViewStudent.student.parents_info?.province
                        && data?.fromStateViewStudent.student.parents_info?.province.label)
                      || (!isCanadianAddress && data?.fromStateViewStudent.student.parents_info?.state
                        && data?.fromStateViewStudent.student.parents_info?.state.label)
                      || ''}
                  </Value>
                </div>
              </div>

              {/* Zip code */}
              <div className={`row ${isMobile ? 'mb-2' : ''} `}>
                <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
                  <Label>
                    {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.ZIP_LABEL')}
                    :
                  </Label>
                </div>
                <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
                  <Value>
                    {data?.fromStateViewStudent.student.parents_info?.postal_code
                      || data?.fromStateViewStudent.student.parents_info?.zip
                      || ''}
                  </Value>
                </div>
              </div>

              {/* Canadian address */}
              <div className={`row ${isMobile ? 'mb-5' : 'mb-4'} `}>
                <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
                  <Label>
                    {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.CANADIAN_ADDRESS')}
                    :
                  </Label>
                </div>
                <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
                  <Value>{isCanadianAddress ? t('YES') : t('NO')}</Value>
                </div>
              </div>

              {/* Employer Name */}
              <div className={`row ${isMobile ? 'mb-2' : ''} `}>
                <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
                  <Label>
                    {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.EMPLOYER_NAME')}
                    :
                  </Label>
                </div>
                <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
                  <Value>
                    {data?.fromStateViewStudent.student.parents_info?.employer_name
                      || data?.fromStateViewStudent.student.parents_info?.employerName
                      || ''}
                  </Value>
                </div>
              </div>

              {/* Employer Phone */}
              <div className={`row ${isMobile ? 'mb-2' : ''} `}>
                <div className={`${isMobile ? 'col-12 mb-1' : 'col-4'} `}>
                  <Label>
                    {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.EMPLOYER_PHONE')}
                    :
                  </Label>
                </div>
                <div className={`${isMobile ? 'col-12' : 'col-4'} `}>
                  <Value>
                    {data?.fromStateViewStudent.student.parents_info?.employer_phone
                      || data?.fromStateViewStudent.student.parents_info?.employerPhone
                      || ''}
                  </Value>
                </div>
              </div>

            </InfoContainer>
          )
          : (
            <div className="d-flex justify-content-between align-items-center my-5">
              <Text>
                {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.THERE_IS_NO_INFO')}
              </Text>
              <EditStudentButton
                $isMobile={isMobile}
                type="SECONDARY"
                onClick={handleAdd}
              >
                <Icon className={`las la-plus-circle ${isMobile ? 'mr-0' : 'mr-2'} `} />
                {!isMobile && t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_VIEW_STUDENT.STUDENT_OPTIONAL_INFO.ADD_INFO')}
              </EditStudentButton>
            </div>
          )}

      </div>
    );
  }
  return null;
});

export default StudentOptionInformation;
