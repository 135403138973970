import styled from 'styled-components';
// Theme
import { colors } from 'theme/theme';

// Components
import StepButtons from 'components/StepButtons';
import Text from 'components/Text';
import Checkbox from 'components/Checkbox';

export const TopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Title = styled(Text)``;

export const StyledStepButtons = styled(StepButtons)<{ $isMobile: boolean }>`
  ${({ $isMobile }) => ($isMobile ? 'margin-top: 56px;'
    : 'margin-top: 80px;'
  )}
`;

export const StyledText = styled(Text)``;

export const Container = styled.div<{ $isMobile: boolean }>`
  padding-top: ${({ $isMobile }) => ($isMobile ? '20px' : '40px')};
  padding-left: 35px;
`;

export const StyledCheckbox = styled(Checkbox)``;

export const ClaimFraudContainer = styled.div<{ $isMobile: boolean }>`
  max-height: 500px;
  border: 1px solid ${colors.lightGrey};
  overflow-y: scroll;
  padding: 10px 20px 10px 35px;
  margin-top: ${({ $isMobile }) => ($isMobile ? '25px' : '50px')};
`;
