import React from 'react';
import styled from 'styled-components';

// Theme
import { colors } from '../../theme/theme';

interface TextProps {
  color?: string,
  size?: number,
  weight?: string,
  lineHeight?: string,
  letterSpacing?: string,
  className?: string,
  children: any,
  fontFamily?: string,
  style?: React.CSSProperties,
}

const StyledText = styled.p`
  margin-bottom: 0;

  ${(props : TextProps) => `
    font-family: Roboto-${props.fontFamily};
    color: ${props.color};
    font-size: ${props.size}px;
    font-weight: ${props.weight};
    line-height: ${props.lineHeight};
    letter-spacing: ${props.letterSpacing};
  `}
`;

const Text = React.memo(({
  color = colors.dark,
  size = 14,
  weight = 'normal',
  lineHeight = 'normal',
  letterSpacing = 'normal',
  children,
  className = '',
  fontFamily = 'Regular',
  style,
}: TextProps) => (
  <StyledText
    className={className}
    color={color}
    size={size}
    weight={weight}
    lineHeight={lineHeight}
    letterSpacing={letterSpacing}
    fontFamily={fontFamily}
    style={style}
  >
    {children}
  </StyledText>
));

export default Text;
