import { gql } from '@apollo/client';
import { tpaElementFragment } from './tpaElements';
// Fragments

export const schoolsTPAFragment = gql`
  fragment schoolsTPA on schools_third_party_administrators {
    id
    school_id
    third_party_administrator_id
    policy_number
    tpa_identifier
    effective_date
    activities
    third_party_administrator {
      id
      name
      code
    }
    created_at
    updated_at
    is_active
    is_default
  }
`;

export const schoolsTPAFragmentWithElments = gql`
  fragment schoolsTPA on schools_third_party_administrators {
    id
    school_id
    third_party_administrator_id
    policy_number
    tpa_identifier
    effective_date
    activities
    third_party_administrator {
      id
      name
      code
      third_party_administrators_dashboard_elements {
        ...tpaElement
      }
    }
    created_at
    updated_at
    is_active
    is_default
  }
  ${tpaElementFragment}
`;
