import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

// Theme
import { colors } from 'theme/theme';

interface TableRowProps {
  className?: string;
  isOdd: boolean,
  children: any,
}

const Container = styled.div<{ isOdd: boolean }>`
  background-color: ${props => (props.isOdd ? colors.white : colors.disabledGrey)};
  padding: 13px 30px;
  display: flex;
  width: 100%;
`;

const TableRow = React.memo(({
  className,
  children,
  isOdd,
}: TableRowProps) => (
  <Container
    className={classnames(className)}
    isOdd={isOdd}
  >
    {children}
  </Container>
));

export default TableRow;
