/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';

// Data
import states from 'data/static/states.json';
import provinces from 'data/static/provinces.json';

// Hooks
import { useResponsive } from 'hooks/useResponsive';
import useStudentContext from 'hooks/useStudentContext';

// Styles
import {
  TopContainer,
  GridItem,
  TextInput,
  StyledDropdown,
  StyledCheckbox,
} from './style';

type StateKey = keyof typeof states;
type ProvinceKey = keyof typeof provinces;

const stateOptions: OptionsType<OptionType> = Object.keys(states)
  // @ts-ignore
  .map((key: StateKey) => ({ value: key, label: states[key] }));

const provinceOptions: OptionsType<OptionType> = Object.keys(provinces)
  // @ts-ignore
  .map((key: ProvinceKey) => ({ value: key, label: provinces[key] }));

const ParentInfo = React.memo(() => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const {
    watch,
    register,
    errors,
    inputMaxSizes,
    control,
  } = useStudentContext();

  const isCanadianAddress: boolean = watch('parent.canadianAddress', false);

  return (
    <>
      {/* Top Container */}
      <TopContainer $isMobile={isMobile}>
        {/* Field: First Name */}
        <GridItem
          className="area-firstname"
        >
          <TextInput
            ref={register()}
            name="parent.firstName"
            title={t('PARENT_INFO_MODAL.FIRST_NAME_LABEL')}
            icon=""
            maxLength={inputMaxSizes.firstName}
            error={errors.parent?.firstName !== undefined}
            ignoreLastPass
            tabIndex={14}
          />
        </GridItem>

        {/* Field: Address 1 */}
        <GridItem
          className="area-address1"
        >
          <TextInput
            ref={register()}
            name="parent.address1"
            title={t('PARENT_INFO_MODAL.ADDRESS_1_LABEL')}
            icon=""
            maxLength={inputMaxSizes.address1}
            error={errors.parent?.address1 !== undefined}
            ignoreLastPass
            tabIndex={16}
          />
        </GridItem>

        {/* Field: Last Name */}
        <GridItem
          className="area-lastname"
        >
          <TextInput
            ref={register()}
            name="parent.lastName"
            title={t('PARENT_INFO_MODAL.LAST_NAME_LABEL')}
            icon=""
            maxLength={inputMaxSizes.lastName}
            error={errors.parent?.lastName !== undefined}
            ignoreLastPass
            tabIndex={15}
          />
        </GridItem>

        {/* Field: Address 2 */}
        <GridItem
          className="area-address2"
        >
          <TextInput
            ref={register()}
            name="parent.address2"
            title={t('PARENT_INFO_MODAL.ADDRESS_2_LABEL')}
            icon=""
            maxLength={inputMaxSizes.address2}
            error={errors.parent?.address2 !== undefined}
            ignoreLastPass
            tabIndex={17}
          />
        </GridItem>

        {/* Field: City */}
        <GridItem
          className="area-city"
        >
          <TextInput
            ref={register()}
            name="parent.city"
            title={t('PARENT_INFO_MODAL.CITY_LABEL')}
            icon=""
            maxLength={inputMaxSizes.city}
            error={errors.parent?.city !== undefined}
            ignoreLastPass
            tabIndex={18}
          />
        </GridItem>

        {/* Field Item: State/Province */}
        <GridItem
          className="area-state pr-0"
          key={isCanadianAddress ? 1 : 2}
        >
          {
            isCanadianAddress ? (
              <Controller
                control={control}
                name="parent.province"
                render={({ onChange, value, name }) => (
                  <StyledDropdown
                    title={t('PARENT_INFO_MODAL.PROVINCE_LABEL')}
                    name={name}
                    selected={value}
                    options={provinceOptions}
                    onSelect={(key, v) => onChange(v)}
                    error={errors.parent?.province !== undefined}
                    tabIndex="19"
                  />
                )}
              />
            )
              : (
                <Controller
                  control={control}
                  name="parent.state"
                  render={({ onChange, value, name }) => (
                    <StyledDropdown
                      title={t('PARENT_INFO_MODAL.STATE_LABEL')}
                      name={name}
                      selected={value}
                      options={stateOptions}
                      onSelect={(key, v) => onChange(v)}
                      error={errors.parent?.state !== undefined}
                      tabIndex="19"
                    />
                  )}
                />
              )
          }

        </GridItem>

        {/* Field Item: Zip */}
        <GridItem
          className="area-zip pl-0"
        >
          <TextInput
            ref={register()}
            name="parent.zip"
            title={t('PARENT_INFO_MODAL.ZIP_LABEL')}
            icon=""
            maxLength={inputMaxSizes.zip}
            error={errors.parent?.zip !== undefined}
            ignoreLastPass
            tabIndex={20}
          />
        </GridItem>

        <GridItem
          className="area-country my-auto"
        >
          <Controller
            control={control}
            name="parent.canadianAddress"
            render={({ onChange, value }) => (
              <StyledCheckbox
                checked={value}
                onClick={() => onChange(!value)}
                label={t('PARENT_INFO_MODAL.CANADIAN_ADDRESS')}
                alternativeStyle
                tabIndex={21}
              />
            )}
          />
        </GridItem>

        {/* Field: Employer Name */}
        <GridItem
          className="area-employer-name"
        >
          <TextInput
            ref={register()}
            name="parent.employerName"
            title={t('PARENT_INFO_MODAL.EMPLOYER_NAME_LABEL')}
            icon=""
            maxLength={inputMaxSizes.firstName}
            error={errors.parent?.employerName !== undefined}
            ignoreLastPass
            tabIndex={22}
          />
        </GridItem>

        {/* Field: Employer Phone */}
        <GridItem
          className="area-employer-phone"
        >
          <TextInput
            ref={register()}
            name="parent.employerPhone"
            title={t('PARENT_INFO_MODAL.EMPLOYER_PHONE_LABEL')}
            icon=""
            maxLength={inputMaxSizes.phone}
            error={errors.parent?.employerPhone !== undefined}
            ignoreLastPass
            tabIndex={23}
          />
        </GridItem>
      </TopContainer>
    </>
  );
});

export default ParentInfo;
