import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

// Components
import PageLayout from 'components/PageLayout';
import LoadingComponent from 'components/Loading';

// Utils
import { availableRoutes } from 'utils/routesList';

// Theme
import { theme } from 'theme/theme';

// Tabs
import { getSchoolById } from 'data/school';
import GeneralTab from './Tabs/GeneralTab';
import UsersTab from './Tabs/UsersTab';
import InsuranceInfoTab from './Tabs/InsuranceInfoTab';
import NotesTab from './Tabs/NotesTab';

// Styles
import {
  MainCard,
  InnerHeaderContainer,
  BackButton,
  SchoolNameText,
  TabsContainer,
  TabButton,
  TabButtonIcon,
  TabContainer,
} from './style';

export enum SchoolManagementTabs {
  GENERAL = 'TAB_GENERAL',
  USER = 'TAB_USERS',
  INSURANCE = 'TAB_INSURANCE',
  NOTES = 'TAB_NOTES',
}

export type SchoolsManagementTab =
  SchoolManagementTabs.GENERAL
  | SchoolManagementTabs.USER
  | SchoolManagementTabs.INSURANCE
  | SchoolManagementTabs.NOTES;

const tabIcons = {
  [SchoolManagementTabs.GENERAL]: 'las la-cog',
  [SchoolManagementTabs.USER]: 'las la-users-cog',
  [SchoolManagementTabs.INSURANCE]: 'las la-shield-alt',
  [SchoolManagementTabs.NOTES]: 'las la-sticky-note',
};

const SchoolsManagementPage = React.memo(() => {
  // Hooks
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<{ id?: string }>();
  const { params } = match;
  // NOTE: If we have an schooldId then we are editing, else we are creating a new school
  const { id: schooldId } = params;

  // States
  const [currentTab, setCurrrentTab] = React.useState<SchoolsManagementTab>(SchoolManagementTabs.GENERAL);

  // Queries
  const {
    data: dataGetSchoolById,
    loading: loadingGetSchoolById,
    refetch: refetchGetSchoolById,
  } = useQuery<GetSchoolByIdResponse, GetSchoolByIdVariables>(getSchoolById, {
    variables: {
      schoolId: schooldId || '',
    },
    skip: !schooldId,
    onError: () => {
      // The school id is not valid
      history.replace(availableRoutes.schools);
    },
    onCompleted: _data => {
      if (_data && (!_data.schools_by_pk || _data.schools_by_pk.deleted_at !== null)) {
        // The school id doesn't exist
        history.replace(availableRoutes.schools);
      }
    },
  });

  const school = React.useMemo(() => {
    if (dataGetSchoolById && dataGetSchoolById.schools_by_pk) {
      return dataGetSchoolById.schools_by_pk;
    }
    return undefined;
  }, [dataGetSchoolById]);

  // Handlers
  const handleOnBack = React.useCallback(() => {
    history.push(availableRoutes.schools);
  }, [history]);

  const handleSelectTab = React.useCallback((tabId: SchoolsManagementTab) => {
    // We can only change the current tab when there is an school
    if (school) {
      setCurrrentTab(s => ((s !== tabId) ? tabId : s));
    }
  }, [school]);

  // Memos
  const tabs = React.useMemo(() => Object.values(SchoolManagementTabs).map(tabValue => ({
    id: tabValue,
    label: t(`SCHOOLS_MANAGEMENT_PAGE.TABS.${tabValue}`),
    icon: tabIcons[tabValue],
  })), [t]);

  const headerSchoolName = React.useMemo(() => {
    if (school) {
      return school.name;
    }
    return null;
  }, [school]);

  if (loadingGetSchoolById) {
    return <LoadingComponent />;
  }

  return (
    <PageLayout>
      {/* Main Card */}
      <MainCard
        padding="20px 40px 35px 30px"
      >
        {/* Inner Header Container */}
        <InnerHeaderContainer>
          {/* Back Button */}
          <BackButton
            type="SECONDARY"
            onClick={handleOnBack}
          >
            <i className="las la-angle-left" />
          </BackButton>

          {/* SchoolNameText */}
          {
            headerSchoolName && (
              <SchoolNameText
                weight={theme.fontWeights.bold}
                size={18}
                fontFamily={theme.fontFamilies.roboto.bold}
                color={theme.colors.dark}
              >
                {headerSchoolName}
              </SchoolNameText>
            )
          }
        </InnerHeaderContainer>

        {/* Tabs Container */}
        <TabsContainer>
          {
            tabs.map(tab => (
              <TabButton
                key={tab.id}
                type="SECONDARY"
                $selected={currentTab === tab.id}
                disabled={tab.id !== SchoolManagementTabs.GENERAL && !school}
                onClick={() => handleSelectTab(tab.id)}
              >
                <TabButtonIcon className={tab.icon} />
                {tab.label}
              </TabButton>
            ))
          }
        </TabsContainer>

        {/* Tab Container */}
        <TabContainer>
          {/* General Tab */}
          {
            currentTab === SchoolManagementTabs.GENERAL && (
              <GeneralTab
                school={school}
                refetchGetSchoolById={refetchGetSchoolById}
              />
            )
          }

          {/* Users Tab */}
          {
            school && currentTab === SchoolManagementTabs.USER && (
              <UsersTab
                school={school}
              />
            )
          }

          {/* InsuranceInfo Tab */}
          {
            school && currentTab === SchoolManagementTabs.INSURANCE && (
              <InsuranceInfoTab
                school={school}
              />
            )
          }

          {/* Notes Tab */}
          {
            school && currentTab === SchoolManagementTabs.NOTES && (
              <NotesTab
                school={school}
              />
            )
          }
        </TabContainer>
      </MainCard>
    </PageLayout>
  );
});
export default SchoolsManagementPage;
