/* eslint-disable no-console */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

// Components
import Card from 'components/Card';
import CardTitle from 'components/Card/CardTitle';
import Text from 'components/Text';
import Icon from 'components/Icon';
/* import Button, { ButtonTypes } from 'components/Button'; */
import Table from 'components/Table';
import TableRow from 'components/Table/TableRow';
import Column from 'components/Table/Column';
import DataTablePaginator from 'components/Paginator';
import AccordionCard from 'components/AccordionCard';
import ClaimAccordionItem from 'components/ClaimAccordionItem';
import TwoStatusBox from 'components/TwoStatusBox';

// Utils
/* import { availableRoutes } from 'utils/routesList'; */

// Theme
import { colors } from 'theme/theme';
import PageSizeSelector from 'components/PageSizeSelector';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

interface ClaimsListCard {
  items?: Claim[],
  pageSize: number,
  pagination?: Pagination;
  setPageSize: (newPageSize: number) => void;
  onChangePage: (pageNumber: number) => void;
  sortBy: CommonJSON;
  onSort: (column: string) => void;
}

const ClaimsListCard = React.memo(({
  items,
  pageSize,
  pagination,
  setPageSize,
  onChangePage,
  sortBy,
  onSort,
}: ClaimsListCard) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<URLParams>();
  const { isMobile } = useResponsive();

  const options: SizeOptionType[] = React.useMemo(() => {
    const temp: SizeOptionType[] = [
      { value: 23, label: 23 },
      { value: 100, label: 100 },
      { value: 200, label: 200 },
    ];
    return temp;
  }, []);

  const columns: TableColumn[] = [
    {
      title: t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.COLUMN_CLAIMANT_NAME'),
      sorteable: true,
      order: Array.isArray(sortBy) && sortBy[0].student && sortBy[0].student.last_name ? sortBy[0].student.last_name.toUpperCase() : 'ASC',
      width: 23,
      onClick: () => { onSort('claimant_name'); },
    },
    {
      title: t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.COLUMN_STUDENT_ID'),
      sorteable: true,
      order: sortBy.student && sortBy.student.academic_id ? sortBy.student.academic_id.toUpperCase() : 'ASC',
      width: 18,
      onClick: () => { onSort('student_id'); },
    },
    /* {
      title: t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.COLUMN_TPA'),
      sorteable: true,
      order: sortBy.schools_third_party_administrator
        && sortBy.schools_third_party_administrator.third_party_administrator
        && sortBy.schools_third_party_administrator.third_party_administrator.code
        ? sortBy.schools_third_party_administrator.third_party_administrator.code.toUpperCase()
        : 'ASC',
      width: 18,
      onClick: () => { onSort('tpa'); },
    }, */
    {
      title: t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.COLUMN_STATUS'),
      sorteable: true,
      order: sortBy.status ? sortBy.status.toUpperCase() : 'ASC',
      width: 15,
      onClick: () => { onSort('status'); },
    },
    {
      title: t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.COLUMN_SUBMITTED_BY'),
      sorteable: true,
      order: Array.isArray(sortBy) && sortBy[0].user && sortBy[0].user.last_name ? sortBy[0].user.last_name.toUpperCase() : 'ASC',
      width: 18,
      onClick: () => { onSort('submitted_by'); },
    },
    {
      title: t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.COLUMN_DATE_OF_ACCIDENT'),
      sorteable: true,
      order: sortBy.injury_date ? sortBy.injury_date.toUpperCase() : 'ASC',
      width: 18,
      onClick: () => { onSort('injury_date'); },
    },
    {
      title: t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.COLUMN_DATE_SUBMITTED'),
      sorteable: true,
      order: sortBy.submitted_to_tpa_at ? sortBy.submitted_to_tpa_at.toUpperCase() : 'ASC',
      width: 15,
      onClick: () => { onSort('submitted_to_tpa_at'); },
    },
    {
      title: t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.ACTIONS'),
      width: 3,
    },
  ];

  /* const handleNewClaim = useCallback(() => {
    history.push(availableRoutes.newSchoolClaim.replace(':id', params.id!));
  }, []); */

  const handleViewClaim = useCallback(claimId => {
    history.push(`/dashboard/${params.id}/claims/${claimId}`);
  }, []);

  const handleEditClaim = React.useCallback(claimId => {
    history.push(`/dashboard/${params.id}/claims/${claimId}/edit`);
  }, []);

  const rows = React.useMemo(() => {
    if (items) {
      return items.map(item => ({
        id: item.id,
        fullName: `${item.student.last_name} ${item.student.first_name}`,
        academic_id: item.student.academic_id,
        // tpa: item.schools_third_party_administrator.third_party_administrator?.code,
        submittedBy: `${item.user.last_name} ${item.user.first_name}`,
        dateOfAccident: item.injury_date,
        dateSubmitted: item.submitted_to_tpa_at ? moment.utc(item.submitted_to_tpa_at).format('MM/DD/YYYY') : '',
        status: item.status,
      }));
    }
    return [];
  }, [items]);

  return (
    <Card
      padding="25px 30px 20px 30px;"
      className="mb-4"
      title={(
        <div className="d-flex justify-content-between align-items-center">
          <CardTitle>{t('CLAIMS_PAGE.CLAIMS_RESULT.CARD_TITLE')}</CardTitle>
          {/* <Button onClick={handleNewClaim} type={ButtonTypes.SECONDARY}>
            <Icon
              icon="las la-plus-circle"
              size={18}
              className="mr-2"
            />
            {!isMobile && (t('CLAIMS_PAGE.CLAIMS_RESULT.CTA_BUTTON_NEW_CLAIM_LABEL'))}
          </Button> */}
        </div>
      )}
    >
      {!isMobile && (
        <Table
          columns={columns}
          rows={rows.map((row, index) => (
            <TableRow isOdd={index % 2 === 0}>
              <Column width={columns[0].width}><Text fontFamily="Bold">{row.fullName}</Text></Column>
              <Column width={columns[1].width}><Text>{row.academic_id}</Text></Column>
              {/* <Column width={columns[2].width}><Text fontFamily="Bold">{row.tpa}</Text></Column> */}
              <Column width={columns[2].width}>
                <TwoStatusBox
                  status={row.status}
                  status1Name="DELIVERED"
                  status1Label={t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.LABEL_STATUS_SENT')}
                  status2Name="ERROR"
                  status2Label={t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.LABEL_STATUS_PENDING')}
                  status3Name="PENDING"
                  status3Label={t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.LABEL_STATUS_PENDING')}
                />
              </Column>
              <Column width={columns[3].width}><Text>{row.submittedBy}</Text></Column>
              <Column width={columns[4].width}><Text>{moment(row.dateOfAccident).format('MM/DD/YYYY')}</Text></Column>
              <Column width={columns[5].width}><Text>{row.dateSubmitted}</Text></Column>
              <Column width={columns[6].width} className="d-flex justify-content-center">
                <Icon icon="las la-eye" onClick={() => { handleViewClaim(row.id); }} size={20} color={colors.grey} className="cursor-pointer" />
                <Icon icon="las la-edit" onClick={() => handleEditClaim(row.id)} size={20} color={colors.grey} className="cursor-pointer ml-2" />
              </Column>
            </TableRow>
          ))}
        />
      )}
      {isMobile && (
        <>
          {rows.map((row, index) => (
            <AccordionCard
              isOdd={index % 2 === 0}
              title={row.fullName}
            >
              <ClaimAccordionItem row={row} handleViewClaim={handleViewClaim} />
            </AccordionCard>
          ))}
        </>
      )}
      <div className="d-flex justify-content-between my-4">
        {!isMobile && <PageSizeSelector pageSize={pageSize} setPageSize={setPageSize} options={options} />}
        {
          pagination && (
            <DataTablePaginator
              onPage={onChangePage}
              pagination={pagination}
            />
          )
        }
      </div>
    </Card>
  );
});

export default ClaimsListCard;
