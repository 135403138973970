/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import * as React from 'react';

// CSS
import './DataTablePaginator.css';
import { Button } from 'reactstrap';
import Icon from 'components/Icon';
import { colors } from 'theme/theme';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

const MAX_PAGE_RENDER = 5; // 1 2 3 4 5 6 7 8 9
const CENTER_PAGE = Math.ceil(MAX_PAGE_RENDER / 2);

interface PaginatorButton {
  id: number;
  type: string;
  label: string;
}

// Props
interface DataTablePaginatorProps {
  pagination: Pagination;
  onPage: (pageNumber: number) => void;
}

const DataTablePaginator = React.memo(({
  pagination,
  onPage,
}: DataTablePaginatorProps) => {
  // State
  const [buttons, setButtons] = React.useState<PaginatorButton[]>([]);
  const { isMobile } = useResponsive();

  // Handlers
  const onPrevious = React.useCallback(() => {
    if (pagination.hasPrevious) {
      onPage(pagination.currentPage - 1);
    }
  }, [onPage, pagination]);

  const onNext = React.useCallback(() => {
    if (pagination.hasNext) {
      onPage(pagination.currentPage + 1);
    }
  }, [onPage, pagination]);

  // Effects
  React.useEffect(() => {
    const output:PaginatorButton[] = [];
    let forInit = 1;
    let forEnd = MAX_PAGE_RENDER;
    if (pagination.pages < MAX_PAGE_RENDER) {
      forEnd = pagination.pages;
    } else if (pagination.currentPage < CENTER_PAGE) {
      forEnd = MAX_PAGE_RENDER;
    } else if (pagination.currentPage > (pagination.pages - (CENTER_PAGE - 1))) {
      forInit = pagination.pages - (CENTER_PAGE + 1);
      forEnd = pagination.pages;
    } else {
      forInit = pagination.currentPage - (CENTER_PAGE - 1);
      forEnd = pagination.currentPage + (CENTER_PAGE - 1);
      forEnd = forEnd > pagination.pages ? pagination.pages : forEnd;
    }
    // Render only MAX_PAGE_RENDER buttons
    for (let i = forInit; i <= forEnd; i += 1) {
      const type = pagination.currentPage === i ? 'current-page' : '';
      const btnObject:PaginatorButton = {
        id: i,
        label: i.toString(),
        type,
      };
      output.push(btnObject);
    }
    setButtons(output);
  }, [pagination]);

  return (
    <div className={`PaginationFooter ${isMobile ? 'justify-content-between w-100' : ''}`}>
      <Button
        className="pagination-btn arrow-btn"
        onClick={onPrevious}
        disabled={pagination.currentPage === 1}
      >
        <Icon icon="las la-angle-left" color={colors.grey} />
      </Button>
      <div className={`d-flex ${isMobile ? 'w-75 justify-content-around' : ''}`}>
        {
          buttons.map(b => (
            <Button
              key={`${b.id}-${b.type}`}
              className={`pagination-btn ${b.type}`}
              onClick={() => onPage(b.id)}
            >
              {b.label}
            </Button>
          ))
        }
      </div>
      <Button
        className="pagination-btn arrow-btn"
        disabled={pagination.currentPage === pagination.pages}
        onClick={onNext}
      >
        <Icon icon="las la-angle-right" color={colors.grey} />
      </Button>
    </div>
  );
});

export default DataTablePaginator;
