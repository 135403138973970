import React from 'react';

// Components
import Text from 'components/Text';

interface CardTitleProps {
  children: any,
  className?: string,
}

const CardTitle = React.memo(({
  children,
  className = '',
}: CardTitleProps) => (
  <Text size={18} fontFamily="Bold" className={className}>{children}</Text>
));

export default CardTitle;
