import styled from 'styled-components';

// Components
import Icon from 'components/Icon';

// Theme
import { theme } from '../../theme/theme';

export const AccordionItemCard = styled.div`
  background-color: ${theme.colors.white};
  display: block;
  width: 100%;
`;

export const StyledIcon = styled(Icon)``;

export const IconContainer = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  width: 100%;
  padding-top: 10px;
  padding-left: 20px; 
`;
