import { gql } from '@apollo/client';

// Fragments
import {
  studentComplexFragment,
  studentProviderFragment,
  studentSoftFragment,
  studentFragmentWithProviders,
} from 'data/fragments';

export const getPaginatedStudents = gql`
  query getPaginatedStudents(
    $where: students_bool_exp,
    $offset: Int,
    $limit: Int,
    $order_by: [students_order_by!],
  ) {
    # Aggregation
    students_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    students(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...student
    }
  }
  ${studentSoftFragment}
`;

export const getPaginatedComplexStudents = gql`
  query getPaginatedStudents(
    $where: students_bool_exp,
    $offset: Int,
    $limit: Int,
    $order_by: [students_order_by!],
  ) {
    # Aggregation
    students_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    students(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...student
      students_providers(
        where: {
          deleted_at: { _is_null: true }
        }
        order_by:{primary:desc_nulls_last , updated_at:asc}){
        ...studentProvider
      }
    }
  }
  ${studentSoftFragment}
  ${studentProviderFragment}
`;

export const getStudentById = gql`
  query getStudentById($studentId: uuid!) {
    students_by_pk(id: $studentId) {
      ...complexStudent
    }
  }
  ${studentComplexFragment}
`;

export const getStudentWithProvidersById = gql`
  query getStudentById($studentId: uuid!) {
    students_by_pk(id: $studentId) {
      ...studentWithProvider
    }
  }
  ${studentFragmentWithProviders}
`;
