/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';
import { useQuery } from '@apollo/client';

// Data
import {
  getProviders,
} from 'data/providers';
import states from 'data/static/states.json';
import provinces from 'data/static/provinces.json';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

// Components
import LoadingComponent from 'components/Loading';

// Styles
import {
  StyledModal,
  TopContainer,
  GridItem,
  BottomContainer,
  TextInput,
  AddButton,
  StyledDropdown,
  // StyledCheckbox,
} from './style';

export interface InsuranceInformationModalProps {
  isAdditional?: boolean;
  form?: StudentProviderForm;
  providersIdsToIgnore: string[];
  onAddOrEdit: (form: StudentProviderForm) => void;
  lastFormId: number;
  // Modal
  isOpen: boolean;
  toggle: () => void;
}

type StateKey = keyof typeof states;
type ProvinceKey = keyof typeof provinces;

const stateOptions: OptionsType<OptionType> = Object.keys(states)
  // @ts-ignore
  .map((key: StateKey) => ({ value: key, label: states[key] }));

const provinceOptions: OptionsType<OptionType> = Object.keys(provinces)
  // @ts-ignore
  .map((key: ProvinceKey) => ({ value: key, label: provinces[key] }));

const getDefaultValues = function getDefaultValues(prevForm?: StudentProviderForm): CommonJSON {
  const out: any = {
    insuranceCompany: undefined,
    providerName: '',
    insuranceMemberId: '',
    insuranceGroupId: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: undefined,
    province: undefined,
    zip: '',
    canadianAddress: false,
  };

  if (prevForm) {
    out.insuranceCompany = { value: prevForm.insuranceCompanyId, label: prevForm.insuranceCompanyLabel };

    out.providerName = prevForm.providerName || '';

    out.insuranceMemberId = prevForm.insuranceMemberId || '';

    out.insuranceGroupId = prevForm.insuranceGroupNumber || '';

    out.phone = prevForm.phone || '';

    out.address1 = prevForm.address1 || '';

    out.address2 = prevForm.address2 || '';

    out.city = prevForm.city || '';

    if (prevForm.country === 'United States') {
      out.state = { value: prevForm.stateProv, label: prevForm.stateProvLabel };
    } else {
      out.province = { value: prevForm.stateProv, label: prevForm.stateProvLabel };
    }

    out.canadianAddress = prevForm.country === 'Canada';
  }

  return out;
};

const getResetValuesWithProvider = function getResetValuesWithProvider(provider: Provider) {
  const out = {
    insuranceCompany: { value: provider.id, label: provider.name },
    insuranceMemberId: '',
    insuranceGroupId: '',
    // phone: provider.phone, TODO: not possible yet o es campo de provider
    phone: '',
    /* TODO: Eliminar? Estos campos ya no se muestran */
    address1: provider.address || '', // TODO: in the future use address_1
    address2: '', // TODO: change in the future
    city: provider.city || '',
    state: provider.state
      ? stateOptions.find(opt => opt.value === provider.state) ?? undefined
      : undefined,
    province: provider.state
      ? provinceOptions.find(opt => opt.value === provider.state) ?? undefined
      : undefined,
    zip: provider.postal_code || '',
    // canadianAddress: provider.country === 'Canada', // TODO: not possible yet
    canadianAddress: false, // TODO: change in the future
  };
  return out;
};

const inputMaxSizes = {
  providerName: 50,
  insuranceMemberId: 50,
  insuranceGroupId: 50,
  phone: 30,
  /* TODO: Eliminar? Estos campos ya no se muestran */
  address1: 100,
  address2: 100,
  city: 75,
  state: 50,
  zip: 10,
  country: 20,
};

const insuranceSchema = Yup.object({
  // students-providers
  insuranceCompany: Yup.object({
    value: Yup.string().required('INSURANCE_COMPANY_VALUE_REQUIRED'),
    label: Yup.string().required('INSURANCE_COMPANY_LABEL_REQUIRED'),
  }).required('INSURANCE_COMPANY_REQUIRED'),
  providerName: Yup.string().max(inputMaxSizes.providerName, 'INSURANCE_NAME_MAX')
    .when('insuranceCompany', {
      is: (insuranceCompany: CommonJSON) => (insuranceCompany.value === 'OTHER'),
      then: Yup.string()
        .required('PROVIDER_NAME_REQUIRED'),
    }),
  insuranceMemberId: Yup.string().notRequired()
    .max(inputMaxSizes.insuranceMemberId, 'INSURANCE_MEMBER_ID_MAX'),
  insuranceGroupId: Yup.string().notRequired().max(inputMaxSizes.insuranceMemberId, 'INSURANCE_GROUP_ID_MAX'),
  // providers
  phone: Yup.string().notRequired()
    .max(inputMaxSizes.phone, 'PHONE_INVALID'),
  /* TODO: Eliminar? Estos campos ya no se muestran */
  address1: Yup.string().notRequired().max(inputMaxSizes.address1, 'ADDRESS_1_INVALID'),
  address2: Yup.string().notRequired().max(inputMaxSizes.address2, 'ADDRESS_2_INVALID'),
  city: Yup.string().notRequired().max(inputMaxSizes.city, 'CITY_INVALID'),
  state: Yup.object({
    value: Yup.string().notRequired(),
    label: Yup.string().notRequired(),
  }).notRequired(),
  province: Yup.object({
    value: Yup.string().notRequired(),
    label: Yup.string().notRequired(),
  }).notRequired(),
  zip: Yup.string().notRequired().max(inputMaxSizes.zip, 'ZIP_INVALID'),
  canadianAddress: Yup.bool().notRequired(), // Turns to "country"
}).defined();

const InsuranceInformationModal = React.memo(({
  isAdditional,
  form,
  providersIdsToIgnore,
  onAddOrEdit,
  lastFormId = 0,
  // Modal
  isOpen,
  toggle,
}: InsuranceInformationModalProps) => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const {
    register,
    control,
    errors,
    watch,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(insuranceSchema),
  });

  // Refs
  const canRefreshForm = React.useRef<boolean>(false);

  // Queries
  const {
    loading: loadingGetProviders,
    data: dataGetProviders,
  } = useQuery<GetProvidersResponse>(getProviders);

  const providers = React.useMemo(() => {
    if (dataGetProviders && dataGetProviders.providers) {
      return dataGetProviders.providers;
    }
    return [];
  }, [dataGetProviders]);

  const providersOptions = React.useMemo(
    () => {
      let out = providers
        .map(provider => ({
          value: provider.id,
          label: provider.name,
        }));

      if (form) {
        // Filter other existing providers except for the current provider
        const toIgnoreWithoutCurrent = providersIdsToIgnore.filter(i => i !== form.insuranceCompanyId);
        out = out.filter(opt => !toIgnoreWithoutCurrent.includes(opt.value));
      } else {
        // Filter all existing providers
        out = out.filter(opt => !providersIdsToIgnore.includes(opt.value));
      }

      out.push({
        value: 'OTHER',
        label: t('INSURANCE_INFORMATION_MODAL.OTHER'),
      });
      out.sort((a, b) => a.label.localeCompare(b.label));

      return out;
    },
    [form, providers, providersIdsToIgnore],
  );

  const insuranceCompany = watch('insuranceCompany');

  const currentProvider = React.useMemo(() => {
    if (insuranceCompany) {
      return providers.find(p => p.id === insuranceCompany.value);
    }
    return null;
  }, [insuranceCompany, providers]);

  const filterOption = (
    candidate: OptionType,
    input: string,
  ) => candidate.label.toLowerCase().includes(input.toLowerCase()) || candidate.value === 'OTHER';

  // Effects
  React.useEffect(() => {
    if (isOpen) {
      if (form) {
        reset(getDefaultValues(form));
      } else {
        reset(getDefaultValues());
      }
    } else {
      canRefreshForm.current = false;
      reset(getDefaultValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, form]);

  React.useEffect(() => {
    if (currentProvider && canRefreshForm.current) {
      reset(getResetValuesWithProvider(currentProvider));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProvider]);

  // Handlers
  const handleAdd = React.useCallback(async (data: CommonJSON) => {
    if (data) {
      const newForm: StudentProviderForm = {
        formId: form ? form.formId : lastFormId + 1,
        providerName: data.insuranceCompany.value === 'OTHER' ? data.providerName : '',
        insuranceCompanyId: data.insuranceCompany.value,
        insuranceCompanyLabel: data.insuranceCompany.label,
        insuranceCompanyIsOther: data.insuranceCompany.value === 'OTHER',
        insuranceMemberId: data.insuranceMemberId,
        insuranceGroupNumber: data.insuranceGroupId,
        phone: data.phone,
        /* TODO: Eliminar? Estos campos ya no se muestran */
        address1: data.address1 || '',
        address2: data.address2 || '',
        city: data.city || '',
        stateProv: data.canadianAddress
          ? (data.province ? data.province.value : undefined)
          : (data.state ? data.state.value : undefined),
        stateProvLabel: data.canadianAddress
          ? (data.province ? data.province.label : undefined)
          : (data.state ? data.state.label : undefined),
        country: data.canadianAddress ? 'Canada' : 'United States',
        zip: data.zip || '',
      };

      onAddOrEdit(newForm);
    }
  }, [
    form,
    onAddOrEdit,
    lastFormId,
  ]);

  // Memos

  const title = React.useMemo(() => {
    let out = '';
    if (isAdditional) {
      out = t('INSURANCE_INFORMATION_MODAL.ADD_ADDITIONAL_TITLE');
    } else if (form) {
      out = t('INSURANCE_INFORMATION_MODAL.EDIT_TITLE');
    } else {
      out = t('INSURANCE_INFORMATION_MODAL.ADD_TITLE');
    }
    return out;
  }, [isAdditional, form, t]);

  // const isCanadianAddress: boolean = watch('canadianAddress', false);
  const isCreateModal: boolean = watch('insuranceCompany') ? watch('insuranceCompany').value === 'OTHER' : false;
  const showCompleteForm: boolean = !!watch('insuranceCompany') || false;
  return (
    <StyledModal
      $isMobile={isMobile}
      isOpen={isOpen}
      toggle={toggle}
      title={title}
    >
      {/* Top Container */}
      <TopContainer
        $isCreateModal={isCreateModal}
        $isMobile={isMobile}
        $showCompleteForm={showCompleteForm}
      >
        {/* Field: First Name */}
        <GridItem
          className="area-insurance-company"
        >
          <Controller
            control={control}
            name="insuranceCompany"
            render={({ onChange, value, name }) => (
              <>
                {
                  loadingGetProviders ? (
                    <LoadingComponent />
                  ) : (
                    <StyledDropdown
                      title={t('INSURANCE_INFORMATION_MODAL.INSURANCE_COMPANY_LABEL')}
                      name={name}
                      selected={value}
                      options={providersOptions}
                      onSelect={(key, v) => { canRefreshForm.current = true; onChange(v); }}
                      error={errors[name] !== undefined}
                      filterOption={filterOption}
                      required
                    />
                  )
                }
              </>
            )}
          />
        </GridItem>

        {/* Field: Insurance other name */}
        {isCreateModal
          && (
            <GridItem
              className="area-other-insurance-name"
            >
              <TextInput
                className="mt-4"
                ref={register()}
                name="providerName"
                placeholder={t('INSURANCE_INFORMATION_MODAL.INSURANCE_COMPANY_NAME_PLACEHOLDER')}
                icon=""
                maxLength={inputMaxSizes.providerName}
                error={errors.providerName !== undefined}
                ignoreLastPass
                readOnly={!isCreateModal}
              />
            </GridItem>
          )}

        {/* Field: Insurance Member Id */}
        <GridItem
          className="area-insurance-member-id"
        >
          <TextInput
            ref={register()}
            name="insuranceMemberId"
            title={t('INSURANCE_INFORMATION_MODAL.MEMBER_ID_LABEL')}
            icon=""
            maxLength={inputMaxSizes.insuranceMemberId}
            error={errors.insuranceMemberId !== undefined}
            ignoreLastPass
          // tabIndex={2}
          />
        </GridItem>

        {/* Field: Address 1 */}
        {/* <GridItem
          className="area-address1"
        >
          <TextInput
            ref={register()}
            name="address1"
            title={t('INSURANCE_INFORMATION_MODAL.ADDRESS_1_LABEL')}
            icon=""
            maxLength={inputMaxSizes.address1}
            error={errors.address1 !== undefined}
            ignoreLastPass
            // tabIndex={6}
            readOnly={!isCreateModal}
          />
        </GridItem> */}

        {/* Field: Insurance group id */}
        <GridItem
          className="area-insurance-group-id"
        >
          <TextInput
            ref={register()}
            name="insuranceGroupId"
            title={t('INSURANCE_INFORMATION_MODAL.GROUP_NUMBER_LABEL')}
            icon=""
            maxLength={inputMaxSizes.insuranceGroupId}
            error={errors.insuranceGroupId !== undefined}
            ignoreLastPass
          // tabIndex={3}
          />
        </GridItem>

        {/* Field: Address 2 */}
        {/* <GridItem
          className="area-address2"
        >
          <TextInput
            ref={register()}
            name="address2"
            title={t('INSURANCE_INFORMATION_MODAL.ADDRESS_2_LABEL')}
            icon=""
            maxLength={inputMaxSizes.address2}
            error={errors.address2 !== undefined}
            ignoreLastPass
            // tabIndex={7}
            readOnly={!isCreateModal}
          />
        </GridItem> */}

        {/* Field: Phone */}
        <GridItem
          className="area-phone"
        >
          <TextInput
            ref={register()}
            name="phone"
            title={t('INSURANCE_INFORMATION_MODAL.PHONE_LABEL')}
            icon=""
            maxLength={inputMaxSizes.phone}
            error={errors.phone !== undefined}
            ignoreLastPass
          // tabIndex={5}
          // readOnly={!isCreateModal}
          />
        </GridItem>

        {/* Field: City */}
        {/* <GridItem
          className="area-city"
        >
          <TextInput
            ref={register()}
            name="city"
            title={t('INSURANCE_INFORMATION_MODAL.CITY_LABEL')}
            icon=""
            maxLength={inputMaxSizes.city}
            error={errors.city !== undefined}
            ignoreLastPass
            // tabIndex={8}
            readOnly={!isCreateModal}
          />
        </GridItem> */}

        {/* Field Item: State/Province */}
        {/* <GridItem
          className="area-state"
          key={isCanadianAddress ? 1 : 2}
        >
          {
            isCanadianAddress ? (
              <Controller
                control={control}
                name="province"
                render={({ onChange, value, name }) => (
                  <StyledDropdown
                    title={t('INSURANCE_INFORMATION_MODAL.PROVINCE_LABEL')}
                    name={name}
                    selected={value}
                    options={provinceOptions}
                    onSelect={(key, v) => onChange(v)}
                    error={errors[name] !== undefined}
                    readOnly={!isCreateModal}
                  />
                )}
              />
            ) : (
              <Controller
                control={control}
                name="state"
                render={({ onChange, value, name }) => (
                  <StyledDropdown
                    title={t('INSURANCE_INFORMATION_MODAL.STATE_LABEL')}
                    name={name}
                    selected={value}
                    options={stateOptions}
                    onSelect={(key, v) => onChange(v)}
                    error={errors[name] !== undefined}
                    readOnly={!isCreateModal}
                  />
                )}
              />
            )
          }

        </GridItem> */}

        {/* Field Item: Zip */}
        {/* <GridItem
          className="area-zip"
        >
          <TextInput
            ref={register()}
            name="zip"
            title={t('INSURANCE_INFORMATION_MODAL.ZIP_LABEL')}
            icon=""
            maxLength={inputMaxSizes.zip}
            error={errors.zip !== undefined}
            ignoreLastPass
            // tabIndex={8}
            readOnly={!isCreateModal}
          />
        </GridItem>

        <GridItem
          className="area-country"
        >
          <Controller
            control={control}
            name="canadianAddress"
            render={({ onChange, value }) => (
              <StyledCheckbox
                checked={value}
                onClick={() => onChange(!value)}
                label={t('INSURANCE_INFORMATION_MODAL.CANADIAN_ADDRESS')}
                alternativeStyle
                readOnly={!isCreateModal}
              />
            )}
          />
        </GridItem> */}

      </TopContainer>

      {/* Bottom Container */}
      <BottomContainer>
        {/* Add Button */}
        <AddButton
          type="PRIMARY"
          onClick={handleSubmit(handleAdd)}
        >
          {
            form ? t('INSURANCE_INFORMATION_MODAL.UPDATE_CTA_BUTTON_LABEL')
              : t('INSURANCE_INFORMATION_MODAL.ADD_CTA_BUTTON_LABEL')
          }
        </AddButton>
      </BottomContainer>
    </StyledModal>
  );
});

export default InsuranceInformationModal;
