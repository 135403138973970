// import 'wdyr';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/browser';

// Containers
import { App } from 'containers/App';

// Hooks
import useAuth from 'hooks/useAuth';

// Apollo
import { client } from './client';

// Default Styles
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';

// Context
import {
  useProvideAuth,
  authContext,
  COUNT,
  PAGE_ACTIVE,
} from './context/Auth';

// i18n
import './i18n';

const TIME_OUT_TIME = 300000;

function StrartApp() {
  useEffect(() => {
    window.addEventListener('load', () => {
      let count: string | null | number = localStorage.getItem(COUNT);
      if (count) {
        count = parseInt(count, 10);
      } else {
        count = 0;
      }
      localStorage.setItem(COUNT, (count + 1).toString());
      sessionStorage.setItem(PAGE_ACTIVE, 'true');
    });
    window.addEventListener('beforeunload', () => {
      let count: string | null | number = localStorage.getItem(COUNT);
      if (count) {
        count = parseInt(count, 10) - 1;
      } else {
        count = 0;
      }
      localStorage.setItem(COUNT, count.toString());
    });

    if (process.env.REACT_APP_SENTRY_ENABLE && process.env.REACT_APP_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
      });
    }
  }, []);

  const auth = useProvideAuth();
  const { getMe } = useAuth();

  return (
    <authContext.Provider value={auth}>
      <IdleTimer timeout={TIME_OUT_TIME} onIdle={getMe} />
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </authContext.Provider>
  );
}

ReactDOM.render(
  <StrartApp />,
  document.getElementById('root'),
);
