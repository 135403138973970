/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

// Modals
import CustomModal from 'modals/CustomModal';

// Components
import Button, { ButtonTypes } from 'components/Button';
import Text from 'components/Text';

// Theme
import { theme } from 'theme/theme';

export interface ConfirmationModalProps {
  isOpen: boolean;
  toggle: () => void;
  title: string;
  text: string;
  buttonText: string;
  onConfirm: () => void;
}

const ConfirmationModal = React.memo(({
  isOpen,
  toggle,
  title,
  text,
  buttonText,
  onConfirm,
  ...props
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    toggle();
  }, [toggle]);

  return (
    <CustomModal
      {...props}
      isOpen={isOpen}
      toggle={handleClose}
      title={title}
    >
      <Text color={theme.colors.grey} className="text-center">{text}</Text>
      <div className="d-flex justify-content-end mt-5">
        <Button
          onClick={handleClose}
          type={ButtonTypes.SECONDARY}
          minWidth="130px"
          className="mr-3"
        >
          {t('CONFIRMATION_MODAL.CANCEL')}
        </Button>
        <Button
          onClick={onConfirm}
          type={ButtonTypes.PRIMARY}
          minWidth="130px"
        >
          {buttonText}
        </Button>
      </div>
    </CustomModal>
  );
});

export default ConfirmationModal;
