import styled from 'styled-components';

// Components
import Button from 'components/Button';
import Input from 'components/Input';
import UserModal from 'modals/UserModal';
import UsersResults from './UsersResults';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledAddCircleIcon = styled.i`
  margin-right: 4px;
  font-size: 16px;
`;

export const BottomContainer = styled.div`
  width: 100%;
`;

export const TextInput = styled(Input)`
  width: 100%;
  max-width: 520px;

  input {
    background-image: none !important;
  }
`;

export const NewUserButton = styled(Button)`
  width: 100%;
  max-width: 124px;
`;

export const StyledUsersResults = styled(UsersResults)`
  width: 100%;
`;

export const StyledUserModal = styled(UserModal)``;
