/* eslint-disable no-console */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ApolloCache } from '@apollo/client';
import { getSchoolTPAs } from './schools_tpa.query';

// Remove SchoolTPA
export function removeSchoolTPAFromGetSchoolTPAs(
  cache: ApolloCache<DeleteSoftSchoolTPAResponse>,
  schoolTPAId: string,
  schoolId: string,
) {
  try {
    const base = {
      query: getSchoolTPAs,
      variables: {
        schoolId,
      },
    };
    const r = cache.readQuery<GetSchoolTPAsResponse, GetSchoolTPAsVariables>(base);
    cache.writeQuery({
      ...base,
      data: {
        schools_third_party_administrators:
          r?.schools_third_party_administrators?.filter(a => a.id !== schoolTPAId) ?? [],
      },
    });
  } catch (e) {
    console.error(e.message);
  }
}
