import styled from 'styled-components';

// Theme
import { theme } from 'theme/theme';

// Components
import Text from 'components/Text';
import Button from 'components/Button';
import PageSizeSelector from 'components/PageSizeSelector';
import DataTablePaginator from 'components/Paginator/DataTablePaginator';
import Column from 'components/Table/Column';
import TableRow from 'components/Table/TableRow';

export const TopContainer = styled.div<{ $isMobile: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ $isMobile }) => ($isMobile ? '' : '20px')};
`;

export const Title = styled(Text)``;

export const AddStudentButton = styled(Button)<{ $isMobile: boolean}>`
  ${({ $isMobile }) => ($isMobile ? `
    width: 40px;
    padding: 12px 12px;
  `
    : ` width: 100%;
    padding: 0px 12px;
  `
  )} 
  max-width: 165px;
  line-height: 36px;
`;

export const StyledAddCircleIcon = styled.i`
  margin-right: 10px;
  font-size: 16px;
`;

export const MiddleContainer = styled.div`
  margin-top: 20px;
`;

export const StyledTableRow = styled(TableRow)`
  padding: 13px 20px;
`;

export const SelectStudentButton = styled(Button)`
  width: 100%;
  max-width: 98px;
  padding: 0 12px;
  line-height: 25px;
`;

export const StyledColumn = styled(Column)<{ $justifyContent?: string }>`
  justify-content: ${({ $justifyContent }) => $justifyContent};
  padding: 0 5px;

  &:first-of-type {
    padding: 0 5px 0px 0px;
  }

  &:last-of-type {
    padding: 0 0px 0px 5px;
  }
`;

export const TableText = styled(Text)<{ $uppercase?: boolean, $bold?: boolean }>`
  word-break: break-all;
  ${({ $uppercase }) => ($uppercase ? `
    text-transform: uppercase;
  ` : '')}

  ${({ $bold }) => ($bold ? `
    font-family: 'Roboto-Medium';
    font-weigth: ${theme.fontWeights.medium};
  ` : '')}
`;

export const BottomContainer = styled.div<{ $isMobile: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ $isMobile }) => ($isMobile ? '17' : '55')}px;
  padding: ${({ $isMobile }) => ($isMobile ? '15' : '20')}px;
`;

export const StyledPageSizeSelector = styled(PageSizeSelector)``;

export const StyledDataTablePaginator = styled(DataTablePaginator)``;
