/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

// Data
import {
  getTPAsForDropdown,
} from 'data/tpa';
import {
  associateSchoolTPA,
} from 'data/schools_tpa';
import {
  getMaxOrderAllComponentaBySchool,
  insertManyDashboardElementOrder,
} from 'data/school_dashboard_elements_order';

// Utils
import { Toaster } from 'utils/toaster';

// Component
import LoadingComponent from 'components/Loading';

// Styles
import {
  StyledModal,
  TopContainer,
  BottomContainer,
  StyledDropdown,
  AddOrEditButton,
} from './style';

export interface SchoolsTPAModalProps {
  isOpen: boolean;
  schoolId: string;
  toggle: () => void;
  onCreate: () => void;
}

const getDefaultValues = function getDefaultValues(): CommonJSON {
  return {
    tpa: undefined,
  };
};

const tpaSchema = Yup.object({
  tpa: Yup.object({
    label: Yup.string().notRequired(),
    value: Yup.string().notRequired(),
  }).required('TPA_REQUIRED'),
}).defined();

const SchoolsTPAModal = React.memo(({
  schoolId,
  isOpen,
  toggle,
  onCreate,
}: SchoolsTPAModalProps) => {
  // Hooks
  const { t } = useTranslation();
  const [itemsToInsert, setItemsToInsert] = React.useState<TpaAttachmentResponse[]>();
  const {
    control,
    errors,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(tpaSchema),
    defaultValues: getDefaultValues(),
  });

  const [
    callInsertManyDashboardElementOrder,
  ] = useMutation<
  CreateManySchoolDashboardElementOrderResponse,
  CreateManySchoolDashboardElementOrderVariables
  >(insertManyDashboardElementOrder);

  // Queries
  const {
    loading: loadingGetTPAs,
    data: dataGetTPAs,
  } = useQuery<GetTPAsForDropdownResponse>(getTPAsForDropdown);

  const [callGetMaxOrderAllComponentaBySchool] = useLazyQuery<
  GetMaxOrderAllComponentsBySchoolResponse,
  GetMaxOrderAllComponentsBySchoolVariables
  >(getMaxOrderAllComponentaBySchool, {
    onCompleted: _data => {
      const componentOrder:CommonJSON = {
        CLAIMS_MANAGEMENT: _data.max_claims.aggregate.max.maxOrder || 0,
        CONTACT_INFORMATION: _data.max_contact.aggregate.max.maxOrder || 0,
        POLICY_AND_REPORTS: _data.max_policy.aggregate.max.maxOrder || 0,
        VALUE_ADDED_PROGRAMS: _data.max_programs.aggregate.max.maxOrder || 0,
        FORMS: _data.max_forms.aggregate.max.maxOrder || 0,
        RISK_MANAGEMENT: _data.max_risk.aggregate.max.maxOrder || 0,
      };
      const elementsOrder:CommonJSON[] = [];
      itemsToInsert?.forEach(item => {
        componentOrder[item.component] += 1;
        elementsOrder.push({
          component: item.component,
          school_id: schoolId,
          order: componentOrder[item.component],
          third_party_administrators_dashboard_element_id: item.id,
          created_at: new Date(),
          updated_at: new Date(),
        });
      });
      const variables: CreateManySchoolDashboardElementOrderVariables = {
        objects: elementsOrder,
      };
      callInsertManyDashboardElementOrder({ variables });
    },
  });

  // Mutations
  const [callAssociateSchoolTPA, {
    loading: loadingAssociateSchoolTPA,
  }] = useMutation<AssociateSchoolTPAResponse, AssociateSchoolTPAVariables>(associateSchoolTPA, {
    onError: _error => {
      console.error('Error [associateSchoolTPA]', _error);
      Toaster('error', t('TOASTER.ERROR.SCHOOL_TPA_ASSOCIATION_FAILED'));
    },
    onCompleted: _data => {
      if (_data && _data.insert_schools_third_party_administrators_one) {
        setItemsToInsert(_data
          .insert_schools_third_party_administrators_one
          .third_party_administrator?.third_party_administrators_dashboard_elements);
        callGetMaxOrderAllComponentaBySchool({
          variables: {
            schoolId: _data.insert_schools_third_party_administrators_one.school_id,
          },
        });
        Toaster('success', t('TOASTER.SUCCESS.SCHOOL_TPA_ASSOCIATION_SUCCESSFULLY'));
        onCreate();
      }
    },
  });

  // Effects
  React.useEffect(() => {
    reset(getDefaultValues());
  }, [isOpen, reset]);

  // Handlers
  const handleAdd = React.useCallback(async (data: CommonJSON) => {
    if (schoolId) {
      const variables: AssociateSchoolTPAVariables = {
        object: {
          school_id: schoolId,
          third_party_administrator_id: data.tpa ? data.tpa.value : '',
          created_at: new Date(),
          updated_at: new Date(),
          is_active: true,
          is_default: false,
        },
      };

      callAssociateSchoolTPA({ variables });
    }
  }, [
    schoolId,
    callAssociateSchoolTPA,
  ]);

  // Memos
  const tpaOptions = React.useMemo(() => {
    if (dataGetTPAs && dataGetTPAs.third_party_administrators) {
      return dataGetTPAs.third_party_administrators.map(tpa => ({
        value: tpa.id || '',
        label: tpa.code,
      }));
    }
    return [];
  }, [dataGetTPAs]);

  const loading = React.useMemo(
    () => loadingGetTPAs || loadingAssociateSchoolTPA,
    [loadingGetTPAs, loadingAssociateSchoolTPA],
  );

  return (
    <StyledModal
      isOpen={isOpen}
      toggle={toggle}
      title={t('TPA_MODAL.TITLE')}
    >
      {/* Top Container */}
      <TopContainer>

        {/* Field: Select */}
        <Controller
          control={control}
          name="tpa"
          render={({ onChange, value, name }) => (
            <>
              {
                  loadingGetTPAs ? (
                    <LoadingComponent />
                  ) : (
                    <StyledDropdown
                      title={t('TPA_MODAL.SELECT_LABEL')}
                      name={name}
                      selected={value}
                      options={tpaOptions}
                      onSelect={(key, v) => onChange(v)}
                      error={errors[name] !== undefined}
                    />
                  )
                }
            </>
          )}
        />

      </TopContainer>

      {/* Bottom Container */}
      <BottomContainer>
        {/* Add or Edit Button */}
        <AddOrEditButton
          type="PRIMARY"
          disabled={loading}
          onClick={handleSubmit(handleAdd)}
        >
          {t('TPA_MODAL.CTA_ADD_LABEL')}
        </AddOrEditButton>
      </BottomContainer>
    </StyledModal>
  );
});

export default SchoolsTPAModal;
