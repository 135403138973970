/* eslint-disable no-param-reassign */
/* eslint-disable no-console */

import axios from 'axios';
import { SIS_TOKEN } from 'context/Auth';

/* eslint-disable @typescript-eslint/naming-convention */
const MB_TO_BYTES = 1024 * 1024;
const SCHOOL_LOGO_FILE_MAX_FILESIZE_MB = 10;
const SCHOOL_LOGO__FILE_MAX_FILESIZE_BYTES = SCHOOL_LOGO_FILE_MAX_FILESIZE_MB * MB_TO_BYTES;

// Check minimum file size
const checkFileDimensions = async function checkFileDimensions(
  value: File,
  minWidthPx?: number,
  minHeightPx?: number,
) {
  if (!value) {
    return true;
  }
  let objectUrl: string;
  let needsRevoke = false;

  if (typeof (value) === 'string') {
    objectUrl = value;
  } else {
    objectUrl = URL.createObjectURL(value);
    needsRevoke = true;
  }

  const img = document.createElement('img');
  img.src = objectUrl;

  const result = await new Promise<boolean>(resolve => {
    img.onload = () => {
      if (needsRevoke) {
        URL.revokeObjectURL(objectUrl);
      }
      let out = true;
      if (minWidthPx !== undefined && img.naturalWidth < minWidthPx) {
        out = false;
      }
      if (minHeightPx !== undefined && img.naturalHeight < minHeightPx) {
        out = false;
      }
      resolve(out);
    };
    img.onerror = () => {
      resolve(false);
    };
  });

  return result;
};

const readImage = function readImage(file: File) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onabort = () => {
        console.error('[ImageHelper-readImage] File reading was aborted');
        reject();
      };
      reader.onerror = () => {
        console.error('[ImageHelper-readImage] File reading has failed');
        reject();
      };
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        resolve(binaryStr);
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('[ImageHelper-readImage] ERROR', error);
      reject();
    }
  });
};

export const uploadSchoolLogo = async function uploadSchoolLogo(schoolId: string, file: File) {
  let out: string | null = null;
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }

    const url = `${process.env.REACT_APP_API}/v1/upload/school-logo`;

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('school_id', schoolId);
    formData.append('title', 'school-logo');
    formData.append('component', 'school-logo');

    const response = await axios(url, {
      method: 'post',
      data: formData,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });

    if (response && response.data && response.data.success) {
      const { location } = response.data;
      out = location || '';
    }
  } catch (error) {
    console.error('ERROR [uploadSchoolLogo]', error);
  }
  return out;
};

export const updateSchoolLogo = async function updateSchoolLogo(elementId: string, file: File) {
  let out: string | null = null;
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }

    const url = `${process.env.REACT_APP_API}/v1/upload/school-element`;

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('element_id', elementId);

    const response = await axios(url, {
      method: 'post',
      data: formData,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });

    if (response && response.data && response.data.success) {
      const { location } = response.data;
      out = location || '';
    }
  } catch (error) {
    console.error('ERROR [updateSchoolLogo]', error);
  }
  return out;
};

export const deleteSchoolLogo = async function deleteSchoolLogo(elementId: string) {
  let out: string | null = null;
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }

    const url = `${process.env.REACT_APP_API}/v1/remove/school-element`;

    const formData = {
      file_id: elementId,
    };

    const response = await axios(url, {
      method: 'post',
      data: formData,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });

    if (response && response.data && response.data.success) {
      const { location } = response.data;
      out = location || '';
    }
  } catch (error) {
    console.error('ERROR [deleteSchoolLogo]', error);
  }
  return out;
};

export const getSchoolLogo = function getSchoolLogo(school?: School) {
  let out: string = '';
  if (school && school.schools_dashboard_elements && school.schools_dashboard_elements.length > 0) {
    const element = school.schools_dashboard_elements.find(e => e.type === 'FILE' && e.title === 'school-logo');
    if (element) {
      out = `${(process.env.REACT_APP_CDN_URL || '').replace(/\/$/, '')}/schools/${school.id}/${element.filename}`;
    }
  }

  return out;
};

export const getSchoolLogoElement = function getSchoolLogoElement(school?: School) {
  if (school && school.schools_dashboard_elements && school.schools_dashboard_elements.length > 0) {
    const element = school.schools_dashboard_elements.find(e => e.type === 'FILE' && e.title === 'school-logo');
    if (element) {
      return element;
    }
  }

  return null;
};

export const uploadTpaElement = async function uploadTpaElement(
  tpaId: string,
  file: File,
  title: string,
  component: string,
) {
  let out: string | null = null;
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }
    // TODO: change
    const url = `${process.env.REACT_APP_API}/v1/upload/tpa`;

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('tpa_id', tpaId);
    formData.append('title', title);
    formData.append('component', component);

    const response = await axios(url, {
      method: 'post',
      data: formData,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });

    if (response && response.data && response.data.success) {
      const { element_id } = response.data;
      out = element_id || '';
    }
  } catch (error) {
    console.error('ERROR [uploadTpaElement]', error);
  }
  return out;
};

export const updateTpaElement = async function updateTpaElement(
  elementId: string,
  file: File,
) {
  let out: string | null = null;
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }
    // TODO: change
    const url = `${process.env.REACT_APP_API}/v1/upload/tpa-element`;

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('element_id', elementId);

    const response = await axios(url, {
      method: 'post',
      data: formData,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });

    if (response && response.data && response.data.success) {
      const { location } = response.data;
      out = location || '';
    }
  } catch (error) {
    console.error('ERROR [updateTpaElement]', error);
  }
  return out;
};

export const deleteTpaElement = async function deleteTpaElement(fileId: string) {
  let out: string | null = null;
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }
    // TODO: change
    const url = `${process.env.REACT_APP_API}/v1/remove/tpa-element`;

    const response = await axios(url, {
      method: 'post',
      data: {
        file_id: fileId,
      },
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });

    if (response && response.data && response.data.success) {
      const { location } = response.data;
      out = location || '';
    }
  } catch (error) {
    console.error('ERROR [deleteTpaElement]', error);
  }
  return out;
};

export const uploadSchoolElement = async function uploadSchoolElement(
  schoolId: string,
  file: File,
  title: string,
  component: string,
) {
  let out: string | null = null;
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }
    // TODO: change
    const url = `${process.env.REACT_APP_API}/v1/upload/school-documents`;

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('school_id', schoolId);
    formData.append('title', title);
    formData.append('component', component);

    const response = await axios(url, {
      method: 'post',
      data: formData,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });

    if (response && response.data && response.data.success) {
      const { element_id } = response.data;
      out = element_id || '';
    }
  } catch (error) {
    console.error('ERROR [uploadSchoolElement]', error);
  }
  return out;
};

export const updateSchoolElement = async function updateSchoolElement(
  elementId: string,
  file: File,
) {
  let out: string | null = null;
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }
    // TODO: change
    const url = `${process.env.REACT_APP_API}/v1/upload/school-element`;

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('element_id', elementId);

    const response = await axios(url, {
      method: 'post',
      data: formData,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });

    if (response && response.data && response.data.success) {
      const { location } = response.data;
      out = location || '';
    }
  } catch (error) {
    console.error('ERROR [updateSchoolElement]', error);
  }
  return out;
};

export const deleteSchoolElement = async function deleteSchoolElement(
  elementId: string,
) {
  let out: string | null = null;
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }
    // TODO: change
    const url = `${process.env.REACT_APP_API}/v1/remove/school-element`;

    const response = await axios(url, {
      method: 'post',
      data: {
        file_id: elementId,
      },
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });

    if (response && response.data && response.data.success) {
      const { location } = response.data;
      out = location || '';
    }
  } catch (error) {
    console.error('ERROR [deleteSchoolElement]', error);
  }
  return out;
};

export const csvToJson = function csvToJson(text: string, delim: string = ',') {
  const headers = text.slice(0, text.indexOf('\n')).toLowerCase().split(delim)
    .map(col => col.replace('\r', ''));
  let rows = text.slice(text.indexOf('\n') + 1).split('\n');
  rows = rows.map(row => row.replace('\r', ''));

  const newArray = rows.map(row => {
    const values = row.split(delim);
    const eachObject = headers.reduce((obj: CommonJSON, header, i) => {
      obj[header.trim()] = values[i] ? values[i].trim() : '';
      return obj;
    }, {});
    return eachObject;
  });

  return newArray;
};

const ImagesHelper = {
  SCHOOL_LOGO_FILE_MAX_FILESIZE_MB,
  SCHOOL_LOGO__FILE_MAX_FILESIZE_BYTES,
  checkFileDimensions,
  readImage,
  // School Specific
  uploadSchoolLogo,
  updateSchoolLogo,
  deleteSchoolLogo,
  getSchoolLogo,
  getSchoolLogoElement,
  uploadSchoolElement,
  deleteSchoolElement,
  // Tpa elements,
  uploadTpaElement,
  deleteTpaElement,
  updateTpaElement,
  // File Converter
  csvToJson,
};

export default ImagesHelper;
