import React from 'react';
import styled from 'styled-components';

// Components
import Text from 'components/Text';

// Theme
import { colors } from '../../theme/theme';

const State = styled.span`
  font-family: Roboto-Bold;
  margin-right: 5px;
`;

const ClaimFraudText = React.memo(() => (
  <div>
    <Text size={16} fontFamily="BOLD" color={colors.grey} className="mb-1">CLAIM FORM FRAUD STATEMENT</Text>
    <Text size={12} fontFamily="BOLD" color={colors.grey} className="mb-3">FOR RESIDENTS OF ALL STATES OTHER THAN THOSE LISTED BELOW</Text>
    <Text size={12} color={colors.grey} className="mb-3">
      Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit
      or knowingly presents false information in an application for insurance
      is guilty of a crime and may be subject to fines and confinement in prison
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>ARIZONA:</State>
      For your protection Arizona law requires the following statement to appear on this form.
      Any person who knowingly presents a false or fraudulent claim for payment of a loss is subject
      to criminal and civil penalties
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>ALABAMA:</State>
      Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or who knowingly
      presents false information in an application for insurance is guilty of a crime and may be subject to
      restitution, fines or confinement in prison or any combination thereof.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>ALASKA:</State>
      A person who knowingly and with intent to injure, defraud, or deceive an insurance company files a claim
      containing false, incomplete, or misleading information may be prosecuted under state law.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>ARKANSAS, RHODE ISLAND AND WEST VIRGINIA:</State>
      Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly
      presents false information in an application for insurance is guilty of a crime and may be subject to fines
      and confinementin prison.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>CALIFORNIA:</State>
      For your protection California law requires the following to appear on this form: Any person who knowingly
      presents a false or fraudulent claim for the payment of a loss is guilty of a crime and may be subject to fines
      and confinement in state prison.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>COLORADO:</State>
      It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance
      company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment,
      fines, denial of insurance and civil damages. Any insurance company or agent of an insurance company who
      knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the
      purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award
      payable from insurance proceeds shall be reported to the Colorado division of insurance within the department
      of regulatory agencies.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>DELAWARE:</State>
      Any person who knowingly, and with intent to injure, defraudor deceive any insurer, files a statement of claim
      containing any false, incomplete or misleading information is guilty of a felony.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>DISTRICT OF COLUMBIA:</State>
      WARNING: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding
      the insurer ot any other person. Penalties include imprisonment and/or fines. In addition. An insurer may deny
      insurance benefits if false information materially related to a claim was provided by the applicant.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>FLORIDA:</State>
      Any person who knowingly and with intent to injure,defraud, or deceive any insurer files a statement of claim
      or an applicationcontaining any false, incomplete, or misleading information is guilty of a felony
      of the third degree.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>IDAHO:</State>
      Any person who knowingly, and with intent to defraud or deceive any insurance company, files a statement of
      claim containing any false, incomplete, or misleading information is guilty of a felony.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>IDAHO:</State>
      Any person who knowingly, and with intent to defraud or deceive any insurance company, files a statement of
      claim containing any false, incomplete, or misleading information is guilty of a felony.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>INDIANA:</State>
      A person who knowingly and with intent to defraud an insurer files a statement of claim containing any false,
      incomplete, or misleading information commits a felony.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>KENTUCKY:</State>
      Any person who knowingly and with intent to defraud any insurance company or other person files a statement of
      claim containing any materially false information or conceals, for the purpose of misleading, information
      concerning any fact material thereto commits a fraudulent insurance act, which is a crime.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>MAINE AND WASHINGTON:</State>
      It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the
      purpose of defrauding the company. Penalties may include imprisonment, fines or a denial of insurance benefits.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>MARYLAND:</State>
      Any person who knowingly or willfully presents a false or fraudulent claim for payment of a loss or benefit or
      who knowingly or willfully presents false information in an application for insurance is guilty of a crime and
      may be subject to fines and confinement in prison.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>MINNESOTA:</State>
      A person who files a claim with intent to defraud or helps commit a fraud against an insurer is guilty of a crime.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>NEW HAMPSHIRE:</State>
      Any person who, with a purpose to injure, defraud, or deceive any insurance company, files a statement of claim
      containing anyfalse, incomplete, or misleading information is subject to prosecution and punishment for insurance
      fraud, as provided in RSA 638:20.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>NEW MEXICO and PENNSYLVANIA:</State>
      Any person who knowingly and withintent to defraud any insurance company or other person files an application
      for insurance or statement of claim containing any materially false information or conceals for the purpose of
      misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime
      and subjects such person to criminal and civil penalties.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>NEW YORK:</State>
      Any person who knowingly and with intent to defraud any insurance company or other person files an application
      for insurance or statement of claim containing any materially false information, or conceals for the purpose of
      misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a
      crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of
      the claim for each such violation.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>NORTH CAROLINA AND OREGON:</State>
      Any person who, with intent to defraud or knowing that he/she is facilitating a fraud against an insurer,
      submits an application or files a claim containing a false or deceptive statement, commits insurance fraud,
      which is a crime and subjects the person to civil and criminal penalties.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>OHIO:</State>
      Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an
      application or files a claim containing a false or deceptive statement is guilty of insurance fraud.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>OKLAHOMA:</State>
      Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the
      proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>TENNESSEE:</State>
      It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the
      purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>TEXAS:</State>
      Any person who knowingly presents a false or fraudulent claim forpayment of a loss is guilty of a crime and may
      be subject to fines and confinement in state prison.
    </Text>

    <Text size={12} color={colors.grey} className="mb-3">
      <State>VIRGINIA:</State>
      Any person who, with the intent to defraud or knowing that he is facilitating a fraud against an insurer,
      submits an application or files a claim containing a false or deceptive statement may have violated state law.
    </Text>

  </div>
));

export default ClaimFraudText;
