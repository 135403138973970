import { gql } from '@apollo/client';
import { useShortFragment } from './user';

// Fragments
export const claimChangeLogsFragment = gql`
fragment claim_change_logs on claims_change_logs{
  id
  submitted_to_tpa_at
  changes_log
  user {
    ...shortUser
  }
}
${useShortFragment}
`;
