/* eslint-disable no-console */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useQuery } from '@apollo/client';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';

// Component
import Card from 'components/Card';
import CardTitle from 'components/Card/CardTitle';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import Button, { ButtonTypes } from 'components/Button';

// Data
import {
  getAdminsForDropdown,
} from 'data/user/user.query';
import status from 'data/static/status.json';

// hooks
import { useResponsive } from 'hooks/useResponsive';

type StatusKey = keyof typeof status;

const initialValues = {
  firstName: '',
  lastName: '',
  schoolName: '',
  academicId: '',
  tpa: '',
  submittedBy: { value: '', label: '' },
  status: { value: '', label: '' },
};

interface SearchSubmittedClaimsProps {
  onSearch: (newFilters: ClaimAdminFilters) => void;
}

const SearchSubmittedClaims = React.memo(({ onSearch }: SearchSubmittedClaimsProps) => {
  const { t } = useTranslation();
  const [searchState, setSearchState] = React.useState<ClaimAdminFilters>(initialValues);
  const { isMobile } = useResponsive();

  const statusList: OptionsType<OptionType> = useMemo(() => Object.keys(status)
    // @ts-ignore
    .map((key: StatusKey) => ({ value: key, label: status[key] })), [status]);

  const and: CommonJSON[] = [];
  and.push({
    deleted_at: { _is_null: true },
  });

  const where = {
    _and: and,
  };

  // Queries
  const {
    loading: loadingGetAdmins,
    data: dataGetAdmins,
  } = useQuery<GetDropDownUsersResponse, GetDropDownUsersVariables>(getAdminsForDropdown, {
    variables: { where },
  });

  const adminsOptions = React.useMemo(() => {
    if (dataGetAdmins && dataGetAdmins.users) {
      return dataGetAdmins.users.map(item => ({
        value: item.id,
        label: `${item.last_name} ${item.first_name}`,
      }));
    }
    return [];
  }, [dataGetAdmins, loadingGetAdmins]);

  const buttonDisabled = useMemo(() => {
    if (!searchState.firstName && !searchState.lastName && !searchState.academicId
      && !searchState.schoolName && !searchState.tpa && !searchState.submittedBy
      && !searchState.status) {
      return true;
    }
    return false;
  }, [searchState]);

  const handleOnChange = useCallback((key: string, value: string) => {
    setSearchState(state => ({
      ...state,
      [key]: value,
    }));
  }, []);

  const handleSelectChange = useCallback((key: string, value: OptionType | undefined) => {
    setSearchState(state => ({
      ...state,
      [key]: value,
    }));
  }, []);

  const handleClear = useCallback(() => {
    setSearchState(state => ({
      ...state,
      ...initialValues,
    }));
    onSearch(initialValues);
  }, [onSearch]);

  const handlePressEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(searchState);
    }
  }, [onSearch, searchState]);

  return (
    <Card
      padding="25px 30px 20px 30px;"
      className="mb-4"
      title={(
        <CardTitle>{t('CLAIMS_PAGE.CLAIMS_FILTER.CARD_TITLE')}</CardTitle>
      )}
    >
      <div className="row">
        <div className={`${isMobile ? 'col-12' : 'col-4'}`}>
          <Input
            value={searchState.firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange('firstName', e.target.value)}
            title={t('CLAIMS_PAGE.CLAIMS_FILTER.CLAIMANT_FIRST_NAME_LABEL')}
            className="mb-4"
            icon="las la-user"
            onKeyDown={handlePressEnter}
          />
        </div>
        <div className={`${isMobile ? 'col-12' : 'col-4'}`}>
          <Input
            value={searchState.lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange('lastName', e.target.value)}
            title={t('CLAIMS_PAGE.CLAIMS_FILTER.LAST_NAME_LABEL')}
            className="mb-4"
            icon="las la-user"
            onKeyDown={handlePressEnter}
          />
        </div>
        <div className={`${isMobile ? 'col-12' : 'col-4'}`}>
          <Input
            value={searchState.academicId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange('academicId', e.target.value)}
            title={t('CLAIMS_PAGE.CLAIMS_FILTER.STUDENT_ID_LABEL')}
            className="mb-3"
            icon="las la-id-card"
            onKeyDown={handlePressEnter}
          />
        </div>
      </div>
      <div className="row">
        <div className={`${isMobile ? 'col-12' : 'col-4'}`}>
          <Input
            value={searchState.schoolName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange('schoolName', e.target.value)}
            title={t('CLAIMS_PAGE.CLAIMS_FILTER.SCHOOL_LABEL')}
            className="mb-3"
            onKeyDown={handlePressEnter}
          />
        </div>
        <div className={`${isMobile ? 'col-12' : 'col-4'}`}>
          <Input
            value={searchState.tpa}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange('tpa', e.target.value)}
            title={t('CLAIMS_PAGE.CLAIMS_FILTER.TPA_LABEL')}
            className="mb-3"
            icon="las la-id-card"
            onKeyDown={handlePressEnter}
          />
        </div>
        <div className={`${isMobile ? 'col-12' : 'col-4'}`}>
          <Dropdown
            title={t('CLAIMS_PAGE.CLAIMS_FILTER.SUBMITTED_BY_LABEL')}
            selected={searchState.submittedBy}
            onSelect={handleSelectChange}
            options={adminsOptions}
            name="submittedBy"
          />
        </div>
      </div>
      <div className="row">

        <div className={`${isMobile ? 'col-12' : 'col-4'}`}>
          <Dropdown
            title={t('CLAIMS_PAGE.CLAIMS_FILTER.STATUS_LABEL')}
            selected={searchState.status}
            onSelect={handleSelectChange}
            options={statusList}
            name="status"
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        {/* Clear Button */}
        <Button
          onClick={handleClear}
          type={ButtonTypes.SECONDARY}
          className="mr-2"
        >
          {t('CLAIMS_PAGE.CLAIMS_RESULT.CTA_CLEAR_BUTTON_LABEL')}
        </Button>

        <Button
          disabled={buttonDisabled}
          onClick={() => { onSearch(searchState); }}
          type={ButtonTypes.PRIMARY}
          minWidth="100px"
        >
          {t('CLAIMS_PAGE.CLAIMS_FILTER.CTA_SEARCH_BUTTON_LABEL')}
        </Button>
      </div>
    </Card>
  );
});

export default SearchSubmittedClaims;
