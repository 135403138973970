import styled from 'styled-components';
import { theme } from 'theme/theme';

export const MainContainer = styled.div<{ $color: string }>`
  width: 100%;
  max-width: 84px;
  line-height: 25px;
  background-color: ${({ $color }) => $color};
  opacity: 0.8;
  border-radius: 4px;
  color: ${theme.colors.white};
  text-align: center;
  padding: 0 16px;
  font-family: 'Lato';
  font-size: 14px;
  font-weight: bold;
`;
