import styled from 'styled-components';

// Components
import CustomModal from 'modals/CustomModal';
import Button from 'components/Button';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';

export const StyledModal = styled(CustomModal)<{ $isMobile: boolean}>`
  max-width: ${({ $isMobile }) => ($isMobile ? '346px' : '738px')};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const TopContainer = styled.div<{ $isMobile: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $isMobile }) => ($isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr')};
  grid-template-rows: ${({ $isMobile }) => ($isMobile ? '1fr 1fr 1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr')};
  gap: ${({ $isMobile }) => ($isMobile ? '20px 10px' : '20px 20px')};
  grid-template-areas: ${({ $isMobile }) => ($isMobile ? `
    "area-firstname area-firstname"
    "area-lastname area-lastname"
    "area-address1 area-address1"
    "area-address2 area-address2"
    "area-city area-city"
    "area-state area-zip"
    "area-country area-country";`
    : `
    "area-firstname area-firstname area-address1 area-address1"
    "area-lastname area-lastname area-address2 area-address2"
    "area-city area-city area-state area-zip"
    "area-country area-country . .";`)}
`;

export const GridItem = styled.div`
  width: 100%;

  &.area-firstname {
    grid-area: area-firstname;
  }

  &.area-lastname { 
    grid-area: area-lastname;
  }

  &.area-address1 { 
    grid-area: area-address1;
  }

  &.area-address2 { 
    grid-area: area-address2;
  }

  &.area-city { 
    grid-area: area-city;
  }

  &.area-state { 
    grid-area: area-state;
  }

  &.area-zip { 
    grid-area: area-zip;
  }

  &.area-country { 
    grid-area: area-country;
  }

  &.area-employer-name { 
    grid-area: area-employer-name;
  }

  &.area-employer-phone { 
    grid-area: area-employer-phone;
  }

`;

export const EmployerContainer = styled.div<{ $isMobile: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $isMobile }) => ($isMobile ? '1fr' : '1fr 1fr')};
  grid-template-rows: ${({ $isMobile }) => ($isMobile ? '1fr 1fr' : '1fr')};
  gap: ${({ $isMobile }) => ($isMobile ? '20px 10px' : '20px 20px')};
  grid-template-areas: ${({ $isMobile }) => ($isMobile ? `
    "area-employer-name"
    "area-employer-phone";`
    : `
    "area-employer-name area-employer-phone";`)}
`;

export const TextInput = styled(Input)`
  width: 100%;
  margin-top: 20px;
  
  input {
    background-image: none !important;
  }

  &:first-of-type {
    margin-top: 0px;
  }
`;

export const StyledDropdown = styled(Dropdown)``;

export const StyledCheckbox = styled(Checkbox)``;

export const BottomContainer = styled.div<{ $isMobile: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${({ $isMobile }) => ($isMobile ? '30px' : '40px')};
`;

export const AddButton = styled(Button)`
  width: 100%;
  max-width: 124px;
`;
