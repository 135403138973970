import { gql } from '@apollo/client';

import {
  schoolDashboardElementFragment,
} from 'data/fragments';

export const deleteSoftSchoolDashboardElement = gql`
  mutation deleteSoftSchoolDashboardElement($schoolDashboardElementId: uuid!) {
    update_schools_dashboard_elements_by_pk (
      pk_columns: { id: $schoolDashboardElementId }, _set: { deleted_at: "now()" },
    ) {
      ...schoolDashboardElement
    }
  }
  ${schoolDashboardElementFragment}
`;

export const deleteSchoolDashboardElement = gql`
  mutation deleteSchoolDashboardElement($schoolDashboardElementId: uuid!) {
    delete_schools_dashboard_elements_by_pk (
      id: $schoolDashboardElementId
    ) {
      ...schoolDashboardElement
    }
  }
  ${schoolDashboardElementFragment}
`;
