import { gql } from '@apollo/client';

import {
  schoolsTPAFragment,
  schoolsTPAFragmentWithElments,
} from 'data/fragments';

export const getSchoolTPAs = gql`
  query getSchoolTPAs($schoolId: uuid!) {
    schools_third_party_administrators (
      where: {
        _and: [
          { school_id: { _eq: $schoolId } },
          { deleted_at: { _is_null: true }}
        ],
      },
    ) {
      ...schoolsTPA
    }
  }
  ${schoolsTPAFragmentWithElments}
`;

export const getSchoolActiveTPAs = gql`
  query getSchoolActiveTPAs($schoolId: uuid!, $currentTPAId: schools_third_party_administrators_bool_exp!) {
    schools_third_party_administrators (
      where: {
        _or: [
          {
            _and: [
              { school_id: { _eq: $schoolId } },
              { deleted_at: { _is_null: true }},
              { is_active: { _eq: true }}
            ]
          },
          $currentTPAId
        ]
      },
    ) {
      ...schoolsTPA
    }
  }
  ${schoolsTPAFragment}
`;

export const getSchoolActiveTPAById = gql`
  query getSchoolActiveTPAs($schoolId: uuid!, $tpaId: uuid!) {
    schools_third_party_administrators (
      where: {
        _and: [
          { school_id: { _eq: $schoolId } },
          { id: { _eq: $tpaId } },
          { deleted_at: { _is_null: true }},
          { is_active: { _eq: true }}
        ],
      },
    ) {
      ...schoolsTPA
    }
  }
  ${schoolsTPAFragment}
`;

export const getTPAById = gql`
  query getSchoolTPAsById($tpaId: uuid!) {
    schools_third_party_administrators_by_pk(id: $tpaId) {
      id
      policy_number
      third_party_administrator {
        name
      }
    }
  }
`;
