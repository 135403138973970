import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Select, { ValueType } from 'react-select';

// Theme
import { colors } from 'theme/theme';

// Components
import Text from 'components/Text';

// Style
import './PageSizeSelector.css';

interface PageSizeSelectorProps {
  pageSize: number,
  setPageSize: (size: number) => void,
  options: SizeOptionType[],
}

const Container = styled.div`
  background-color: ${colors.softGrey};
  padding: 10px 19px;
  border-radius: 6px;
  display: flex;
  align-items: center;
`;

const PageSizeSelector = React.memo(({
  pageSize,
  setPageSize,
  options,
}: PageSizeSelectorProps) => {
  const { t } = useTranslation();

  const selected = useMemo(() => {
    const size = options.find((option: any) => option.value === pageSize);
    if (size) {
      return size;
    }
    return options[0];
  }, [pageSize]);

  const handleOnChange = useCallback((value?: number) => {
    if (value) {
      setPageSize(value);
    }
  }, []);

  return (
    <Container className="d-flex">
      <Text className="custom-page-size-text" size={12} color={colors.grey}>
        {t('PAGE_SIZE_SELECTOR.ITEMS_PER_PAGE')}
        :
      </Text>
      <Select
        className="custom-page-size-select"
        classNamePrefix="page-size-select"
        options={options}
        value={selected}
        onChange={val => handleOnChange(val?.value)}
      />
    </Container>
  );
});

export default PageSizeSelector;
