import styled from 'styled-components';

// Theme
// import { theme } from 'theme/theme';

// Components
import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
// import DatePicker from 'components/DatePicker';

export const Title = styled(Text)``;

export const Grid = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px 20px;
`;

export const GridItem = styled.div`
  width: 100%;
`;

export const TextInput = styled(Input)``;

export const StyledDropdown = styled(Dropdown)``;

// export const CustomLabel = styled(Text)`
//   margin-bottom: 8px;
// `;

// export const StyledDatePicker = styled(DatePicker)`
//   width: 132px;
// `;

// export const DatePickerContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   width: 100%;
// `;

// export const DatePickerLabel = styled(Text)`

//   &.from-label {
//     margin-right: 10px;
//   }

//   &.to-label {
//     margin: 0 10px;
//   }
// `;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > button {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
`;

export const SearchButton = styled(Button)`
  width: 100%;
  max-width: 130px;
`;

export const ClearButton = styled(Button)`
  width: 100%;
  max-width: 130px;
`;
