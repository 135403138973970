/* eslint-disable no-console */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

// Styles
import { theme } from 'theme/theme';
import {
  MainContainer,
  TextInput,
  Title,
  GridUp,
  GridItem,
  GridDown,
  GridItemDelete,
  DeleteButton,
  StyledTrashIcon,
  StyledDatePicker,
  StyledTagSelect,
  StyledLabel,
  StyledToggle,
} from './style';

export interface InsuranceInfoRowProps {
  className?: string;
  schoolTPA: SchoolsTPA;
  activitiesOptions: OptionType[];
  // Form
  baseName: string;
  idx: number;
  register: Function;
  control: any;
  errors?: CommonJSON;
  // Events
  onDelete: (schoolTPAId: string) => void;
}

interface ValType {
  value: string;
  label: string;
}

const InsuranceInfoRow = React.memo(({
  className,
  schoolTPA,
  activitiesOptions,
  baseName,
  idx,
  register,
  control,
  errors,
  onDelete,
}: InsuranceInfoRowProps) => {
  // Hooks
  const { t } = useTranslation();

  // Handlers
  const handleOnDelete = React.useCallback(() => {
    if (schoolTPA) {
      onDelete(schoolTPA.id);
    }
  }, [schoolTPA, onDelete]);

  const handleChange = ((value?: any) => {
    if (value.length > 0) {
      if (value[value.length - 1].value === '*') {
        return activitiesOptions.slice(1, activitiesOptions.length);
      }
      value.sort((a: ValType, b: ValType) => a.label.localeCompare(b.label));
      return value;
    }
    return value;
  });

  return (
    <MainContainer
      className={classnames(className)}
    >

      {/* Title */}
      <Title
        size={16}
        fontFamily={theme.fontFamilies.roboto.medium}
        weight={theme.fontWeights.medium}
        color={theme.colors.dark}
      >
        {schoolTPA.third_party_administrator && schoolTPA.third_party_administrator.code}
      </Title>

      {/* Grid */}
      <GridUp>
        {/* Grid Item: Policy Number */}
        <GridItem>
          <TextInput
            ref={register()}
            maxLength={20}
            name={`${baseName}[${idx}].policyNumber`}
            title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.POLICY_NUMBER_LABEL')}
            error={errors && errors.policyNumber !== undefined}
          />
        </GridItem>

        {/* Grid Item: Group Number */}
        <GridItem>
          <TextInput
            ref={register()}
            maxLength={50}
            name={`${baseName}[${idx}].groupNumber`}
            title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.GROUP_NUMBER_LABEL')}
            error={errors && errors.groupNumber !== undefined}
          />
        </GridItem>
        {/* Grid Item: Effective Date */}
        <GridItem>
          {/* From DatePicker */}
          <Controller
            control={control}
            name={`${baseName}[${idx}].effectiveDate`}
            render={({
              onChange, value, name,
            }) => (
              <StyledDatePicker
                title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.EFFECTIVE_DATE_LABEL')}
                name={name}
                startDate={value}
                setStartDate={(key: string, date: Date | null | [Date, Date]) => onChange(date)}
              />
            )}
          />
        </GridItem>
      </GridUp>
      <GridDown>
        {/* Grid Item: Sport Options */}
        <GridItem>
          <Controller
            control={control}
            name={`${baseName}[${idx}].sportOptions`}
            render={({
              onChange, value, name,
            }) => (
              <StyledTagSelect
                title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.SPORTS_OPTIONS_LABEL')}
                placeholder=""
                options={activitiesOptions}
                name={name}
                value={value}
                onSelect={(_, v) => { onChange(handleChange(v)); }}
                notAllowInvalidValues
              />
            )}
          />
        </GridItem>
        {/* Grid Item: Status */}
        <GridItem
          className="area-status d-block"
        >
          {/* Label */}
          <StyledLabel
            size={14}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
            fontFamily={theme.fontFamilies.roboto.regular}
          >
            {`${t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.STATUS.LABEL')}:`}
          </StyledLabel>

          {/* Toggle */}
          <StyledToggle
            ref={register()}
            id={`school-tpa-${idx}-tab-status`}
            defaultChecked={schoolTPA ? schoolTPA.is_active : false}
            name={`${baseName}[${idx}].isActive`}
            truthyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.STATUS.LABEL_ACTIVE')}
            falsyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.STATUS.LABEL_INACTIVE')}
          />
        </GridItem>
        {/* Grid Item: Default */}
        <GridItem
          className="area-status d-block"
        >
          {/* Label */}
          <StyledLabel
            size={14}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
            fontFamily={theme.fontFamilies.roboto.regular}
          >
            {`${t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.DEFAULT.LABEL')}:`}
          </StyledLabel>

          {/* Toggle */}
          <StyledToggle
            ref={register()}
            id={`school-tpa-${idx}-tab-default`}
            defaultChecked={schoolTPA ? schoolTPA.is_default : false}
            name={`${baseName}[${idx}].isDefault`}
            truthyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.DEFAULT.LABEL_YES')}
            falsyLabel={t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.DEFAULT.LABEL_NO')}
          />
        </GridItem>

        {/* Grid Item: Actions */}
        <GridItemDelete>
          <DeleteButton
            type="TERTIARY"
            onClick={handleOnDelete}
          >
            <StyledTrashIcon
              className="las la-trash-alt"
            />
            {t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.DELETE_CTA_LABEL')}
          </DeleteButton>
        </GridItemDelete>
      </GridDown>
    </MainContainer>
  );
});
export default InsuranceInfoRow;
