import styled from 'styled-components';

// Components
import CustomModal from 'modals/CustomModal';
import Button from 'components/Button';
import Input from 'components/Input';
import Toggle from 'components/Toggle';
import Text from 'components/Text';

export const StyledModal = styled(CustomModal)``;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const ToggleContainer = styled.div<{ $show: boolean }>`
  width: fit-content;
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
`;

export const TextInput = styled(Input)`
  width: 100%;
  max-width: 520px;
  margin-top: 20px;
  
  input {
    background-image: none !important;
  }

  &:first-of-type {
    margin-top: 0px;
  }
`;

export const StyledLabel = styled(Text)``;

export const StyledToggle = styled(Toggle)`
  margin-left: 12px;
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
`;

export const DeleteButton = styled(Button)`
  width: 100%;
  max-width: 124px;
`;

export const AddOrEditButton = styled(Button)`
  width: 100%;
  max-width: 124px;
`;
