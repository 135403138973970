import { gql } from '@apollo/client';

import {
  providersFragment,
} from 'data/fragments';

export const getProviders = gql`
  query getProviders {
    providers(where: { deleted_at: { _is_null: true }}) {
      ...provider
    }
  }
  ${providersFragment}
`;
