import React from 'react';
import styled from 'styled-components';

// Theme
import { colors } from '../../theme/theme';

const Line = styled.div`
  height: 1px;
  width: 100%;
  background: ${colors.lightGrey}
`;

interface DividerProps {
  className?: string,
}

const Divider = React.memo(({
  className = '',
}: DividerProps) => (
  <Line className={className} />
));

export default Divider;
