/* eslint-disable no-console */
import React, { useMemo } from 'react';
import classnames from 'classnames';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ActionMeta, components, ValueType } from 'react-select';
import { intersectionBy } from 'lodash';

// Components
import Text from 'components/Text';

// Styles
import { theme } from 'theme/theme';
import {
  MainContainer,
  StyledSelect,
} from './style';

export interface TagSelectProps {
  className?: string;
  name: string;
  title?: string;
  required?: boolean;
  placeholder?: string;
  options: OptionType[];
  value: OptionType[];
  onSelect: (key: string, value: OptionType | undefined) => void;
  notAllowInvalidValues?: boolean;
}

const MultiValueRemove = React.memo((props: any) => (
  <components.MultiValueRemove {...props}>
    <i className="las la-times-circle" />
  </components.MultiValueRemove>
));

const TagSelect = ({
  className,
  options,
  title,
  required,
  placeholder,
  name,
  value,
  onSelect,
  notAllowInvalidValues,
}: TagSelectProps, _ref: React.Ref<any>) => {
  // Handlers
  const handleOnChangeBranchFilter = React.useCallback(
    (_value: ValueType<OptionType, boolean>, _action: ActionMeta<OptionType>) => {
      if (_action.action === 'clear') {
        onSelect(name, undefined);
      } else if (_value && ['select-option', 'remove-value'].includes(_action.action)) {
        onSelect(name, _value as (OptionType));
      }
    }, [name, onSelect],
  );

  const clearValue = useMemo(() => (
    notAllowInvalidValues
      ? intersectionBy(value, options, 'value')
      : value
  ), [value, options, notAllowInvalidValues]);

  return (
    <MainContainer
      ref={_ref}
      className={classnames(className, 'no-tap-light')}
    >
      {
      (title || required) && (
      <Text
        size={14}
        color={theme.colors.darkGrey}
        className="mb-2 d-flex"
      >
        {title}
        {
          required && (
            <Text
              size={14}
              color={theme.colors.red}
              className="ml-1"
            >
              *
            </Text>
          )
        }
      </Text>
      )
    }
      <StyledSelect
        className="TagSelect"
        classNamePrefix="tag-select"
        isMulti
        isClearable={false}
        closeMenuOnSelect
        placeholder={placeholder}
        options={options}
        components={{
          MultiValueRemove,
        }}
        value={clearValue}
        onChange={handleOnChangeBranchFilter}
      />

    </MainContainer>
  );
};

const FowardRefTagSelect = React.forwardRef(TagSelect);

export default React.memo(FowardRefTagSelect);
