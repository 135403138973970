export const getPagination = function getPagination(
  currentPage: number,
  totalItems: number,
  pageSize: number,
) {
  const pages = Math.ceil(totalItems / pageSize);

  const out: Pagination = {
    hasPrevious: currentPage > 1,
    hasNext: currentPage < pages,
    pageSize,
    currentPage,
    total: totalItems,
    pages,
  };

  return out;
};
