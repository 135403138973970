import React from 'react';
import { useMediaQuery } from 'react-responsive';

export function useResponsive() {
  // State
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const output = React.useMemo(() => ({
    isMobile,
  }), [isMobile]);
  return output;
}
