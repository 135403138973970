import styled from 'styled-components';

// Components
import StepButtons from 'components/StepButtons';
import Text from 'components/Text';
import Dropdown from 'components/Dropdown';
import DatePicker from 'components/DatePicker';
import Input from 'components/Input';
import TextArea from 'components/TextArea';

// Theme
import { theme } from 'theme/theme';

export const TopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Title = styled(Text)``;

export const MiddleContainer = styled.div<{ $isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 30px;
  padding: 0 ${({ $isMobile }) => ($isMobile ? '35px 0 20px' : '70px')};
`;

export const ListItem = styled.div<{ $isMobile: boolean}>`
  ${({ $isMobile }) => ($isMobile ? `
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;`
    : `
    align-items: center;
    justify-content: space-between;`
  )}
  margin-top: 20px;
  width: 100%;
  display: flex;
  
  &:first-of-type {
    margin-top: 0px;
  }

  &.double-margin-top {
    margin-top: 40px;
  }

  &.width-half {
    max-width: 50%;
  }

  &.width-for-divider-note {
    max-width: 627px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &.vertical-flow {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.full-datepicker {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
`;

export const StyledLabel = styled(Text)`
  &.margin-top-5 {
    margin-top: 5px;
  }
`;

export const StyledRequired = styled.span`
  color: ${theme.colors.red};
  margin-left: 4px;
`;

export const StyledDropdown = styled(Dropdown)<{ $isMobile: boolean}>`
  width: 100%;
  ${({ $isMobile }) => ($isMobile ? ''
    : `max-width: 167px;
    margin-left: 16px;`
  )}
`;

export const StyledDatePicker = styled(DatePicker)<{ $isMobile: boolean}>`
  width: 100%;
  ${({ $isMobile }) => ($isMobile ? ''
    : `max-width: 167px;
    margin-left: 16px;`
  )}  

  &.full {
    max-width: unset;
    margin-left: 0px;

    .react-datepicker-wrapper {
      width: 100%;
    }
  }
`;

export const TextInput = styled(Input)`
  width: 100%;
  max-width: unset;
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
`;

export const StyledStepButtons = styled(StepButtons)<{ $isMobile: boolean }>`
  ${({ $isMobile }) => ($isMobile ? 'margin-top: 56px;'
    : 'margin-top: 80px;'
  )}
`;

export const TimeItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const TimeInput = styled(Input)`
  width: 30%;
  max-width: unset;
`;

export const StyledTimeDatePicker = styled(DatePicker)<{ $isMobile: boolean}>`
  width: 100%;
  ${({ $isMobile }) => ($isMobile ? ''
    : 'max-width: 140px;'
  )}

  &.full {
    max-width: unset;
    margin-left: 0px;

    .react-datepicker-wrapper {
      width: 100%;
    }
  }
`;

export const UnderlinedText = styled.span`
  text-decoration: underline;
`;
