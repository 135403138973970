import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Text from 'components/Text';

// Theme
import { colors } from 'theme/theme';

// Style
import { StyledContainer } from './style';

const InsuranceProviderStatus = ({ status }: { status: string | null }) => {
  const { t } = useTranslation();

  const statusConfig = {
    color: colors.grey,
    label: '',
  };

  switch (status) {
    case 'VERIFIED':
      statusConfig.label = t('INSURANCE_PROVIDER_STATUS.VERIFIED');
      statusConfig.color = colors.successGreen;
      break;
    case 'POLICY_NOT_ACTIVE':
      statusConfig.label = t('INSURANCE_PROVIDER_STATUS.NOT_ACTIVE');
      statusConfig.color = colors.grey;
      break;
    case 'NO_INSURANCE':
      statusConfig.label = t('INSURANCE_PROVIDER_STATUS.NO_INSURANCE');
      statusConfig.color = colors.statusBlue;
      break;
    case 'QUEUED':
      statusConfig.label = t('INSURANCE_PROVIDER_STATUS.QUEUED');
      statusConfig.color = colors.grey;
      break;
    case null:
      statusConfig.label = t('INSURANCE_PROVIDER_STATUS.NOT_VERIFIED');
      statusConfig.color = colors.grey;
      break;
    default:
      statusConfig.label = t('INSURANCE_PROVIDER_STATUS.NEEDS_AUDIT');
      statusConfig.color = colors.yellow;
      break;
  }

  return (
    <StyledContainer color={statusConfig.color}>
      <Text className="w-100 text-center" color={colors.white}>
        {statusConfig.label}
      </Text>
    </StyledContainer>
  );
};

export default InsuranceProviderStatus;
