import styled from 'styled-components';
import { theme } from 'theme/theme';

export const GreyDiv = styled.div`
  display:flex;
  background-color: ${theme.colors.softGrey};
  width:100%;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;

  > i {
    font-size: 20px;
    color: ${theme.colors.grey};
  }
`;

export const Container = styled.div`
  display:flex;
  width:100%;
  margin: 0px .5rem;
  justify-content: space-between;
`;
