import { gql } from '@apollo/client';

import {
  schoolsTPAFragment,
  schoolsTPAFragmentWithElments,
} from 'data/fragments';

export const associateSchoolTPA = gql`
  mutation insert_schools_third_party_administrators_one (
    $object: schools_third_party_administrators_insert_input!,
  ) {
    insert_schools_third_party_administrators_one(object: $object) {
      ...schoolsTPA
    }
  }
  ${schoolsTPAFragmentWithElments}
`;

export const updateSchoolTPA = gql`
  mutation updateSchoolTPA($schoolTPAId: uuid!, $input: schools_third_party_administrators_set_input) {
    update_schools_third_party_administrators_by_pk (pk_columns: { id: $schoolTPAId }, _set: $input) {
      ...schoolsTPA
    }
  }
  ${schoolsTPAFragment}
`;

export const deleteSchoolTPA = gql`
  mutation deleteSchoolTPA($schoolTPAId: uuid!) {
    delete_schools_third_party_administrators_by_pk(id: $schoolTPAId) {
      ...schoolsTPA
    }
  }
  ${schoolsTPAFragment}
`;

export const deleteSoftSchoolTPA = gql`
  mutation deleteSoftSchoolTPA($schoolTPAId: uuid!) {
    update_schools_third_party_administrators_by_pk (pk_columns: { id: $schoolTPAId }, _set: { deleted_at: "now()" }) {
      ...schoolsTPA
    }
  }
  ${schoolsTPAFragment}
`;
