import React from 'react';
import styled from 'styled-components';

// Components
import Icon from 'components/Icon';
import Text from 'components/Text';

// Theme
import { colors } from '../../theme/theme';

const Container = styled.div<{ checked: boolean, $alternativeStyle?: boolean }>`
  border-radius: 4px;
  background-color: ${props => (props.checked ? colors.lightBlue : colors.white)};
  border: solid 1px ${colors.mediumGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 16px;
  width: 16px;

  ${({ $alternativeStyle, checked }) => ($alternativeStyle ? `
    width: 20px;
    height: 20px;
    background-color: ${checked ? colors.lightBlue : colors.lightGrey};
  ` : '')}
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;
interface CheckboxProps {
  checked: boolean,
  onClick: () => void,
  className?: string,
  title?: string,
  label?: string,
  alternativeStyle?: boolean;
  readOnly?: boolean;
  tabIndex?: number;
}
const Checkbox = React.memo(({
  checked,
  onClick,
  className,
  title,
  label,
  alternativeStyle,
  readOnly,
  tabIndex,
}: CheckboxProps) => {
  // Handlers
  const handleClick = React.useCallback(() => {
    if (!readOnly) {
      onClick();
    }
  }, [readOnly, onClick]);

  return (
    <div className={`text-left ${className}`}>
      {title && <Text size={14} color={colors.grey} className="text-left mb-2">{title}</Text>}
      <FlexContainer className="d-flex">
        <Container
          checked={checked}
          onClick={handleClick}
          $alternativeStyle={alternativeStyle}
          tabIndex={tabIndex}
        >
          {checked && (
          <div className="d-flex">
            <Icon className="fa fa-check" size={10} />
          </div>
          )}
        </Container>
        {label && <Text size={14} className="ml-2">{label}</Text>}
      </FlexContainer>
    </div>
  );
});

export default Checkbox;
