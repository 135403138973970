/* eslint-disable no-console */
import React from 'react';
import { useLazyQuery } from '@apollo/client';

// Data
import { getPaginatedSchools } from 'data/school';

// Components
import PageLayout from 'components/PageLayout';
import { getPagination } from 'utils/paginationHelper';
import SchoolFilter from './SchoolFilter';

// Utils

// Styles
import {
  StyledSchoolResults,
} from './style';

const SchoolsPage = React.memo(() => {
  // Refs
  const lastPaginationVariables = React.useRef<GetPaginatedSchoolsVariables | null>(null);

  // States
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [filters, setFilters] = React.useState<CommonJSON>({ status: true });
  const [sortByGql, setSortByGql] = React.useState<any>({ name: 'asc' });

  // Queries
  const [callGetPaginatedSchools, {
    data: dataGetPaginatedSchools,
    refetch: refetchPaginatedSchools,
  }] = useLazyQuery<GetPaginatedSchoolsResponse, GetPaginatedSchoolsVariables>(getPaginatedSchools, {
    fetchPolicy: 'network-only',
  });

  // Effects
  React.useEffect(() => {
    const and: CommonJSON[] = [
      {
        deleted_at: { _is_null: true },
      },
    ];

    if (filters) {
      const {
        modifiedFrom,
        modifiedTo,
        salesRep,
        schoolName,
        status,
        tpa,
      } = filters;

      if (schoolName) {
        and.push({
          name: { _ilike: `%${schoolName}%` },
        });
      }

      if (salesRep) {
        and.push({
          sales_rep_id: { _eq: salesRep },
        });
      }

      if (tpa) {
        and.push({
          schools_third_party_administrators: {
            third_party_administrator_id: {
              _eq: tpa,
            },
            deleted_at: {
              _is_null: true,
            },
          },
        });
      }

      if (status !== undefined) {
        and.push({
          is_active: { _eq: status },
        });
      }

      if (modifiedFrom) {
        and.push({
          updated_at: { _gte: modifiedFrom },
        });
      }

      if (modifiedTo) {
        and.push({
          updated_at: { _lte: modifiedTo },
        });
      }
    }

    const where = {
      _and: and,
    };

    const variables: GetPaginatedSchoolsVariables = {
      where,
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
      order_by: sortByGql,
    };

    lastPaginationVariables.current = variables;

    callGetPaginatedSchools({ variables });
  }, [
    pageSize,
    currentPage,
    filters,
    sortByGql,
    callGetPaginatedSchools,
  ]);

  // Handlers
  const handleOnSearch = React.useCallback((newFilters: SchoolFilters) => {
    setFilters(newFilters);
  }, []);

  const handleOnPageChange = React.useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  const handleOnPageSizeChange = React.useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  }, []);

  const handleOnClear = React.useCallback(() => {
    setCurrentPage(1);
    setFilters({ status: true });
  }, []);

  const handleRefetch = React.useCallback(() => {
    if (lastPaginationVariables.current && refetchPaginatedSchools) {
      refetchPaginatedSchools(lastPaginationVariables.current);
    }
  }, [refetchPaginatedSchools]);

  const handleSort = React.useCallback(column => {
    let auxSortGql = {};
    switch (column) {
      case 'sales_rep_name':
        auxSortGql = {
          sales_rep: {
            last_name: (sortByGql.sales_rep && sortByGql.sales_rep.last_name && sortByGql.sales_rep.last_name === 'desc') ? 'asc' : 'desc',
            first_name: (sortByGql.sales_rep && sortByGql.sales_rep.first_name && sortByGql.sales_rep.first_name === 'desc') ? 'asc' : 'desc',
          },
        };
        break;
      case 'tpa':
        auxSortGql = {
          schools_third_party_administrators: {
            third_party_administrator: {
              code: (sortByGql.schools_third_party_administrators
                && sortByGql.schools_third_party_administrators.third_party_administrator
                && sortByGql.schools_third_party_administrators.third_party_administrator.code === 'desc_nulls_last') ? 'asc_nulls_last' : 'desc_nulls_last',
            },
          },
        };
        break;

      case 'school':
        auxSortGql = { school: { name: (sortByGql.school && sortByGql.school.name && sortByGql.school.name === 'desc') ? 'asc' : 'desc' } };
        break;
      case 'student_id':
        auxSortGql = { student: { academic_id: (sortByGql.student && sortByGql.student.academic_id && sortByGql.student.academic_id === 'desc') ? 'asc' : 'desc' } };
        break;
      case 'submitted_by':
        auxSortGql = {
          user: {
            last_name: (sortByGql.user && sortByGql.user.last_name && sortByGql.user.last_name === 'desc') ? 'asc' : 'desc',
            first_name: (sortByGql.user && sortByGql.user.first_name && sortByGql.user.first_name === 'desc') ? 'asc' : 'desc',
          },
        };
        break;
      default: // name, is_active, schools_notes and updated_at
        auxSortGql = { [column]: (sortByGql[column] && sortByGql[column] === 'desc') ? 'asc' : 'desc' };
        break;
    }
    setSortByGql(auxSortGql);
  }, [sortByGql]);

  const {
    schools,
    pagination,
  } = React.useMemo(() => {
    const out: {
      schools: School[];
      pagination?: Pagination;
    } = {
      schools: [],
      pagination: undefined,
    };
    if (dataGetPaginatedSchools) {
      out.schools = dataGetPaginatedSchools.schools;
      const totalItems = dataGetPaginatedSchools.schools_aggregate.aggregate.totalCount;
      out.pagination = getPagination(currentPage, totalItems, pageSize);
    }
    return out;
  }, [
    dataGetPaginatedSchools,
    currentPage,
    pageSize,
  ]);

  return (
    <PageLayout>
      {/* School Filter */}
      <SchoolFilter
        onSearch={handleOnSearch}
        onClear={handleOnClear}
      />

      {/* School Results */}
      <StyledSchoolResults
        items={schools}
        pageSize={pageSize}
        pagination={pagination}
        onRefetch={handleRefetch}
        setPageSize={handleOnPageSizeChange}
        onChangePage={handleOnPageChange}
        sortBy={sortByGql}
        onSort={handleSort}
      />
    </PageLayout>
  );
});
export default SchoolsPage;
