import { gql } from '@apollo/client';

// Fragments

export const userSoftFragment = gql`
  fragment user on users {
    id
    first_name
    last_name
    email_address
    is_active
    created_at
    updated_at
    deleted_at
  }
`;

export const userComplexFragment = gql`
  fragment user on users {
    id    
    created_at
    updated_at
    deleted_at
  }
`;

export const userDropDownFragment = gql`
  fragment user on users {
    id
    first_name
    last_name
  }
`;

export const useShortFragment = gql`
  fragment shortUser on users {
    id
    first_name
    last_name
  }
`;
