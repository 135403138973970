import styled from 'styled-components';
import { theme } from 'theme/theme';

export const MainContainer = styled.div`
  width: 40px;
  height: 40px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.gallery};
  border-radius: 5px;
`;

export const StyledIcon = styled.i`
  font-size: 30px;
  color: ${theme.colors.grey};
  opacity: 0.5;
`;
