import { gql } from '@apollo/client';

// Fragments
import {
  studentProviderFragment,
  temporalStudentProviderFragment,
} from './studentProviders';

export const studentSoftFragment = gql`
  fragment student on students {
    id
    is_active
    first_name
    last_name
    academic_id
    created_at
    updated_at
    deleted_at
  }
`;

export const studentComplexFragment = gql`
  fragment complexStudent on students {
    id
    is_active
    academic_id
    first_name
    last_name
    gender
    date_of_birth
    email_address
    primary_phone
    address_1
    address_2
    city
    state_prov
    country
    postal_code
    parents_info
    created_at
    updated_at
    deleted_at
  }
`;

export const studentFragmentWithProviders = gql`
  fragment studentWithProvider on students {
    id
    is_active
    academic_id
    first_name
    last_name
    gender
    date_of_birth
    email_address
    primary_phone
    address_1
    address_2
    city
    state_prov
    country
    postal_code
    parents_info
    created_at
    updated_at
    deleted_at
    students_providers (
      where: {
        deleted_at: { _is_null: true }
      }
      order_by:{
        primary:desc_nulls_last,
        updated_at:asc
      }
    ) {
      ...studentProvider
    }
  }
  ${studentProviderFragment}
`;

export const temporalStudentFragmentWithProviders = gql`
  fragment temporalStudentWithProviders on temporal_students {
    id
    is_active
    academic_id
    first_name
    last_name
    gender
    date_of_birth
    email_address
    primary_phone
    address_1
    address_2
    city
    state_prov
    country
    postal_code
    parents_info
    created_at
    updated_at
    deleted_at
    temporal_students_providers {
      ...temporalStudentProvider
    }
  }
  ${temporalStudentProviderFragment}
`;
