import { gql } from '@apollo/client';

import {
  activityFragment,
} from 'data/fragments';

export const getActivities = gql`
  query getActivities {
    activities(
      where: { deleted_at: { _is_null: true }},
      order_by: { activity: asc }
    ) {
      ...activity
    }
  }
  ${activityFragment}
`;

export const createActivity = gql`
  mutation createActivity($object: activities_insert_input!) {
    insert_activities_one(object: $object) {
      ...activity
    }
  }
  ${activityFragment}
`;

export const updateActivity = gql`
  mutation updateActivity($id: uuid!, $input: activities_set_input) {
    update_activities_by_pk (pk_columns: { id: $id }, _set: $input) {
      ...activity
    }
  }
  ${activityFragment}
`;

export const removeActivityById = gql`
  mutation deleteActivity($id: uuid!) {
    delete_activities_by_pk(id: $id) {
      ...activity
    }
  }
  ${activityFragment}
`;
