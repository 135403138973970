/* eslint-disable no-console */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { compact } from 'lodash';

// Hooks
import { useResponsive } from 'hooks/useResponsive';
import useStateContext from 'hooks/useStateContext';

// Data
import { getSchoolActiveTPAs } from 'data/schools_tpa';

import {
  getActivities,
} from 'data/activity';

// Theme
import { theme } from 'theme/theme';

// Data
import {
  ReportNewClaimSteps,
} from 'data/static/ReportNewClaimSteps';

// Components
import LoadingComponent from 'components/Loading';
import Card from 'components/Card';

// Styles
import {
  StyledStepButtons,
  Title,
  TopContainer,
  MiddleContainer,
  ListItem,
  StyledDropdown,
  StyledLabel,
  StyledDatePicker,
  TextInput,
  StyledTextArea,
  StyledRequired,
  UnderlinedText,
} from './style';

export interface StepClaimInformationProps {
  className?: string;
  schoolId: string;
  currentTPAId?: string;
}

interface GetDefaultValuesProps {
  form?: ReportNewClaimFormStepClaimInformation;
  formInsuranceInformation?: ReportNewClaimFormStepInsuranceInformation;
  tpaList?: OptionType[];
  yesNoList?: OptionType[];
  activityList?: OptionType[],
  activityTypes?: OptionType[],
  activityLevels?: OptionType[],
  defaultTPA?: String,
}

const getDefaultValues = function getDefaultValues(props?: GetDefaultValuesProps): CommonJSON {
  let defaultTPA;
  // Default TPA
  if (props?.defaultTPA && !props.form?.tpaId) {
    defaultTPA = props?.tpaList?.find(t => t.value === props.defaultTPA);
  // Auto select when there is only one tpa
  } else if (props && props.tpaList && props.tpaList.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    defaultTPA = props.tpaList[0];
  }

  let hasInsurance;
  if (props?.formInsuranceInformation?.studentsProvider
    && props?.formInsuranceInformation?.studentsProvider?.length > 0) {
    hasInsurance = props?.yesNoList?.find(t => t.value === 'YES');
  } else {
    hasInsurance = props?.yesNoList?.find(t => t.value === 'NO');
  }

  const out: any = {
    tpaId: defaultTPA || undefined,
    benefitsDueAnotherProvider: hasInsurance,
    injuryDate: undefined,
    injuryReportedDate: undefined,
    activity: undefined,
    activityType: undefined,
    activityLevel: undefined,
    isWorkRelated: { value: 'NO', label: 'No' },
    isPreviousInjury: undefined,
    locationOfInjury: '',
    injuryDescription: '',
    injuryType: '',
    firstTreatmentDate: undefined,
  };

  if (props && props.form) {
    if (props.form.tpaId && props.tpaList && props.tpaList.length > 0) {
      out.tpaId = props.tpaList.find(t => t.value === props.form?.tpaId);
    }
    if (!out.tpaId && defaultTPA) {
      out.tpaId = defaultTPA;
    }

    if (props.yesNoList && props.yesNoList.length > 0) {
      if (props.form.benefitsDueAnotherProvider !== undefined) {
        out.benefitsDueAnotherProvider = props.form.benefitsDueAnotherProvider
          ? props.yesNoList.find(t => t.value === 'YES')
          : props.yesNoList.find(t => t.value === 'NO');
      }

      if (props.form.isWorkRelated !== undefined) {
        out.isWorkRelated = props.form.isWorkRelated
          ? props.yesNoList.find(t => t.value === 'YES')
          : props.yesNoList.find(t => t.value === 'NO');
      }

      if (props.form.isPreviousInjury !== undefined) {
        out.isPreviousInjury = props.form.isPreviousInjury
          ? props.yesNoList.find(t => t.value === 'YES')
          : props.yesNoList.find(t => t.value === 'NO');
      }
    }

    if (props.form.activity && props.activityList && props.activityList.length > 0) {
      out.activity = props.activityList.find(t => t.value === props.form?.activity || t.label === props.form?.activity);
    }

    if (props.form.activityType && props.activityTypes && props.activityTypes.length > 0) {
      out.activityType = props.activityTypes.find(t => t.value === props.form?.activityType);
    }

    if (props.form.activityLevel && props.activityLevels && props.activityLevels.length > 0) {
      out.activityLevel = props.activityLevels.find(t => t.value === props.form?.activityLevel);
    }

    if (props.form.injuryDate) {
      out.injuryDate = props.form.injuryDate;
    }

    if (props.form.injuryReportedDate) {
      out.injuryReportedDate = props.form.injuryReportedDate;
    }

    if (props.form.locationOfInjury) {
      out.locationOfInjury = props.form.locationOfInjury;
    }

    if (props.form.injuryDescription) {
      out.injuryDescription = props.form.injuryDescription;
    }

    if (props.form.injuryType) {
      out.injuryType = props.form.injuryType;
    }

    if (props.form.firstTreatmentDate) {
      out.firstTreatmentDate = props.form.firstTreatmentDate;
    }
  }

  return out;
};

const maxInputSizes = {
  locationOfInjury: 255,
  injuryDescription: 2048,
  injuryType: 255,
};

const stepClaimInformationSchema = Yup.object({
  // TPA
  tpaId: Yup.object({
    label: Yup.string().required('TPA_LABEL_REQUIRED'),
    value: Yup.string().required('TPA_VALUE_REQUIRED'),
  }).required('TPA_REQUIRED'),
  // Are benefits due for this claim under other insurance coverages?
  benefitsDueAnotherProvider: Yup.object({
    label: Yup.string().required('ANOTHER_PROVIDER_LABEL_REQUIRED'),
    value: Yup.string().required('ANOTHER_PROVIDER_VALUE_REQUIRED'),
  }).notRequired(),
  // Date Accident or Illness Began *
  injuryDate: Yup.date().required('INJURY_DATE_REQUIRED'),
  // Injury Reported Date
  injuryReportedDate: Yup.date().required('INJURY_REPORTED_DATE'),
  // Activity or Sport *
  activity: Yup.object({
    label: Yup.string().required('ACTIVITY_LABEL_REQUIRED'),
    value: Yup.string().required('ACTIVITY_VALUE_REQUIRED'),
  }).required('ACTIVITY_REQUIRED'),
  // Activity Type *
  activityType: Yup.object({
    label: Yup.string().required('ACTIVITY_TYPE_LABEL_REQUIRED'),
    value: Yup.string().required('ACTIVITY_TYPE_VALUE_REQUIRED'),
  }).required('ACTIVITY_TYPE_REQUIRED'),
  // Activity Level *
  activityLevel: Yup.object({
    label: Yup.string().required('ACTIVITY_LEVEL_LABEL_REQUIRED'),
    value: Yup.string().required('ACTIVITY_LEVEL_VALUE_REQUIRED'),
  }).required('ACTIVITY_LEVEL_REQUIRED'),
  // Was the accident due to employment?
  isWorkRelated: Yup.object({
    label: Yup.string().required('IS_WORK_RELATED_LABEL_REQUIRED'),
    value: Yup.string().required('IS_WORK_RELATED_VALUE_REQUIRED'),
  }).required('IS_WORK_RELATED_REQUIRED'),
  // Have you ever been treated for this condition before? *
  isPreviousInjury: Yup.object({
    label: Yup.string().required('IS_PREVIOUS_INJURY_LABEL_REQUIRED'),
    value: Yup.string().required('IS_PREVIOUS_INJURY_VALUE_REQUIRED'),
  }).required('IS_PREVIOUS_INJURY_REQUIRED'),
  // Location of Accident *
  locationOfInjury: Yup.string().required('LOCATION_OF_INJURY_REQUIRED')
    .max(maxInputSizes.locationOfInjury, 'LOCATION_OF_INJURY_INVALID'),
  // Description of Accident *
  injuryDescription: Yup.string().required('INJURY_DESCRIPTION_REQUIRED')
    .max(maxInputSizes.injuryDescription, 'INJURY_DESCRIPTION_REQUIRED'),
  // Description of Injury *
  injuryType: Yup.string().required('INJURY_TYPE_REQUIRED')
    .max(maxInputSizes.injuryType, 'INJURY_TYPE_INVALID'),
  // First Treatment Date *
  firstTreatmentDate: Yup.date().required('FIRST_TREATMENT_DATE_REQUIRED'),
}).defined();

const yesNoToBoolean = function yesNoToBoolean({ value }: any) {
  return value === 'YES';
};

const StepClaimInformation = React.memo(({
  className,
  currentTPAId,
}: StepClaimInformationProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const {
    step,
    setStep,
    data,
    setData,
  } = useStateContext();
  const urlParams = useParams<URLParams>();
  const [defaultTPA, setDefaultTPA] = React.useState<string>('');
  // const [tpaOptions, setTpaOptions] = React.useState<OptionType[]>([]);

  // Refs
  const reseted = React.useRef<boolean>(false);

  const {
    register,
    control,
    errors,
    reset,
    watch,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(stepClaimInformationSchema),
    defaultValues: getDefaultValues(),
  });

  // Queries
  const [callGetSchoolTPAs, {
    loading: loadingGetSchoolTPAs,
    data: dataGetSchoolTPAs,
    called: calledGetSchoolTPAs,
  }] = useLazyQuery<GetSchoolTPAsResponse, GetSchoolTPAsVariables>(getSchoolActiveTPAs, {
    variables: {
      schoolId: urlParams.id || '',
      currentTPAId: { id: { _in: compact([currentTPAId]) } },
    },
    fetchPolicy: 'network-only',
  });

  const {
    loading: loadingGetActivities,
    data: dataGetActivites,
    called: calledGetActivities,
  } = useQuery<GetActivitiesResponse, GetActivitiesVariables>(getActivities);

  // Handlers
  const handlePrev = React.useCallback(() => {
    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_FILL_INSURANCE_INFORMATION,
    }));
  }, [setStep]);

  const handleNext = React.useCallback((_data: CommonJSON) => {
    if (_data) {
      // Update the state
      setData((prevData?: ReportNewClaimWizard) => ({
        ...prevData,
        formStepClaimInformation: {
          ...prevData?.formStepClaimInformation,
          tpaId: _data.tpaId ? _data.tpaId.value : undefined,
          benefitsDueAnotherProvider: yesNoToBoolean(_data.benefitsDueAnotherProvider),
          injuryDate: _data.injuryDate,
          injuryReportedDate: _data.injuryReportedDate,
          activity: _data.activity ? _data.activity.value : undefined,
          activityType: _data.activityType ? _data.activityType.value : undefined,
          activityLevel: _data.activityLevel ? _data.activityLevel.value : undefined,
          isWorkRelated: yesNoToBoolean(_data.isWorkRelated),
          isPreviousInjury: yesNoToBoolean(_data.isPreviousInjury),
          locationOfInjury: _data.locationOfInjury,
          injuryDescription: _data.injuryDescription,
          injuryType: _data.injuryType,
          firstTreatmentDate: _data.firstTreatmentDate,
        },
      }));

      // Change the step
      setStep((s: Step) => ({
        ...s,
        step: ReportNewClaimSteps.STEP_FILL_SIGNATURE,
      }));
    }
  }, [setStep, setData]);

  const handleCancel = React.useCallback(() => {
    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_CLAIM_PREVIEW,
      editMode: false,
    }));
  }, [setStep]);

  const handleSave = React.useCallback((_data: CommonJSON) => {
    if (_data) {
      // Update the state
      setData((prevData?: ReportNewClaimWizard) => ({
        ...prevData,
        formStepClaimInformation: {
          ...prevData?.formStepClaimInformation,
          tpaId: _data.tpaId ? _data.tpaId.value : undefined,
          benefitsDueAnotherProvider: yesNoToBoolean(_data.benefitsDueAnotherProvider),
          injuryDate: _data.injuryDate,
          injuryReportedDate: _data.injuryReportedDate,
          activity: _data.activity ? _data.activity.value : undefined,
          activityType: _data.activityType ? _data.activityType.value : undefined,
          activityLevel: _data.activityLevel ? _data.activityLevel.value : undefined,
          isWorkRelated: yesNoToBoolean(_data.isWorkRelated),
          isPreviousInjury: yesNoToBoolean(_data.isPreviousInjury),
          locationOfInjury: _data.locationOfInjury,
          injuryDescription: _data.injuryDescription,
          injuryType: _data.injuryType,
          firstTreatmentDate: _data.firstTreatmentDate,
        },
      }));
      // Change the step
      setStep((s: Step) => ({
        ...s,
        step: ReportNewClaimSteps.STEP_CLAIM_PREVIEW,
        editMode: false,
      }));
    }
  }, [setStep, setData]);

  // Memos
  React.useEffect(() => {
    callGetSchoolTPAs();
  }, []);

  const watchTpa = watch('tpaId');
  const tpaOptions = React.useMemo(() => {
    if (dataGetSchoolTPAs && dataGetSchoolTPAs.schools_third_party_administrators) {
      dataGetSchoolTPAs.schools_third_party_administrators.forEach(schoolTpa => {
        if (schoolTpa.is_default) {
          setDefaultTPA(schoolTpa.id);
        }
      });
      return dataGetSchoolTPAs.schools_third_party_administrators.map(schooTpa => ({
        value: schooTpa.id || '',
        label: schooTpa?.third_party_administrator ? schooTpa?.third_party_administrator.name : '',
      }));
    }
    return [];
  }, [dataGetSchoolTPAs]);

  const activities = React.useMemo(() => {
    // if I have selected a TPA I'll display the TPA for that
    if (dataGetActivites
      && dataGetActivites.activities
      && watchTpa
      && dataGetSchoolTPAs?.schools_third_party_administrators) {
      // Get tpa activites
      const tpaActivitesIds = dataGetSchoolTPAs?.schools_third_party_administrators
        .find((schoolTpa: SchoolsTPA) => schoolTpa.id === watchTpa.value)?.activities;
      if (tpaActivitesIds && tpaActivitesIds.length > 0) {
        const a: Activity[] = [];
        tpaActivitesIds.forEach((activityId: string) => {
          const found = dataGetActivites.activities?.find(item => item.id === activityId);
          if (found) {
            a.push(found);
          }
        });
        return a;
      }
      return dataGetActivites.activities;
    }
    return [];
  }, [dataGetActivites, dataGetSchoolTPAs, watchTpa]);

  const activitiesOptions = React.useMemo(() => activities.map(activity => ({
    value: activity.id,
    label: activity.activity,
  })).sort((a, b) => a.label.localeCompare(b.label)), [activities]);

  const yesNoOptions = React.useMemo(
    () => [
      {
        value: 'YES',
        label: t('YES'),
      },
      {
        value: 'NO',
        label: t('NO'),
      },
    ],
    [t],
  );

  const activityTypeOptions = React.useMemo(() => [
    {
      value: 'PRACTICE',
      label: t('PRACTICE'),
    },
    {
      value: 'GAME',
      label: t('GAME'),
    },
    {
      value: 'TRAVEL',
      label: t('TRAVEL'),
    },
    {
      value: 'OTHER',
      label: t('OTHER'),
    },
  ], [t]);

  const activityLevelOptions = React.useMemo(() => [
    {
      value: 'INTERCOLLEGIATE',
      label: t('INTERCOLLEGIATE'),
    },
    {
      value: 'CLUB_SPORT',
      label: t('CLUB_SPORT'),
    },
    {
      value: 'INTRAMURAL_SPORT',
      label: t('INTRAMURAL_SPORT'),
    },
    {
      value: '24_HOUR_ACCIDENT',
      label: t('24_HOUR_ACCIDENT'),
    },
    {
      value: 'OTHER',
      label: t('OTHER'),
    },
  ], [t]);

  const now = React.useMemo(
    () => new Date(),
    [],
  );

  // Effects
  React.useEffect(() => {
    if (calledGetSchoolTPAs && calledGetActivities) {
      const withLists = yesNoOptions.length > 0 && activityTypeOptions.length > 0 && activityLevelOptions.length > 0;
      if (withLists && !reseted.current && data) {
        const form: ReportNewClaimFormStepClaimInformation = data.formStepClaimInformation;
        const formInsuranceInformation: ReportNewClaimFormStepInsuranceInformation = data.formStepInsuranceInformation;
        const activitiesFormated = dataGetActivites?.activities
          ? dataGetActivites?.activities.map(activity => ({
            value: activity.id,
            label: activity.activity,
          }))
          : [];
        const params = {
          form,
          formInsuranceInformation,
          tpaList: tpaOptions,
          yesNoList: yesNoOptions,
          activityList: activitiesFormated,
          activityTypes: activityTypeOptions,
          activityLevels: activityLevelOptions,
          defaultTPA,
        };
        reset(getDefaultValues(params));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calledGetSchoolTPAs,
    calledGetActivities,
    tpaOptions,
    yesNoOptions,
    activityTypeOptions,
    activityLevelOptions,
    defaultTPA,
    dataGetActivites,
  ]);

  return (
    <Card
      padding="25px 30px 20px;"
      className={classnames(className)}
    >
      {/* Top Container (Title and CTA Button) */}
      <TopContainer>
        {/* Title */}
        <Title
          size={18}
          fontFamily={theme.fontFamilies.roboto.bold}
          color={theme.colors.dark}
          weight={theme.fontWeights.bold}
        >
          {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.TITLE')}
        </Title>
      </TopContainer>

      {/* Middle Container */}
      <MiddleContainer $isMobile={isMobile}>
        {/* Item: Select TPA */}
        <ListItem
          $isMobile={isMobile}
          className={`${isMobile ? '' : 'width-half'} `}
        >
          {/* Title */}
          <StyledLabel
            size={14}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.SELECT_TPA_LABEL')}
            <StyledRequired>
              *
            </StyledRequired>
          </StyledLabel>
          {/* Field: Select */}
          <Controller
            control={control}
            name="tpaId"
            render={({ onChange, value, name }) => (
              <>
                {
                  loadingGetSchoolTPAs ? (
                    <LoadingComponent />
                  ) : (
                    <StyledDropdown
                      $isMobile={isMobile}
                      name={name}
                      selected={value}
                      options={tpaOptions}
                      onSelect={(key, v) => onChange(v)}
                      error={errors[name] !== undefined}
                    />
                  )
                }
              </>
            )}
          />
        </ListItem>

        {/* Item: Benefits due another provider */}
        <ListItem
          $isMobile={isMobile}
          className={`${isMobile ? '' : 'width-half'} `}
        >
          {/* Title */}
          <StyledLabel
            size={14}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.BENEFITS_DUE_ANOTHER_PROVIDER_LABEL')}
            <StyledRequired>
              *
            </StyledRequired>
          </StyledLabel>

          {/* Field: Select */}
          <Controller
            control={control}
            name="benefitsDueAnotherProvider"
            render={({ onChange, value, name }) => (
              <StyledDropdown
                $isMobile={isMobile}
                name={name}
                selected={value}
                options={yesNoOptions}
                onSelect={(key, v) => onChange(v)}
                error={errors[name] !== undefined}
              />
            )}
          />
        </ListItem>

        {/* Item: Injury date */}
        <ListItem
          $isMobile={isMobile}
          className={`${isMobile ? 'full-datepicker' : 'width-half'} `}
        >
          {/* Title */}
          <StyledLabel
            size={14}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.INJURY_DATE_LABEL')}
            <StyledRequired>
              *
            </StyledRequired>
          </StyledLabel>

          {/* DatePicker */}
          <Controller
            control={control}
            name="injuryDate"
            render={({
              onChange, value, name,
            }) => (
              <StyledDatePicker
                $isMobile={isMobile}
                title=""
                name={name}
                startDate={value}
                setStartDate={(key: string, date: Date | null | [Date, Date]) => onChange(date)}
                maxDate={now}
                error={errors[name] !== undefined}
                usePortal
              />
            )}
          />
        </ListItem>

        {/* Item: Injury date reported */}
        <ListItem
          $isMobile={isMobile}
          className={`${isMobile ? 'full-datepicker' : 'width-half'} `}
        >
          {/* Title */}
          <StyledLabel
            size={14}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.INJURY_DATE_REPORTED_LABEL')}
            <StyledRequired>
              *
            </StyledRequired>
          </StyledLabel>

          {/* DatePicker */}
          <Controller
            control={control}
            name="injuryReportedDate"
            render={({
              onChange, value, name,
            }) => (
              <StyledDatePicker
                $isMobile={isMobile}
                title=""
                name={name}
                startDate={value}
                setStartDate={(key: string, date: Date | null | [Date, Date]) => onChange(date)}
                minDate={watch('injuryDate') || undefined}
                maxDate={now}
                error={errors[name] !== undefined}
                usePortal
              />
            )}
          />
        </ListItem>

        {/* Item: Activity or Sport  */}
        <ListItem
          $isMobile={isMobile}
          className={`${isMobile ? '' : 'width-half'} `}
        >
          {/* Title */}
          <StyledLabel
            size={14}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.ACTIVITY_OR_SPORT_LABEL')}
            <StyledRequired>
              *
            </StyledRequired>
          </StyledLabel>

          {/* Field: Select */}
          <Controller
            control={control}
            name="activity"
            render={({ onChange, value, name }) => (
              <>
                {
                  loadingGetActivities ? (
                    <LoadingComponent />
                  ) : (
                    <StyledDropdown
                      $isMobile={isMobile}
                      name={name}
                      selected={value}
                      options={activitiesOptions}
                      onSelect={(key, v) => onChange(v)}
                      error={errors[name] !== undefined}
                    />
                  )
                }
              </>
            )}
          />
        </ListItem>

        {/* Item: Activity or Sport  */}
        <ListItem
          $isMobile={isMobile}
          className="width-for-divider-note"
        >
          {/* Title */}
          <StyledLabel
            size={11}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.grey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.ACTIVITY_TYPE_INFO_LABEL')}
          </StyledLabel>
        </ListItem>

        {/* Item: Activity Type  */}
        <ListItem
          $isMobile={isMobile}
          className={`${isMobile ? '' : 'width-half'} `}
        >
          {/* Title */}
          <StyledLabel
            size={14}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.ACTIVITY_TYPE_LABEL')}
            <StyledRequired>
              *
            </StyledRequired>
          </StyledLabel>

          {/* Field: Select */}
          <Controller
            control={control}
            name="activityType"
            render={({ onChange, value, name }) => (
              <StyledDropdown
                $isMobile={isMobile}
                name={name}
                selected={value}
                options={activityTypeOptions}
                onSelect={(key, v) => onChange(v)}
                error={errors[name] !== undefined}
              />
            )}
          />
        </ListItem>

        {/* Item: Activity Level  */}
        <ListItem
          $isMobile={isMobile}
          className={`${isMobile ? '' : 'width-half'} `}
        >
          {/* Title */}
          <StyledLabel
            size={14}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.ACTIVITY_LEVEL_LABEL')}
            <StyledRequired>
              *
            </StyledRequired>
          </StyledLabel>

          {/* Field: Select */}
          <Controller
            control={control}
            name="activityLevel"
            render={({ onChange, value, name }) => (
              <StyledDropdown
                $isMobile={isMobile}
                name={name}
                selected={value}
                options={activityLevelOptions}
                onSelect={(key, v) => onChange(v)}
                error={errors[name] !== undefined}
              />
            )}
          />
        </ListItem>

        {/* Item: Is work related? */}
        <ListItem
          $isMobile={isMobile}
          className={`${isMobile ? '' : 'width-half'} `}
        >
          {/* Title */}
          <StyledLabel
            size={14}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.IS_WORK_RELATED')}
            <StyledRequired>
              *
            </StyledRequired>
          </StyledLabel>

          {/* Field: Select */}
          <Controller
            control={control}
            name="isWorkRelated"
            render={({ onChange, value, name }) => (
              <StyledDropdown
                $isMobile={isMobile}
                name={name}
                selected={value}
                options={yesNoOptions}
                onSelect={(key, v) => onChange(v)}
                error={errors[name] !== undefined}
              />
            )}
          />
        </ListItem>

        {/* Item: Is previous injury? */}
        <ListItem
          $isMobile={isMobile}
          className={`${isMobile ? '' : 'width-half'} `}
        >
          {/* Title */}
          <StyledLabel
            size={14}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.darkGrey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.IS_PREVIOUS_INJURY')}
            <StyledRequired>
              *
            </StyledRequired>
          </StyledLabel>

          {/* Field: Select */}
          <Controller
            control={control}
            name="isPreviousInjury"
            render={({ onChange, value, name }) => (
              <StyledDropdown
                $isMobile={isMobile}
                name={name}
                selected={value}
                options={yesNoOptions}
                onSelect={(key, v) => onChange(v)}
                error={errors[name] !== undefined}
              />
            )}
          />
        </ListItem>

        {/* Item: Location of Accident */}
        <ListItem
          $isMobile={isMobile}
          className="vertical-flow double-margin-top"
        >
          {/* Text Input */}
          <TextInput
            ref={register()}
            name="locationOfInjury"
            required
            title={t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.LOCATION_OF_INJURY_LABEL')}
            maxLength={maxInputSizes.locationOfInjury}
            error={errors.locationOfInjury !== undefined}
          />

          {/* Footer */}
          <StyledLabel
            className="margin-top-5"
            size={12}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.grey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.LOCATION_OF_INJURY_FOOTER_LABEL')}
          </StyledLabel>
        </ListItem>

        {/* Item: Description of Accident */}
        <ListItem
          $isMobile={isMobile}
          className="vertical-flow"
        >
          <Controller
            control={control}
            name="injuryDescription"
            render={({ onChange, value, name }) => (
              <StyledTextArea
                name={name}
                title={t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.INJURY_DESCRIPTION_LABEL')}
                placeholder=""
                required
                maxLength={maxInputSizes.injuryDescription}
                rows={5}
                error={errors[name] !== undefined}
                value={value}
                onChange={onChange}
              />
            )}
          />

          {/* Footer */}
          <StyledLabel
            className="margin-top-5"
            size={12}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.grey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.INJURY_DESCRIPTION_FOOTER_LABEL')}
          </StyledLabel>
        </ListItem>

        {/* Item: Location of Accident */}
        <ListItem
          $isMobile={isMobile}
          className="vertical-flow"
        >
          {/* Text Input */}
          <TextInput
            ref={register()}
            name="injuryType"
            required
            title={t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.INJURY_TYPE_LABEL')}
            maxLength={maxInputSizes.injuryType}
            error={errors.injuryType !== undefined}
            className="bold"
          />

          {/* Footer */}
          <StyledLabel
            className="margin-top-5"
            size={12}
            fontFamily={theme.fontFamilies.roboto.bold}
            color={theme.colors.grey}
            weight={theme.fontWeights.bold}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.INJURY_TYPE_FOOTER_LABEL_1')}
            <UnderlinedText>{t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.INJURY_TYPE_FOOTER_LABEL_2')}</UnderlinedText>
          </StyledLabel>
        </ListItem>

        {/* Item: First treatment date */}
        <ListItem
          $isMobile={isMobile}
          className="vertical-flow full-datepicker double-margin-top"
        >
          {/* DatePicker */}
          <Controller
            control={control}
            name="firstTreatmentDate"
            render={({
              onChange, value, name,
            }) => (
              <StyledDatePicker
                $isMobile={isMobile}
                className="full"
                title={t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.FIRST_TREATMENT_DATE_LABEL')}
                required
                name={name}
                startDate={value}
                setStartDate={(key: string, date: Date | null | [Date, Date]) => onChange(date)}
                // minDate={watch('injuryDate') || undefined}
                maxDate={now}
                error={errors[name] !== undefined}
                usePortal
              />
            )}
          />

          {/* Footer */}
          <StyledLabel
            className="margin-top-5"
            size={12}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.grey}
            weight={theme.fontWeights.normal}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_CLAIM_INFORMATION.FORM.FIRST_TREATMENT_DATE_FOOTER_LABEL')}
          </StyledLabel>
        </ListItem>

      </MiddleContainer>

      {/* Step Buttons */}
      <StyledStepButtons
        $isMobile={isMobile}
        showPrev
        showNext
        onPrev={step?.editMode ? handleCancel : handlePrev}
        onNext={step?.editMode ? handleSubmit(handleSave) : handleSubmit(handleNext)}
        editMode={step?.editMode}
      />
    </Card>
  );
});
export default StepClaimInformation;
