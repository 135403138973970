/* eslint-disable no-console */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';

// Utils
import { Toaster } from 'utils/toaster';

// Data
import {
  getSchoolTPAs,
  removeSchoolTPAFromGetSchoolTPAs,
  updateSchoolTPA,
  deleteSoftSchoolTPA,
} from 'data/schools_tpa';
import {
  getActivities,
} from 'data/activity';
import {
  deleteManyDashboardElementOrder,
} from 'data/school_dashboard_elements_order';
// Hooks
import { useModal } from 'hooks/useModal';

// Components
import LoadingComponent from 'components/Loading';
import SchoolsTPAModal from 'modals/SchoolsTPAModal';
import ConfirmationModal from 'modals/ConfirmationModal';

// Styles
import { theme } from 'theme/theme';
import {
  MainContainer,
  ListOfItems,
  RowItem,
  TopContainer,
  ActionsContainer,
  StyledLabel,
  AddButton,
  StyledAddCircleIcon,
  BottomContainer,
  UpdateButton,
} from './style';

export interface InsuranceInfoTabProps {
  className?: string;
  school: School;
}

interface SubmissionSchoolTPA {
  schoolTPAId: string;
  policyNumber?: string;
  groupNumber?: string;
  effectiveDate?: Date;
  sportOptions: {
    label: string;
    value: string;
  }[];
  isActive: boolean;
  isDefault: boolean;
}

const singleInsuranceInfoSchema = Yup.object({
  policyNumber: Yup.string().notRequired().max(20, 'INVALID_POLICY_NUMBER'),
  groupNumber: Yup.string().notRequired().max(50, 'INVALID_POLICY_NUMBER'),
  effectiveDate: Yup.date().notRequired(),
  sportOptions: Yup.array().of(Yup.object({
    label: Yup.string().notRequired(),
    value: Yup.string().notRequired(),
  })).notRequired(),
  isActive: Yup.boolean().notRequired(),
  isDefault: Yup.boolean().notRequired(),
}).defined();

const insuranceInfoSchema = Yup.object({
  tpas: Yup.array().of(singleInsuranceInfoSchema.required()).required().min(1),
}).defined();

const getDefaultValues = function getDefaultValues(schoolTPAs?: SchoolsTPA[], activities?: Activity[]): CommonJSON {
  const out: CommonJSON = {
    tpas: [],
  };

  if (schoolTPAs && schoolTPAs.length > 0) {
    out.tpas = schoolTPAs.map(schoolTPA => ({
      id: schoolTPA.id,
      policyNumber: schoolTPA.policy_number || '',
      groupNumber: schoolTPA.tpa_identifier || '',
      effectiveDate: schoolTPA.effective_date ? moment(schoolTPA.effective_date).toDate() : undefined,
      sportOptions: (schoolTPA.activities && activities)
        ? schoolTPA.activities.map((activity: string) => ({
          value: activity,
          label: activities.find(a => a.id === activity)?.activity ?? '',
        }))
        : [],
      isActive: schoolTPA.is_active,
      isDefault: schoolTPA.is_default,

    }));
  }

  return out;
};

const InsuranceInfoTab = React.memo(({
  className,
  school,
}: InsuranceInfoTabProps) => {
  // Hooks
  const { t } = useTranslation();
  const {
    isOpen: isOpenTPAModal,
    toggleModal: toggleTPAModal,
  } = useModal();
  const {
    isOpen: isOpenConfirmationModal,
    openModal: openConfirmationModal,
    toggleModal: toggleConfirmationModal,
  } = useModal();
  const {
    register,
    control,
    errors,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(insuranceInfoSchema),
    defaultValues: {
      tpas: [],
    },
  });

  // States
  const [canFetch, setCanFetch] = React.useState<boolean>(false);
  const [schoolTPAToDelete, setSchoolTPAToDelete] = React.useState<SchoolsTPA>();

  // Queries
  const {
    loading: loadingGetActivities,
    data: dataGetActivites,
  } = useQuery<GetActivitiesResponse, GetActivitiesVariables>(getActivities);

  // Memos
  const activities = React.useMemo(() => {
    if (dataGetActivites && dataGetActivites.activities) {
      return dataGetActivites.activities;
    }
    return [];
  }, [dataGetActivites]);

  React.useEffect(() => {
    if (dataGetActivites && dataGetActivites.activities !== undefined) {
      setCanFetch(true);
    }
  }, [dataGetActivites]);

  // Mutations
  const [callUpdateSchoolTPA, {
    loading: loadingUpdateSchoolTPA,
  }] = useMutation<UpdateSchoolTPAResponse, UpdateSchoolTPAVariables>(updateSchoolTPA, {
    onError: () => {
      Toaster('error', t('TOASTER.ERROR.SCHOOL_TPA_UPDATE_FAILED'));
    },
  });

  const {
    loading: loadingGetSchoolTPAs,
    data: dataGetSchoolTPAs,
    refetch: refetchGetSchoolTPAs,
  } = useQuery<GetSchoolTPAsResponse, GetSchoolTPAsVariables>(getSchoolTPAs, {
    variables: {
      schoolId: school.id,
    },
    skip: !canFetch,
  });

  const [callDeleteSchoolTPA, {
    loading: loadingDeleteSchoolTPA,
  }] = useMutation<DeleteSoftSchoolTPAResponse, DeleteSoftSchoolTPAVariables>(deleteSoftSchoolTPA, {
    update: (cache, result: { data?: DeleteSoftSchoolTPAResponse | null }) => {
      if (result.data && result.data.update_schools_third_party_administrators_by_pk) {
        removeSchoolTPAFromGetSchoolTPAs(
          cache,
          result.data.update_schools_third_party_administrators_by_pk.id,
          school.id,
        );
      }
    },
  });

  const [
    callDeleteManyDashboardElementOrder,
  ] = useMutation<
  DeleteManySchoolDashboardElementOrderResponse,
  DeleteManySchoolDashboardElementOrderVariables
  >(deleteManyDashboardElementOrder, {
    onError: () => {
      Toaster('error', t('TOASTER.ERROR.SCHOOL_TPA_UPDATE_FAILED'));
    },
  });

  // Memos
  const schoolTPAs = React.useMemo(() => {
    if (dataGetSchoolTPAs && dataGetSchoolTPAs.schools_third_party_administrators) {
      return dataGetSchoolTPAs.schools_third_party_administrators;
    }
    return [];
  }, [dataGetSchoolTPAs]);

  React.useEffect(() => {
    if (schoolTPAs && schoolTPAs.length > 0) {
      reset(getDefaultValues(schoolTPAs, activities));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolTPAs, activities]);

  // Handlers
  const handleAddTPA = React.useCallback(() => {
    toggleTPAModal();
  }, [toggleTPAModal]);

  const handleOnCreateSchoolTpa = React.useCallback(() => {
    refetchGetSchoolTPAs();
    toggleTPAModal();
  }, [toggleTPAModal, refetchGetSchoolTPAs]);

  const handleUpdate = React.useCallback(async (data: CommonJSON) => {
    if (schoolTPAs && schoolTPAs.length > 0 && data && data.tpas && data.tpas.length > 0) {
      let countDefaults = 0;
      let defaultInactive = false;
      data.tpas.forEach((schoolTpa: CommonJSON) => {
        if (schoolTpa.isDefault) {
          countDefaults += 1;
        }
        if (schoolTpa.isDefault && !schoolTpa.isActive) {
          defaultInactive = true;
        }
      });
      if (countDefaults === 1 && !defaultInactive) {
        const combinedArray = schoolTPAs.map((sTPA: any, idx: number) => ({
          ...data.tpas[idx],
          schoolTPAId: sTPA.id,
        }));
        combinedArray.reduce(
          (prevPromise: Promise<any>, tpa: SubmissionSchoolTPA) => prevPromise.then(async () => {
            try {
              const variables: UpdateSchoolTPAVariables = {
                schoolTPAId: tpa.schoolTPAId,
                input: {
                  policy_number: tpa.policyNumber || '',
                  tpa_identifier: tpa.groupNumber || '',
                  effective_date: tpa.effectiveDate,
                  activities: (tpa.sportOptions && tpa.sportOptions.length > 0)
                    ? tpa.sportOptions.map((i: { value: string; }) => i.value)
                    : [],
                  updated_at: new Date(),
                  is_active: tpa.isActive,
                  is_default: tpa.isDefault,
                },
              };
              await callUpdateSchoolTPA({ variables });
            } catch (error) {
              console.error('Error [handleUpdate]', error);
            }
            return Promise.resolve();
          }), Promise.resolve(),
        );
      } else if (countDefaults === 0) {
        // At least one default
        Toaster('error', t('TOASTER.ERROR.AT_LEAST_ONE_DEFAULT'));
      } else if (countDefaults > 1) {
        // No more than one default
        Toaster('error', t('TOASTER.ERROR.NO_MORE_THAN_ONE_DEFAULT'));
      } else {
        // The default TPA has to be active
        Toaster('error', t('TOASTER.ERROR.THE_DEFAULT_HAS_TO_BE_ACTIVE'));
      }
    }
  }, [
    schoolTPAs,
    callUpdateSchoolTPA,
  ]);

  const handleDelete = React.useCallback((schoolTPAId: string) => {
    const targetSchoolTPA = schoolTPAs.find(sTPA => sTPA.id === schoolTPAId);
    if (targetSchoolTPA) {
      setSchoolTPAToDelete(targetSchoolTPA);
      openConfirmationModal();
    }
  }, [schoolTPAs, openConfirmationModal]);

  const handleCloseDeleteConfirmationModal = React.useCallback(() => {
    setSchoolTPAToDelete(undefined);
    toggleConfirmationModal();
  }, [toggleConfirmationModal]);

  const handleOnDeleteSchoolTPA = React.useCallback(() => {
    if (schoolTPAToDelete) {
      const variables: DeleteSoftSchoolTPAVariables = {
        schoolTPAId: schoolTPAToDelete.id,
      };
      callDeleteSchoolTPA({ variables });

      const elementsToDelete:string[] = [];
      schoolTPAToDelete.third_party_administrator?.third_party_administrators_dashboard_elements.forEach(item => {
        elementsToDelete.push(item.id);
      });
      const varsToDelete: DeleteManySchoolDashboardElementOrderVariables = {
        tpadeIds: elementsToDelete,
        schoolId: schoolTPAToDelete.school_id,
      };
      callDeleteManyDashboardElementOrder({ variables: varsToDelete });

      handleCloseDeleteConfirmationModal();
    }
  }, [
    schoolTPAToDelete,
    callDeleteSchoolTPA,
    handleCloseDeleteConfirmationModal,
  ]);

  // Memos

  const activitiesOptions = React.useMemo(() => {
    const ActivitiesAll = activities.map(activity => ({
      value: activity.id,
      label: activity.activity,
    })).sort((a, b) => a.label.localeCompare(b.label));
    ActivitiesAll.unshift({
      value: '*',
      label: t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.FORM.ALL_SPORTS'),
    });
    return ActivitiesAll;
  }, [activities]);

  const generalLoading = React.useMemo(
    () => loadingGetSchoolTPAs || loadingGetActivities,
    [loadingGetSchoolTPAs, loadingGetActivities],
  );

  if (generalLoading) {
    return (
      <LoadingComponent />
    );
  }

  return (
    <>
      <MainContainer
        className={classnames(className)}
      >
        <TopContainer>
          {/* Actions Container */}
          <ActionsContainer>
            {/* Text */}
            {
              schoolTPAs.length === 0 ? (
                <StyledLabel
                  size={14}
                  color={theme.colors.dark}
                >
                  {t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.COACH_TEXT')}
                </StyledLabel>
              ) : (
                <div />
              )
            }

            {/* Add Button */}
            <AddButton
              type="SECONDARY"
              onClick={handleAddTPA}
            >
              <StyledAddCircleIcon
                className="las la-plus-circle"
              />
            </AddButton>
          </ActionsContainer>

          {/* Items */}
          {
            schoolTPAs.length > 0 && (
              <ListOfItems>
                {
                  schoolTPAs.map((schoolTPA: SchoolsTPA, idx: number) => (
                    <RowItem
                      key={schoolTPA.id}
                      schoolTPA={schoolTPA}
                      activitiesOptions={activitiesOptions}
                      // Form
                      baseName="tpas"
                      idx={idx}
                      register={register}
                      control={control}
                      errors={errors.tpas ? errors.tpas[idx] : undefined}
                      // Events
                      onDelete={handleDelete}
                    />
                  ))
                }
              </ListOfItems>
            )
          }
        </TopContainer>

        {/* Bottom Container */}
        <BottomContainer>
          {/* Update */}
          <UpdateButton
            type="PRIMARY"
            disabled={schoolTPAs.length === 0 || generalLoading || loadingUpdateSchoolTPA || loadingDeleteSchoolTPA}
            onClick={handleSubmit(handleUpdate)}
          >
            {t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.CTA_UPDATE_TPA_LABEL')}
          </UpdateButton>
        </BottomContainer>
      </MainContainer>

      {/* Modals */}
      <SchoolsTPAModal
        schoolId={school.id}
        isOpen={isOpenTPAModal}
        toggle={toggleTPAModal}
        onCreate={handleOnCreateSchoolTpa}
      />
      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        toggle={handleCloseDeleteConfirmationModal}
        title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.DELETE_CONFIRMATION_MODAL.TITLE')}
        text={t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.DELETE_CONFIRMATION_MODAL.MESSAGE')}
        buttonText={t('SCHOOLS_MANAGEMENT_PAGE.TAB_INSURANCE_INFO.DELETE_CONFIRMATION_MODAL.CONFIRM_LABEL')}
        onConfirm={handleOnDeleteSchoolTPA}
      />
    </>
  );
});
export default InsuranceInfoTab;
