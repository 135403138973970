/* eslint-disable no-console */
import React from 'react';
import classnames from 'classnames';

// Styles
import { theme } from 'theme/theme';
import {
  MainContainer,
  StyledSwitch,
  StyledLabel,
} from './style';

export interface ToggleProps {
  id: string;
  className?: string;
  defaultChecked: boolean;
  name?: string;
  truthyLabel?: string;
  falsyLabel?: string;
  readOnly?: boolean;
  onChange?: (key: string, value: boolean) => void,
}

const Toggle = ({
  id,
  className,
  defaultChecked,
  name,
  truthyLabel,
  falsyLabel,
  readOnly,
  onChange,
}: ToggleProps, _ref: React.Ref<any>) => {
  // States
  const [checkedValue, setCheckedValue] = React.useState<boolean>(defaultChecked);

  // Handlers
  const handleOnChange = React.useCallback((event: React.ChangeEvent<any>) => {
    const { target } = event;
    const { checked } = target;
    setCheckedValue(checked);
    event.stopPropagation();
    if (onChange && name) {
      onChange(name, checked);
    }
  }, [name, onChange]);

  return (
    <MainContainer
      className={classnames(className, 'no-tap-light')}
    >
      {/* Switch */}
      <StyledSwitch
        innerRef={_ref}
        id={id}
        className="no-tap-light"
        name={name}
        type="switch"
        disabled={readOnly}
        defaultChecked={defaultChecked}
        onChange={handleOnChange}
      />

      {/* Label */}
      {
        truthyLabel && truthyLabel && (
          <StyledLabel
            className="no-tap-light"
            size={14}
            weight={theme.fontWeights.normal}
            fontFamily={theme.fontFamilies.roboto.regular}
          >
            {checkedValue ? truthyLabel : falsyLabel}
          </StyledLabel>
        )
      }
    </MainContainer>
  );
};

const FowardRefToggle = React.forwardRef(Toggle);

export default React.memo(FowardRefToggle);
