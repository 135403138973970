import { gql } from '@apollo/client';

// Fragments

export const activityFragment = gql`
  fragment activity on activities {
    id
    activity
    created_at
    updated_at
    deleted_at
  }
`;
