import { gql } from '@apollo/client';

import {
  salesRepSoftFragment,
  salesRepGridFragment,
  salesRepsFragment,
} from 'data/fragments';

export const getSalesRepsForDropdown = gql`
  query getSalesRepForDropdown {
    sales_reps {
      ...salesRep
    }
  }
  ${salesRepSoftFragment}
`;

export const getSalesRepsForGrid = gql`
  query getSalesRepForGrid (
    $where: sales_reps_bool_exp,
    $offset: Int,
    $limit: Int,
    $order_by: [sales_reps_order_by!],) {
    sales_reps (where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...salesRep
    }
    sales_reps_aggregate(where : $where) {
      aggregate{
        totalCount: count
      }
    }
  }
  ${salesRepGridFragment}  
`;

export const getSalesRepById = gql`
  query getSalesReps($id: uuid!) {
    sales_reps_by_pk(id: $id) {
      ...salesRep
    }
  }
  ${salesRepsFragment}
`;
