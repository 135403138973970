import { gql } from '@apollo/client';

import {
  studentComplexFragment,
  temporalStudentFragmentWithProviders,
} from 'data/fragments';

export const createStudent = gql`
  mutation createStudent($object: students_insert_input!) {
    insert_students_one(object: $object) {
      ...complexStudent
    }
  }
  ${studentComplexFragment}
`;

export const updateStudent = gql`
  mutation updateStudent($studentId: uuid!, $input: students_set_input ) {
    update_students_by_pk(pk_columns: { id: $studentId }, _set: $input) {
      ...complexStudent
    }
  }
  ${studentComplexFragment}
`;

export const createTemporalStudents = gql`
  mutation createTemporalStudents($objects: [temporal_students_insert_input!]! ) {
    insert_temporal_students(objects: $objects) {
      returning { ...temporalStudentWithProviders }
    }
  }
  ${temporalStudentFragmentWithProviders}
`;

export const createStudents = gql`
  mutation createStudents($objects: [students_insert_input!]! ) {
    insert_students(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const resetStudents = gql`
  mutation resetStudents($schoolId: uuid!, $now: timestamptz!, $uuid: String) {
    update_students (
      where: {
        _and: [
          { deleted_at: { _is_null: true } },
          { school_id: { _eq: $schoolId } }
        ],
      },
      _set: {
        deleted_at: $now,
        delete_token: $uuid,
      }
    ) {
      affected_rows
    }
    update_students_providers (
      where: {
        _and: [
          { deleted_at: { _is_null: true } },
          { school_id: { _eq: $schoolId } }
        ],
      },
      _set: {
        deleted_at: $now,
        delete_token: $uuid,
      }
    ) {
      affected_rows
    }
  }
`;
