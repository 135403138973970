import React from 'react';
import styled from 'styled-components';

interface ColumnProps {
  width: number,
  children: any,
  className?: string,
  justifyContent?: string;
  onClick?: () => void;
}

const Container = styled.div<{ $width: number, $justifyContent?: string }>`
  width: ${props => props.$width}%;
  display: flex;
  align-items: center;
  justify-content: ${({ $justifyContent }) => ($justifyContent || 'flex-start')};
`;

const Column = React.memo(({
  width,
  children,
  className,
  justifyContent,
  onClick,
}: ColumnProps) => (
  <Container
    className={className}
    $width={width}
    $justifyContent={justifyContent}
    onClick={onClick}
  >
    {children}
  </Container>
));

export default Column;
