import React from 'react';

// Context
import { StateContext } from 'context/StateContext';

// Hook
function useStateContext() {
  // Hooks
  const ctx = React.useContext(StateContext);
  return ctx;
}

export default useStateContext;
