import { gql } from '@apollo/client';

import {
  schoolNoteFragment,
} from 'data/fragments';

export const createSchoolNote = gql`
  mutation insert_schools_notes_one (
    $object: schools_notes_insert_input!,
  ) {
    insert_schools_notes_one(object: $object) {
      ...schoolNote
    }
  }
  ${schoolNoteFragment}
`;

export const updateSchoolNote = gql`
  mutation updateSchoolNote($schoolNoteId: uuid!, $input: schools_notes_set_input) {
    update_schools_notes_by_pk (pk_columns: { id: $schoolNoteId }, _set: $input) {
      ...schoolNote
    }
  }
  ${schoolNoteFragment}
`;
