import { gql } from '@apollo/client';

// Fragments
export const claimEmailLogFragment = gql`
  fragment claim_email_log on claims_email_logs{
    id
    claim_id
    created_at
    updated_at
    viewed_at
    claim{
      status
    }
    claims_change_log{
      status
    }
  }
`;

export const claimEmailLogIdFragment = gql`
  fragment claim_email_log on claims_email_logs{
    id
    claim_id
    created_at
    updated_at
    claim{
      status
      claim_id
      schools_third_party_administrator{
        third_party_administrator{
          name
          electronic_claims_email_address
        }
      }
    }
    claims_change_log{
      status
      schools_third_party_administrator {
        third_party_administrator {
          name
          electronic_claims_email_address
        }
      }
    }
  }
`;
