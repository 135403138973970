import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

// CSSS
import './TextArea.css';

// Theme
import { colors } from 'theme/theme';

// Components
import Text from 'components/Text';

interface TextAreaProps {
  title?: string,
  placeholder?: string,
  value?: string | number,
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
  error?: boolean,
  className?: string,
  name: string,
  required?: boolean,
  rows?: number,
  maxLength?: number,
}

const Container = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.dark};
  padding: 10px 12px;
  border-radius: 3px;
  border: solid 1px ${props => props.color};
`;

const TextArea = ({
  title = '',
  placeholder = '',
  value = '',
  onChange,
  error = false,
  className = '',
  name,
  required,
  rows = 3,
  maxLength,
}: TextAreaProps, _ref: React.Ref<HTMLTextAreaElement>) => {
  const [focus, setFocus] = useState(false);

  const TextAreaColor = useMemo(() => {
    if (error) {
      return colors.red;
    }
    if (focus) {
      return colors.dark;
    }
    return colors.mediumGrey;
  }, [focus, error]);

  return (
    <div className={`text-left ${className}`}>
      {(title || required) && (
      <Text size={14} color={colors.darkGrey} className="mb-2 d-flex">
        {title}
        {required && <Text size={14} color={colors.red} className="ml-1">*</Text>}
      </Text>
      )}
      <Container className="d-flex" color={TextAreaColor}>
        <textarea
          ref={_ref}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={onChange}
          className="text-area"
          name={name}
          rows={rows}
        />
      </Container>
    </div>

  );
};

const FowardRefTextArea = React.forwardRef(TextArea);

export default React.memo(FowardRefTextArea);
