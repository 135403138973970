import React from 'react';

// Theme
import { colors } from 'theme/theme';

// style
import {
  AccordionHeader,
  AccordionContainer,
  StyledText,
  StyledIcon,
} from './style';

interface AccordionCardProps {
  // className?: string;
  isOdd: boolean,
  children: any,
  title: string,
}

const AccordionCard = React.memo(({
  // className,
  children,
  isOdd,
  title,
}: AccordionCardProps) => {
  // States
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <AccordionContainer onClick={() => setOpen(o => !o)}>
        <AccordionHeader isOdd={isOdd}>
          <StyledText
            $uppercase
            $bold
            size={14}
            lineHeight="1.36"
            color={colors.dark}
          >
            {title}
          </StyledText>
          <StyledIcon icon={open ? 'las la-angle-down' : 'las la-angle-right'} color={colors.grey} />
        </AccordionHeader>
        {open && children}
      </AccordionContainer>
    </>
  );
});

export default AccordionCard;
