import styled from 'styled-components';
import { theme } from 'theme/theme';

// Components
import Text from 'components/Text';
import StepButtons from 'components/StepButtons';

export const TopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Title = styled(Text)``;

export const OptionsMenuContainer = styled.div`
  position: absolute;
  top: 65px;
  right: 20px;
  width: 40px;
  height: 40px;
  padding: 12px 12px;
  line-height: 16px;
  background-color: ${theme.colors.softGrey};
  border-radius: 8px;
  z-index: 10;
`;

export const OptionsMenuCard = styled.div`
  background-color: ${theme.colors.white};
  padding: 18px 10px 18px 10px;
  width: 160px;
  display: flex;
  flex-direction: column;
  margin-left: -126px;
  margin-top: 16px;
  box-shadow: 0 3px 6px 0 rgba(153, 155, 168, 0.2);
  z-index: 12;
`;

export const ProviderContainer = styled.div`
  margin-bottom: 30px;
`;

export const OptionsMenuRow = styled.div`
`;

export const OptionsMenuCol = styled.div`
`;

export const StyledText = styled(Text)`
  margin-top: -7px;
`;

export const StyledOptionText = styled(Text)`
`;

export const ClaimFraudContainer = styled.div<{ $isMobile: boolean }>`
  max-height: 500px;
  border: 1px solid ${theme.colors.lightGrey};
  overflow-y: scroll;
  padding: 0px .5rem;
  margin-top: ${({ $isMobile }) => ($isMobile ? '25px' : '17px')};
`;

export const StyledStepButtons = styled(StepButtons)<{ $isMobile: boolean }>`
  ${({ $isMobile }) => ($isMobile ? 'margin-top: 56px;'
    : 'margin-top: 80px;'
  )}
  .next-button {
    max-width: 130px;
  }
`;

export const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(245, 245, 250, 0.5);
`;
