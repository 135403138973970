/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

// Query
import { getClaimEmailById } from 'data/claims_email_log';

// Utils
import { getClaimEmail } from 'utils/emailHelper';
import { downloadClaimEmailPDF } from 'utils/downloadHelper';

// Styles
import {
  StyledModal,
  MainContainer,
  EmailContainer,
  StyledText,
  HeaderContainer,
  StyledButton,
} from './style';

export interface EmailViewModalProps {
  isOpen: boolean;
  toggle: () => void;
  mailId: string;
}

const EmailViewModal = React.memo(({
  isOpen,
  toggle,
  mailId,
}: EmailViewModalProps) => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  // States
  const [email, setEmail] = React.useState('');
  // Queries
  const [callGetClaimEmailById, {
    loading: loadingGetClaimEmailById,
    data: dataGetClaimEmailById,
  }] = useLazyQuery<GetClaimEmailLogIdResponse, GetClaimEmailLogIdVariables>(getClaimEmailById);

  // Handlers
  const getEmail = React.useCallback(async () => {
    if (mailId) {
      const mail = await getClaimEmail(mailId);
      setEmail(mail);
    }
  }, [mailId]);

  const handleDownload = React.useCallback(() => {
    if (mailId && dataGetClaimEmailById && dataGetClaimEmailById.claims_email_logs_by_pk) {
      downloadClaimEmailPDF(mailId);
    }
  }, [mailId, dataGetClaimEmailById]);

  // Effects
  React.useEffect(() => {
    if (mailId && isOpen) {
      callGetClaimEmailById({
        variables: { id: mailId },
      });
      getEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailId, isOpen]);

  return (
    <StyledModal
      $isMobile={isMobile}
      isOpen={isOpen}
      toggle={toggle}
      title={t('CLAIM_EMAIL_LOG_MODAL.EMAIL')}
    >
      {/* Main Container */}
      <MainContainer>
        {!loadingGetClaimEmailById && dataGetClaimEmailById && dataGetClaimEmailById.claims_email_logs_by_pk
          && (
            <HeaderContainer>
              <div className="row w-100 mb-2">
                <div className="col-9 d-flex">
                  <StyledText>
                    {t('CLAIM_EMAIL_LOG_MODAL.SUBJECT')}
                    :
                  </StyledText>
                  <StyledText className="ml-2">
                    {t('CLAIM_EMAIL_LOG_MODAL.ONLINE_CLAIMS')}
                    {dataGetClaimEmailById.claims_email_logs_by_pk.claim.claim_id}
                  </StyledText>
                </div>
                <div className="col-3 d-flex">
                  <StyledText>
                    {t('CLAIM_EMAIL_LOG_MODAL.DATE')}
                    :
                  </StyledText>
                  <StyledText className="ml-2">
                    {moment(dataGetClaimEmailById.claims_email_logs_by_pk.created_at).format('MM/DD/YYYY')}
                  </StyledText>
                </div>
              </div>
              <div className="row w-100 mb-2">
                <div className="col-9 d-flex">
                  <StyledText>
                    {t('CLAIM_EMAIL_LOG_MODAL.TO')}
                    :
                  </StyledText>
                  <StyledText className="ml-2">
                    {dataGetClaimEmailById.claims_email_logs_by_pk.claim.schools_third_party_administrator.third_party_administrator.name}
                    {` <${dataGetClaimEmailById.claims_email_logs_by_pk.claim.schools_third_party_administrator.third_party_administrator.electronic_claims_email_address}>`}
                  </StyledText>
                </div>
                <div className="col-3 d-flex">
                  <StyledText>
                    {t('CLAIM_EMAIL_LOG_MODAL.TIME')}
                    :
                  </StyledText>
                  <StyledText className="ml-2">
                    {moment(dataGetClaimEmailById.claims_email_logs_by_pk.created_at).format('hh:mm')}
                  </StyledText>
                </div>
              </div>
              <div className="row w-100 mb-2">
                <div className="col-9 d-flex">
                  <StyledText>
                    {t('CLAIM_EMAIL_LOG_MODAL.ATTACHMENT')}
                    :
                  </StyledText>
                  <StyledButton onClick={handleDownload}>
                    <StyledText>
                      {t('CLAIM_EMAIL_LOG_MODAL.CLAIM_-')}
                      {dataGetClaimEmailById.claims_email_logs_by_pk.claim.claim_id}
                      {t('CLAIM_EMAIL_LOG_MODAL.PDF')}
                    </StyledText>
                  </StyledButton>
                </div>

              </div>
            </HeaderContainer>
          )}
        <EmailContainer>
          {ReactHtmlParser(atob(email))}
        </EmailContainer>
      </MainContainer>
    </StyledModal>
  );
});

export default EmailViewModal;
