/* eslint-disable no-console */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
// import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';

// Hooks
import { useResponsive } from 'hooks/useResponsive';
import useStateContext from 'hooks/useStateContext';
import { useModal } from 'hooks/useModal';

// Data
import { ReportNewClaimSteps } from 'data/static/ReportNewClaimSteps';
import {
  getStudentsProviders,
} from 'data/studentsProviders';
import states from 'data/static/states.json';
import provinces from 'data/static/provinces.json';

// Theme
import { theme } from 'theme/theme';

// Components
import Card from 'components/Card';
import LoadingComponent from 'components/Loading';
import Dropdown from 'components/Dropdown';
import InsuranceInformationModal from 'modals/InsuranceInformationModal';

// Styles
import {
  StyledStepButtons,
  Title,
  TopContainer,
  MiddleContainer,
  InsuranceItem,
  Label,
  Value,
  InsuranceItemContainer,
  InsuranceItemTopContainer,
  InsuranceItemBottomContainer,
  InsuranceItemTopLeftContainer,
  InsuranceItemTopRightContainer,
  InsuranceItemTopLabel,
  InsuranceItemContent,
  EditButton,
  DeleteButton,
  StyledTrashIcon,
  StyledEditIcon,
  AddContainer,
  AddButton,
  AddContainerLabel,
  StyledAddIcon,
  InsuranceQuestion,
} from './style';

type StateKey = keyof typeof states;
type ProvinceKey = keyof typeof provinces;
type PrimaryIsuranceQuestion = OptionType | undefined;

const stateOptions: OptionsType<OptionType> = Object.keys(states)
  // @ts-ignore
  .map((key: StateKey) => ({ value: key, label: states[key] }));

const provinceOptions: OptionsType<OptionType> = Object.keys(provinces)
  // @ts-ignore
  .map((key: ProvinceKey) => ({ value: key, label: provinces[key] }));

export interface StepInsuranceInformationProps {
  className?: string;
  schoolId: string;
}

const StepInsuranceInformation = React.memo(({
  className,
  schoolId,
}: StepInsuranceInformationProps) => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const {
    data,
    setStep,
    step,
    setData,
  } = useStateContext();
  const {
    isOpen: isOpenInsuranceModal,
    toggleModal: toggleInsuranceModal,
  } = useModal();

  // States
  const [forms, setForms] = React.useState<StudentProviderForm[]>([]);
  const [formToEdit, setFormToEdit] = React.useState<StudentProviderForm>();
  const [haveInsurance, setHaveInsurance] = React.useState<PrimaryIsuranceQuestion>({ value: 'yes', label: 'Yes' });
  // Queries
  const [callGetPreviousStudentProviders, {
    loading: loadingGetPreviousStudentsProviders,
  }] = useLazyQuery<GetStudentProvidersResponse, GetStudentProvidersVariables>(getStudentsProviders, {
    fetchPolicy: 'network-only',
    onCompleted: _data => {
      if (_data && _data.students_providers && _data.students_providers.length > 0) {
        // NOTE: this is a temporary hack (I guess)
        // NOTE: we retrieve the past students_providers relations, sort them DESC by created_at,
        // NOET: and use the latest with a similar created_at (max 1 minute)

        /* const sorted = _data.students_providers
          .slice()
          .sort((a, b) => (new Date(b.created_at).getTime()) - (new Date(a.created_at).getTime()));

        const initialMom = moment(sorted[0].created_at);
        const toConsider = sorted.filter(sortedItem => {
          const currentMom = moment(sortedItem.created_at);
          return Math.abs(initialMom.diff(currentMom, 'minutes')) < 1;
        });
        console.log('toConsider', toConsider); */
        const newForms: StudentProviderForm[] = _data.students_providers.map((e, idx) => {
          let stateProvToUse = '';
          let stateProvLabelToUse = '';
          if (e.provider && e.provider.state) {
            let relatedOpt = stateOptions.find(opt => opt.value === e.provider.state);
            if (!relatedOpt) {
              relatedOpt = provinceOptions.find(opt => opt.value === e.provider.state);
            }
            if (relatedOpt) {
              stateProvToUse = relatedOpt.value as string;
              stateProvLabelToUse = relatedOpt.label;
            }
          }
          return {
            formId: -(idx + 1), // NOTE: to avoid overlapping with the modal formIds
            insuranceCompanyId: e.insurance_provider_id || 'OTHER',
            insuranceCompanyLabel: e.provider ? e.provider.name : 'Other',
            insuranceCompanyIsOther: e.is_other_provider,
            providerName: e.provider ? '' : e.provider_name,
            insuranceMemberId: e.insurance_member_id,
            insuranceGroupNumber: e.insurance_group_id,
            phone: e.phone,
            address1: e.provider && e.provider.address ? e.provider.address : '',
            address2: '', // TODO: fix in the future
            city: e.provider && e.provider.city ? e.provider.city : '',
            stateProv: stateProvToUse,
            stateProvLabel: stateProvLabelToUse,
            zip: e.provider && e.provider.postal_code ? e.provider.postal_code : '',
            country: 'United States', // TODO: Fix in the future
          };
        });

        setForms(newForms);
      }
    },
  });

  // Effects
  React.useEffect(() => {
    if (
      data && data.formStepInsuranceInformation && data.formStepInsuranceInformation.studentsProvider?.length === 0
    ) {
      setHaveInsurance({ value: 'no', label: 'No' });
    } else if (data && data.formStepInsuranceInformation && data.formStepInsuranceInformation.studentsProvider) {
      setForms(data.formStepInsuranceInformation.studentsProvider);
    } else if (data && data.formStepSelectStudent && data.formStepSelectStudent.studentId) {
      // fetch previous students-providers and creat the forms
      const variables: GetStudentProvidersVariables = {
        schoolId,
        studentId: data.formStepSelectStudent.studentId,
      };

      callGetPreviousStudentProviders({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handlers
  const handlePrev = React.useCallback(() => {
    // Update the state
    setData((prevData?: ReportNewClaimWizard) => ({
      ...prevData,
      formStepInsuranceInformation: {
        ...prevData?.formStepInsuranceInformation,
        studentsProvider: forms,
      },
    }));

    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_VIEW_STUDENT,
    }));
  }, [forms, setStep, setData]);

  const handleNext = React.useCallback(() => {
    // Update the state
    setData((prevData?: ReportNewClaimWizard) => ({
      ...prevData,
      formStepInsuranceInformation: {
        ...prevData?.formStepInsuranceInformation,
        studentsProvider: forms,
      },
    }));

    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_FILL_CLAIM_INFORMATION,
    }));
  }, [forms, setStep, setData]);

  const handleCancel = React.useCallback(() => {
    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_CLAIM_PREVIEW,
      editMode: false,
    }));
  }, [setStep]);

  const handleSave = React.useCallback(() => {
    // Update the state
    setData((prevData?: ReportNewClaimWizard) => ({
      ...prevData,
      formStepInsuranceInformation: {
        ...prevData?.formStepInsuranceInformation,
        studentsProvider: forms,
      },
    }));

    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_CLAIM_PREVIEW,
      editMode: false,
    }));
  }, [forms, setStep, setData]);

  const handleAdd = React.useCallback(() => {
    setFormToEdit(undefined);
    toggleInsuranceModal();
  }, [toggleInsuranceModal]);

  const handleEdit = React.useCallback((form: StudentProviderForm) => {
    setFormToEdit(form);
    toggleInsuranceModal();
  }, [toggleInsuranceModal]);

  const handleDelete = React.useCallback((formId: number) => {
    setForms(s => s.filter(_s => _s.formId !== formId));
  }, []);

  const handleClose = React.useCallback(() => {
    setFormToEdit(undefined);
    toggleInsuranceModal();
  }, [toggleInsuranceModal]);

  const handleOnAddOrEdit = React.useCallback((_form: StudentProviderForm) => {
    const targetFormIdx = forms.findIndex(form => form.formId === _form.formId);
    if (targetFormIdx >= 0) {
      // Edit Form
      const newForms = [...forms];
      newForms[targetFormIdx] = _form;
      setForms(newForms);
    } else {
      // Add Form
      setForms(s => [...s, _form]);
    }
    handleClose();
  }, [forms, handleClose]);

  const handleSetHaveInsurance = React.useCallback((value: PrimaryIsuranceQuestion): void => {
    if (value?.value === 'no') setForms(() => []);
    setHaveInsurance(value);
  }, []);

  if (loadingGetPreviousStudentsProviders) {
    return (
      <LoadingComponent />
    );
  }

  return (
    <>
      <Card
        padding="25px 30px 20px;"
        className={classnames(className)}
      >
        {/* Top Container (Title and CTA Button) */}
        <TopContainer>
          {/* Title */}
          <Title
            size={18}
            fontFamily={theme.fontFamilies.roboto.bold}
            color={theme.colors.dark}
            weight={theme.fontWeights.bold}
          >
            {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.TITLE')}
          </Title>
          {/* Add Container */}
          {
            haveInsurance?.value === 'yes' && (
              <AddContainer>
                {/* Add Container Label */}
                <AddContainerLabel>
                  {
                    forms.length === 0 && (
                      <>
                        {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.NO_INSURANCE_LABEL')}
                      </>
                    )
                  }
                </AddContainerLabel>
                {/* Add Button */}
                <AddButton
                  $isMobile={isMobile}
                  type="SECONDARY"
                  onClick={handleAdd}
                >
                  <StyledAddIcon
                    className={`las la-plus-circle ${isMobile ? 'mr-0' : ''} `}
                  />
                  {!isMobile && t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.CTA_ADD_LABEL')}
                </AddButton>
              </AddContainer>
            )
          }
        </TopContainer>
        {/* Middle Container */}
        <MiddleContainer>
          <InsuranceQuestion>
            <Dropdown
              name="haveInsurance"
              title={t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.PRIMARY_INSURANCE_QUESTION')}
              onSelect={(key, value) => {
                handleSetHaveInsurance(value);
              }}
              selected={haveInsurance}
              options={[
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
              ]}
            />
          </InsuranceQuestion>
          {
            forms.map(form => (
              <InsuranceItem
                key={form.formId}
              >
                <InsuranceItemContainer>
                  {/* Insurance Item Top Container */}
                  <InsuranceItemTopContainer>
                    {/* Insurance Item Top Left Container */}
                    <InsuranceItemTopLeftContainer>
                      {/* Insurance Item Top Label */}
                      <InsuranceItemTopLabel
                        className="item-top-left-label"
                      >
                        {form.insuranceCompanyIsOther ? form.providerName || '' : form.insuranceCompanyLabel || ''}
                      </InsuranceItemTopLabel>
                    </InsuranceItemTopLeftContainer>

                    {/* Insurance Item Top Right Container */}
                    <InsuranceItemTopRightContainer>
                      {/* Delete Button */}
                      <DeleteButton
                        $isMobile={isMobile}
                        type="OUTLINE"
                        onClick={() => handleDelete(form.formId)}
                      >
                        <StyledTrashIcon
                          className={`las la-trash-alt ${isMobile ? 'mr-0' : ''} `}
                        />
                        {!isMobile && t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.CTA_DELETE_LABEL')}
                      </DeleteButton>

                      {/* Edit Button */}
                      <EditButton
                        $isMobile={isMobile}
                        type="PRIMARY"
                        onClick={() => handleEdit(form)}
                      >
                        <StyledEditIcon
                          className={`las la-edit ${isMobile ? 'mr-0' : ''} `}
                        />
                        {!isMobile && t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.CTA_EDIT_LABEL')}
                      </EditButton>
                    </InsuranceItemTopRightContainer>
                  </InsuranceItemTopContainer>

                  {/* Insurance Item Bottom Container */}
                  <InsuranceItemBottomContainer>

                    {/* Item: Member ID */}
                    <InsuranceItemContent $isMobile={isMobile}>
                      <Label>
                        {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.FORM.MEMBER_ID_LABEL')}
                      </Label>
                      <Value>
                        {form.insuranceMemberId || ''}
                      </Value>
                    </InsuranceItemContent>

                    {/* Item: Group Number */}
                    <InsuranceItemContent $isMobile={isMobile}>
                      <Label>
                        {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.FORM.GROUP_NUMBER_LABEL')}
                      </Label>
                      <Value>
                        {form.insuranceGroupNumber || ''}
                      </Value>
                    </InsuranceItemContent>

                    {/* Item: Phone */}
                    <InsuranceItemContent $isMobile={isMobile}>
                      <Label>
                        {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.FORM.PHONE_LABEL')}
                      </Label>
                      <Value>
                        {form.phone || '-'}
                      </Value>
                    </InsuranceItemContent>

                  </InsuranceItemBottomContainer>
                  <InsuranceItemBottomContainer>
                    {/* Item: Address 1 */}
                    {/*  <InsuranceItemContent $isMobile={isMobile}>
                      <Label>
                        {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.FORM.ADDRESS_1_LABEL')}
                      </Label>
                      <Value>
                        {form.address1 || '-'}
                      </Value>
                    </InsuranceItemContent> */}

                    {/* Item: Address 2 */}
                    {/*      <InsuranceItemContent $isMobile={isMobile}>
                      <Label>
                        {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.FORM.ADDRESS_2_LABEL')}
                      </Label>
                      <Value>
                        {form.address2 || '-'}
                      </Value>
                    </InsuranceItemContent> */}

                    {/* Item: City */}
                    {/*       <InsuranceItemContent $isMobile={isMobile}>
                      <Label>
                        {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.FORM.CITY_LABEL')}
                      </Label>
                      <Value>
                        {form.city || '-'}
                      </Value>
                    </InsuranceItemContent> */}

                    {/* Item: State */}
                    {/*    <InsuranceItemContent $isMobile={isMobile}>
                      <Label>
                        {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.FORM.STATE_LABEL')}
                      </Label>
                      <Value>
                        {form.stateProvLabel || '-'}
                      </Value>
                    </InsuranceItemContent> */}

                    {/* Item: Zip */}
                    {/*    <InsuranceItemContent $isMobile={isMobile}>
                      <Label>
                        {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.FORM.ZIP_LABEL')}
                      </Label>
                      <Value>
                        {form.zip || '-'}
                      </Value>
                    </InsuranceItemContent> */}

                    {/* Item: Canadian Address */}
                    {/*   <InsuranceItemContent $isMobile={isMobile}>
                      <Label>
                        {t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE
                        _INFORMATION.RESULTS.FORM.CANADIAN_ADDRESS_LABEL')}
                      </Label>
                      <Value>
                        {form.country === 'United States' ? t('NO') : t('YES')}
                      </Value>
                    </InsuranceItemContent> */}
                  </InsuranceItemBottomContainer>
                </InsuranceItemContainer>
              </InsuranceItem>
            ))
          }
        </MiddleContainer>

        {/* Step Buttons */}
        <StyledStepButtons
          $isMobile={isMobile}
          $moreMarginTop={forms.length === 0}
          showPrev
          showNext
          nextDisabled={haveInsurance?.value === 'no' ? false : forms.length === 0}
          onPrev={step?.editMode ? handleCancel : handlePrev}
          onNext={step?.editMode ? handleSave : handleNext}
          editMode={step?.editMode}
        />
      </Card>

      {/* Modals */}
      <InsuranceInformationModal
        form={formToEdit}
        providersIdsToIgnore={forms.map(form => form.insuranceCompanyId)}
        lastFormId={forms[forms.length - 1] ? forms[forms.length - 1].formId : 0}
        isOpen={isOpenInsuranceModal}
        toggle={handleClose}
        onAddOrEdit={handleOnAddOrEdit}
      />
    </>
  );
});
export default StepInsuranceInformation;
