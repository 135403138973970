import styled from 'styled-components';

// Components
import Select from 'react-select';

// Theme
import { theme } from 'theme/theme';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  font-family: 'Roboto-Regular';
  font-size: 14px;
  color: ${theme.colors.dark};

  &.with-error {
    > .tag-select__control {
      &:not(.tag-select__control--is-focused) {
        border-color: ${theme.colors.red};
      }
    }
  }

  > .tag-select__control {
    box-shadow: none ;
    min-height: unset;
    border: 1px solid;
    border-color: ${theme.colors.mediumGrey};
    min-height: 40px;
    max-height: 90px;
    overflow: auto;

    &.tag-select__control--is-focused {
      border-color: ${theme.colors.dark};
    }

    &:hover {
      &:not(.tag-select__control--is-focused) {
        border-color: ${theme.colors.lightGrey};
      }
    }

    > .tag-select__value-container {
      &.tag-select__value-container--is-multi {
        padding: 2px 8px;
        min-height: unset;

        > .tag-select__placeholder {
          margin: 0;
        }

        > .tag-select__multi-value {
          background-color: ${theme.colors.lightGrey};
          border-radius: 3px;
          margin: 2px 2px;
          min-height: 25px;
          display: flex;
          align-items: center;

          > .tag-select__multi-value__label {
            font-family: 'Roboto-Regular';
            font-size: 12px;
            color: ${theme.colors.grey};
            padding: 0px 0px 0px 5px;
          }

          > .tag-select__multi-value__remove {
            padding: 0 5px;
            cursor: pointer;

            > i {
              font-size: 14px;
              color: ${theme.colors.grey};
              margin-top: 1px;
            }

            &:hover {
              background-color: transparent !important;
              
              i {
                color: ${theme.colors.red};
              }
            }
          }
        }
      }
    }

    > .tag-select__indicators {
      align-items: flex-start;
      
      > .tag-select__indicator-separator {
        display: none;
      }

      > .tag-select__dropdown-indicator {

        > svg {
          height: 18px;
          width: 19px;
        } 
      }
    }
  }

  > .tag-select__menu {
    > .tag-select__menu-list {
      > .tag-select__option {
        padding: 0px 22px;
        font-family: 'Roboto-Regular';
        font-size: 14px;
        color: ${theme.colors.grey};
        line-height: 26px;
        background-color: ${theme.colors.white};

        &:first-child {
          border-bottom: 2px solid #eeeeee;
          padding: 3px 22px;
        }

        &:hover {
          background-color: ${theme.colors.lightGrey};
          cursor: pointer;
        }
      }
    }
  }
`;
