import styled from 'styled-components';

// Components
import Button from 'components/Button';
import TextArea from 'components/TextArea';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-height: 500px;
`;

export const TopContainer = styled.div`
  width: 100%;
`;

export const StyledTextArea = styled(TextArea)``;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 50px;

  > button {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
`;

export const CancelButton = styled(Button)`
  width: 100%;
  max-width: 135px;
`;

export const UpdateButton = styled(Button)`
  width: 100%;
  max-width: 135px;
`;
