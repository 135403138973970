import React from 'react';
import './ClaimViewLine.css';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

// Components
import Text from 'components/Text';

// Theme
import { colors } from 'theme/theme';

interface ClaimViewLineProps {
  field: string,
  value: string,
  className?: string,
}

const ClaimViewLine = ({
  field,
  value,
  className,
}: ClaimViewLineProps) => {
  const { isMobile } = useResponsive();
  return (
    <div className={`${className} row ml-2 ${isMobile ? 'mb-2' : ''}`}>
      <div className={`${isMobile ? 'col-12' : 'col thirty'} `}>
        <Text size={14} color={colors.grey} lineHeight="19px">{field}</Text>
      </div>
      <div className={`${isMobile ? 'col-12' : 'col seventy'} `}>
        <Text size={14} color={colors.dark} lineHeight="19px">{value}</Text>
      </div>
    </div>
  );
};

export default ClaimViewLine;
