import { gql } from '@apollo/client';

// Fragments
export const salesRepSoftFragment = gql`
  fragment salesRep on sales_reps {
    id
    first_name
    last_name
  }
`;

export const salesRepGridFragment = gql`
  fragment salesRep on sales_reps {
    id
    first_name
    last_name
    email_address
    updated_at
  }
`;

export const salesRepsFragment = gql`
  fragment salesRep on sales_reps {
    id
    first_name
    last_name
    address_1
    address_2
    city
    state
    postal_code
    email_address
    phone
    see_all_schools
    created_by
    created_at
    updated_at
  }
`;
