import React from 'react';
// import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { createPortal } from 'react-dom';

// CSSS
import './DatePicker.css';

// Components
import Input from 'components/Input';

interface DatePickerProps {
  title?: string;
  placeholder?: string;
  setStartDate: (key: string, date: Date | null | [Date, Date]) => void;
  error?: boolean;
  className?: string;
  name: string;
  startDate?: Date;
  required?: boolean;
  showTimeSelect?: boolean;
  timeIntervals?: number;
  minDate?: Date;
  maxDate?: Date;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  usePortal?: boolean;
  tabIndex?: number;
}

const CustomDatePicker = React.memo(({
  title = '',
  placeholder = '',
  setStartDate,
  error = false,
  className = '',
  name,
  startDate,
  required,
  showTimeSelect,
  timeIntervals,
  minDate,
  maxDate,
  showMonthDropdown,
  showYearDropdown,
  usePortal,
  tabIndex,
}: DatePickerProps) => (
  <DatePicker
    title={title}
    name={name}
    required={required}
    selected={startDate}
    placeholderText={placeholder}
    onChange={date => setStartDate(name, date)}
    showTimeSelect={showTimeSelect}
    timeIntervals={timeIntervals}
    minDate={minDate}
    maxDate={maxDate}
    showMonthDropdown={showMonthDropdown}
    showYearDropdown={showYearDropdown}
    tabIndex={tabIndex}
    popperContainer={usePortal ? ({ children }) => createPortal(children, document.body) : undefined}
    customInput={(
      <Input
        value={startDate ? '' : moment(startDate).format('MM/DD/YYYY')}
        className={className}
        icon="las la-calendar"
        iconLeft
        error={error}
      />
    )}
  />
));

export default CustomDatePicker;
