import styled from 'styled-components';

// Components
import Button from 'components/Button';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import DatePicker from 'components/DatePicker';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const TopContainer = styled.div<{ $isMobile: boolean}>`
  padding: 0 16px;
  width: 100%;
  display: grid;
  grid-template-columns: '1fr 1fr';
  grid-auto-columns: ${({ $isMobile }) => ($isMobile ? '50%' : 'auto')};
  grid-template-rows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr';
  gap: ${({ $isMobile }) => ($isMobile ? '20px 10px' : '20px 20px')};
  grid-template-areas:
    "area-firstname area-firstname"
    "area-lastname area-lastname"
    "area-student-id area-student-id"
    "area-email area-email"
    "area-gender area-dob"
    "area-phone area-phone"
    "area-address1 area-address1"
    "area-address2 area-address2"
    "area-city area-city"
    "area-state area-zip"
    "area-country area-country";
  `;

export const GridItem = styled.div`
  width: 100%;

  &.area-firstname {
    grid-area: area-firstname;
  }
  &.area-phone { 
    grid-area: area-phone;
  }

  &.area-lastname { 
    grid-area: area-lastname;
  }

  &.area-address1 { 
    grid-area: area-address1;
  }

  &.area-address2 { 
    grid-area: area-address2;
  }

  &.area-student-id { 
    grid-area: area-student-id;
  }

  &.area-email { 
    grid-area: area-email;
  }

  &.area-city { 
    grid-area: area-city;
  }

  &.area-gender { 
    grid-area: area-gender;
  }

  &.area-dob { 
    grid-area: area-dob;
  }

  &.area-state { 
    grid-area: area-state;
  }

  &.area-zip { 
    grid-area: area-zip;
  }

  &.area-country { 
    grid-area: area-country;
  }
`;

export const TextInput = styled(Input)`
  width: 100%;
  margin-top: 20px;
  
  input {
    background-image: none !important;
  }

  &:first-of-type {
    margin-top: 0px;
  }
`;

export const StyledDropdown = styled(Dropdown)``;

export const StyledCheckbox = styled(Checkbox)``;

export const StyledDatePicker = styled(DatePicker)``;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AddButton = styled(Button)`
  width: 100%;
  max-width: 124px;
`;
