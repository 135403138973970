import { gql } from '@apollo/client';

// Fragments
export const claimProviderFragment = gql`
  fragment claim_provider on claims_providers  {
    id
    is_other_provider
    provider_name
    insurance_provider_id
    insurance_member_id
    insurance_group_id
    phone
  }
`;
