import styled from 'styled-components';

// Components
import Text from 'components/Text';
import Button from 'components/Button';

export const Container = styled.div``;

export const Title = styled(Text)``;

export const StyledButton = styled(Button)`
  width: 135px
`;
