/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

// Query
import { getClaimIdById } from 'data/claim';
import { getClaimEmailLog } from 'data/claims_email_log';

// Components
import PageLayout from 'components/PageLayout';
import Card from 'components/Card';
import { getPagination } from 'utils/paginationHelper';
import ClaimEmailsLogList from './ClaimEmailsLogList';

const ClaimEmailsLogPage = React.memo(() => {
  // Hooks
  const { t } = useTranslation();
  const params = useParams<URLParams>();
  // Queries
  const {
    data: dataGetClaimIdById,
  } = useQuery<GetClaimIdResponse, GetClaimVariables>(getClaimIdById, {
    variables: {
      id: params.claimId || '',
    },
  });

  // States
  const [pageSize, setPageSize] = React.useState(25);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const crumbs = React.useMemo(() => {
    if (params.id) {
      return [
        { title: t('SERVICE_CENTER'), to: '/dashboard' },
        { title: t('REPORTED_CLAIMS'), to: `/dashboard/${params.id}/claims` },
        { title: `${t('VIEW_CLAIM.CLAIM')}: ${dataGetClaimIdById?.claims_by_pk.claim_id}`, to: `/dashboard/${params.id}/claims/${params.claimId}` },
        { title: t('VIEW_CLAIM.EMAIL_LOG'), to: '' },
      ];
    }
    return [
      { title: t('VIEW_CLAIM.CLAIMS'), to: '/claims' },
      { title: `${t('VIEW_CLAIM.CLAIM')}: ${dataGetClaimIdById?.claims_by_pk.claim_id}`, to: `/claims/${params.claimId}` },
      { title: t('VIEW_CLAIM.EMAIL_LOG'), to: '' },
    ];
  }, [params]);

  // Queries
  const [callGetClaimEmailLog, {
    loading: loadingGetClaimEmailLog,
    data: dataGetClaimEmailLog,
  }] = useLazyQuery<GetClaimEmailLogResponse, GetClaimEmailLogVariables>(getClaimEmailLog);

  // Effects
  React.useEffect(() => {
    if (params.claimId) {
      callGetClaimEmailLog({
        variables: {
          claimId: params.claimId,
          offset: (currentPage - 1) * pageSize,
          limit: pageSize,
          // Todo order by
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageSize,
    currentPage,
    callGetClaimEmailLog,

  ]);

  // Handlers
  const handleOnPageChange = React.useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  const handleOnPageSizeChange = React.useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  const {
    claimsEmails,
    pagination,
  } = React.useMemo(() => {
    const out: {
      claimsEmails?: ClaimEmailLog[],
      pagination?: Pagination,
    } = {
      claimsEmails: [],
      pagination: undefined,
    };
    if (!loadingGetClaimEmailLog && dataGetClaimEmailLog) {
      out.claimsEmails = dataGetClaimEmailLog.claims_email_logs;
      const totalItems = dataGetClaimEmailLog.claims_email_logs_aggregate.aggregate.totalCount;
      out.pagination = getPagination(currentPage, totalItems, pageSize);
    }
    return out;
  }, [
    loadingGetClaimEmailLog,
    dataGetClaimEmailLog,
    currentPage,
    pageSize,
  ]);

  return (
    <PageLayout
      crumbs={crumbs}
    >
      <Card padding="25px 30px 30px 34px;">
        <ClaimEmailsLogList
          items={claimsEmails}
          pageSize={pageSize}
          pagination={pagination}
          setPageSize={handleOnPageSizeChange}
          onChangePage={handleOnPageChange}
        />
      </Card>
    </PageLayout>

  );
});

export default ClaimEmailsLogPage;
