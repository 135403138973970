/* eslint-disable no-console */

import axios from 'axios';
import { SIS_TOKEN } from 'context/Auth';

export const createUserForSchool = async function createUserForSchool(data: CommonJSON) {
  const out = {
    success: false,
  };
  try {
    const tokenCredentials = localStorage.getItem(SIS_TOKEN);
    if (!tokenCredentials) {
      throw new Error('No Token Credentials');
    }

    const url = `${process.env.REACT_APP_API}/v1/user`;

    const response = await axios(url, {
      method: 'post',
      data,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenCredentials}`,
      },
    });

    if (response && response.data && response.data.success !== undefined) {
      return {
        success: true,
      };
    }
  } catch (error) {
    console.error('ERROR [createUserForSchool]', error);
    return {
      success: false,
    };
  }
  return out;
};

const UserHelper = {
  createUserForSchool,
};

export default UserHelper;
