import React, { useMemo } from 'react';
import styled from 'styled-components';

// Theme
import { colors } from 'theme/theme';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

interface CardProps {
  children: any,
  className?: string,
  padding: string,
  title?: any,
}

const Container = styled.div<{ $isMobile: boolean, padding: string }>`
  padding: ${props => props.padding};
  border-radius: 8px;
  background-color: ${colors.white};
  width: 100%;
`;

const Card = React.memo(({
  children,
  className = '',
  padding,
  title,
}: CardProps) => {
  const { isMobile } = useResponsive();

  const mobilePadding = useMemo(() => '25px 15px 30px 15px', []);

  return (
    <Container padding={isMobile ? mobilePadding : padding} className={className} $isMobile={isMobile}>
      {title && <div className="mb-4">{title}</div>}
      {children}
    </Container>
  );
});

export default Card;
