import styled from 'styled-components';
import { theme } from 'theme/theme';

// Components
import StepButtons from 'components/StepButtons';
import Text from 'components/Text';
import Button from 'components/Button';

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(Text)`
  flex: none;
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const InsuranceItem = styled.div<{ $withMT?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  &:first-of-type {
    .item-top-left-label {
      display: none;
    }
  }
`;

export const InsuranceItemTopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 0 30px;
`;

export const InsuranceItemTopLeftContainer = styled.div``;

export const InsuranceItemTopRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
`;

export const InsuranceItemTopLabel = styled.div`
font-family: 'Roboto-Regular';
font-size: 14px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: ${theme.colors.grey};
`;

export const AddButton = styled(Button)<{ $isMobile: boolean}>`
  ${({ $isMobile }) => ($isMobile ? `
    width: 40px;
    padding: 12px 12px;
  `
    : ` width: 100%;
    padding: 0px 12px;
  `
  )}
  max-width: 95px;
  line-height: 40px;
  margin-left: 2rem;
`;

export const EditButton = styled(Button)<{ $isMobile: boolean}>`
  ${({ $isMobile }) => ($isMobile ? `
    width: 40px;
    padding: 12px 12px;
  `
    : ` width: 100%;
    padding: 0px 12px;
  `
  )}
  max-width: 95px;
  line-height: 40px;
`;

export const DeleteButton = styled(Button)<{ $isMobile: boolean}>`
  ${({ $isMobile }) => ($isMobile ? `
    width: 40px;
    padding: 12px 12px;
  `
    : ` width: 100%;
    padding: 0px 12px;
  `
  )}
  max-width: 100;
  line-height: 40px;
`;

export const StyledAddIcon = styled.i`
  margin-right: 10px;
  font-size: 16px;
`;

export const StyledTrashIcon = styled.i`
  margin-right: 10px;
  font-size: 16px;
`;

export const StyledEditIcon = styled.i`
  margin-right: 10px;
  font-size: 16px;
`;

export const InsuranceItemContainer = styled.div`
  width: 100%;
  background-color: #f5f5fa;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
`;

export const InsuranceItemBottomContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(17%,1fr) minmax(17%,1fr) minmax(17%,1fr) minmax(17%,1fr) 
  minmax(17%,1fr) minmax(17%,1fr) minmax(17%,1fr);
  grid-template-rows: 1fr;
  gap: 0px opx;
  margin-top: 15px; 
  padding: 0 30px;
`;

export const InsuranceItemContent = styled.div<{ $isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  flex-wrap: ${({ $isMobile }) => ($isMobile ? 'wrap' : 'nowrap')};
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  ${({ $isMobile }) => ($isMobile ? `
    margin-bottom: 10px;
  `
    : ''
  )} 
`;

export const Label = styled.div`
  width: 310px;
  font-family: 'Roboto-Regular';
  font-size: 14px;
  font-weight: normal;
  color: ${theme.colors.grey};
`;

export const Value = styled.div`
  width: 100%;
  font-family: 'Roboto-Regular';
  font-size: 14px;
  color: ${theme.colors.dark};
`;

export const AddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 0 0 20px;
`;

export const AddContainerLabel = styled.div`
font-family: 'Roboto-Regular';
`;

export const StyledStepButtons = styled(StepButtons)<{ $moreMarginTop?: boolean, $isMobile: boolean }>`
margin-top: 500px;
${({ $moreMarginTop, $isMobile }) => (!$moreMarginTop && !$isMobile ? 'margin-top: 25px;' : '')} 
${({ $moreMarginTop, $isMobile }) => (!$moreMarginTop && $isMobile ? 'margin-top: 45px;' : '')} 
`;

export const InsuranceQuestion = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 20px;
`;
