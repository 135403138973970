/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */

import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';

// Data
import { deleteSoftUser, getPaginatedUsersBySchoolId } from 'data/user';

// Hooks
import { useModal } from 'hooks/useModal';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect';

// Utils
import { getPagination } from 'utils/paginationHelper';
import { Toaster } from 'utils/toaster';

// Components
import LoadingComponent from 'components/Loading';

// Styles
import ConfirmationModal from 'modals/ConfirmationModal';
import {
  MainContainer,
  TopContainer,
  StyledAddCircleIcon,
  BottomContainer,
  TextInput,
  NewUserButton,
  StyledUserModal,
  StyledUsersResults,
} from './style';

export interface UsersTabProps {
  className?: string;
  school: School;
}

const UsersTab = React.memo(({ className, school }: UsersTabProps) => {
  // Hooks
  const { t } = useTranslation();
  const {
    isOpen: isOpenModal, openModal, closeModal, toggleModal,
  } = useModal();
  const {
    isOpen: isOpenConfirmationModal,
    openModal: openConfirmationModal,
    toggleModal: toggleConfirmationModal,
  } = useModal();

  // Refs
  const lastPaginationVariables = React.useRef<GetPaginatedUsersVariables | null>(null);

  // States
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [filterValue, setFilterValue] = React.useState<string>('');
  const [userToEdit, setUserToEdit] = React.useState<User>();
  const [userToDelete, setUserToDelete] = React.useState<User>();

  // Queries
  const [
    callGetPaginatedUsers,
    { loading: loadingGetPaginatedUsers, data: dataGetPaginatedUsers, refetch: refetchPaginatedUser },
  ] = useLazyQuery<GetPaginatedUsersResponseBySchoolId, GetPaginatedUsersVariables>(
    /* getPaginatedUsers */ getPaginatedUsersBySchoolId,
    {
      fetchPolicy: 'network-only',
    },
  );

  // Mutations
  // TODO: change to soft delete
  const [callDeleteUser, { loading: loadingDeleteUser }] = useMutation<
  DeleteSoftUserResponse,
  DeleteSoftUserVariables
  >(deleteSoftUser, {
    onError: _error => {
      console.error('ERROR [deleteUser]', _error);
      Toaster('error', t('TOASTER.ERROR.USER_DELETION_FAILED'));
    },
    onCompleted: () => {
      Toaster('success', t('TOASTER.SUCCESS.USER_DELETED'));
      // Refresh the current Pagination
      if (lastPaginationVariables.current && refetchPaginatedUser) {
        refetchPaginatedUser(lastPaginationVariables.current);
      }
    },
  });

  // Memos
  const { users, pagination } = React.useMemo(() => {
    const out: {
      users: User[];
      pagination?: Pagination;
    } = {
      users: [],
      pagination: undefined,
    };
    if (dataGetPaginatedUsers && dataGetPaginatedUsers.users_schools) {
      out.users = dataGetPaginatedUsers.users_schools.map(({ user }) => user);
      const totalItems = dataGetPaginatedUsers.users_schools_aggregate.aggregate.totalCount;

      out.pagination = getPagination(currentPage, totalItems, pageSize);
    }
    return out;
  }, [dataGetPaginatedUsers, currentPage, pageSize]);

  // Effects
  React.useEffect(() => {
    let variables: GetPaginatedUsersVariables | undefined;
    const or: CommonJSON[] = [];

    variables = {
      where: {
        and: [
          {
            school_id: { _eq: school.id },
          },
          {
            user: { deleted_at: { _is_null: true } },
          },
        ],
      },
    };

    if (filterValue) {
      // first_name
      or.push({
        user: { first_name: { _ilike: `%${filterValue}%` } },
      });

      // last_name
      or.push({
        user: { last_name: { _ilike: `%${filterValue}%` } },
      });

      // email_address
      or.push({
        user: { email_address: { _ilike: `%${filterValue}%` } },
      });
    }

    const resultingAnd = or.length > 0 ? [...variables.where.and, { _or: or }] : [...variables.where.and];

    variables = {
      where: {
        _and: resultingAnd,
      },
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
      // TODO: order_by
    };

    lastPaginationVariables.current = variables;

    callGetPaginatedUsers({ variables });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage, filterValue, callGetPaginatedUsers]);

  useDebouncedEffect(
    () => {
      setFilterValue(searchValue);
    },
    500,
    [searchValue],
  );

  // Handler
  const handleAddNewUser = React.useCallback(() => {
    openModal();
  }, [openModal]);

  const handleOnUserCreate = React.useCallback(() => {
    // Refresh the current Pagination
    if (lastPaginationVariables.current && refetchPaginatedUser) {
      refetchPaginatedUser(lastPaginationVariables.current);
    }
  }, [refetchPaginatedUser]);

  const handleEditUser = React.useCallback(
    (userId: string) => {
      const targetUser = users.find(user => user.id === userId);
      if (targetUser) {
        setUserToEdit(targetUser);
        openModal();
      }
    },
    [users, openModal],
  );

  const handleDeleteUser = React.useCallback(
    (userId: string) => {
      const targetUser = users.find(user => user.id === userId);
      if (targetUser) {
        closeModal();
        setUserToDelete(targetUser);
        openConfirmationModal();
      }
    },
    [users, closeModal, openConfirmationModal],
  );

  const handleCloseDeleteConfirmationModal = React.useCallback(() => {
    setUserToDelete(undefined);
    setUserToEdit(undefined);
    toggleConfirmationModal();
  }, [toggleConfirmationModal]);

  const handleOnDeleteUser = React.useCallback(() => {
    if (userToDelete) {
      const variables: DeleteUserVariables = {
        userId: userToDelete.id,
      };
      callDeleteUser({ variables });
      handleCloseDeleteConfirmationModal();
    }
  }, [userToDelete, callDeleteUser, handleCloseDeleteConfirmationModal]);

  const handleOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value } = target;
    setSearchValue(value);
  }, []);

  const handleToggleModal = React.useCallback(() => {
    toggleModal();
    setUserToEdit(undefined);
  }, [toggleModal]);

  const handleOnChangePage = React.useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  // Memos
  const loading = React.useMemo(
    () => loadingGetPaginatedUsers || loadingDeleteUser,
    [loadingGetPaginatedUsers, loadingDeleteUser],
  );

  return (
    <>
      <MainContainer className={classnames(className)}>
        {/* Top Container */}
        <TopContainer>
          {/* Text Input */}
          <TextInput
            name="search"
            icon="las la-search"
            placeholder={t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.CTA_NEW_USER_PLACEHOLDER')}
            value={searchValue}
            onChange={handleOnChange}
          />

          {/* Search Button */}
          <NewUserButton type="SECONDARY" onClick={handleAddNewUser}>
            <StyledAddCircleIcon className="las la-plus-circle" />
            {t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.CTA_NEW_USER_LABEL')}
          </NewUserButton>
        </TopContainer>

        {/* Bottom Container */}
        <BottomContainer>
          {loading ? (
            <LoadingComponent />
          ) : (
            <StyledUsersResults
              items={users}
              pageSize={pageSize}
              pagination={pagination}
              setPageSize={setPageSize}
              onChangePage={handleOnChangePage}
              onActionEdit={handleEditUser}
              onActionDelete={handleDeleteUser}
            />
          )}
        </BottomContainer>
      </MainContainer>

      {/* Modals */}
      <StyledUserModal
        schoolId={school.id}
        user={userToEdit}
        isOpen={isOpenModal}
        toggle={handleToggleModal}
        onUserCreate={handleOnUserCreate}
        onUserDelete={handleDeleteUser}
      />
      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        toggle={handleCloseDeleteConfirmationModal}
        title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.DELETE_CONFIRMATION_MODAL.TITLE')}
        text={t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.DELETE_CONFIRMATION_MODAL.MESSAGE')}
        buttonText={t('SCHOOLS_MANAGEMENT_PAGE.TAB_USER.DELETE_CONFIRMATION_MODAL.CONFIRM_LABEL')}
        onConfirm={handleOnDeleteUser}
      />
    </>
  );
});
export default UsersTab;
