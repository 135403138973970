/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';
import { useMutation, useQuery } from '@apollo/client';
import useAxios from 'axios-hooks';

// Data
import {
  getProviders,
} from 'data/providers';
import { updateStudentProvider } from 'data/studentsProviders/studentsProviders.mutation';

// Context
import { SIS_TOKEN } from 'context/Auth';

// Hooks
import useAuth from 'hooks/useAuth';
import { useResponsive } from 'hooks/useResponsive';
import useStudentContext from 'hooks/useStudentContext';
import { useModal } from 'hooks/useModal';

// Modals
import ConfirmationModal from 'modals/ConfirmationModal';

// Components
import LoadingComponent from 'components/Loading';
import Text from 'components/Text';
import Divider from 'components/Divider';

// Utils
import { Toaster } from 'utils/toaster';

// style
import { colors } from 'theme/theme';
import {
  GreyDiv,
  Container,
  PrimaryContainer,
  GridItem,
  TextInput,
  StyledDropdown,
  StyledButton,
  BottomContainer,
  AddButtonContainer,
  AddButton,
  StyledAddIcon,
  StyledTrashIcon,
  StyledDeleteButton,
  HiddenInput,
  StyledInsuranceProviderStatus,
} from './style';

export interface InsuranceInfoProps {
  student?: Student;
  setProvidersToDelete: Function;
  school?: School;
  refetch?: Function;
}

const InsuranceInfo = React.memo(({
  student,
  setProvidersToDelete,
  school,
  refetch,
}: InsuranceInfoProps) => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { isUserType } = useAuth();
  const {
    register,
    errors,
    inputMaxSizes,
    control,
    setValue,
    watch,
    getValues,
    formState,
  } = useStudentContext();
  const tokenCredentials = localStorage.getItem(SIS_TOKEN);
  const [providerToDelete, setProviderToDelete] = useState(0);

  // Modals
  const {
    isOpen: isOpenConfirmationModal,
    toggleModal: toggleConfirmationModal,
    openModal: openConfirmationModal,
  } = useModal();

  // Axios
  const [{
    data, loading, error,
  }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_API}/v1/student/piv`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokenCredentials}`,
      },
    },
    {
      manual: true,
    },
  );

  // Queries
  const {
    loading: loadingGetProviders,
    data: dataGetProviders,
  } = useQuery<GetProvidersResponse>(getProviders);

  // Mutations
  const [
    callUpdateStudentProvider, { loading: loadingUpdateStundetProvider },
  ] = useMutation<UpdateStudentProviderResponse, UpdateStudentProviderVariables>(updateStudentProvider, {
    onError: _error => {
      console.error('ERROR [UpdateStudentProvider]', _error);
      Toaster('error', t('TOASTER.ERROR.STUDENT_CREATION_FAILED'));
    },
    onCompleted: (respose: UpdateStudentProviderResponse) => {
      executePost({
        data: { studentProviderId: respose.update_students_providers_by_pk?.id },
      });
    },
  });

  const {
    fields, swap, remove, append,
  } = useFieldArray({
    name: 'providers',
    control,
  });
  const watchProviders = watch('providers');
  // Memos
  const isAdmin = React.useMemo(() => !!isUserType('admin'), [isUserType]);
  const isEdit = React.useMemo(() => {
    if (student && student.id) {
      return true;
    }
    return false;
  }, [student]);

  const providers = React.useMemo(() => {
    if (dataGetProviders && dataGetProviders.providers) {
      return dataGetProviders.providers;
    }
    return [];
  }, [dataGetProviders]);

  const providersIdsToIgnore = React.useMemo(() => {
    const ids: string[] = [];
    if (watchProviders.length > 0) {
      watchProviders.forEach((provider: StudentProviderInput) => {
        if (provider.insuranceCompany
          && provider.insuranceCompany.value) {
          ids.push(provider.insuranceCompany.value);
        }
      });
    }
    return ids;
  }, [watchProviders]);

  const providersOptions = React.useMemo(
    () => {
      let out = providers
        .map(provider => ({
          value: provider.id,
          label: provider.name,
        }));

      // Filter all existing providers
      out = out.filter(opt => !providersIdsToIgnore.includes(opt.value));

      out.push({
        value: 'OTHER',
        label: t('INSURANCE_INFORMATION_MODAL.OTHER'),
      });
      out.sort((a, b) => a.label.localeCompare(b.label));

      return out;
    },
    [providers, providersIdsToIgnore],
  );

  const filterOption = (
    candidate: OptionType,
    input: string,
  ) => candidate.label.toLowerCase().includes(input.toLowerCase()) || candidate.value === 'OTHER';

  // Handlers
  const handleClear = useCallback((index: number) => {
    const empty = {
      id: null,
      _id: null,
      insuranceCompany: null,
      providerName: '',
      insuranceGroupId: '',
      insuranceMemberId: '',
      phone: '',
      primary: null,
      status: null,
    };

    const provToDelete = fields[index];
    if (provToDelete && provToDelete['_id'] && index === 0) {
      setProvidersToDelete((s: StudentProviderInput[]) => (s ? [...s, provToDelete] : [provToDelete]));
    }
    setValue(`providers.${index}`, empty);
  }, [fields, getValues, setValue]);

  const handleAddProvider = () => {
    append({
      insuranceCompany: null,
      providerName: '',
      insuranceGroupId: '',
      insuranceMemberId: '',
      phone: '',
      primary: null,
      status: null,
    });
  };

  const handleOnPressDelete = useCallback((index: number) => {
    const provToDelete = fields[index];
    if (provToDelete && provToDelete['_id']) {
      setProviderToDelete(index);
      openConfirmationModal();
    } else {
      remove(index);
    }
  }, [fields, watchProviders]);

  const handleOnDelete = useCallback(() => {
    const provToDelete = fields[providerToDelete];
    setProvidersToDelete((s: StudentProviderInput[]) => (s ? [...s, provToDelete] : [provToDelete]));
    remove(providerToDelete);
    toggleConfirmationModal();
  }, [fields, providerToDelete]);

  const makePrimary = useCallback((index: number) => {
    swap(index, 0);
  }, [swap]);

  const handleVerify = useCallback(async (index: number) => {
    const studentProviderId = student?.students_providers[index].id;
    if (studentProviderId) {
      await callUpdateStudentProvider({
        variables: {
          studentProviderId,
          input: {
            id: studentProviderId,
            primary: true,
          },
        },
      });
    }
  }, [student]);

  // Effects
  useEffect(() => {
    if (data && !loading && !error) {
      Toaster('success', t('TOASTER.SUCCESS.STUDENTS_VERIFICATION'));
      if (refetch) {
        refetch();
      }
    } else if (!loading && error) {
      Toaster('error', t('TOASTER.ERROR.STUDENTS_VERIFICATION_FAILED'));
    }
  }, [data, loading, error]);

  return (
    <>
      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        toggle={toggleConfirmationModal}
        title={t('NEW_STUDENT_PAGE.DELETE')}
        text={t('NEW_STUDENT_PAGE.DELETE_MESSAGE')}
        buttonText={t('NEW_STUDENT_PAGE.YES_DELETE')}
        onConfirm={handleOnDelete}
      />
      {fields.map((_, index) => (
        <div key={_.id}>
          {index === 0 && (
            <GreyDiv>
              <Container>
                <Text size={16} color={colors.dark} fontFamily="REGULAR" lineHeight="27px" className="p-2">
                  {t('NEW_STUDENT_PAGE.PRIMARY_INSURANCE')}
                </Text>
                {school?.show_verification_status && (
                  <div className="col-2 my-auto">
                    <StyledInsuranceProviderStatus
                      status={fields[index].status || null}
                    />
                  </div>
                )}
              </Container>
            </GreyDiv>
          )}

          {index > 1 && <Divider className="my-2" />}

          <PrimaryContainer $isMobile={isMobile}>
            <GridItem
              className="area-insurance-company"
            >
              <HiddenInput
                ref={register()}
                name={`providers.${index}._id`}
                // eslint-disable-next-line no-underscore-dangle
                defaultValue={fields[index]._id}
              />
              <HiddenInput
                ref={register()}
                name={`providers.${index}.status`}
                defaultValue={fields[index].status}
              />
              <HiddenInput
                ref={register()}
                name={`providers.${index}.primary`}
                defaultValue={fields[index].primary}
                type="checkbox"
              />
              <Controller
                control={control}
                name={`providers.${index}.insuranceCompany`}
                defaultValue={fields[index].insuranceCompany}
                render={({ onChange, value, name }) => (
                  <>
                    {
                      loadingGetProviders ? (
                        <LoadingComponent />
                      ) : (
                        <StyledDropdown
                          title={t('INSURANCE_INFORMATION_MODAL.INSURANCE_COMPANY_LABEL')}
                          name={name}
                          selected={value}
                          options={providersOptions}
                          onSelect={(_key, v) => { onChange(v); }}
                          error={errors[name] !== undefined}
                          filterOption={filterOption}
                          // required
                          tabIndex={`${index}24`}
                        />
                      )
                    }
                  </>
                )}
              />
            </GridItem>

            {/* Field: Insurance other name */}
            <GridItem
              className="area-other-insurance-name"
            >
              {watchProviders[index]?.insuranceCompany?.value === 'OTHER'
                && (
                  <TextInput
                    ref={register()}
                    name={`providers.${index}.providerName`}
                    title={t('INSURANCE_INFORMATION_MODAL.INSURANCE_COMPANY_NAME')}
                    placeholder={t('INSURANCE_INFORMATION_MODAL.INSURANCE_COMPANY_NAME_PLACEHOLDER')}
                    icon=""
                    defaultValue={fields[index].providerName}
                    maxLength={inputMaxSizes.providerName}
                    error={errors.providerName !== undefined}
                    ignoreLastPass
                    tabIndex={index * 100 + 25}
                  />
                )}
            </GridItem>

            <GridItem className="area-nothing" />

            {/* Field: Insurance Member Id */}
            <GridItem
              className="area-insurance-member-id"
            >
              <TextInput
                ref={register()}
                name={`providers.${index}.insuranceMemberId`}
                title={t('INSURANCE_INFORMATION_MODAL.MEMBER_ID_LABEL')}
                icon=""
                defaultValue={fields[index].insuranceMemberId}
                maxLength={inputMaxSizes.insuranceMemberId}
                error={errors.insuranceMemberId !== undefined}
                ignoreLastPass
                tabIndex={index * 100 + 26}
              />
            </GridItem>
            {/* Field: Insurance group id */}
            <GridItem
              className="area-insurance-group-id"
            >
              <TextInput
                ref={register()}
                name={`providers.${index}.insuranceGroupId`}
                title={t('INSURANCE_INFORMATION_MODAL.GROUP_NUMBER_LABEL')}
                icon=""
                defaultValue={fields[index].insuranceGroupId}
                maxLength={inputMaxSizes.insuranceGroupId}
                error={errors.insuranceGroupId !== undefined}
                ignoreLastPass
                tabIndex={index * 100 + 27}
              />
            </GridItem>
            {/* Field: Phone */}
            <GridItem
              className="area-phone"
            >
              <TextInput
                ref={register()}
                name={`providers.${index}.phone`}
                title={t('INSURANCE_INFORMATION_MODAL.PHONE_LABEL')}
                icon=""
                defaultValue={fields[index].phone}
                maxLength={inputMaxSizes.phone}
                error={errors.phone !== undefined}
                ignoreLastPass
                tabIndex={index * 100 + 28}
              />
            </GridItem>
          </PrimaryContainer>
          <BottomContainer>
            {index === 0 && (
              <StyledButton
                type="OUTLINE"
                onClick={() => handleClear(index)}
                disabled={fields.length > 1}
              >
                {t('NEW_STUDENT_PAGE.CLEAR')}
              </StyledButton>
            )}
            {index === 0 && isEdit && (isAdmin || school?.allow_piv) && (
              <StyledButton
                type="SECONDARY"
                loading={loading || loadingUpdateStundetProvider}
                disabled={loading
                  || loadingUpdateStundetProvider
                  || formState.isDirty
                  || !student?.students_providers[index]?.id}
                onClick={() => handleVerify(index)}
              >
                {t('NEW_STUDENT_PAGE.VERIFY')}
              </StyledButton>
            )}

            {index > 0 && (
              <>
                <StyledButton
                  type="OUTLINE"
                  onClick={() => makePrimary(index)}
                >
                  {t('NEW_STUDENT_PAGE.MAKE_PRIMARY')}
                </StyledButton>
                <StyledDeleteButton
                  type="SECONDARY"
                  onClick={() => handleOnPressDelete(index)}
                >
                  <StyledTrashIcon
                    className="las la-trash-alt"
                  />
                </StyledDeleteButton>
              </>
            )}
            {/* <StyledButton
              type="SECONDARY"
              disabled={!primaryInsuranceCompany
                || !primaryInsuranceCompany.label}
            >
              {t('NEW_STUDENT_PAGE.VERIFY')}
            </StyledButton> */}

          </BottomContainer>
          {index === 0 && (
            <>
              <GreyDiv>
                <Container>
                  <Text size={16} color={colors.dark} fontFamily="REGULAR" lineHeight="27px" className="p-2">
                    {t('NEW_STUDENT_PAGE.ADDITIONAL_INSURANCE')}
                  </Text>
                </Container>
              </GreyDiv>

              <AddButtonContainer>
                <AddButton
                  $isMobile={isMobile}
                  type="SECONDARY"
                  onClick={handleAddProvider}
                >
                  <StyledAddIcon
                    className={`las la-plus-circle ${isMobile ? 'mr-0' : ''} `}
                  />
                  {!isMobile && t('REPORT_NEW_CLAIM_PAGE.STEPS.STEP_INSURANCE_INFORMATION.RESULTS.CTA_ADD_LABEL')}
                </AddButton>
              </AddButtonContainer>
            </>
          )}
        </div>
      ))}
    </>
  );
});

export default InsuranceInfo;
