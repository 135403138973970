const availableRoutes = {
  login: '/login',
  resertPassword: '/reset-password',
  schools: '/schools',
  addSchool: '/schools/management',
  editSchool: '/schools/management/:id',
  newSchoolClaim: '/dashboard/:id/claims/new-claim',
  viewSchoolClaim: '/dashboard/:id/claims/:claimId',
  editSchoolClaim: '/dashboard/:id/claims/:claimId/edit',
  viewSchoolClaimEmails: '/dashboard/:id/claims/:claimId/emails',
  schoolClaims: '/dashboard/:id/claims',
  dashboard: '/dashboard',
  confirmAccount: '/confirm',
  salesReps: '/sales-reps',
  newSalesReps: '/sales-reps/new-sales-rep',
  editSalesReps: '/sales-reps/:id',
  tpaList: '/tpas',
  tpaNew: '/tpas/management',
  tpaEdit: '/tpas/management/:id',
  newClaim: '/claims/new-claim',
  claimsList: '/claims',
  viewClaim: '/claims/:claimId',
  viewClaimEmails: '/claims/:claimId/emails',
  students: '/dashboard/:id/students',
  importStudents: '/dashboard/:id/students/import',
  newStudent: '/dashboard/:id/students/new-student',
  editStudent: '/dashboard/:id/students/:studentId',
  activities: '/activities',
};

export {
  availableRoutes,
};
