import { gql } from '@apollo/client';

// Fragments
import { userSoftFragment, userDropDownFragment } from 'data/fragments';

export const getPaginatedUsersBySchoolId = gql`
  query getPaginatedUsersBySchoolId(
    $where: users_schools_bool_exp
    $offset: Int
    $limit: Int
    $order_by: [users_schools_order_by!]
  ) {
    # Aggregation
    users_schools_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }

    users_schools(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      user {
        id
        first_name
        last_name
        email_address
        is_active
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;

export const getPaginatedUsers = gql`
  query getPaginatedUsers($where: users_bool_exp, $offset: Int, $limit: Int, $order_by: [users_order_by!]) {
    # Aggregation
    users_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    users(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...user
    }
  }
  ${userSoftFragment}
`;

export const getAdminsForDropdown = gql`
  query getAdminsForDropdown($where: users_bool_exp) {
    users(where: $where) {
      ...user
    }
  }
  ${userDropDownFragment}
`;
