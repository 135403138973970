/* eslint-disable no-console */
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import Select, {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OptionsType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ValueType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ActionMeta,
} from 'react-select';

// CSSS
import './Dropdown.css';

// Theme
import { colors } from 'theme/theme';

// Components
import Text from 'components/Text';

interface DropdownProps {
  title?: string,
  placeholder?: string,
  onSelect: (key: string, value: OptionType | undefined) => void,
  selected: OptionType | undefined,
  error?: boolean,
  className?: string,
  options: OptionsType<OptionType>,
  required?: boolean,
  isSearchable?: boolean,
  isClearable?: boolean,
  name: string,
  readOnly?: boolean;
  filterOption?: any,
  tabIndex?: string | null;
}

const Container = styled.div<{ $readOnly?: boolean }>`
font-family: Open Sans;
font-size: 12px;
font-weight: 600;
color: ${colors.dark};
padding: 10px 12px;
border-radius: 3px;
border: solid 1px ${props => props.color};
background-color: ${({ $readOnly }) => ($readOnly ? colors.disabledGrey : '')};
`;

const Dropdown = React.memo(({
  title = '',
  placeholder = 'Select an option',
  selected,
  onSelect,
  error = false,
  className = '',
  options,
  isSearchable,
  isClearable,
  required,
  name,
  readOnly,
  filterOption,
  tabIndex,
}: DropdownProps) => {
  const [focus, setFocus] = useState(false);
  const inputColor = useMemo(() => {
    if (error) {
      return colors.red;
    }
    if (focus) {
      return colors.dark;
    }
    return colors.mediumGrey;
  }, [focus, error]);

  const handleOnChangeBranchFilter = React.useCallback(
    (value: ValueType<OptionType, boolean>, action: ActionMeta<OptionType>) => {
      if (action.action === 'clear') {
        onSelect(name, undefined);
      } else if (value && action.action === 'select-option') {
        onSelect(name, value as (OptionType));
      }
    }, [name, onSelect],
  );

  return (
    <div className={`text-left ${className}`}>
      {(title || required) && (
        <Text size={14} color={colors.darkGrey} className="mb-2 d-flex">
          {title}
          {required && <Text size={14} color={colors.red} className="ml-1">*</Text>}
        </Text>
      )}
      <Container
        className="d-flex"
        color={inputColor}
        $readOnly={readOnly}
      >
        <Select
          className="custom-async-select"
          classNamePrefix="custom-select"
          placeholder={placeholder}
          isSearchable={isSearchable}
          isClearable={isClearable}
          options={options}
          value={selected}
          onChange={handleOnChangeBranchFilter}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          isDisabled={readOnly}
          filterOption={filterOption}
          tabIndex={tabIndex}
        />
      </Container>
    </div>

  );
});

export default Dropdown;
