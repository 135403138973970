import { gql } from '@apollo/client';

import {
  schoolDashboardElementOrderFragment,
} from 'data/fragments';

export const updateSchoolDashboardElementOnMove = gql`
  mutation update_schools_dashboard_elements_order(
    $schoolId: uuid!,
    $component: String,
    $startIndex: Int,
    $endIndex: Int,
    $newIndex: Int,
    $elementId: uuid!,
    $movPos: Int,
  ) {
    increment: update_schools_dashboard_elements_order(
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: $component}, 
        _and: [
          {order: {_gte: $startIndex}},
          {order: {_lte: $endIndex}}
        ]
      }
        _inc: {order: $movPos}) {
      returning {
        ...schoolDashboardElementOrder
      }
    }
    set_pos_item_moved:  update_schools_dashboard_elements_order(
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: $component}, 
        _or: [
          {schools_dashboard_contact_id: {_eq: $elementId}}, 
          {schools_dashboard_element_id: {_eq: $elementId}}, 
          {third_party_administrators_dashboard_element_id: {_eq: $elementId}}
        ], 
      }, _set: {order: $newIndex}) {
      returning {
        ...schoolDashboardElementOrder
      }
    }
  }
  ${schoolDashboardElementOrderFragment}
`;

export const deleteSchoolDashboardElementOrder = gql`
  mutation delete_schools_dashboard_elements_order(
    $schoolId: uuid,
    $component: String,
    $startIndex: Int,
    $elementId: uuid,
  ) {
    delete_schools_dashboard_elements_order(
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: $component}, 
        _or: [
          {schools_dashboard_contact_id: {_eq: $elementId}}, 
          {schools_dashboard_element_id: {_eq: $elementId}}, 
          {third_party_administrators_dashboard_element_id: {_eq: $elementId}}
        ], 
      }){
      returning {
        ...schoolDashboardElementOrder
      }
    }
    update_schools_dashboard_elements_order(
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: $component}, 
        order: {_gte: $startIndex},
      },  _inc: {order: -1}) {
      returning {
        ...schoolDashboardElementOrder
      }
    }
  }
  ${schoolDashboardElementOrderFragment}
`;

export const insertSchoolDashboardElementOrder = gql`
  mutation addItem (
    $object: schools_dashboard_elements_order_insert_input!,
  ) {
    insert_schools_dashboard_elements_order_one(object: $object) {
      ...schoolDashboardElementOrder
    }
  }
${schoolDashboardElementOrderFragment}
`;

export const deleteTPADashboardElementOrder = gql`
  mutation delete_schools_dashboard_elements_order(
    $schoolId: uuid,
    $component: String,
    $elementId: uuid,
  ) {
    delete_schools_dashboard_elements_order(
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: $component}, 
        third_party_administrators_dashboard_element_id: {_eq: $elementId}
      }){ 
      returning {
        ...schoolDashboardElementOrder
      }
    }
  }
  ${schoolDashboardElementOrderFragment}
`;

export const reorderAfterDashboardElementOrder = gql`
  mutation reorder_schools_dashboard_elements_order(
    $schoolId: uuid,
    $component: String,
    $startIndex: Int,
  ) {
    update_schools_dashboard_elements_order(
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: $component}, 
        order: {_gte: $startIndex},
      },  _inc: {order: -1}) {
      returning {
        ...schoolDashboardElementOrder
      }
    }
  }
  ${schoolDashboardElementOrderFragment}
`;

export const insertManyDashboardElementOrder = gql`
  mutation insert_multiple_schools_dashboard_elements_order(
    $objects: [schools_dashboard_elements_order_insert_input!]! ) {
    insert_schools_dashboard_elements_order(objects: $objects) {
      returning {
        ...schoolDashboardElementOrder
      }
    }
  }
  ${schoolDashboardElementOrderFragment}
`;

export const deleteManyDashboardElementOrder = gql`
  mutation delete_multiple_schools_dashboard_elements_order(
    $tpadeIds: [uuid!]!,
    $schoolId: uuid,
     ) {
    delete_schools_dashboard_elements_order(where: {
      school_id: {_eq: $schoolId}, 
      third_party_administrators_dashboard_element_id: {_in: $tpadeIds}, 
    }) {
      returning {
        ...schoolDashboardElementOrder
      }
    }
  }
  ${schoolDashboardElementOrderFragment}
`;
