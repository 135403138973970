import React from 'react';
import classnames from 'classnames';

// Styles
import {
  MainContainer,
} from './style';

interface StatusBoxProps {
  className?: string;
  status: boolean;
  truthyLabel: string;
  falsyLabel: string;
}

const StatusBox = React.memo(({
  className,
  status,
  truthyLabel,
  falsyLabel,
}: StatusBoxProps) => (
  <MainContainer
    className={classnames(className)}
    $status={status}
  >
    {status ? truthyLabel : falsyLabel}
  </MainContainer>
));

export default StatusBox;
