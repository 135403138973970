import styled from 'styled-components';

// Components
import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';

export const Title = styled(Text)``;

export const Grid = styled.div<{ $isMobile: boolean}>`
  margin-left: 1rem;
  margin-top: 35px;
  margin-bottom:${({ $isMobile }) => ($isMobile ? '30px' : '')};
  display: grid;
  grid-template-columns:${({ $isMobile }) => ($isMobile ? '' : 'repeat(2, minmax(200px, 340px));')};
  grid-template-rows: repeat(1, 1fr);
  gap: 20px 20px;
`;

export const GridItem = styled.div`
  width: 100%;
`;

export const TextInput = styled(Input)`
  input {
    background-image: none !important;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

`;

export const SearchButton = styled(Button)`
  width: 100%;
  max-width: 130px;
`;
