import React from 'react';
import * as Toast from 'react-toastify';
import { Trans } from 'react-i18next';

// Components
import Text from 'components/Text';

// Theme
import { colors } from '../theme/theme';

export const Toaster = (type: string, message: string) => {
  switch (type) {
    case 'warning':
      return Toast.toast.warning(
        <div className="row d-flex">
          <div className="col-2 m-auto">
            <i className="las la-exclamation-triangle" />
          </div>
          <div className="col">
            <div className="ml-2">
              <div>
                <Text size={12} fontFamily="BOLD" color={colors.yellow}>
                  <Trans i18nKey="TOASTER.WARNING.WARNING">
                    Warning
                  </Trans>
                </Text>
              </div>
              <div>
                <Text size={12} color={colors.yellow}>{message}</Text>
              </div>
            </div>
          </div>
        </div>, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    case 'error':
      return Toast.toast.error(
        <div className="row d-flex">
          <div className="col-2 m-auto">
            <i className="las la-exclamation-circle" />
          </div>
          <div className="col">
            <div className="ml-2">
              <div>
                <Text size={12} fontFamily="BOLD" color={colors.red}>
                  <Trans i18nKey="TOASTER.ERROR.ERROR">
                    Error
                  </Trans>
                </Text>
              </div>
              <div>
                <Text size={12} color={colors.red}>{message}</Text>
              </div>
            </div>
          </div>
        </div>, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    case 'success':
      return Toast.toast.success(
        <div className="row d-flex">
          <div className="col-2 m-auto">
            <i className="las la-check-circle" />
          </div>
          <div className="col">
            <div className="ml-2">
              <div>
                <Text size={12} fontFamily="BOLD" color={colors.successGreen}>
                  <Trans i18nKey="TOASTER.SUCCESS.SUCCESS">
                    Success
                  </Trans>
                </Text>
              </div>
              <div>
                <Text size={12} color={colors.successGreen}>{message}</Text>
              </div>
            </div>
          </div>
        </div>, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    case 'info':
      return Toast.toast.info(message);
    case 'dark':
      return Toast.toast.dark(message);
    default:
      return Toast.toast(message);
  }
};
