/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';

// Data
import states from 'data/static/states.json';
import provinces from 'data/static/provinces.json';
import genders from 'data/static/genders.json';

// Hooks
import { useResponsive } from 'hooks/useResponsive';
import useStudentContext from 'hooks/useStudentContext';

// Styles
import {
  TopContainer,
  GridItem,
  TextInput,
  StyledDropdown,
  StyledCheckbox,
  StyledDatePicker,
} from './style';

type StateKey = keyof typeof states;
type ProvinceKey = keyof typeof provinces;
type GenderKey = keyof typeof genders;

const stateOptions: OptionsType<OptionType> = Object.keys(states)
  // @ts-ignore
  .map((key: StateKey) => ({ value: key, label: states[key] }));

const provinceOptions: OptionsType<OptionType> = Object.keys(provinces)
  // @ts-ignore
  .map((key: ProvinceKey) => ({ value: key, label: provinces[key] }));

const genderOptions: OptionsType<OptionType> = Object.keys(genders)
  // @ts-ignore
  .map((key: GenderKey) => ({ value: key, label: genders[key] }));

const StudentInfo = React.memo(() => {
  // Hooks
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const {
    watch,
    register,
    errors,
    inputMaxSizes,
    control,
  } = useStudentContext();

  const now = React.useMemo(() => new Date(), []);

  const isCanadianAddress: boolean = watch('student.canadianAddress', false);

  return (
    <>
      {/* Top Container */}
      <TopContainer $isMobile={isMobile}>
        {/* Field: First Name */}
        <GridItem
          className="area-firstname"
        >
          <TextInput
            ref={register()}
            name="student.firstName"
            title={t('STUDENT_MODAL.FIRST_NAME_LABEL')}
            icon=""
            maxLength={inputMaxSizes.firstName}
            error={errors.student?.firstName !== undefined}
            ignoreLastPass
            tabIndex={1}
            required
          />
        </GridItem>

        {/* Field: Phone */}
        <GridItem
          className="area-phone"
        >
          <TextInput
            ref={register()}
            name="student.phone"
            title={t('STUDENT_MODAL.PHONE_LABEL')}
            icon=""
            maxLength={inputMaxSizes.phone}
            error={errors.student?.phone !== undefined}
            ignoreLastPass
            tabIndex={7}
          />
        </GridItem>

        {/* Field: Last Name */}
        <GridItem
          className="area-lastname"
        >
          <TextInput
            ref={register()}
            name="student.lastName"
            title={t('STUDENT_MODAL.LAST_NAME_LABEL')}
            icon=""
            maxLength={inputMaxSizes.lastName}
            error={errors.student?.lastName !== undefined}
            ignoreLastPass
            tabIndex={2}
            required
          />
        </GridItem>

        {/* Field: Address 1 */}
        <GridItem
          className="area-address1"
        >
          <TextInput
            ref={register()}
            name="student.address1"
            title={t('STUDENT_MODAL.ADDRESS_1_LABEL')}
            icon=""
            maxLength={inputMaxSizes.address1}
            error={errors.student?.address1 !== undefined}
            ignoreLastPass
            tabIndex={8}
            required
          />
        </GridItem>

        {/* Field: Academic ID */}
        <GridItem
          className="area-student-id"
        >
          <TextInput
            ref={register()}
            name="student.academicId"
            title={t('STUDENT_MODAL.ACADEMIC_ID_LABEL')}
            icon=""
            maxLength={inputMaxSizes.academicId}
            error={errors.student?.academicId !== undefined}
            ignoreLastPass
            tabIndex={3}
          />
        </GridItem>

        {/* Field: Address 2 */}
        <GridItem
          className="area-address2"
        >
          <TextInput
            ref={register()}
            name="student.address2"
            title={t('STUDENT_MODAL.ADDRESS_2_LABEL')}
            icon=""
            maxLength={inputMaxSizes.address2}
            error={errors.student?.address2 !== undefined}
            ignoreLastPass
            tabIndex={9}
          />
        </GridItem>

        {/* Field: Email */}
        <GridItem
          className="area-email"
        >
          <TextInput
            ref={register()}
            name="student.email"
            title={t('STUDENT_MODAL.EMAIL_LABEL')}
            icon=""
            maxLength={inputMaxSizes.email}
            error={errors.student?.email !== undefined}
            ignoreLastPass
            tabIndex={4}
          />
        </GridItem>

        {/* Field: City */}
        <GridItem
          className="area-city"
        >
          <TextInput
            ref={register()}
            name="student.city"
            title={t('STUDENT_MODAL.CITY_LABEL')}
            icon=""
            maxLength={inputMaxSizes.city}
            error={errors.student?.city !== undefined}
            ignoreLastPass
            tabIndex={10}
            required
          />
        </GridItem>

        {/* Field: Gender */}
        <GridItem
          className="area-gender"
        >
          <Controller
            control={control}
            name="student.gender"
            render={({ onChange, value, name }) => (
              <StyledDropdown
                title={t('STUDENT_MODAL.GENDER_LABEL')}
                name={name}
                selected={value}
                options={genderOptions}
                onSelect={(key, v) => onChange(v)}
                error={errors.student?.gender !== undefined}
                required
                tabIndex="5"
              />
            )}
          />
        </GridItem>

        {/* Field: DOB */}
        <GridItem
          className="area-dob"
        >
          <Controller
            control={control}
            name="student.dob"
            render={({
              onChange, value, name,
            }) => (
              <StyledDatePicker
                title={t('STUDENT_MODAL.DOB_LABEL')}
                name={name}
                startDate={value}
                setStartDate={(key: string, date: Date | null | [Date, Date]) => onChange(date)}
                maxDate={now}
                showMonthDropdown
                showYearDropdown
                error={errors.student?.dob !== undefined}
                required
                tabIndex={6}
              />
            )}
          />
        </GridItem>

        {/* Field Item: State/Province */}
        <GridItem
          className="area-state pr-0"
          key={isCanadianAddress ? 1 : 2}
        >
          {
            isCanadianAddress ? (
              <Controller
                control={control}
                name="student.province"
                render={({ onChange, value, name }) => (
                  <StyledDropdown
                    title={t('STUDENT_MODAL.PROVINCE_LABEL')}
                    name={name}
                    selected={value}
                    options={provinceOptions}
                    onSelect={(key, v) => onChange(v)}
                    error={errors.student?.province !== undefined}
                    required
                    tabIndex="11"
                  />
                )}
              />
            ) : (
              <Controller
                control={control}
                name="student.state"
                render={({ onChange, value, name }) => (
                  <StyledDropdown
                    title={t('STUDENT_MODAL.STATE_LABEL')}
                    name={name}
                    selected={value}
                    options={stateOptions}
                    onSelect={(key, v) => onChange(v)}
                    error={errors.student?.state !== undefined}
                    required
                    tabIndex="11"
                  />
                )}
              />
            )
          }

        </GridItem>

        {/* Field Item: Zip */}
        <GridItem
          className="area-zip pl-0"
        >
          <TextInput
            ref={register()}
            name="student.zip"
            title={t('STUDENT_MODAL.ZIP_LABEL')}
            icon=""
            maxLength={inputMaxSizes.zip}
            error={errors.student?.zip !== undefined}
            ignoreLastPass
            tabIndex={12}
            required
          />
        </GridItem>

        <GridItem
          className="area-country my-auto"
        >
          <Controller
            control={control}
            name="student.canadianAddress"
            render={({ onChange, value }) => (
              <StyledCheckbox
                checked={value}
                onClick={() => onChange(!value)}
                label={t('STUDENT_MODAL.CANADIAN_ADDRESS')}
                alternativeStyle
                tabIndex={13}
              />
            )}
          />
        </GridItem>

      </TopContainer>
    </>
  );
});

export default StudentInfo;
