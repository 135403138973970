import styled from 'styled-components';

// Components
import Text from 'components/Text';
import Button from 'components/Button';
import Toggle from 'components/Toggle';
import Input from 'components/Input';
import ImageDropZone from 'components/ImageDropZone';
import Dropdown from 'components/Dropdown';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: 20px 50px;
  grid-template-areas:
    "area-status area-status area-status"
    "area-name area-name area-logo"
    ". . area-logo"
    ". . area-logo"
    ". . area-notes"
    ". . area-notes"
    "area-piv area-students-import area-show-verification-status";
`;

export const GridItem = styled.div`
  &.area-status {
    grid-area: area-status;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.area-piv {
    grid-area: area-piv;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.area-students-import {
    grid-area: area-students-import;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.area-show-verification-status {
    grid-area: area-show-verification-status;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.area-name {
    grid-area: area-name;
  }

  &.area-logo {
    grid-area: area-logo;
  }

  /* &.area-notes {
    grid-area: area-notes;
  } */

  &.as-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    > * {
      width: 100%;
      margin-right: 20px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
`;

export const StyledLabel = styled(Text)``;

export const StyledToggle = styled(Toggle)`
  margin-left: 30px;
`;

export const TextInput = styled(Input)`
  input {
    background-image: none !important;
  }
`;

export const StyledImageDropZone = styled(ImageDropZone)``;

export const StyledDropdown = styled(Dropdown)``;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 50px;

  > button {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
`;

export const CancelButton = styled(Button)`
  width: 100%;
  max-width: 130px;
`;

export const UpdateButton = styled(Button)`
  width: 100%;
  max-width: 135px;
`;
