/* eslint-disable no-console */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components
import Card from 'components/Card';
import CardTitle from 'components/Card/CardTitle';
import DataTablePaginator from 'components/Paginator';
import ThreeStatusBox from 'components/ThreeStatusBox';

// Theme
import { colors } from 'theme/theme';
import PageSizeSelector from 'components/PageSizeSelector';

// Hooks
import { useResponsive } from 'hooks/useResponsive';
import { useModal } from 'hooks/useModal';

// Modals
import EmailViewModal from 'modals/EmailViewModal';

// Utils
import { resendClaimEmail } from 'utils/emailHelper';
import { Toaster } from 'utils/toaster';

// Styles
import {
  ResendButton,
  StyledTable,
  StyledTableRow,
  StyledColumn,
  StyledText,
  StyledIcon,
} from './style';

interface ClaimEmailsLogListProps {
  items?: ClaimEmailLog[],
  pageSize: number,
  pagination?: Pagination;
  setPageSize: (newPageSize: number) => void;
  onChangePage: (pageNumber: number) => void;
}

const ClaimEmailsLogList = React.memo(({
  items,
  pageSize,
  pagination,
  setPageSize,
  onChangePage,
}: ClaimEmailsLogListProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  // Modals
  const {
    isOpen: isOpenClaimMailModal,
    toggleModal: toggleClaimMailModal,
    openModal: openClaimMailModal,
  } = useModal();

  // States
  const [mailId, setMailId] = React.useState();

  const options: SizeOptionType[] = React.useMemo(() => {
    const temp: SizeOptionType[] = [
      { value: 25, label: 25 },
      { value: 100, label: 100 },
      { value: 200, label: 200 },
    ];
    return temp;
  }, []);

  const columns: TableColumn[] = React.useMemo(() => {
    const temp: TableColumn[] = [
      {
        title: t('CLAIM_EMAIL_LOG_MODAL.SENT'),
        sorteable: true,
        order: 'ASC',
        width: 30,
      },
      {
        title: t('CLAIM_EMAIL_LOG_MODAL.VIEWED'),
        sorteable: true,
        order: 'ASC',
        width: 30,
      },

      {
        title: t('CLAIM_EMAIL_LOG_MODAL.STATUS'),
        width: 30,
        sorteable: true,
      },
      {
        title: t('CLAIMS_PAGE.CLAIMS_RESULT.TABLE.ACTIONS'),
        width: 10,
        sorteable: false,
        justifyContent: 'flex-end',
      },
    ];
    return temp;
  }, [t]);

  const rows = React.useMemo(() => {
    if (items) {
      return items.map(item => ({
        id: item.id,
        sent_at: item.updated_at,
        viewed_at: item.viewed_at && item.viewed_at !== null ? moment(item.viewed_at).format('MM/DD/YYYY') : '-',
        status: item.claims_change_log ? item.claims_change_log.status : item.claim.status,
      }));
    }
    return [];
  }, [items]);

  // Handlers
  const handleResend = useCallback(async emailId => {
    const { success } = await resendClaimEmail(emailId);
    if (success) {
      Toaster('success', t('TOASTER.SUCCESS.CLAIM_EMAIL_RESENT'));
    } else {
      Toaster('error', t('TOASTER.ERROR.CLAIM_EMAIL_RESEND_FAILED'));
    }
  }, []);

  const handleViewClaimEmail = useCallback(emailId => {
    setMailId(emailId);
    openClaimMailModal();
  }, []);

  return (
    <>
      <EmailViewModal
        isOpen={isOpenClaimMailModal}
        toggle={toggleClaimMailModal}
        mailId={mailId || ''}
      />
      <Card
        padding="25px 30px 20px 30px;"
        className="mb-4"
        title={(
          <div className="d-flex justify-content-between align-items-center">
            <CardTitle>{t('CLAIM_EMAIL_LOG_MODAL.TITLE')}</CardTitle>
          </div>
        )}
      >
        <StyledTable
          columns={columns}
          rows={rows.map((row, index) => (
            <StyledTableRow isOdd={index % 2 === 0}>
              <StyledColumn width={columns[0].width}><StyledText>{moment(row.sent_at).format('MM/DD/YYYY')}</StyledText></StyledColumn>
              <StyledColumn width={columns[1].width}><StyledText>{row.viewed_at}</StyledText></StyledColumn>
              <StyledColumn width={columns[2].width}>
                <ThreeStatusBox
                  status={row.status}
                  status1Name="DELIVERED"
                  status1Label={t('CLAIM_EMAIL_LOG_MODAL.LABEL_STATUS_DELIVERED')}
                  status2Name="ERROR"
                  status2Label={t('CLAIM_EMAIL_LOG_MODAL.LABEL_STATUS_ERROR')}
                  status3Name="PENDING"
                  status3Label={t('CLAIM_EMAIL_LOG_MODAL.LABEL_STATUS_PENDING')}
                />
              </StyledColumn>
              <StyledColumn width={columns[3].width}>
                <StyledIcon icon="las la-eye" onClick={() => { handleViewClaimEmail(row.id); }} size={20} color={colors.grey} className="cursor-pointer" />
                {/* Resend Button */}
                <ResendButton
                  type="OUTLINE"
                  onClick={() => { handleResend(row.id); }}
                >
                  {t('CLAIM_EMAIL_LOG_MODAL.RESEND_CTA_BUTTON_LABEL')}
                </ResendButton>
              </StyledColumn>
            </StyledTableRow>
          ))}
        />
        <div className="d-flex justify-content-between my-4">
          {!isMobile && <PageSizeSelector pageSize={pageSize} setPageSize={setPageSize} options={options} />}
          {
            pagination && (
              <DataTablePaginator
                onPage={onChangePage}
                pagination={pagination}
              />
            )
          }
        </div>
      </Card>
    </>
  );
});

export default ClaimEmailsLogList;
