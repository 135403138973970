import React from 'react';
import styled from 'styled-components';

// Theme
import { colors } from 'theme/theme';

// Components
import ColumnHeader from './ColumnHeader';

interface TableHeaderProps {
  columns: TableColumn[],
}

const Container = styled.div`
  background-color: ${colors.softGrey};
  padding: 13px 30px;
  display: flex;
`;

const TableHeader = React.memo(({ columns }: TableHeaderProps) => (
  <Container>
    {columns.map(column => (
      <ColumnHeader
        width={column.width}
        title={column.title}
        sorteable={column.sorteable}
        order={column.order}
        justifyContent={column.justifyContent}
        onClick={column.onClick}
      />
    ))}
  </Container>
));

export default TableHeader;
