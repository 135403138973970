import styled from 'styled-components';

// Components
import CustomModal from 'modals/CustomModal';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

export const StyledModal = styled(CustomModal)``;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const StyledDropdown = styled(Dropdown)``;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const AddOrEditButton = styled(Button)`
  width: 100%;
  max-width: 124px;
`;
