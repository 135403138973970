import { gql } from '@apollo/client';

export const getMaxOrderByComponentAndSchool = gql`
  query MaxOrderByComponentAndSchool(
    $schoolId: uuid!,
    $component: String,
    ) {
    schools_dashboard_elements_order_aggregate (
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: $component}
      }
    ){
      aggregate {
        max {
          maxOrder: order
        }
      }
    }
  }
`;

export const getMaxOrderAndElement = gql`
  query getMaxOrderAndElement(
    $elementId: uuid!,
    $schoolId: uuid!,
    $component: String,
    ) {
      third_party_administrators_dashboard_elements_by_pk(id: $elementId) {
        id
        component
        third_party_administrator{
          id
          schools_third_party_administrators(where: { school_id: {_eq: $schoolId}}){
            school_id
          }
        }
      }
    schools_dashboard_elements_order_aggregate (
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: $component}
      }
    ){
      aggregate {
        max {
          maxOrder: order
        }
      }
    }
  }
`;

export const getMaxOrderAllComponentaBySchool = gql`
  query MaxOrderAllComponentsBychool(
    $schoolId: uuid!,
    ) {
    max_risk: schools_dashboard_elements_order_aggregate (
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: "RISK_MANAGEMENT"}
      }
    ){
      aggregate {
        max {
          maxOrder: order
        }
      }
    }
    max_forms: schools_dashboard_elements_order_aggregate (
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: "FORMS"}
      }
    ){
      aggregate {
        max {
          maxOrder: order
        }
      }
    }
    max_policy: schools_dashboard_elements_order_aggregate (
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: "POLICY_AND_REPORTS"}
      }
    ){
      aggregate {
        max {
          maxOrder: order
        }
      }
    }
    max_contact: schools_dashboard_elements_order_aggregate (
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: "CONTACT_INFORMATION"}
      }
    ){
      aggregate {
        max {
          maxOrder: order
        }
      }
    }
    max_claims: schools_dashboard_elements_order_aggregate (
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: "CLAIMS_MANAGEMENT"}
      }
    ){
      aggregate {
        max {
          maxOrder: order
        }
      }
    }
    max_programs: schools_dashboard_elements_order_aggregate (
      where: {
        school_id: {_eq: $schoolId}, 
        component: {_eq: "VALUE_ADDED_PROGRAMS"}
      }
    ){
      aggregate {
        max {
          maxOrder: order
        }
      }
    }
  } 
`;
