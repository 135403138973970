/* eslint-disable no-console */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';

// Data
import {
  addSchoolNoteToGetSchoolById,
  createSchoolNote,
  updateSchoolNote,
} from 'data/schools_notes';

// Utils
import { Toaster } from 'utils/toaster';

// Hooks
import useAuth from 'hooks/useAuth';

// Styles
import {
  MainContainer,
  TopContainer,
  StyledTextArea,
  BottomContainer,
  CancelButton,
  UpdateButton,
} from './style';

export interface NotesTabProps {
  className?: string;
  school: School;
}

const notesSchema = Yup.object({
  notes: Yup.string().notRequired().max(2048, 'INVALID_NOTES'),
}).defined();

const getDefaultValues = function getDefaultValues(school: School): CommonJSON {
  return {
    notes: (school.schools_notes && school.schools_notes[0]) ? school.schools_notes[0].note : '',
  };
};

const NotesTab = React.memo(({
  className,
  school,
}: NotesTabProps) => {
  // Hooks
  const { t } = useTranslation();
  const { user } = useAuth();
  const {
    control,
    errors,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(notesSchema),
    defaultValues: getDefaultValues(school),
  });

  // Mutations
  const [callCreateSchoolNote, {
    loading: loadingCreateSchoolNote,
  }] = useMutation<CreateSchoolNoteResponse, CreateSchoolNoteVariables>(createSchoolNote, {
    update: (cache, result: { data?: CreateSchoolNoteResponse | null }) => {
      if (result.data && result.data.insert_schools_notes_one) {
        addSchoolNoteToGetSchoolById(
          cache,
          result.data.insert_schools_notes_one,
          school.id,
        );
      }
    },
    onCompleted: () => {
      Toaster('success', t('TOASTER.SUCCESS.SCHOOL_NOTE_CREATED'));
    },
    onError: _error => {
      console.error('Error [createSchoolNote]', _error);
      Toaster('error', t('TOASTER.ERROR.SCHOOL_NOTE_CREATION_FAILED'));
    },
  });

  const [callUpdateSchoolNote, {
    loading: loadingUpdateSchoolNote,
  }] = useMutation<UpdateSchoolNoteResponse, UpdateSchoolNoteVariables>(updateSchoolNote, {
    onCompleted: () => {
      Toaster('success', t('TOASTER.SUCCESS.SCHOOL_NOTE_UPDATED'));
    },
    onError: _error => {
      console.error('Error [updateSchoolNote]', _error);
      Toaster('error', t('TOASTER.ERROR.SCHOOL_NOTE_UPDATE_FAILED'));
    },
  });

  // Handlers
  const handleUpdate = React.useCallback((data: CommonJSON) => {
    if (user) {
      if (school.schools_notes && school.schools_notes.length > 0) {
        // Update
        const variables: UpdateSchoolNoteVariables = {
          schoolNoteId: school.schools_notes[0].id,
          input: {
            note: data.notes || '',
            updated_at: new Date(),
          },
        };

        callUpdateSchoolNote({ variables });
      } else {
        // Create
        const variables: CreateSchoolNoteVariables = {
          object: {
            note: data.notes || '',
            school_id: school.id,
            created_by: user.id,
            created_at: new Date(),
            updated_at: new Date(),
          },
        };

        callCreateSchoolNote({ variables });
      }
    }
  }, [
    school,
    user,
    callCreateSchoolNote,
    callUpdateSchoolNote,
  ]);

  const handleCancel = React.useCallback(() => {
    reset(getDefaultValues(school));
  }, [reset, school]);

  // Memos
  const loading = React.useMemo(
    () => loadingCreateSchoolNote || loadingUpdateSchoolNote,
    [loadingCreateSchoolNote, loadingUpdateSchoolNote],
  );

  return (
    <>
      <MainContainer
        className={classnames(className)}
      >
        {/* Top Container */}
        <TopContainer>
          <Controller
            control={control}
            name="notes"
            render={({ onChange, value, name }) => (
              <StyledTextArea
                name={name}
                title={t('SCHOOLS_MANAGEMENT_PAGE.TAB_NOTES.FORM.NOTES_LABEL')}
                placeholder=""
                maxLength={2048}
                rows={5}
                error={errors.notes !== undefined}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </TopContainer>

        {/* Bottom Container */}
        <BottomContainer>
          {/* Cancel */}
          <CancelButton
            type="SECONDARY"
            disabled={loading}
            onClick={handleCancel}
          >
            {t('SCHOOLS_MANAGEMENT_PAGE.TAB_NOTES.CTA_CANCEL_LABEL')}
          </CancelButton>

          {/* Update */}
          <UpdateButton
            type="PRIMARY"
            disabled={loading}
            onClick={handleSubmit(handleUpdate)}
          >
            {t('SCHOOLS_MANAGEMENT_PAGE.TAB_NOTES.CTA_UPDATE_LABEL')}
          </UpdateButton>
        </BottomContainer>
      </MainContainer>
    </>
  );
});

export default NotesTab;
