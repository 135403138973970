import React from 'react';
import { useLazyQuery } from '@apollo/client';

// Components
import PageLayout from 'components/PageLayout';
import SearchSalesRepCard from 'components/SearchSalesRepCard';
import { getPagination } from 'utils/paginationHelper';
import SalesRepsListCard from 'components/SalesRepsListCard';

// Queries
import { getSalesRepsForGrid } from 'data/salesRep';

const SalesRepsPage = React.memo(() => {
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [filters, setFilters] = React.useState<SalesRepFilters>();
  const [sortByGql, setSortByGql] = React.useState<any>({ first_name: 'asc', last_name: 'asc' });

  // Refs
  const lastPaginationVariables = React.useRef<GetSalesRepsGridVariables | null>(null);

  // Queries
  const [salesRepsQuery, {
    data: salesRepsData,
    refetch: refetchSalesRepsData,
  }] = useLazyQuery<GetSalesRepsGridResponse, GetSalesRepsGridVariables>(getSalesRepsForGrid, {
    fetchPolicy: 'network-only',
  });

  // Effects
  React.useEffect(() => {
    const and: CommonJSON[] = [];
    if (filters) {
      const {
        firstName,
        lastName,
        emailAddress,
      } = filters;

      if (firstName) {
        and.push({
          first_name: { _ilike: `%${firstName}%` },
        });
      }

      if (lastName) {
        and.push({
          last_name: { _ilike: `%${lastName}%` },
        });
      }

      if (emailAddress) {
        and.push({
          email_address: { _ilike: `%${emailAddress}%` },
        });
      }
    }
    and.push({
      is_active: { _eq: true },
    });

    and.push({
      deleted_at: { _is_null: true },
    });

    const where = {
      _and: and,
    };

    const variables = {
      where,
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
      order_by: sortByGql,
    };

    lastPaginationVariables.current = variables;
    salesRepsQuery({ variables });
  }, [
    pageSize,
    currentPage,
    filters,
    sortByGql,
    salesRepsQuery,
  ]);

  // Handlers
  const handleOnSearch = React.useCallback((newFilters?: SalesRepFilters) => {
    setFilters(newFilters);
  }, []);

  const handleOnPageChange = React.useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  const handleOnPageSizeChange = React.useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  const handleSort = React.useCallback(column => {
    let auxSortGql = {};
    switch (column) {
      case 'name':
        auxSortGql = {
          first_name: (sortByGql.first_name && sortByGql.first_name === 'desc') ? 'asc' : 'desc',
          last_name: (sortByGql.last_name && sortByGql.last_name === 'desc') ? 'asc' : 'desc',
        };
        break;
      default: // email_address and updated_at
        auxSortGql = { [column]: (sortByGql[column] && sortByGql[column] === 'desc') ? 'asc' : 'desc' };
        break;
    }
    setSortByGql(auxSortGql);
  }, [sortByGql]);

  const {
    salesReps,
    pagination,
  } = React.useMemo(() => {
    const out: {
      salesReps?: SalesRepGrid[],
      pagination?: Pagination,
    } = {
      salesReps: [],
      pagination: undefined,
    };
    if (salesRepsData) {
      out.salesReps = salesRepsData.sales_reps;
      const totalItems = salesRepsData.sales_reps_aggregate.aggregate.totalCount;
      out.pagination = getPagination(currentPage, totalItems, pageSize);
    }
    return out;
  }, [
    salesRepsData,
    currentPage,
    pageSize,
  ]);

  return (
    <PageLayout
      crumbs={[]}
    >
      {/* Sales Rep Filter */}
      <SearchSalesRepCard
        onSearch={handleOnSearch}
      />

      {/* Sales Rep Results */}
      <SalesRepsListCard
        items={salesReps}
        pageSize={pageSize}
        pagination={pagination}
        setPageSize={handleOnPageSizeChange}
        onChangePage={handleOnPageChange}
        lastPaginationVariables={lastPaginationVariables.current ? lastPaginationVariables.current : undefined}
        refetchSalesRepsData={refetchSalesRepsData}
        sortBy={sortByGql}
        onSort={handleSort}
      />
    </PageLayout>
  );
});

export default SalesRepsPage;
