// Public Pages
import LoginPage from 'containers/LoginPage';
import ResetPasswordPage from 'containers/ResetPasswordPage';
import SchoolsPage from 'containers/SchoolsPage';
import SchoolsManagementPage from 'containers/SchoolsManagementPage';
import ConfirmAccountPage from 'containers/ConfirmAccountPage';
// Private Pages
import DashboardPage from 'containers/DashboardPage';
import ReportNewClaimPage from 'containers/ReportNewClaimPage';
import ReportedClaimsPage from 'containers/ReportedClaimsPage';
import ViewClaimPage from 'containers/ViewClaimPage';
import TPAsPage from 'containers/TPAsPage';
import NewTPAPage from 'containers/NewTPAPage';
import SalesRepsPage from 'containers/SalesRepsPage';
import NewSalesRepPage from 'containers/NewSalesRepPage';
import ClaimsPage from 'containers/ClaimsPage';
import ClaimsEmailsLogPage from 'containers/ClaimEmailsLogPage';
import StudentsPage from 'containers/StudentsPage';
import ImportStudentsPage from 'containers/ImportStudentsPage';
import NewStudentPage from 'containers/NewStudentPage';
import Activities from 'containers/Activities';

// Utils
import { availableRoutes } from './routesList';

const routes: SCRouter.RouteStructure = {
  public: [
    {
      route: availableRoutes.login,
      label: 'Login',
      container: LoginPage,
    },
    {
      route: availableRoutes.resertPassword,
      label: 'Reset Password',
      container: ResetPasswordPage,
    },
    {
      route: availableRoutes.confirmAccount,
      label: 'Confirm Account',
      container: ConfirmAccountPage,
    },
  ],
  private: {
    user: [
      {
        route: availableRoutes.dashboard,
        label: 'Dashboard',
        container: DashboardPage,
      },
      {
        route: availableRoutes.newSchoolClaim,
        label: 'Report a New Claim',
        container: ReportNewClaimPage,
      },
      {
        route: availableRoutes.editSchoolClaim,
        label: 'Edit Claim',
        container: ReportNewClaimPage,
      },
      {
        route: availableRoutes.viewSchoolClaim,
        label: 'View Claim',
        container: ViewClaimPage,
      },
      {
        route: availableRoutes.viewSchoolClaimEmails,
        label: 'View Claim Emails Log',
        container: ClaimsEmailsLogPage,
      },
      {
        route: availableRoutes.schoolClaims,
        label: 'Reported Claims',
        container: ReportedClaimsPage,
      },
      {
        route: availableRoutes.students,
        label: 'Students',
        container: StudentsPage,
      },
      {
        route: availableRoutes.importStudents,
        label: 'ImportStudents',
        container: ImportStudentsPage,
      },
      {
        route: availableRoutes.newStudent,
        label: 'New Student',
        container: NewStudentPage,
      },
      {
        route: availableRoutes.editStudent,
        label: 'Edit Student',
        container: NewStudentPage,
      },
    ],
    admin: [
      {
        route: availableRoutes.claimsList,
        label: 'Claims',
        container: ClaimsPage,
      },
      {
        route: availableRoutes.dashboard,
        label: 'Dashboard',
        container: DashboardPage,
      },
      {
        route: availableRoutes.schools,
        label: 'Schools',
        container: SchoolsPage,
      },
      {
        route: availableRoutes.newSchoolClaim,
        label: 'Report a New Claim',
        container: ReportNewClaimPage,
      },
      {
        route: availableRoutes.editSchoolClaim,
        label: 'Edit Claim',
        container: ReportNewClaimPage,
      },
      {
        route: availableRoutes.viewClaim,
        label: 'View Claim',
        container: ViewClaimPage,
      },
      {
        route: availableRoutes.viewSchoolClaim,
        label: 'View Claim',
        container: ViewClaimPage,
      },
      {
        route: availableRoutes.viewClaimEmails,
        label: 'View Claim Emails Log',
        container: ClaimsEmailsLogPage,
      },
      {
        route: availableRoutes.viewSchoolClaimEmails,
        label: 'View Claim Emails Log',
        container: ClaimsEmailsLogPage,
      },
      {
        route: availableRoutes.schoolClaims,
        label: 'Reported Claims',
        container: ReportedClaimsPage,
      },
      {
        route: availableRoutes.tpaList,
        label: 'TPA Admin',
        container: TPAsPage,
      },
      {
        route: availableRoutes.tpaNew,
        label: 'TPA New',
        container: NewTPAPage,
      },
      {
        route: availableRoutes.tpaEdit,
        label: 'TPA Edit',
        container: NewTPAPage,
      },
      {
        route: availableRoutes.schools,
        label: 'Schools',
        container: SchoolsPage,
      },
      {
        route: availableRoutes.addSchool,
        label: 'Schools',
        container: SchoolsManagementPage,
      },
      {
        route: availableRoutes.editSchool,
        label: 'Schools',
        container: SchoolsManagementPage,
      },
      {
        route: availableRoutes.salesReps,
        label: 'Sales Reps',
        container: SalesRepsPage,
      },
      {
        route: availableRoutes.newSalesReps,
        label: 'Sales Reps New',
        container: NewSalesRepPage,
      },
      {
        route: availableRoutes.editSalesReps,
        label: 'Sales Reps Edit',
        container: NewSalesRepPage,
      },
      {
        route: availableRoutes.students,
        label: 'Students',
        container: StudentsPage,
      },
      {
        route: availableRoutes.importStudents,
        label: 'ImportStudents',
        container: ImportStudentsPage,
      },
      {
        route: availableRoutes.newStudent,
        label: 'New Student',
        container: NewStudentPage,
      },
      {
        route: availableRoutes.editStudent,
        label: 'Edit Student',
        container: NewStudentPage,
      },
      {
        route: availableRoutes.activities,
        label: 'Activities',
        container: Activities,
      },
    ],
  },
};
export {
  routes,
};
