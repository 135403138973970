import { gql } from '@apollo/client';
import { studentProviderFragment } from 'data/fragments/studentProviders';

export const getStudentsProviders = gql`
  query getStudentsProviders($schoolId: uuid!, $studentId: uuid!) {
    students_providers(
      where: { 
        school_id: { _eq: $schoolId },
        student_id: { _eq: $studentId },
        deleted_at: { _is_null: true }
      }
      order_by:{
        primary:desc_nulls_last,
        updated_at:asc
      }
    ) {
      ...studentProvider
    }
  }
  ${studentProviderFragment}
`;
