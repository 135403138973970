import styled from 'styled-components';

// Components
import Text from 'components/Text';
import SelectStudentResults from './StepSelectStudentResults';

export const StyledSelectStudentResults = styled(SelectStudentResults)`
  margin-top: 20px;
`;

export const Title = styled(Text)``;
