import styled from 'styled-components';
import { theme } from 'theme/theme';

export const MainContainer = styled.div`
  width: 40px;
  height: 40px;
`;

export const StyledCircle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${theme.colors.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.white};
  font-size: 24px;
  font-family: 'Roboto';
  font-weight: ${theme.fontWeights.black};
  line-height: 1;
`;
