import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useQuery } from '@apollo/client';

// Component
import Card from 'components/Card';
import CardTitle from 'components/Card/CardTitle';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import Button, { ButtonTypes } from 'components/Button';

// Data
import {
  getAdminsForDropdown,
} from 'data/user/user.query';

// Hooks
import { useResponsive } from 'hooks/useResponsive';

const initialValues = {
  firstName: '',
  lastName: '',
  submittedBy: { value: '', label: '' },
};

interface SearchSubmittedClaimsProps {
  onSearch: (newFilters: ClaimFilters) => void;
}

const SearchSubmittedClaims = React.memo(({ onSearch }: SearchSubmittedClaimsProps) => {
  const { t } = useTranslation();
  const [searchState, setSearchState] = React.useState<ClaimFilters>(initialValues);
  const { isMobile } = useResponsive();

  const and: CommonJSON[] = [];

  and.push({
    deleted_at: { _is_null: true },
  });

  const where = {
    _and: and,
  };

  // Queries
  const {
    loading: loadingGetAdmins,
    data: dataGetAdmins,
  } = useQuery<GetDropDownUsersResponse, GetDropDownUsersVariables>(getAdminsForDropdown, {
    variables: { where },
  });

  const adminsOptions = React.useMemo(() => {
    if (dataGetAdmins && dataGetAdmins.users) {
      return dataGetAdmins.users.map(item => ({
        value: item.id,
        label: `${item.last_name} ${item.first_name}`,
      }));
    }
    return [];
  }, [dataGetAdmins, loadingGetAdmins]);

  const buttonDisabled = useMemo(() => {
    if (!searchState.firstName && !searchState.lastName && !searchState.academicId
      && !searchState.tpa && (!searchState.submittedBy || searchState.submittedBy.label === '')) {
      return true;
    }
    return false;
  }, [searchState]);

  const handleOnChange = useCallback((key: string, value: string) => {
    setSearchState(state => ({
      ...state,
      [key]: value,
    }));
  }, []);

  const handleSelectChange = useCallback((key: string, value: OptionType | undefined) => {
    setSearchState(state => ({
      ...state,
      [key]: value,
    }));
  }, []);

  const handleClear = useCallback(() => {
    setSearchState(state => ({
      ...state,
      ...initialValues,
    }));
    onSearch(initialValues);
  }, []);

  const handlePressEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(searchState);
    }
  }, [onSearch, searchState]);

  return (
    <Card
      padding="25px 30px 20px 30px;"
      className="mb-4"
      title={(
        <CardTitle>{t('CLAIMS_PAGE.CLAIMS_FILTER.CARD_TITLE')}</CardTitle>
      )}
    >
      <div className="row">
        <div className={`${isMobile ? 'col-12' : 'col-4'}`}>
          <Input
            value={searchState.firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange('firstName', e.target.value)}
            title={t('CLAIMS_PAGE.CLAIMS_FILTER.CLAIMANT_FIRST_NAME_LABEL')}
            className="mb-4"
            icon="las la-user"
            onKeyDown={handlePressEnter}
          />
        </div>
        <div className={`${isMobile ? 'col-12' : 'col-4'}`}>
          <Input
            value={searchState.lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange('lastName', e.target.value)}
            title={t('CLAIMS_PAGE.CLAIMS_FILTER.LAST_NAME_LABEL')}
            className="mb-4"
            icon="las la-user"
            onKeyDown={handlePressEnter}
          />
        </div>
        <div className={`${isMobile ? 'col-12' : 'col-4'}`}>
          <Dropdown
            title={t('CLAIMS_PAGE.CLAIMS_FILTER.SUBMITTED_BY_LABEL')}
            selected={searchState.submittedBy}
            onSelect={handleSelectChange}
            options={adminsOptions}
            name="submittedBy"
          />
        </div>
      </div>
      <div className={`d-flex ${isMobile ? 'justify-content-between mt-4' : 'justify-content-end mt-3'}`}>
        {/* Clear Button */}
        <Button
          onClick={handleClear}
          type={ButtonTypes.SECONDARY}
          className="mr-2"
        >
          {t('CLAIMS_PAGE.CLAIMS_RESULT.CTA_CLEAR_BUTTON_LABEL')}
        </Button>

        <Button
          disabled={buttonDisabled}
          onClick={() => { onSearch(searchState); }}
          type={ButtonTypes.PRIMARY}
          minWidth="100px"
        >
          {t('CLAIMS_PAGE.CLAIMS_FILTER.CTA_SEARCH_BUTTON_LABEL')}
        </Button>
      </div>
    </Card>
  );
});

export default SearchSubmittedClaims;
