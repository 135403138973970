/* eslint-disable no-console */
import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
// import moment from 'moment';

// Data
import {
  getSalesRepsForDropdown,
} from 'data/salesRep';
import {
  getTPAsForDropdown,
} from 'data/tpa';

// Components
import LoadingComponent from 'components/Loading';
import Card from 'components/Card';

// Styles
import { theme } from 'theme/theme';
import {
  Title,
  Grid,
  GridItem,
  BottomContainer,
  TextInput,
  StyledDropdown,
  // StyledDatePicker,
  // DatePickerLabel,
  // CustomLabel,
  // DatePickerContainer,
  SearchButton,
  ClearButton,
} from './style';

export interface SchoolFilterProps {
  className?: string;
  onSearch: (filters: SchoolFilters) => void;
  onClear: () => void;
}

const initialValues = {
  schoolName: '',
  salesRep: undefined,
  tpa: undefined,
  status: '',
  // modifiedFrom: undefined,
  // modifiedTo: undefined,
};

const SchoolFilter = React.memo(({
  className,
  onSearch,
  onClear,
}: SchoolFilterProps) => {
  // Hooks
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    // watch,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      ...initialValues,
      status: {
        label: t('SCHOOLS_PAGE.SCHOOLS_FILTER.STATUS_OPTION_ACTIVE'),
        value: 'active',
      },
    },
  });

  // States
  const [renderCount, setRenderCount] = React.useState<number>(0);

  // Queries
  const {
    loading: loadingGetSalesReps,
    data: dataGetSalesReps,
  } = useQuery<GetSalesRepsResponse, GetSalesRepsVariables>(getSalesRepsForDropdown);

  const {
    loading: loadingGetTPAs,
    data: dataGetTPAs,
  } = useQuery<GetTPAsForDropdownResponse>(getTPAsForDropdown);

  // Handlers
  const handleSearch = React.useCallback((data: CommonJSON) => {
    if (data) {
      const {
        schoolName,
        salesRep,
        tpa,
        status,
        // modifiedFrom,
        // modifiedTo,
      } = data;

      const out = {
        schoolName,
        salesRep: salesRep ? salesRep.value : undefined,
        tpa: tpa ? tpa.value : undefined,
        status: status ? status.value === 'active' : undefined,
        // modifiedFrom: modifiedFrom ? moment(modifiedFrom as string).startOf('day').toDate() : undefined,
        // modifiedTo: modifiedTo ? moment(modifiedTo as string).endOf('day').toDate() : undefined,
      };

      onSearch(out);
    } else {
      onSearch({});
    }
  }, [onSearch]);

  const handleClear = React.useCallback(() => {
    reset({
      ...initialValues,
      status: {
        label: t('SCHOOLS_PAGE.SCHOOLS_FILTER.STATUS_OPTION_ACTIVE'),
        value: 'active',
      },
    });

    setRenderCount(s => s + 1);

    onClear();
  }, [reset, onClear]);

  // Memos
  const optionsStatus = React.useMemo(() => [
    {
      label: t('SCHOOLS_PAGE.SCHOOLS_FILTER.STATUS_OPTION_ACTIVE'),
      value: 'active',
    },
    {
      label: t('SCHOOLS_PAGE.SCHOOLS_FILTER.STATUS_OPTION_INACTIVE'),
      value: 'inactive',
    },
  ], [t]);

  const salesRepsOptions = React.useMemo(() => {
    if (dataGetSalesReps && dataGetSalesReps.sales_reps) {
      return dataGetSalesReps.sales_reps.map(item => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}`,
      }));
    }
    return [];
  }, [dataGetSalesReps]);

  const tpaOptions = React.useMemo(() => {
    if (dataGetTPAs && dataGetTPAs.third_party_administrators) {
      return dataGetTPAs.third_party_administrators.map(tpa => ({
        value: tpa.id || '',
        label: tpa.code,
      }));
    }
    return [];
  }, [dataGetTPAs]);

  const handlePressEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      padding="25px 30px 20px;"
      className={classnames(className)}
    >
      {/* Title */}
      <Title
        size={18}
        fontFamily={theme.fontFamilies.roboto.bold}
        color={theme.colors.dark}
        weight={theme.fontWeights.bold}
      >
        {t('SCHOOLS_PAGE.SCHOOLS_FILTER.CARD_TITLE')}
      </Title>

      {/* Grid */}
      <Grid
        key={renderCount}
      >
        {/* Grid Item: School Name */}
        <GridItem>
          <TextInput
            ref={register({ required: false })}
            name="schoolName"
            title={t('SCHOOLS_PAGE.SCHOOLS_FILTER.SCHOOL_NAME_LABEL')}
            icon="las la-user"
            onKeyDown={handlePressEnter}
          />
        </GridItem>

        {/* Grid Item: Sales Representative */}
        <GridItem>
          <Controller
            control={control}
            name="salesRep"
            render={({ onChange, value, name }) => (
              <>
                {
                  loadingGetSalesReps ? (
                    <LoadingComponent />
                  ) : (
                    <StyledDropdown
                      title={t('SCHOOLS_PAGE.SCHOOLS_FILTER.SALES_REPRESENTATIVE_LABEL')}
                      name={name}
                      selected={value}
                      options={salesRepsOptions}
                      onSelect={(key, v) => onChange(v)}
                    />
                  )
                }
              </>
            )}
          />
        </GridItem>

        {/* Grid Item: TPA */}
        <GridItem>
          <Controller
            control={control}
            name="tpa"
            render={({ onChange, value, name }) => (
              <>
                {
                  loadingGetTPAs ? (
                    <LoadingComponent />
                  ) : (
                    <StyledDropdown
                      title={t('SCHOOLS_PAGE.SCHOOLS_FILTER.TPA_LABEL')}
                      name={name}
                      selected={value}
                      options={tpaOptions}
                      onSelect={(key, v) => onChange(v)}
                    />
                  )
                }
              </>
            )}
          />
        </GridItem>

        {/* Grid Item: Status */}
        <GridItem>
          <Controller
            control={control}
            name="status"
            render={({
              onChange, value, name,
            }) => (
              <StyledDropdown
                title={t('SCHOOLS_PAGE.SCHOOLS_FILTER.STATUS_LABEL')}
                selected={value}
                name={name}
                onSelect={(key: string, v?: OptionType) => onChange(v)}
                options={optionsStatus}
              />
            )}
          />
        </GridItem>

        {/* Grid Item: Modified */}
        <GridItem
          className="modified-inputs"
        >
          {/* Custom Label */}
          {/* <CustomLabel
            size={14}
            fontFamily={theme.fontFamilies.roboto.regular}
            color={theme.colors.grey}
            weight={theme.fontWeights.normal}
          >
            {t('SCHOOLS_PAGE.SCHOOLS_FILTER.MODIFIED_LABEL')}
          </CustomLabel> */}

          {/* <DatePickerContainer>
            <DatePickerLabel
              className="from-label"
              size={14}
              fontFamily={theme.fontFamilies.roboto.regular}
              color={theme.colors.grey}
              weight={theme.fontWeights.normal}
            >
              {t('SCHOOLS_PAGE.SCHOOLS_FILTER.MODIFIED_FROM_LABEL')}
            </DatePickerLabel>
            <Controller
              control={control}
              name="modifiedFrom"
              render={({
                onChange, value, name,
              }) => (
                <StyledDatePicker
                  title=""
                  name={name}
                  startDate={value}
                  setStartDate={(key: string, date: Date | null | [Date, Date]) => onChange(date)}
                  maxDate={watch('modifiedTo') ? watch('modifiedTo') : undefined}
                />
              )}
            />
            <DatePickerLabel
              className="to-label"
              size={14}
              fontFamily={theme.fontFamilies.roboto.regular}
              color={theme.colors.grey}
              weight={theme.fontWeights.normal}
            >
              {t('SCHOOLS_PAGE.SCHOOLS_FILTER.MODIFIED_TO_LABEL')}
            </DatePickerLabel>
            <Controller
              control={control}
              name="modifiedTo"
              render={({
                onChange, value, name,
              }) => (
                <StyledDatePicker
                  title=""
                  name={name}
                  startDate={value}
                  setStartDate={(key: string, date: Date | null | [Date, Date]) => onChange(date)}
                  minDate={watch('modifiedFrom') ? watch('modifiedFrom') : undefined}
                />
              )}
            />
          </DatePickerContainer> */}
        </GridItem>

      </Grid>

      {/* Bottom Container */}
      <BottomContainer>
        {/* Clear Button */}
        <ClearButton
          type="SECONDARY"
          onClick={handleClear}
        >
          {t('SCHOOLS_PAGE.SCHOOLS_FILTER.CTA_CLEAR_BUTTON_LABEL')}
        </ClearButton>

        {/* Search Button */}
        <SearchButton
          type="PRIMARY"
          onClick={handleSubmit(handleSearch)}
        >
          {t('SCHOOLS_PAGE.SCHOOLS_FILTER.CTA_SEARCH_BUTTON_LABEL')}
        </SearchButton>
      </BottomContainer>
    </Card>
  );
});

export default SchoolFilter;
