import { gql } from '@apollo/client';

import {
  schoolComplexFragment,
  userSoftFragment,
  schoolsTPAFragment,
  schoolNoteFragment,
} from 'data/fragments';

export const createSchool = gql`
  mutation createSchool($object: schools_insert_input!) {
    insert_schools_one(object: $object) {
      ...school
    }
  }
  ${schoolComplexFragment}
`;

export const updateSchool = gql`
  mutation updateSchool($schoolId: uuid!, $input: schools_set_input) {
    update_schools_by_pk (pk_columns: { id: $schoolId }, _set: $input) {
      ...school
    }
  }
  ${schoolComplexFragment}
`;

export const deleteSoftSchool = gql`
  mutation deleteSoftSchool($schoolId: uuid!) {
    # Soft Delete the School
    update_schools_by_pk (pk_columns: { id: $schoolId }, _set: { deleted_at: "now()" }) {
      ...school
    }

    # Soft Delete Users
    update_users (where: { school_id: { _eq: $schoolId }}, _set: { deleted_at: "now()" }) {
      returning {
        ...user
      }
    }

    # Soft Delete TPA
    update_schools_third_party_administrators (where: { school_id: { _eq: $schoolId }}, _set: { deleted_at: "now()" }) {
      returning {
        ...schoolsTPA
      }
    }

    # Soft Delete Notes
    update_schools_notes (where: { school_id: { _eq: $schoolId }}, _set: { deleted_at: "now()" }) {
      returning {
        ...schoolNote
      }
    }
  }
  ${schoolComplexFragment}
  ${userSoftFragment}
  ${schoolsTPAFragment}
  ${schoolNoteFragment}
`;
