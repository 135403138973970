import styled from 'styled-components';
// import { theme } from 'theme/theme';

// Components
import Button from 'components/Button';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PrevIcon = styled.i`
  font-size: 18px;
  margin-right: 12px;
`;

export const PrevButton = styled(Button)`
  justify-self: flex-start;
  width: 100%;
  max-width: 100px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  line-height: 46px;
`;

export const NextIcon = styled.i`
  font-size: 18px;
  margin-left: 12px;
`;

export const NextButton = styled(Button)`
  justify-self: flex-end;
  width: 100%;
  max-width: 100px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  line-height: 46px;
`;
