/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionsType } from 'react-select';

// Hooks
import { useResponsive } from 'hooks/useResponsive';
import useStateContext from 'hooks/useStateContext';

// Queries
import { getStudentById } from 'data/student';

// Data
import { ReportNewClaimSteps } from 'data/static/ReportNewClaimSteps';
import states from 'data/static/states.json';
import provinces from 'data/static/provinces.json';

// Components
import Card from 'components/Card';
import StudentView from './StudentView/StudentView';
import StudentOptionInformation from './StudentOptionInformation';
import { StyledStepButtons } from './style';

const StepViewStudent = React.memo(() => {
  type StateKey = keyof typeof states;
  type ProvinceKey = keyof typeof provinces;

  const stateOptions: OptionsType<OptionType> = Object.keys(states)
    // @ts-ignore
    .map((key: StateKey) => ({ value: key, label: states[key] }));

  const provinceOptions: OptionsType<OptionType> = Object.keys(provinces)
    // @ts-ignore
    .map((key: ProvinceKey) => ({ value: key, label: provinces[key] }));

  // Hooks
  const { isMobile } = useResponsive();

  const {
    data,
    setData,
    step,
    setStep,
  } = useStateContext();

  // Queries
  const [callGetStudent, {
    loading: loadingGetStudent,
    data: dataGetStudent,
  }] = useLazyQuery<GetStudentResponse, GetStudentVariables>(getStudentById);

  useEffect(() => {
    if (data?.formStepSelectStudent) {
      callGetStudent({
        variables: { studentId: data.formStepSelectStudent.studentId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (dataGetStudent
      && !loadingGetStudent
      && (
        !data?.fromStateViewStudent
        || (data?.fromStateViewStudent.student.id !== data.formStepSelectStudent.studentId))) {
      const stateProv = dataGetStudent.students_by_pk.country === 'Canada'
        ? provinceOptions.find(prov => prov.value === dataGetStudent.students_by_pk.state_prov
          || prov.label === dataGetStudent.students_by_pk.state_prov)
        : stateOptions.find(state => state.value === dataGetStudent.students_by_pk.state_prov
          || state.label === dataGetStudent.students_by_pk.state_prov);
      setData((s: ReportNewClaimWizard) => ({
        ...s,
        fromStateViewStudent: {
          student: {
            ...dataGetStudent.students_by_pk,
            state_prov: stateProv?.label,
          },
          studentUpdated: false,
          unmodifiedData: {
            student: {
              ...dataGetStudent.students_by_pk,
              state_prov: stateProv?.label,
            },
            studentUpdated: false,
          },
        },
      }));
    }
  }, [dataGetStudent, loadingGetStudent, setData, data]);

  // Handlers
  const handlePrev = React.useCallback(() => {
    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_SELECT_STUDENT,
    }));
  }, [setStep]);

  const handleNext = React.useCallback(() => {
    setData((s: ReportNewClaimWizard) => ({
      ...s,
      fromStateViewStudent: {
        ...s.fromStateViewStudent,
        unmodifiedData: {
          ...s.fromStateViewStudent,
        },
      },
    }));
    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_FILL_INSURANCE_INFORMATION,
    }));
  }, [setStep]);

  const handleCancel = React.useCallback(() => {
    // Restore unmodified data
    setData((s: ReportNewClaimWizard) => ({
      ...s,
      fromStateViewStudent: {
        ...s.fromStateViewStudent,
        ...data?.fromStateViewStudent?.unmodifiedData!,
      },
    }));

    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_CLAIM_PREVIEW,
      editMode: false,
    }));
  }, [setStep]);

  const handleSave = React.useCallback(() => {
    // Change the step
    setStep((s: Step) => ({
      ...s,
      step: ReportNewClaimSteps.STEP_CLAIM_PREVIEW,
      editMode: false,
    }));
  }, [setStep]);

  return (
    <Card padding="25px 34px 20px;">
      <StudentView />

      <StudentOptionInformation />

      {/* Step Buttons */}
      <StyledStepButtons
        $isMobile={isMobile}
        showPrev
        showNext
        onPrev={step?.editMode ? handleCancel : handlePrev}
        onNext={step?.editMode ? handleSave : handleNext}
        editMode={step?.editMode}
      />

    </Card>
  );
});
export default StepViewStudent;
